import { Col, Modal, Row, Upload, UploadProps, Image, GetProp, UploadFile } from 'antd';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import React from 'react';
import { getUserProfile, patchUserProfile } from '../../../Actions/UsersManagementActions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import Avatar from '../../../../assets/images/Avatar.png';
import { getCompanyDetails } from '../../../Actions/OnboardingActions';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import MailIcon from '../../../../Components/BEIcons/MailIcon';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export interface IProfileModalProps {
    open: boolean;
    setOpen: Function;
}

export function ProfileModal(props: IProfileModalProps) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    const userName = userInfo?.first_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : `${userInfo?.username}`;
    const userRole = useTypedSelector((state) => state.user.userRole) as string;
    const dispatch = useAppDispatch();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const userProfile = useTypedSelector((state) => state.user.userProfile);
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        if (userProfile?.status === 'idle')
            dispatch(getUserProfile());
    }, []);

    React.useEffect(() => {
        const data: UploadFile = {
            uid: '1',
            name: 'user.png',
            status: 'done',
            url: userProfile?.data?.profile_pic,
        }
        setFileList([data]);
    }, [userProfile]);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        console.log(file);
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <Modal
            title="My Profile"
            closable={true}
            open={props.open}
            footer={null}
            onCancel={() => props.setOpen(false)}
        >
            <Row style={{ padding: "1rem 0" }} gutter={[10, 10]}>
                <Col span={6}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "1.5rem" }}>
                        <Upload
                            listType="picture-circle"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList?.length == 1 ? null : uploadButton}
                        </Upload>
                        {previewImage && (
                            <Image
                                style={{ width: "100px" }}
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                </Col>
                <Col offset={1} span={17}>
                    <Row style={{ margin: "1rem 0" }}>
                        <p style={{ fontSize: "0.8rem", color: PrimaryTheme.primaryGray }}>Name</p>
                        <BEInput name='name' style={{ fontWeight: "600", color: "black", opacity: "1" }} value={userName} disabled />
                    </Row>
                    <Row style={{ margin: "1rem 0" }}>
                        <p style={{ fontSize: "0.8rem", color: PrimaryTheme.primaryGray }}>Role</p>
                        <BEInput name='role' style={{ fontWeight: "600", color: "black", opacity: "1" }} color={PrimaryTheme.primaryBlack} value={userRole} disabled />
                    </Row>
                </Col>
            </Row>
            <Row justify={"end"}>
                <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                    <BEButton
                        onClick={() => props.setOpen(false)}
                        size='large'>
                        Close
                    </BEButton>
                    <BEButton
                        size='large'
                        className='primary'
                        loading={load}
                        onClick={async () => {
                            let data = {};
                            let isFile = false;
                            if (fileList?.length === 0) {
                                data = {
                                    profile_pic: null
                                }
                            } else {
                                const fileItem = fileList[0].originFileObj as FileType
                                data = {
                                    profile_pic: fileItem
                                }
                                isFile = true;
                            }
                            setLoad(true);
                            await dispatch(patchUserProfile(data, isFile));
                            setLoad(false);
                            props.setOpen(false);
                        }}
                    >
                        Save
                    </BEButton>
                </div>
            </Row>

        </Modal>
    );
}

interface IUserProfileModalProps {
    open: boolean;
    setOpen: Function;
}

export function UserProfileModal(props: IUserProfileModalProps) {
    const dispatch = useAppDispatch();
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    const userName = userInfo?.first_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : `${userInfo?.username}`;
    const userRole = useTypedSelector((state) => state.user.userRole) as string;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const userProfile = useTypedSelector((state) => state.user.userProfile);
    const companyDetails = useTypedSelector((state) => state.companies.companyDetails)
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        if (companyDetails?.status === 'idle') dispatch(getCompanyDetails());
    }, [companyDetails]);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        console.log(file);
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );
    React.useEffect(() => {
        if (userProfile?.status === 'idle')
            dispatch(getUserProfile());
    }, []);

    React.useEffect(() => {
        const data: UploadFile = {
            uid: '1',
            name: 'user.png',
            status: 'done',
            url: userProfile?.data?.profile_pic,
        }
        setFileList([data]);
    }, [userProfile]);

    const aboutItems = [
        {
            key: '1',
            label: 'Role',
            icon: <UsersIcon fill={PrimaryTheme.primaryGray} inheritSize />,
            value: userRole,
        },
        {
            key: '2',
            label: 'Email address',
            icon: <MailIcon fill={PrimaryTheme.primaryGray} inheritSize />,
            value: userInfo?.email,
        },
        // {
        //     key: '3',
        //     label: 'Joined on',
        //     icon: <UsersIcon fill={PrimaryTheme.primaryGray} inheritSize />,
        //     value: userInfo?.email,
        // },
    ]
    return (
        <Modal
            width={'450px'}
            title="My Profile"
            closable={true}
            open={props.open}
            footer={null}
            onCancel={() => props.setOpen(false)}
        >
            <>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', paddingBottom: '1rem' }}>
                    <div
                        className='user-picture'
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={() => false}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList?.length == 1 ? null : uploadButton}
                        </Upload>
                        {previewImage && (
                            <Image
                                className='preview-image'
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                    <div>
                        <h3>{userName}</h3>
                        <p>{companyDetails?.data?.firm_name}</p>
                    </div>
                </div>
                <div
                    style={{
                        borderTop: `1px solid ${PrimaryTheme.primaryGreyLight}`,
                        paddingTop: '1rem'
                    }}
                >
                    <p style={{ fontWeight: 500, color: PrimaryTheme.primaryGray }}>About</p>
                    {
                        aboutItems.map((item) =>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
                                <div
                                    style={{
                                        height: '1.2rem',
                                        width: '1.2rem',
                                        backgroundColor: PrimaryTheme.primaryGreyLight,
                                        padding: '0.5rem',
                                        borderRadius: '7px',
                                    }}
                                >
                                    {item.icon}
                                </div>
                                <div>
                                    <p
                                        style={{
                                            fontSize: '10px',
                                            color: PrimaryTheme.primaryGray
                                        }}
                                    >
                                        {item.label}
                                    </p>
                                    <p>{item.value}</p>
                                </div>

                            </div>
                        )
                    }
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    borderTop: `1px solid ${PrimaryTheme.primaryGreyLight}`,
                    marginTop: '1rem',
                    paddingTop: '1rem'
                }}>

                    <BEButton
                        size='large'
                        className='primary'
                        loading={load}
                        onClick={async () => {
                            let data = {};
                            let isFile = false;
                            if (fileList?.length === 0) {
                                data = {
                                    profile_pic: null
                                }
                            } else {
                                const fileItem = fileList[0].originFileObj as FileType
                                data = {
                                    profile_pic: fileItem
                                }
                                isFile = true;
                            }
                            setLoad(true);
                            await dispatch(patchUserProfile(data, isFile));
                            setLoad(false);
                            props.setOpen(false);
                        }}
                    >
                        Save changes
                    </BEButton>
                    <BEButton
                        onClick={() => props.setOpen(false)}
                        size='large'>
                        Close
                    </BEButton>
                </div>
            </>
        </Modal>
    );
}
