import * as React from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCurrentBusinessGroup, setCurrentBusinessUnit } from '../../../../Redux/CommonReducer';
import { getBusinessGroups } from '../../../Actions/BusinessGroupActions';
import { getBusinessUnitRefs, getBusinessUnits, getContributorBusinessUnits } from '../../../Actions/BusinessUnitActions';
import { getCompanyDetails } from '../../../Actions/OnboardingActions';
import { BusinessUnitType, businessGroupType } from '../../../../Redux/Types/entityTypes';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import ArrowDownIcon from '../../../../Components/BEIcons/ArrowDownIcon';
import BGIcon from '../../.././../assets/images/businessUnitVector.png'
import RightArrow from '../../../../assets/icons/RightArrow.svg'
import BENoBuModal from '../../../../Components/BENoBuModal';
import { logoutAction } from '../../../Actions/LoginActions';
import { BENotify } from '../../../../Components/BENotify';
import { getRecentlyViewedGroups, getRecentlyViewedUnits, patchRecentlyViewedGroups, patchRecentlyViewedUnits, postRecentlyViewedGroups, postRecentlyViewedUnits } from '../../../Actions/RecentlyViewedActions';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { GlobalLoader } from '../../../../Components/GlobalLoader';

export interface IEntitySelectCascaderProps {
}

export function EntitySelectCascader(props: IEntitySelectCascaderProps) {

    const location = useLocation();
    const dispatch = useAppDispatch();
    const businessGroups = useTypedSelector(state => state.entity.BusinessGroups);
    const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const businessUnifRef = useTypedSelector(state => state.entity.BusinessUnitRefs);
    const companyDetails = useTypedSelector(state => state.companies.companyDetails);
    const recentlyViewedUnits = useTypedSelector(state => state.entity.RecentlyViewedUnits);
    const recentlyViewedGroups = useTypedSelector(state => state.entity.RecentlyViewedGroups);
    const [cascader, setShowCascader] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const [showDropdown, setShowDropdown] = React.useState(true);
    const [selectedValue, setSelectedValue] = React.useState<string>('');
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [isInputHovered, setIsInputHovered] = React.useState(false);
    const role = useTypedSelector((state) => state.user.userRole);
    const [filteredBg, setfilteredBg] = React.useState<businessGroupType[]>([]);
    const [filteredBu, setFilteredBu] = React.useState<BusinessUnitType[]>([]);
    const [recent, setRecent] = React.useState<any[]>([]);
    const [userName, setUserName] = React.useState<string>('');
    const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false);
    const [prevSearchTerm, setPrevSearchTerm] = React.useState<string>('');
    const [showBu, setShowBu] = React.useState<boolean>();
    const [load, setLoad] = React.useState<boolean>(false);
    const contributorBusinessUnitRef = useTypedSelector(state => state.entity.BusinessUnitContributors);
    const currentBusinessGroup = useTypedSelector((state) => state.common.homePage.currentBusinessGroup);
    const currentBusinessUnit = useTypedSelector((state) => state.common.homePage.currentBusinessUnit);


    // All Functions ******************************************************************************************************************
    const handleSearchChange = (event?: React.ChangeEvent<HTMLInputElement>) => {

        setIsInputHovered(true);
        const newSearchTerm = event ? event.target.value : prevSearchTerm;
        setPrevSearchTerm(newSearchTerm)
        setSearchTerm(newSearchTerm);

        const filteredBusinessGroups = businessGroups?.data.filter((item) =>
            item.name?.toLowerCase().includes(newSearchTerm?.toLowerCase())
        );

        if (filteredBusinessGroups?.length > 1) {
            filteredBusinessGroups.sort(customSort);
        }

        setfilteredBg(filteredBusinessGroups);
        let itemsBU = businessUnits?.data.map((bu) => bu);

        if (role === 'CONTRIBUTOR') {
            const userInfo = localStorage.getItem("userInfo");
            const userInfoParsed = userInfo ? JSON.parse(userInfo) : null;
            const contributorBusinessUnits = businessUnits?.data.filter((item: any) => contributorBusinessUnitRef?.data.filter((item2: any) => item2.user === Number(userInfoParsed.id) && item2.business_unit === item.id)?.length > 0);
            itemsBU = contributorBusinessUnits.map((item) => item);
        }

        const filteredBusinessUnits = itemsBU.filter((item) =>
            item.name?.toLowerCase().includes(newSearchTerm?.toLowerCase())
        );

        if (filteredBusinessUnits?.length > 1) {
            filteredBusinessUnits.sort(customSort);
        }

        setFilteredBu(filteredBusinessUnits);
    };

    const handleItemClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => {
        setShowDropdown(false);
        const value = event.currentTarget.innerText;
        const business_group = type === "Group" ? businessGroups?.data?.find((bg) => bg.name.trim() === value.trim()) : 0;
        const business_unit = type === "Unit" ? businessUnits?.data?.find((bu) => bu.name.trim() === value.trim()) : 0;

        if (value?.length > 24) {
            setSelectedValue(value.slice(0, 23) + '...');
        } else {
            setSelectedValue(value);
        }

        setLoad(true);
        if (business_group) {
            await addRecentlyViewed(String(business_group.id), "Group");
            dispatch(setCurrentBusinessGroup(business_group.id));
            dispatch(setCurrentBusinessUnit(0))
        } else if (business_unit) {
            await addRecentlyViewed(business_unit.id, "Unit");
            dispatch(setCurrentBusinessUnit(business_unit.id))
            dispatch(setCurrentBusinessGroup(0))
        }
        else {
            dispatch(setCurrentBusinessUnit(0))
            dispatch(setCurrentBusinessGroup(0))
        }
        setLoad(false);
    };

    const addRecentlyViewed = async (entityId: string, type: string) => {
        if (!type) return;

        const userInfo = localStorage.getItem("userInfo");
        const userInfoParsed = userInfo ? JSON.parse(userInfo) : null;

        if (type === "Group") {
            const recentBG = recentlyViewedGroups?.data?.find((item) => item.business_group === entityId)
            if (recentBG) {
                await dispatch(patchRecentlyViewedGroups(recentBG.id, { last_viewed: Date.now() }));
            } else {
                await dispatch(postRecentlyViewedGroups({ business_group: entityId, user: userInfoParsed.id }));
            }
        } else if (type === "Unit") {
            const recentBU = recentlyViewedUnits?.data?.find((item) => item.business_unit === entityId)
            if (recentBU) {
                await dispatch(patchRecentlyViewedUnits(recentBU.id, { last_viewed: Date.now() }));
            } else {
                await dispatch(postRecentlyViewedUnits({ business_unit: entityId, user: userInfoParsed.id }));
            }
        }
    }


    const getBusinessUnitsByBusinessGroups = (bg: businessGroupType) => {
        const bgId: Number = Number(bg.id)
        const newBusinessUnitIds = businessUnifRef?.data.filter((item) => item.business_group === bgId)
            .map((item) => item.business_unit)
        const newBusinessUnits = businessUnits?.data.filter((bu) => newBusinessUnitIds.includes(Number(bu.id)));
        return newBusinessUnits
    }

    const additionalElementStyles: React.CSSProperties = {
        visibility: isInputHovered ? 'visible' : 'hidden',
        opacity: isInputHovered ? 1 : 0,
    };

    const customSort = (item1: any, item2: any) => {
        const a = item1?.name;
        const b = item2?.name;
        const alphaNumRegex = /([a-zA-Z\s]+)([0-9]*)/;
        const [, aAlpha, aNum] = a.match(alphaNumRegex);
        const [, bAlpha, bNum] = b.match(alphaNumRegex);

        if (aAlpha !== bAlpha) {
            return aAlpha.localeCompare(bAlpha);
        }

        const aNumInt = parseInt(aNum || "0");
        const bNumInt = parseInt(bNum || "0");
        return aNumInt - bNumInt;
    }

    // Functions End ******************************************************************************************************************





    // All UseEffects ******************************************************************************************************************
    React.useEffect(() => {
        if (businessUnits?.status === "idle") dispatch(getBusinessUnits());
        if (role !== 'CONTRIBUTOR') {
            if (businessGroups?.status === "idle")
                dispatch(getBusinessGroups());
        }
        else {
            if (businessUnits?.status === "idle")
                dispatch(getContributorBusinessUnits());
            setSelectedValue('')
        }
        if (companyDetails?.status === "idle")
            dispatch(getCompanyDetails());
        if (businessUnifRef?.status === "idle")
            dispatch(getBusinessUnitRefs());
        if (recentlyViewedUnits?.status === "idle")
            dispatch(getRecentlyViewedUnits());
        if (recentlyViewedGroups?.status === "idle")
            dispatch(getRecentlyViewedGroups());
        if (contributorBusinessUnitRef?.status === "idle")
            dispatch(getContributorBusinessUnits());
    }, []);


    React.useEffect(() => {
        if (businessUnits?.status === "success" && businessUnifRef?.status === "success" && contributorBusinessUnitRef?.status === 'success') {
            if(role === 'CONTRIBUTOR') {
                const userInfo = localStorage.getItem("userInfo");
                const userInfoParsed = userInfo ? JSON.parse(userInfo) : null;
                const contributorBusinessUnits = businessUnits?.data.filter((item: any) => contributorBusinessUnitRef?.data.filter((item2: any) => item2.user === Number(userInfoParsed.id) && item2.business_unit === item.id)?.length > 0);
                console.log('contributorBusinessUnits', contributorBusinessUnits)
                const contributorBusinessUnitsName = contributorBusinessUnits.map((item) => item.name);
                if (contributorBusinessUnits?.length > 1) {
                    contributorBusinessUnits.sort(customSort);
                }
                setFilteredBu(contributorBusinessUnits);
                setUserName(userInfoParsed.first_name);
                setSelectedValue(contributorBusinessUnitsName[0]);
                if (contributorBusinessUnitsName?.length) {
                    dispatch(setCurrentBusinessUnit(contributorBusinessUnits[0]?.id))
                } else {
                    setShowLogoutModal(true);
                }
            }
            const currentBusinessGroup = localStorage.getItem('currentBusinessGroup');
            const currentBusinessUnit = localStorage.getItem('currentBusinessUnit');
            if (currentBusinessGroup && currentBusinessUnit) {
                dispatch(setCurrentBusinessGroup(Number(currentBusinessGroup)));
                dispatch(setCurrentBusinessUnit(Number(currentBusinessUnit)));
            }
        }
       
    }, [businessUnits?.status, businessUnifRef?.status, contributorBusinessUnitRef?.status]);


    React.useEffect(() => {
        const value: string = currentBusinessGroup !== 0 ?
            businessGroups?.data?.find((item) => item.id === currentBusinessGroup)?.name as string :
            currentBusinessUnit !== 0 ?
                businessUnits?.data?.find((item) => Number(item.id) === currentBusinessUnit)?.name as string : ''
        setSelectedValue(value);
        if (value !== '')
            BENotify.success(`${value}`, `You are now viewing data for ${value}`, () => { }, false);
    }, [currentBusinessGroup, currentBusinessUnit]);



    React.useEffect(() => {
        let path = location.pathname.split('/')[2];
        const paths = ['suppliers', 'materiality', 'settings', 'analytics', 'internal', 'entity-manager', 'targets', 'reporting']
        if (paths.includes(path)) {
            setShowCascader(false);
            if (location.pathname.includes('data-download')) {
                setShowCascader(true);
            }
        } else {
            setShowCascader(true);
        }
    }, [location.pathname, companyDetails?.data]);



    React.useEffect(() => {
        if (recentlyViewedUnits?.status === "success" && recentlyViewedGroups?.status === "success") {

            const recentBU = recentlyViewedUnits?.data.map((item) => {
                return {
                    id: item.id,
                    business_unit: item.business_unit,
                    name: item.business_unit_name,
                    last_viewed: item.last_viewed,
                    type: 'Unit'
                }
            });

            const recentBG = recentlyViewedGroups?.data.map((item) => {
                return {
                    id: item.id,
                    business_group: item.business_group,
                    name: item.business_group_name,
                    last_viewed: item.last_viewed,
                    type: 'Group'
                }
            });

            let recentItems = [...recentBU, ...recentBG];
            recentItems.sort((a, b) => b.last_viewed > a.last_viewed ? 1 : -1);
            setRecent(recentItems.slice(0, 4));
        }
    }, [recentlyViewedUnits?.status, recentlyViewedGroups?.status]);

    // UseEffects End ******************************************************************************************************************









    // All Mouse Events ******************************************************************************************************************
    const handleBgItemOver = (bg: any) => {
        setIsHovered(true);
        const newBusinessUnits = getBusinessUnitsByBusinessGroups(bg);
        if (newBusinessUnits?.length > 1) {
            newBusinessUnits.sort((a, b) => a.name.localeCompare(b.name));
        }
        setFilteredBu(newBusinessUnits);
    };

    const handleBgItemOut = () => {
    };

    const handlePanelOneHover = () => {
        setIsInputHovered(true);
        setShowBu(false);
    };

    const handlePanelOneLeave = () => {
        setIsInputHovered(false);
        setfilteredBg(businessGroups?.data);
        { role !== 'CONTRIBUTOR' && handleSearchChange() };
    };

    const handleBuHeadHover = () => {
        handleSearchChange();
        setIsInputHovered(true);
        setShowBu(true);
    };

    const handleBuHeadLeave = () => {
        setIsInputHovered(false);
        setShowBu(false);
    };

    const handlePanel2Hover = () => {
        setIsInputHovered(true);
    };

    const handlePanel2Leave = () => {
        setIsInputHovered(false);
    };

    const handleBuItemsHover = () => {
        handleSearchChange();
        setIsInputHovered(true);
        setShowBu(true);
    };

    const handleBuItemsLeave = () => {
        setFilteredBu(businessUnits?.data);
        setIsInputHovered(false);
        setShowBu(false);
    };

    const handleViewHover = () => {
        setShowDropdown(true);
    }
    // Mouse Events End ******************************************************************************************************************






    return (
        <div className='cascader'>
            <div
                className='viewer'
                onMouseOver={handleViewHover}
            >
                <img className='icon' src={BGIcon} alt="Logo" />
                <span className='selectedValue'>
                    {
                        load ?
                            <GlobalLoader size='small' height='100%' /> :
                            (!cascader ? companyDetails?.data?.firm_name.slice(0, 23) + "..." :
                                (selectedValue ? selectedValue : (role === 'CONTRIBUTOR' ? '' : companyDetails?.data?.firm_name.slice(0, 23) + "...")))
                    }
                </span>
                <div style={{ flexGrow: "1" }}></div>
                {cascader &&
                    <div style={{ width: "1.25rem" }}>
                        <ArrowDownIcon inheritSize />
                    </div>}
            </div>
            {showDropdown && cascader ?
                <div className='entity-selector'>
                    <div className='list'>
                        <BEInput
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onMouseOver={handlePanelOneHover}
                            onMouseLeave={handlePanelOneLeave}
                            placeholder='Search...'
                            size="large"
                            style={{ width: "230px", margin: "10px 10px 20px 10px" }}
                        />
                        {role !== 'CONTRIBUTOR' ?
                            <>
                                <span
                                    className='list-items'
                                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClick(e, "Company")}
                                >
                                    {companyDetails?.data?.firm_name.slice(0, 23) + "..."}
                                </span>
                                <span
                                    className='list-items'
                                    onMouseOver={handlePanelOneHover}
                                    onMouseLeave={handlePanelOneLeave}
                                >
                                    Business Groups {`(${filteredBg?.length})`}
                                    <div style={{ flexGrow: "1" }}></div>
                                    <img className='icon-right' src={RightArrow} alt="Logo" />
                                </span>

                                <span
                                    className='list-items'
                                    onMouseOver={handleBuHeadHover}
                                    onMouseLeave={handleBuHeadLeave}
                                >
                                    Business Units {`(${businessUnits?.data?.length})`}
                                    <div style={{ flexGrow: "1" }}></div>
                                    <img className='icon-right' src={RightArrow} alt="Logo" />
                                </span>
                                {filteredBg?.length && !showBu ?
                                    <div
                                        className='business-groups-items'
                                        onMouseOver={handlePanel2Hover}
                                        onMouseLeave={handlePanel2Leave}
                                        style={additionalElementStyles}
                                    >
                                        <h4 className='list-heading'>Business Groups {`(${filteredBg?.length})`}</h4>
                                        <div className='business-group-container'>
                                            {
                                                filteredBg.map((bg, index) =>
                                                    <span
                                                        key={index}
                                                        className='list-items'
                                                        onMouseOver={() => handleBgItemOver(bg)}
                                                        onMouseOut={handleBgItemOut}
                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClick(e, "Group")}
                                                    >
                                                        {bg.name}
                                                        <span style={{ flexGrow: "1" }}></span>
                                                        <img className='icon-right' src={RightArrow} alt="Logo" />
                                                    </span>
                                                )
                                            }
                                        </div>
                                        <div
                                            className='business-unit-container'
                                        >
                                            <h4 className='list-heading'>
                                                Business Units {`(${filteredBu?.length})`}
                                            </h4>
                                            {
                                                filteredBu.map((bu, index) =>
                                                    <div
                                                        key={index}
                                                        className='list-items'
                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClick(e, "Unit")}
                                                    >
                                                        {bu.name}
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {showBu &&
                                            <div
                                                className='business-groups-items'
                                                onMouseOver={handleBuItemsHover}
                                                onMouseLeave={handleBuItemsLeave}
                                                style={additionalElementStyles}
                                            >
                                                <h4 className='list-heading'>Business Units {`(${filteredBu?.length})`}</h4>
                                                <div className='business-group-container'>
                                                    {
                                                        filteredBu.map((bu) =>
                                                            <span
                                                                className='list-items'
                                                                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClick(e, "Unit")}
                                                            >
                                                                {bu.name}
                                                                <span style={{ flexGrow: "1" }}></span>
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </> :
                            <>
                                <span
                                    className='list-items'
                                    onMouseOver={handlePanelOneHover}
                                    onMouseLeave={handlePanelOneLeave}
                                >
                                    Business Units {`(${filteredBu?.length})`}
                                    <div style={{ flexGrow: "1" }}></div>
                                    <img className='icon' src={RightArrow} alt="Logo" />
                                </span>
                                <div
                                    className='business-groups-items'
                                    onMouseOver={handlePanel2Hover}
                                    onMouseLeave={handlePanel2Leave}
                                    style={additionalElementStyles}
                                >
                                    <h4 className='list-heading'>Business Units {`(${filteredBu?.length})`}</h4>
                                    <div className='business-group-container'>
                                        {
                                            filteredBu.map((bu) =>
                                                <span
                                                    className='list-items'
                                                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClick(e, "Unit")}
                                                >
                                                    {bu.name}
                                                    <span style={{ flexGrow: "1" }}></span>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        <hr className='hr' />
                        <div className='recently-viewed-heading'>Recently Viewed {`(${recent?.length})`}</div>
                        <div className='recent'>
                            {recent.map((item, index) =>
                                <div
                                    key={index}
                                    className='list-items'
                                >
                                    <span style={{ width: 200 }} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleItemClick(e, item.type)}>{item.name}</span>
                                    <div style={{ flexGrow: "1" }} />
                                    <span style={{ fontSize: "small", color: PrimaryTheme.primaryGray }}>{item.type}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                : ''
            }
            <BENoBuModal
                title="Unassigned to a Business Unit"
                message={`Welcome ${userName}, Please request your administrator to add you to a Business Unit, to access the platform.`}
                visible={showLogoutModal}
                setVisible={setShowLogoutModal}
                onConfirm={async () => {
                    await dispatch(logoutAction());
                }}
            />
        </div>
    );
}
