import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { getDashboardDrilldown } from '../../../../Actions';

export interface IDashboardCustomBarGraphProps {
  data:any
}

export function DashboardCustomBarGraph (props: IDashboardCustomBarGraphProps) {
  const dispatch = useAppDispatch();
  const [graphData, setGraphData] = React.useState<any>([]);
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  
  const getChartData = async() => {
    let tempData:any = await dispatch(getDashboardDrilldown(
      5,1,props.data.bcode as string,[props.data.year]
    ));
    setGraphData([{
      name: props.data.custom_title || props.data.title,
      data: rotateArray(tempData[props.data.year], yearEnd,true)
    }])
  }

  
  React.useEffect(() => {
    if(!props.data) return;
    getChartData();
  }, []);

  return (
    <div>
      <StackedColumnCharts
       data={graphData}
        type={2}
        xAxisLabels={rotateArray(shortMonths,yearEnd,true)} 
        colorTheme={4} 
        yAxisLabel={props.data?.unit}
      />
    </div>
  );
}
