import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, LabelList, Label } from 'recharts';
import {  graphColors, graphColors2, graphColors3, graphColors4, graphColors5 } from '../../../../Config/Theme/graphColors';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { AddCommaToNumber, changeSnakeCaseToSentence } from '../../../../Config/Functions/UsefullFunctions';
import { IStackedColumnChartCoreProps } from '..';
import { generateSymlogTicks } from '../../../../Config/Functions/ScalingFunctions';


const StackedColumnCharts2 = (props: IStackedColumnChartCoreProps) => {

  const [chartData, setChartData] = React.useState<any>([]);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(0);

  React.useEffect(() => {
    if (props.data.length>0 && props.xAxisLabels.length>0) {
      let tempData: any = [];
      // only show top 9 and rest as others
      let data:any =
        props.disableSort ? 
          props.data :
          props.data.sort((a,b) => b.data.reduce((acc:any, item:any) => acc + item, 0) - a.data.reduce((acc:any, item:any) => acc + item, 0));
      data = props.data.slice(0,9);


      if(props.data.length > 9){
        let othersArray:any = Array(props.xAxisLabels.length).fill(0);
        props.data.slice(9).forEach((element: any) => { 
          element.data.forEach((item:any, index:any) => {
            othersArray[index] += item;
          })
        })
        let othersObj: any = {
          name: 'Others',
          data: othersArray
        }
        data.push(othersObj);
      }

      let heighestValue:any = 0;
      let lowestValue:any = 0;

      props.xAxisLabels.map((item: any, index: any) => {
        let tempObj: any = {
          name: item,
        }
        data.forEach((element: any) => {
          let name = changeSnakeCaseToSentence(element.name,false,true)
          tempObj[name] = element.data[index]

          if(element.data[index] > heighestValue) heighestValue = element.data[index]
          if(element.data[index] < lowestValue) lowestValue = element.data[index]
        })
        tempData.push(tempObj);
      })

      setMinValue(lowestValue)
      setMaxValue(heighestValue)
      setChartData(tempData)
    }
  }, [props.data, props.xAxisLabels])

  const renderLabelContent = (props: number) => {
    return AddCommaToNumber(props, false, true, true);
  };

  return (
    <div style={{height: props?.height ? props?.height : '400px',width:'100%'}}>
      <h2>{props.chartTitle}</h2>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={600}
          height={300}
          data={chartData}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
          style={{fontSize: '10px'}}
          barCategoryGap={
            props.data.length > 9 ? '10%' : '20%'
          }
          barGap={0}
        >
          
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" axisLine={false} tickLine={false}/>
          <YAxis padding={{ top: 40 }} 
            // scale={'symlog' as any}
            axisLine={false} tickLine={false}
            // ticks={generateSymlogTicks(minValue,maxValue)}
          >
              <Label value={props.yAxisLabel} angle={-90} style={{ textAnchor: 'middle' }} position={'insideLeft'}/>
          </YAxis>
          <Tooltip formatter={renderLabelContent}/>
          <Legend iconType="circle" iconSize={10} />
          
          {
            chartData[0] && Object.keys(chartData[0]).filter((item:any) => item !== 'name').map((item:any, index:any) => {
              return (
              <>
                  <Bar dataKey={item}  fill={
                    !props.colorTheme ?  
                    graphColors[index%graphColors.length]:
                    props.colorTheme === 2 ?
                    graphColors2[index%graphColors2.length]:
                    props.colorTheme === 3 ?
                    graphColors3[index%graphColors3.length]:
                    props.colorTheme === 4 ?
                    graphColors4[index%graphColors3.length]:
                    props.colorTheme === 5 ?
                    graphColors5[index%graphColors5.length]:
                    'white'
                  } 
                    radius={[10, 10, 0, 0]}
                  >
                    {/* <LabelList dataKey={changeSnakeCaseToSentence(item.name,false,true)} style={{ fill: 'black' }} formatter={renderLabelContent}/> */}
                  </Bar>
               
              </>
                )
            })
          }
          {props.averageLine &&
          <ReferenceLine
          y={props.averageLine}
          stroke={PrimaryTheme.amber}
          label={{ position: "insideBottomRight",  value: 'AVERAGE', fill: PrimaryTheme.amber}}
          />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedColumnCharts2;