import * as React from 'react';
import { BEInput } from '../../../../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import { Form } from 'antd';
import './styles.scss';
import { BETable } from '../../../../../../../Components/BETable';
import { ColumnType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { patchEvent, patchEventAction, postEventUser } from '../../../../Actions';
import DeleteIcon from '../../../../../../../Components/BEIcons/DeleteIcon';
import { BEMessage } from '../../../../../../../Components/BEMessage';

interface IInvestigationTeamTableProps {
	tableData: any[];
	isActionAssigned?: boolean;
	setEventUserId?: Function;
}

export function TeamForm(props: IInvestigationTeamTableProps) {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const userProfie = useTypedSelector(state => state.user.userProfile);
	const [loading, setLoading] = React.useState(false);
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const isDisabled = props.isActionAssigned && props.tableData.length > 0;

	const columns: ColumnType<any>[] = [
		{
			title: 'First Name',
			dataIndex: 'first_name',
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'Action',
			render: (text, record, index) => (
				<>
					<div style={{ height: '1rem', width: '1rem', cursor: 'pointer' }} onClick={() => {
						if (currentEvent) {
							const investigationTeam = currentEvent?.investigation_team?.split(',');
							const filteredTeam = investigationTeam?.filter((id: string) => id !== String(record.id));
							if (filteredTeam.length > 0) {
								dispatch(patchEvent(currentEvent?.id, {
									investigation_team: filteredTeam?.join(',')
								}));
							} else {
								dispatch(patchEvent(currentEvent?.id, {
									investigation_team: ''
								}));
							}
						}
					}}>
						<DeleteIcon stroke='black' inheritSize />
					</div>
				</>
			)
		}
	];

	const handleAdd = async (values: any) => {
		if (userProfie.data) {
			setLoading(true);
			const org = userProfie.data.org;
			dispatch(postEventUser({
				email: values.email,
				first_name: values.firstName,
				last_name: values.lastName,
				org: org,
				event_role: "ACTION_OWNER",
			})).then((res) => {
				if (props.isActionAssigned && props.setEventUserId) {
					props.setEventUserId(res.data.id);
					setLoading(false);
				} else {
					if (currentEvent) {
						if (!currentEvent?.investigation_team?.split(',')?.includes(res?.data?.id?.toString())) {
							dispatch(patchEvent(currentEvent?.id, {
								investigation_team: currentEvent?.investigation_team ? `${currentEvent?.investigation_team},${res.data.id}` : `${res.data.id}`
							}));
						}
						setLoading(false);
					}
				}
			}).catch(() => {
				BEMessage.error('Something went wrong');
				setLoading(false);
			});
		}
		form.resetFields();
	}

	React.useEffect(() => {
		form.resetFields();
	}, []);

	return (
		<>
			<div className='investigation-team-table'>
				<Form form={form} onFinish={handleAdd}>
					<div className='investigation-team-form'>
						<Form.Item name={'firstName'} rules={[{ required: true, message: 'Please input your first name!' }]}>
							<BEInput disabled={isDisabled} placeholder='First Name' />
						</Form.Item>
						<Form.Item name={'lastName'} rules={[{ required: true, message: 'Please input your last name!' }]}>
							<BEInput disabled={isDisabled} placeholder='Last Name' />
						</Form.Item>
						<Form.Item name={'email'} rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}>
							<BEInput disabled={isDisabled} className='email' placeholder='Email' />
						</Form.Item>
					</div>
				</Form>
				<BEButton disabled={isDisabled} loading={loading} onClick={() => form.submit()} className='primary'>Add</BEButton>
			</div>
			<BETable
				columns={columns}
				data={props.tableData}
			/>
		</>
	);
}
