import API from "../../API";

export const GET_MY_DASHBOARD_METRICS = async () => {
    return await API.GET("/cleopatra/my-dashboard-metrics/");
}

export const POST_MY_DASHBOARD_METRICS = async (data:any) => {
    return await API.POST("/cleopatra/my-dashboard-metrics/", data);
}

export const PATCH_MY_DASHBOARD_METRICS = async (id:any, data:any) => {
    return await API.PATCH("/cleopatra/my-dashboard-metrics/"+id+"/", data);
}

export const DELETE_MY_DASHBOARD_METRICS = async (id:any) => {
    return await API.DELETE(`/cleopatra/my-dashboard-metrics/${id}/`);
}


export const GET_QUICKSIGHT_AUTHOR_DASHBOARD_DETAILS = async () => {
    return await API.GET(`/cleopatra/get-quicksight-author-url/`);
}

export const GET_QUICKSIGHT_READER_DASHBOARD_DETAILS = async () => {
    return await API.GET(`/cleopatra/get-quicksight-dashboard-url/`);
}

export const POST_MY_DERIVED_DASHBOARD_METRICS = async (data:any) => {
    return await API.POST("/cleopatra/my-dashboard-derived-metrics/", data);
}

export const PATCH_MY_DERIVED_DASHBOARD_METRICS = async (id:any, data:any) => {
    return await API.PATCH("/cleopatra/my-dashboard-derived-metrics/"+id+"/", data);
}

export const DELETE_MY_DERIVED_DASHBOARD_METRICS = async (id:any) => {
    return await API.DELETE(`/cleopatra/my-dashboard-derived-metrics/${id}/`);
}

export const GET_MY_DERIVED_DASHBOARD_METRICS = async () => {
    return await API.GET("/cleopatra/my-dashboard-derived-metrics/");
}

export const GET_MY_DASHBOARD_ANALYTICS = async (
    metricBcodes: string[],
    businessUnits: number[],
    fromData: string,
    toDate: string
) => {
    return await API.GET(`/cleopatra/dashboard-analytics/?metric=${metricBcodes}&business_units=${businessUnits}&from_date=${fromData}&to_date=${toDate}`);
}

export const GET_MY_DASHBOARD_INSIGHTS = async (
    year: number
) => {
    return await API.GET(`/cleopatra/dashboard-insights/year/${year}/`);
}


export const GET_MY_DASHBOARD_INSIGHTS_ALERT_MESSAGES = async () => {
    return await API.GET("/alert-messages/");
}

export const GET_MY_DASHBOARD_CONTRIBUTER_INSIGHTS = async (
    year: number
) => {
    return await API.GET(`/cleopatra/contributor-insights/${year}/`);
}

export const GET_MY_DASHBOARD_COMPARISION_ANALYTICS = async (query:any) => {
    return await API.GET(`/cleopatra/dashboard-emission-analytics/?${query}`);
}

export const GET_MY_DASHBOARD_DRILLDOWN = async (query:any) => {
    return await API.GET(`/cleopatra/dashboard-drill-down/?${query}`);
}

export const GET_MY_DASHBOARD_INSIGHTS_PROGRESS = async (query:any) => {
    return await API.GET(`/cleopatra/dashboard-insights/?${query}`);
}