
import {
  POST_CUSTOM_QUESTION,
  PATCH_CUSTOM_QUESTION,
  GET_ALL_SUPPLIER_ASSESSMENTS,
  GET_ALL_SUPPLIERS,
  GET_ALL_ASSESMENT_SUPPLIER_REF,
  GET_SUPPLIER_OPTIONS,
  GET_SUPPLIER_QUESTIONS,
  PATCH_SUPPLIER_QUESTIONS,
  GET_SUPPLIER_ANSWERS_BY_ASSESSMENT,
  GET_SUPPLIER_STATUS,
  GET_SUPPLIER_ASSESSMENT_SCORE,
  ADD_SUPPLIER_ASSESSMENT,
  SEND_SUPPLIER_ASSESSMENT,
  ADD_GLOBAL_SUPPLIER,
  PATCH_GLOBAL_SUPPLIER,
  BULK_UPLOAD_GLOBAL_SUPPLIERS_BY_EXCEL,
  BULK_UPLOAD_SUPPLIERS,
  DELETE_GLOBAL_SUPPLIER,
  ADD_SUPPLIER_REFRENCE,
  GET_RISK_METRICS,
  ADD_SUPPLIER_QUESTIONS,
  DELETE_SUPPLIER_ASSESSMENT,
  PATCH_CORE_SUPPLIER_ASSESSMENT,
  GET_BRSR_RISK_METRICS,
  DELETE_SUPPLIER_REFRENCE,
  SEND_REMAINDER_TO_SUPPLIER,
  GET_SUPPLIER_ADDED_QUESTIONS,
  DELETE_SUPPLIER_QUESTIONS,
  GET_SUPPLIERS_AT_RISK_BY_QUESTION,
  GET_ALL_SUPPLIERS_AT_RISK,
} from "../../../Utils/Routes/SupplierRoutes";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import { 
  setSuppliersSurveys,
  setSuppliers,
  setSuppliersSurveyRefs,
  setSupplierOptions,
  setSupplierQuestions,
  setSupplierAnswers,
  setSupplierStatus,
  setCreateSurveyStep,
  setCurrentAssessment,
  setSupplierScore,
  setLastAddedSupplier,
  updateSupplierQuestions
} from "../../../Redux/SupplierReducer";
import exportToCSV, { exportToMultiSheetExcel } from "../../../Utils/Download/Excell/ExportExcell";
import { SupplierRiskClassificationThreshold } from "../Views/AllAssessments/AllAssessments";

export const postCustomQuestion = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_CUSTOM_QUESTION(body))
    if (data) {
        dispatch(getSupplierQuestions());
        BEMessage.success("Custom question added successfully");
        return true
    }
    else {
        BEMessage.error("Custom question couldn't be added");
        return false
    }
}

export const patchCustomQuestions = (id: any,body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_CUSTOM_QUESTION(body, id))
    if (data) {
        dispatch(getSupplierQuestions());
        BEMessage.success("Custom question updated successfully");
        return true
    }
    else {
        BEMessage.error("Custom question couldn't be updated");
        return false
    }
}

export const patchSupplierQuestions = (id:any,body: any) => 
async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(
        PATCH_SUPPLIER_QUESTIONS(body,id)
    )
    if (data) {
        // dispatch(updateSupplierQuestions({
        //     id:id,
        //     data:body
        // }));
        return true
    }
    else {
        BEMessage.error("Supplier questions couldn't be updated");
        return false
    }
}

export const sendRemainderToSupplier = (body:any) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(SEND_REMAINDER_TO_SUPPLIER(body));
    if (data) {
        BEMessage.success("Remainder sent successfully");
    }
    else{
        console.log(error);
    }
}


export const getSupplierSurveys = (year?:number) => 
async (dispatch:AppDispatch,getState:()=>RootState) => {
    let currentYear:number = getState()
        .dataManager.metrics.dateSlicer.currentYear;
    if(year) currentYear = year;

    dispatch(setSuppliersSurveys({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(
        GET_ALL_SUPPLIER_ASSESSMENTS(currentYear)
    );
    if (data) {
        dispatch(setSuppliersSurveys({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliersSurveys({
            status: "error",
            data: []
        }));
    }
}

export const getSuppliers = () => async (dispatch:AppDispatch) => {
    dispatch(setSuppliers({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_SUPPLIERS());
    if (data) {
        dispatch(setSuppliers({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliers({
            status: "error",
            data: []
        }));
    }
}

export const deleteGlobalSupplier = (id:number) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_GLOBAL_SUPPLIER(id));
    if (data) {
        BEMessage.success("Supplier deleted successfully");
        dispatch(getSuppliers());
    }
    else{
        console.log(error);
        BEMessage.error("Supplier couldn't be deleted");
    }
}

export const getSupplierSurveyRefs = (id:number) => async (dispatch:AppDispatch) => {
    dispatch(setSuppliersSurveyRefs({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_ALL_ASSESMENT_SUPPLIER_REF(id));
    if (data) {
        dispatch(setSuppliersSurveyRefs({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSuppliersSurveyRefs({
            status: "error",
            data: []
        }));
    }
}

export const deleteSupplierRef = (id:number,surveyID:any) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_SUPPLIER_REFRENCE(id));
    if (data) {
        dispatch(getSupplierSurveyRefs(surveyID));
        BEMessage.success("Supplier deleted successfully");
    }
    else{
        console.log(error);
        BEMessage.error("Supplier couldn't be deleted");
    }
}

export const getSupplierOptions = () => async (dispatch:AppDispatch) => {
    dispatch(setSupplierOptions({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_OPTIONS());
    if (data) {
        dispatch(setSupplierOptions({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierOptions({
            status: "error",
            data: []
        }));
    }
}

export const getSupplierQuestions = () => async (dispatch:AppDispatch) => {
    dispatch(setSupplierQuestions({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_QUESTIONS());
    if (data) {
        let dataToSet = data?.data?.map((question:any)=>{ 
            return {
                ...question,
                attribute: question?.attribute?.trim()
            }
        });
        dispatch(setSupplierQuestions({
            status: "success",
            data: dataToSet
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierQuestions({
            status: "error",
            data: []
        }));
    }
}

export const getSupplierAnswers = (id:any,supplier_id?:number) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(
        GET_SUPPLIER_ANSWERS_BY_ASSESSMENT(id,supplier_id)
    );
    if (data) {
        return data?.data;
    }
    else{
        console.log(error);
        return null;
    }
}

export const getSupplierStatus = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setSupplierStatus({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_SUPPLIER_STATUS(id));
    if (data) {
        dispatch(setSupplierStatus({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierStatus({
            status: "error",
            data: []
        }));
    }
}



export const addCoreSupplierAssessment = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ADD_SUPPLIER_ASSESSMENT(body))
    if (data) {
        BEMessage.success("BRSR Core Assessment added successfully");
        console.log(data);
        dispatch(setCurrentAssessment(data?.data))
        dispatch(setCreateSurveyStep(2))
        dispatch(getSupplierSurveys());
        return
    }
    else {
        BEMessage.error("BRSR Core Assessment couldn't be added");
        return
    }
}

export const patchCoreSupplierAssessment = (id: any,body: any, ) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_CORE_SUPPLIER_ASSESSMENT(body, id))
    if (data) {
        console.log(data)
        dispatch(getSupplierSurveys());
        dispatch(setCurrentAssessment(data?.data))
        dispatch(setCreateSurveyStep(2))
        return true
    }
    else {
        BEMessage.error("BRSR Core Assessment couldn't be updated");
        return false
    }
}

export const deleteSupplierAssessment = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_SUPPLIER_ASSESSMENT(id))
    if (data) {
        BEMessage.success("BRSR Core Assessment deleted successfully");
        console.log(data)
        dispatch(getSupplierSurveys());
        dispatch(setCurrentAssessment(null))
        dispatch(setCreateSurveyStep(1))
        return
    }
    else {
        BEMessage.error("BRSR Core Assessment couldn't be deleted");
        return
    }
}
export const inviteSuppliers = (data:any,id:Number) => async (dispatch:AppDispatch) => {
    const [response, error] = await handleAPICall(SEND_SUPPLIER_ASSESSMENT(data,id));
    if (response) {
        BEMessage.success("Invitation sent successfully");
        dispatch(getSupplierStatus(data[0].assessment));
    }
    else{
        console.log(error);
    }
}

export const addGlobalSupplier = (body: any) => async (dispatch: AppDispatch,getState:()=>RootState) => {
    let allSUppliers = getState().supplier.Suppliers?.data;
    if(allSUppliers?.find((supplier:any)=>supplier.email === body.email)){
        BEMessage.error("Supplier email already exists");
        return;
    }
    const [data, error] = await handleAPICall(ADD_GLOBAL_SUPPLIER(body));
    if (data) {
        dispatch(setLastAddedSupplier(data?.data[0]))
        BEMessage.success("Supplier added successfully");
        dispatch(getSuppliers());
    }
    else {
        BEMessage.error("Error in adding supplier")
    }
}

export const patchGlobalSupplier = (id: any,body: any) => 
async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_GLOBAL_SUPPLIER(body,id));
    if (data) {
        BEMessage.success("Supplier updated successfully");
        dispatch(getSuppliers());
        return true
    }
    else {
        BEMessage.error(error?.error);
        return false
    }
}

export const bulkUploadGlobalSuppliersByExcel = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(BULK_UPLOAD_GLOBAL_SUPPLIERS_BY_EXCEL(body));
    if (data) {
        console.log(data)
        dispatch(getSuppliers());
        return data;
    }
    else {
        return null;
    }
}


export const bulkUploadSuppliers = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(BULK_UPLOAD_SUPPLIERS(body));
    if (data) {
        console.log(data)
        BEMessage.success("Supplier added successfully");
        dispatch(getSuppliers());
    }
    else {
        BEMessage.error("Supplier email already exists")
    }
}

export const addSupplierAssessmentRef = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ADD_SUPPLIER_REFRENCE(body))
    if (data) {
        BEMessage.success("Suppliers added to assessement")
        dispatch(getSupplierSurveyRefs(body[0]?.assessment));
        // dispatch(setCreateSurveyStep(4));
        console.log(data)
    }
    else {
        BEMessage.error("Suppliers couldn't be added to assessment")
    }
}


export const getSupsplierRiskMetrics = (id:any) => async (dispatch:AppDispatch) => {
    dispatch(setSupplierScore({
            status: "loading",
            data: []
        }));
    const [data, error] = await handleAPICall(GET_RISK_METRICS(id));
    if (data) {
        dispatch(setSupplierScore({
            status: "success",
            data: data?.data
        }));
    }
    else{
        console.log(error);
        dispatch(setSupplierScore({
            status: "error",
            data: []
        }));
    }
}

export const addSupplierQuestions = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ADD_SUPPLIER_QUESTIONS(body))
    if (data) {
        console.log(data)
        return true
    }
    else {
        BEMessage.error("Supplier questions couldn't be added");
        return false
    }
}


export const deleteSupplierQuestions = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_SUPPLIER_QUESTIONS(id))
    if (data) {
        console.log(data)
        return true
    }
    else {
        BEMessage.error("Supplier questions couldn't be deleted");
        return false
    }
}

export const getSupplierAddedQuestions = (id: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_SUPPLIER_ADDED_QUESTIONS(id))
    if (data) {
        return data?.data
    }
    else {
        BEMessage.error("Supplier questions couldn't be added");
        return null
    }
}

export const getBRSRRiskMetrics = (id:any) => 
async (dispatch:AppDispatch,getState:()=>RootState) => {
    dispatch(setSupplierScore({
        ...getState().supplier.SupplierScore,
        status: "loading"
    }));

    const [data, error] = await handleAPICall(GET_BRSR_RISK_METRICS(id));
    if (data) {
        dispatch(
            setSupplierScore({
                status: "success",
                data: data?.data
            })
        );
        return true;
    }
    else{
        console.log(error);
        BEMessage.error("Couldn't fetch BRSR Risk Metrics");
        return false;
    }
}

export const bulkUplodSuppliers = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(BULK_UPLOAD_SUPPLIERS(body))
    if (data) {
        console.log(data)
        return true
    }
    else {
        BEMessage.error("Suppliers couldn't be added");
        return false
    }
}

export const getSuppliersAtRiskByQuestion = (id:any,question_id:any,type:any) =>
async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(
        GET_SUPPLIERS_AT_RISK_BY_QUESTION(id,question_id,type)
    );
    if (data) {
        return data?.data;
    }
    else{
        console.log(error);
        return null;
    }
}

export const getAllSuppliersAtRisk = (id:any) =>
async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(
        GET_ALL_SUPPLIERS_AT_RISK(id)
    );
    if (data) {
        return data?.data;
    }
    else{
        console.log(error);
        return null;
    }
}

export const downloadIndividualSupplierExcell = (supplier_id:number) => 
async (dispatch:AppDispatch,getState:()=>RootState) => {
    let suppliers = getState().supplier.Suppliers?.data;
    let assessment = getState().supplier.currentAssessment;
    let questions = getState().supplier.SupplierQuestions?.data;
    let answers = await dispatch(getSupplierAnswers(assessment?.id,supplier_id));
    if(!answers) return BEMessage.error("Failed to download");
    let data:any = [];
    answers?.map((answer:any)=>{
        let question:any = questions?.find((q:any)=>
            answer.question === q.id && answer.type === q.type
    );
    let value = !answer?.answer ? '-' : (
        !answer?.additional_information ? 'Not Provided' :
        answer?.additional_information + (question?.unit ? question?.unit : '')
    )
    let evidence = !answer?.answer ? '-' : ( 
        !answer?.evidence ? 'Not Attached' : answer?.evidence
    )
        data.push({
            "Question":question?.question,
            "Answer":answer?.answer,
            "Value":value,
            "Evidence":evidence,
        })
    })
    let supplier = suppliers?.find((supplier:any)=>supplier.id === supplier_id);
    exportToCSV(data,assessment?.title + " - " + supplier?.name);
}

export const downloadAllSuppliersExcell = () =>
async (dispatch:AppDispatch,getState:()=>RootState) => {
    let suppliers = getState().supplier.Suppliers?.data;
    let assessment = getState().supplier.currentAssessment;
    let questions = getState().supplier.SupplierQuestions?.data;
    let answers = await dispatch(getSupplierAnswers(assessment?.id));
    if(!answers) return BEMessage.error("Failed to download");
    let data:any = [];
    answers?.map((answer:any)=>{
        let question:any = questions?.find((q:any)=>
            answer.question === q.id && answer.type === q.type
    );
    let supplier = suppliers?.find((supplier:any)=>supplier.id === answer.supplier);
    let value = !answer?.answer ? '-' : (
        !answer?.additional_information ? 'Not Provided' :
        answer?.additional_information + (question?.unit ? question?.unit : '')
    )
    let evidence = !answer?.answer ? '-' : ( 
        !answer?.evidence ? 'Not Attached' : answer?.evidence
    )
        data.push({
            "Supplier":supplier?.name,
            "Industry":supplier?.industry,
            "Description":supplier?.description,
            "Partner code":supplier?.code,
            "Attribute":question?.attribute,
            "Question":question?.question,
            "Answer":answer?.answer,
            "Value":value,
            "Evidence":evidence,
        })
    })
    exportToCSV(data,assessment?.title + " - All Suppliers");
}


export const downloadResultExcelled = (assessment_id:any) =>
    async (dispatch:AppDispatch,getState:()=>RootState) => {
    
    let assessment = getState().supplier.currentAssessment;
    let questions = getState().supplier.SupplierQuestions?.data;
    let suppliers = getState().supplier.Suppliers?.data;
    let scores = getState().supplier.SupplierScore?.data;

    let suppliersAtRisk = await dispatch(getAllSuppliersAtRisk(assessment_id));

    let data1:any = []

    scores.supplier_scores.map((score:any)=>{
        let supplier = suppliers.find(
            (supplier:any)=>supplier.id === score.supplier
        );
        let score_per = scores.total_score === 0 ? 0 : (
            (score.score/scores.total_score)*100
        )
        let risk = (
            score_per >SupplierRiskClassificationThreshold.Low ? 'Low' :
            score_per >SupplierRiskClassificationThreshold.Medium ? 'Medium' :
            'High'
        )
        data1.push({
            "Partner name":supplier?.name,
            "Industry":supplier?.industry,
            "Email":supplier?.email,
            "Partner code":supplier?.code,
            "Description":supplier?.description,
            "Risk classification":risk,
            "Total score(%)":score_per.toFixed(2),
        })
    })

    let data2:any = []

    suppliersAtRisk.map((supplier:any)=>{
        let supplierData = suppliers.find(
            (s:any)=>s.id === supplier.supplier
        );
        let question = questions.find(
            (q:any)=>q.id === supplier.question && q.type === supplier.type 
        );
        data2.push({
            "Partner at risk":supplierData?.name,
            "Industry":supplierData?.industry,
            "Code":supplierData?.code,
            "Description":supplierData?.description,
            "Attribute":question?.attribute,
            "Question Tag":(
                supplier?.question_topic ? supplier?.question_topic :
                question?.tag ? question?.tag :
                '-'
            ),
            "Question":question?.question,
        })
    })

    exportToMultiSheetExcel(
        [
            {
                sheetName:"Suppliers at Risk",
                data:data2
            },
            {
                sheetName:"Supplier Scores",
                data:data1
            }
        ],
        `${assessment?.title} - Results`
    )

}