function symlog(x:number, C = 1) {
  return Math.sign(x) * Math.log1p(Math.abs(x) / C);
}

export function scaleRange(values:number[], C = 1) {
  return values.map(x => symlog(x, C));
}

//function to get symlog percentage relative to the max value
export function getScaledPercentage(values:number[], C = 1) {
  let max = Math.max(...values);
  // return values.map(x => symlog(x, C) / symlog(max, C) * 100);
  //for now its normal percentage
  return values.map(x => x / max * 100);
}

function roundToNice(value: number): number {
  if (value === 0) return 0; // Special case for zero

  let magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(value))));
  let rounded = Math.round(value / magnitude) * magnitude;

  // Handle floating-point precision errors by formatting to a fixed decimal
  return parseFloat(rounded.toPrecision(10));
}

export function generateSymlogTicks(min:number, max:number, C = 1, numTicks = 10) {
  let ticks:any = new Set();

  // Ensure min is less than max
  if (min > max) [min, max] = [max, min];

  // Linear region ticks
  let linearStep = C / Math.ceil(numTicks / 4);
  for (let i = -C; i <= C; i += linearStep) {
      ticks.add(roundToNice(i));
  }

  // Logarithmic region ticks
  let logMin = Math.log10(C);
  let logMax = Math.log10(Math.max(Math.abs(min), Math.abs(max)));
  let logStep = (logMax - logMin) / Math.ceil(numTicks / 2);

  for (let i = logMin; i <= logMax; i += logStep) {
      let logValue = Math.pow(10, i);
      ticks.add(logValue);
      ticks.add(-logValue);
  }
  
  // Convert to sorted array, filter within range, and ensure first tick > max
  let tickArray = [...ticks].sort((a, b) => a - b).filter(tick => tick >= min && tick <= max).map(tick => roundToNice(tick));

  // Ensure first tick is above max
  let firstTick = roundToNice(Math.pow(10, Math.ceil(Math.log10(max))));
  if (!tickArray.includes(firstTick)) {
      tickArray.push(firstTick);
  }
  return tickArray.sort((a, b) => a - b);
}
