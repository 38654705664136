import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { Form } from 'antd';
import { LableRequired } from '../../../../Components/BEFormItems/LableRequired';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { patchSelectedMetricsStatus } from '../../../Actions/OnboardingStep4Actions';
import { patchMyDerivedMetrics } from '../../../../Features/DataManager/Actions';

export interface IEditMyMetricDrawerProps {
    open: boolean;
    setOpen: Function;
    metricToEdit: any;
}

export function EditMyMetricDrawer(props: IEditMyMetricDrawerProps) {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values: any) => {
        setLoading(true);
        if (props.metricToEdit?.hasOwnProperty('direct')) {
            await dispatch(patchMyDerivedMetrics(
                props.metricToEdit?.id,
                {
                    custom_title: values.metricName
                }
            ));
        } else {
            await dispatch(patchSelectedMetricsStatus(
                props.metricToEdit?.id,
                {
                    custom_title: values.metricName
                }
            ));
        }
        setLoading(false);
    };
    React.useEffect(() => {
        form.setFieldsValue({
            metricName: props.metricToEdit?.custom_title || props.metricToEdit?.title
        });
    }, [props.metricToEdit]);
    
    return (
        <BEDrawer
            width='30%'
            heading={`Edit Metric - ${props.metricToEdit?.custom_title || props.metricToEdit?.title}`}
            open={props.open}
            setOpen={props.setOpen}
            footer={
                <>
                    <BEButton
                        onClick={() => form.submit()}
                        className='primary'
                        size='large'
                        loading={loading}
                    >
                        Edit
                    </BEButton>
                    {' '}
                    <BEButton
                        onClick={() => props.setOpen(false)}
                        size='large'
                    >
                        Cancel
                    </BEButton>
                </>
            }
        >
            <Form form={form} onFinish={onFinish}>
                <LableRequired>
                    Metric Name
                </LableRequired>
                <Form.Item
                    name='metricName'
                    rules={[{ required: true, message: 'Please enter metric name' }]}
                >
                    <BEInput
                        maxLength={200}
                        placeholder='Enter custom metric name'
                        defaultValue={props.metricToEdit?.custom_title || props.metricToEdit?.title}
                    />
                </Form.Item>
            </Form>
        </BEDrawer>
    );
}
