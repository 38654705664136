import { Form, FormInstance, Select, TimePicker } from 'antd';
import * as React from 'react';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { SeverityOptions } from '../../../../../data/eventManager';

interface IStep2Props {
	form: FormInstance<any>
	formData: any
}

export function Step2(props: IStep2Props) {

	const onTimeChange = (time: any) => {
		props.form.setFieldsValue({ eventTime: time });
	}

	return (
		<>
			<Form form={props.form} initialValues={props.formData}>
				<p>
					Event time
				</p>
				<Form.Item name={'eventTime'}>
					<TimePicker
						style={{ width: '100%' }}
						onChange={(time) => onTimeChange(time)}
						size='large'
						format={'HH:mm:ss'}
					/>
				</Form.Item>
				<p>
					Actual severity
				</p>
				<Form.Item name={'actualSeverity'}>
					<Select>
						{SeverityOptions.map(option => (
							<Select.Option key={option.value} value={option.value}>
								{option.label}
							</Select.Option>
						))
						}
					</Select>
				</Form.Item>
				<p>
					Potential severity
				</p>
				<Form.Item name={'potentialSeverity'}>
					<Select>
						{SeverityOptions.map(option => (
							<Select.Option key={option.value} value={option.value}>
								{option.label}
							</Select.Option>
						))
						}
					</Select>
				</Form.Item>
				<p>
					Actual Impact
				</p>
				<Form.Item name={'actualImpact'}>
					<Select>
						{SeverityOptions.map(option => (
							<Select.Option key={option.value} value={option.value}>
								{option.label}
							</Select.Option>
						))
						}
					</Select>
				</Form.Item>
				<p>
					Potential Impact
				</p>
				<Form.Item name={'potentialImpact'}>
					<Select>
						{SeverityOptions.map(option => (
							<Select.Option key={option.value} value={option.value}>
								{option.label}
							</Select.Option>
						))
						}
					</Select>
				</Form.Item>
				<p>
					Immediate Action
				</p>
				<Form.Item name={'immediateAction'}>
					<BEInput size='large' />
				</Form.Item>
				<p>
					Immediate findings/reasons
				</p>
				<Form.Item name={'immediateReasons'}>
					<BEInput size='large' />
				</Form.Item>
			</Form>
		</>
	);
}
