import API from "../../API";

export const GET_LOGS = async () => {
  return await API.GET("/cleopatra/my-data-audit-logs/");
}

export const GET_DERIVED_METRICS_REPRESENTATION_GRAPH = async () => {
  return await API.GET("/cleopatra/derived-metrics-representation-graph/");
}

export const GET_AVAILABLE_SYSTEMS = async () => {
  return await API.GET("/developer/available-systems/");
}

export const GET_INTEGRATIONS = async () => {
  return await API.GET("/developer/integration/");
}

export const POST_INTEGRATION = async (data: any) => {
  return await API.POST("/developer/integration/", data);
}

export const PATCH_INTEGRATION_BY_ID = async (id: number, data: any) => {
  return await API.PATCH(`/developer/integration/${id}/`, data);
}

export const GET_SYSTEM_MAPPING = async () => {
  return await API.GET(`/developer/system-mapping/`);
}

export const POST_SYSTEM_MAPPING = async (data: any) => {
  console.log(data);
  return await API.POST(`/developer/system-mapping/`, data);
}

export const PATCH_SYSTEM_MAPPING = async (id: number, data: any) => {
  return await API.PATCH(`/developer/system-mapping/${id}/`, data);
}

export const DELETE_SYSTEM_MAPPING = async (id: number) => {
  return await API.DELETE(`/developer/system-mapping/${id}/`);
}

export const GET_MODULES = async () => {
  return await API.GET(`/subscriptions/modules/`);
}

export const GET_MODULE_SUBSCRIPTIONS = async () => {
    return await API.GET(`/subscriptions/my-subscriptions/`);
}
