import * as React from 'react';
import './style.scss';
import { MetricIconNameList } from '../../../../views/Onboarding/Step4/SideFilter/SIdeItem';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import ToolTip from '../../../../Components/BEToolTip';
import TargetProgressBar from '../../../TargetsNew/Components/TargetProgressBar';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { navigateTo } from '../../../../Navigation/NavigationManager';

export interface IDashboardMetricCardProps {
  bcode: string;
  progress: number;
  metricName: string;
  metricCategory: string;
  metricUnit: string;
  currentValue: number;
  baselineValue: number | null;
  targetType: string | null;
  targetValue: number | null;
}

export function DashboardMetricCard (props: IDashboardMetricCardProps) {
  const [icon, setIcon] = React.useState<React.ReactNode>(null);
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    let TempIcon  = MetricIconNameList.find((item) => item.name === props.metricCategory)?.icon || MetricIconNameList[0]?.icon;
    setIcon(<TempIcon stroke={'black'} inheritSize/>)
  }, [props.metricCategory]);

  const getUnitToDisplay = (unit:string) => {
    if(unit === 'percentage') return '%';
    unit = unit.toLowerCase().trim();
    const unitsToIgnore:any[] = ['number','ratio'] 
    if(unitsToIgnore.includes(unit)) return '';
    unit = unit.charAt(0).toUpperCase() + unit.slice(1); 
    return unit;
  }

  return (
    <div className='dashboard-metric-card'>
      <div className='top-metric-card-section'>
        <div className='metric-icon'>
          {icon}
        </div>
        <p className='drill-down' onClick={() => navigateTo(`/home/dashboard/drilldown/${props.bcode}`)}>
          Drill down →
        </p>
      </div>
      <ToolTip title={props.metricName}>
        <p className='metric-title'>
          {props.metricName}
        </p>
      </ToolTip>
      <p className='metric-value'>
        {AddCommaToNumber(props.currentValue,false,true,true)}
      </p>
      <p className='metric-unit'>
        {getUnitToDisplay(props.metricUnit)}
      </p>
      <div className='dashboard-metric-card-progress'>
        {
          props.targetType !== null && props.targetValue !== null && props.currentValue !== null && props.baselineValue !== null ?
            <div>
              <TargetProgressBar
                targetType={props.targetType}
                progress={props.progress}
                baseLineValue={props.baselineValue}
                targetValue={props.targetValue}
                currentValue={props.currentValue}
                unit={getUnitToDisplay(props.metricUnit)}
              />
            </div>
          :
            <div>
              <p className='dashboard-metric-card-progress-text'>Please set target from Target section</p>
            </div>
        }
      </div>
        
    </div>
  );
}
