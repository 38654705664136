import React from 'react'
import { BETabButton } from '../../../../Components/BETabButton';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import Dashboard from '../../../../Components/BEIcons/Dashboard';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useLocation } from 'react-router-dom';
import { MonthsSlicer } from '../../../DataManager/Components/TopSectionDM/MonthsSlicer';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import Analytics from '../../../../Components/BEIcons/Analytics';
import Insights from '../../../../Components/BEIcons/InsightsIcon';

const TopSection = () => {
  // const dispatch = useAppDispatch();
  const location = useLocation();
  const CompanyID = useTypedSelector((state) => state.companies.companyDetails?.data?.id);
  const role = useTypedSelector((state) => state.user.userRole);

  return (
    <div className="global-top-section">
      <BETabButton
        title="OVERVIEW"
        icon={
          <Dashboard
            stroke={
              // PrimaryTheme.primaryGreen
              !["custom", "insights"].some(path => location.pathname.includes(path))
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={!["custom", "insights"].some(path => location.pathname.includes(path))        }
        onClick={() => navigateTo("/home/dashboard")}
      />
      {role !== 'AUDITOR' && <BETabButton
        title="CUSTOM"
        icon={
          <Analytics
            stroke={
              location.pathname.includes("custom")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("custom")}
        onClick={() => navigateTo("/home/dashboard/custom")}
      />}
      <BETabButton
        title="INSIGHTS"
        icon={
          <Insights
            stroke={
              location.pathname.includes("insights")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("insights")}
        onClick={() => navigateTo("/home/dashboard/insights")}
      />
      <div style={{ marginLeft: 'auto' }}><MonthsSlicer /></div>
    </div>
  );
}

export default TopSection