import React, { useEffect, useState } from 'react'
import { Form, Checkbox } from 'antd';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getBusinessGroups } from '../../../../views/Actions/BusinessGroupActions';
import { getBusinessUnits } from '../../../../views/Actions/BusinessUnitActions';
import SwitchIcon from '../../../../Components/BEIcons/SwitchIcon';
import './style.scss'
import { BEMessage } from '../../../../Components/BEMessage';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import BEStatusTag from '../../../../Components/BEStatusTag';
import { Scope2EmissionConfigurationType } from '../../../../Redux/Types/emisionsTypes';
import { patchScope2EmissionConfigurations } from '../../Actions';


interface ApplyScope2EFDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentEF: any;
  currentMetric: any;
}

export function ApplyScope2EFDrawer(props:ApplyScope2EFDrawerProps)  {

  const dispatch = useAppDispatch()
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups)
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits)
  const [selectedBus, setSelectedBus] = useState<any>([])
  const [openedBgs, setOpenedBgs] = useState<any>([])
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const currentTemplate = useTypedSelector(state => state.reporting.currentTemplate);
  const scope2Configurations = useTypedSelector(state => state.emissions.configurations.scope2EmissionConfigurations)
  const Scope2EmissionFactors = useTypedSelector(state => state.emissions.emissionFactors.scope2EmissionFactors)
  const [assignedBusinessUnits, setAssignedBusinessUnits] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [businessUnitSet, setBusinessUnitSet] = useState<any>(new Set());

  useEffect(() => {
    if(businessUnitRefs?.status === 'success') 
      setBusinessUnitSet(new 
          Set(businessUnitRefs?.data.map((ref: any) => ref.business_unit))
      );
  }, [businessUnitRefs])

  useEffect(() => {
    if(businessUnits.status !== 'success' || scope2Configurations.status !== 'success' || Scope2EmissionFactors.status !== 'success' || !props.currentMetric) return;
    let tempAssignedBusinessUnits: any = {};
    scope2Configurations.data.filter((config: any) => config.my_metric === props.currentMetric.id).forEach((_config: Scope2EmissionConfigurationType) => {
      if(_config.default_emission_factor) tempAssignedBusinessUnits[_config.business_unit] = Scope2EmissionFactors.data.find((ef: any) => ef.id === _config.default_emission_factor && ef.type === 'Default')?.ef_name;
      else if(_config.emission_factor) tempAssignedBusinessUnits[_config.business_unit] = Scope2EmissionFactors.data.find((ef: any) => ef.id === _config.emission_factor && ef.type === 'Custom')?.ef_name;   
    });
    setAssignedBusinessUnits(tempAssignedBusinessUnits);
  }, [businessUnits,scope2Configurations,Scope2EmissionFactors,props.currentMetric])

  useEffect(() => {
    if (businessGroups?.status !== 'success') dispatch(getBusinessGroups());
    if (businessUnits?.status !== "success") dispatch(getBusinessUnits());
  }, []);

  const handleClickBG = (e: any) => {
    let selectedBUSOtherThanThisBG = selectedBus.filter((bu: any) => {
      return businessUnitRefs?.data.filter((ref: any) => ref.business_unit === bu && ref.business_group === e.target.value).length === 0
    })
    if (e.target.checked) {
      let busToAdd = businessUnitRefs?.data.filter((ref: any) => ref.business_group === e.target.value).map((ref: any) => ref.business_unit)
      setSelectedBus([...selectedBUSOtherThanThisBG, ...busToAdd])
    } else {
      setSelectedBus(selectedBUSOtherThanThisBG)
    }
  }

  const handleClickBU = (e: any) => {
    if (e.target.checked) {
      setSelectedBus([...selectedBus, e.target.value])
    } else {
      setSelectedBus(selectedBus.filter((bus: any) => bus !== e.target.value))
    }
  }

  // const handleClickAll = (e: any) => {
  //   if (e.target.checked) {
  //     let allBusIds = businessUnits?.data.map((bu: any) => bu.id)
  //     setSelectedBus(allBusIds)
  //   } else {
  //     setSelectedBus([])
  //   }
  // }

  const handleClickAllBusinessGroups = (e: any) => {
    let allBusIds = businessUnitRefs?.data.map((ref: any) => ref.business_unit)
    if (e.target.checked) {
      setSelectedBus([...selectedBus,...allBusIds])
    } else {
      setSelectedBus(selectedBus.filter((bus: any) => !allBusIds.includes(bus)))
    }
  }

  const handleClickAllBusinessUnits = (e: any) => {
    let allBusIds = businessUnits?.data.filter((bu) =>
         !businessUnitSet.has(bu.id)).map((bu: any) => bu.id)
    if (e.target.checked) {
      setSelectedBus([...selectedBus, ...allBusIds])
    } else {
      setSelectedBus(selectedBus.filter((bus: any) => !allBusIds.includes(bus)))
    }
  }


  const handleClickOpen = (id: any) => {
    if (openedBgs.includes(id)) {
      setOpenedBgs(openedBgs.filter((bg: any) => bg !== id))
    } else {
      setOpenedBgs([...openedBgs, id])
    }
  }

  const handleSave = async() => {
    let dataToSave: any = [];
    selectedBus.forEach((bu: any) => {
      let _config  = scope2Configurations.data.find((config: any) => config.business_unit === bu && config.my_metric === props.currentMetric.id);
      if(!_config) return;
      let config_to_save:any = {id: _config?.id}
      if(props.currentEF.type === 'Default') config_to_save['default_emission_factor'] = props.currentEF.id;
      else config_to_save['emission_factor'] = props.currentEF.id;

      dataToSave.push(config_to_save);
    })
    setLoading(true);
    await dispatch(patchScope2EmissionConfigurations(props.currentMetric.id, dataToSave));
    setLoading(false);

    props.setOpen(false);
    
  }

  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      heading='Apply emission factor'
      width='881px'
      footer={
        <>
          <BEButton className='primary' size='large' onClick={handleSave} 
            loading={loading}
          >Apply</BEButton>
          <BEButton size='large' onClick={() => props.setOpen(false)} >Cancel</BEButton>
            
        </>
      }
      >
        <div style={{ padding: "0 1rem" }} className="apply-ef-drawer">
          <>
            {/* business grps and units */}
            {currentTemplate?.framework !== 'GRESB' &&
            <>
              <div className="select-bus">
                <div className="business-group-top-heading">
                  <div className="check-box">
                    <Checkbox
                      onClick={(e) => handleClickAllBusinessGroups(e)}
                      checked={[...(Array.from(businessUnitSet))]
                          .every((bu_id) => selectedBus.includes(bu_id))}
                    />
                    <p>Business Groups</p>
                  </div>
                </div>

                {
                  businessGroups?.data.map((bg: any) => {
                    return (
                      <div className="business-group" style={{ borderBottom: `1px solid ${PrimaryTheme.primaryGreyLight}` }}>
                        <div className="business-group-heading">
                          <div className="check-box">
                            <Checkbox
                              disabled={
                                businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).length === 0
                              }
                              value={bg.id}
                              checked={
                                businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).every((ref: any) => selectedBus.includes(ref.business_unit))
                                && businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).length > 0
                              }
                              onChange={(e) => handleClickBG(e)}
                            />
                            <p style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(bg.id)}>{bg.name}</p>
                          </div>
                          {
                            businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).length > 0 &&
                            (
                              <div
                                className={`switchs ${openedBgs.includes(bg.id) ? 'active' : ''}`}
                                onClick={() => handleClickOpen(bg.id)}
                              >
                                <SwitchIcon
                                  inheritSize
                                  stroke={PrimaryTheme.primaryGray}
                                />
                              </div>
                            )}
                        </div>
                          {openedBgs.includes(bg.id) &&
                        <div className="business-units">
                          <div className="business-unit-heading business-unit">
                            <p></p>
                            <p>Business Unit</p>
                            <p>Status</p>
                            <p>Assigned Emmision Factor</p>
                          </div>
                           { 
                            businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).map((ref: any) => ref.business_unit)
                                .map((bu: any) => {
                                  return (
                                    <div className="business-unit">
                                      <Checkbox
                                        checked={selectedBus.includes(bu)}
                                        onChange={(e) => handleClickBU(e)}
                                        value={bu}
                                      />
                                      <p>{
                                        businessUnits?.data?.find((unit: any) => unit.id === bu)?.name
                                      }</p>
                                      <div>
                                        <BEStatusTag 
                                          status={assignedBusinessUnits[bu] ? 'success' : 'error'}
                                          size='small'
                                         >
                                          {
                                            assignedBusinessUnits[bu] ? 'Applied' : 'Pending'
                                          }
                                         </BEStatusTag>
                                      </div>
                                      <p>
                                        {
                                          assignedBusinessUnits[bu] ? assignedBusinessUnits[bu] : '-'
                                        }
                                      </p>
                                    </div>
                                  );
                                })
                                }
                          </div>
                              
                          }
                      </div>
                    );
                  })
                }
              </div>
              <div className="select-bus">
                <div className="business-group-top-heading">
                  <div className="check-box">
                    <Checkbox
                      onClick={(e) => handleClickAllBusinessUnits(e)}
                      checked={businessUnits?.data.
                          filter((bu) => !businessUnitSet.has(bu.id)).
                            every((bu) => selectedBus.includes(bu.id))
                      }
                    />
                    <p>Unassigned Business Units</p>
                  </div>
                </div>

                
                 <div className='business-group' 
                    style={{ 
                      borderBottom: `1px solid ${PrimaryTheme.primaryGreyLight}`
                    }}>
                 <div className="business-units">
                 <div className="business-unit-heading business-unit">
                   <p></p>
                   <p>Business Unit</p>
                   <p>Status</p>
                   <p>Assigned Emmision Factor</p>
                 </div>
                  { 

                   businessUnits?.data
                    .filter((bu) => !businessUnitSet.has(bu.id))
                       .map((bu: any) => {
                         return (
                           <div className="business-unit">
                             <Checkbox
                               checked={selectedBus.includes(bu.id)}
                               onChange={(e) => handleClickBU(e)}
                               value={bu.id}
                             />
                             <p>{bu.name}</p>
                             <div>
                               <BEStatusTag 
                                 status={
                                    assignedBusinessUnits[bu.id] ? 
                                    'success' : 'error'}
                                 size='small'
                                >
                                 {
                                   assignedBusinessUnits[bu.id] ?
                                     'Applied' : 'Pending'
                                 }
                                </BEStatusTag>
                             </div>
                             <p>
                               {
                                 assignedBusinessUnits[bu.id] ? 
                                  assignedBusinessUnits[bu.id] : '-'
                               }
                             </p>
                           </div>
                         );
                       })
                       }
                 </div>
             </div>
              </div>
              </>
            }
          </>
      </div>
      </BEDrawer>
  );
};