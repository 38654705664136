import React, { useEffect } from "react";
import { BEDrawer } from "../../../Components/BEDrawer";
import { BEButton } from "../../../Components/BEFormItems/BEButton";
import { Checkbox, Form, Select, Space } from "antd";
import { LableRequired } from "../../../Components/BEFormItems/LableRequired";
import { BEInput } from "../../../Components/BEFormItems/BEInput";
import { addUser, editUser, getAllUsers } from "../../Actions/UsersManagementActions";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { BEEyeButton } from "../../../Components/BEEyeButton";
import BEMultipleSelect from "../../../Components/BEMultipleSelect";
import { UserDepartments } from "../../../Config/Data/genralData";
import { roleMap } from "../../../Config/Data/UsersMap";
import { BEMessage } from "../../../Components/BEMessage";
import { getModuleSubscriptions } from "../../../Features/Settings/Actions";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import ToolTip from "../../../Components/BEToolTip";
import InfoIcon from "../../../Components/BEIcons/InfoIcon";

interface BEAddUserProps {
    open: boolean;
    setOpen: Function;
    isEdit?: boolean;
}

const AddUser = (props: BEAddUserProps) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const addStatus = useTypedSelector((state) => state.userMgmt.addStatus);
    const currentUser = useTypedSelector((state) => state.common.selectedRow);
    const users = useTypedSelector((state) => state.userMgmt);
    const userProfile = useTypedSelector((state) => state.user.userProfile);
    const moduleSubscriptions = useTypedSelector((state) => state.settings.moduleSubscriptions);
    // const [departmentHead, setDepartmentHead] = React.useState(false);
    const [moduleCodes, setModuleCodes] = React.useState<string[]>([]);

    const onFinish = async (values: any) => {
        const existingUserDepartments = users?.data.filter((user) => user.role === "DEPARTMENT_HEAD").map((user) => user.department);
        if (values.role === "DEPARTMENT_HEAD" && existingUserDepartments.includes(values.department)) {
            if (props.isEdit) {
                if (currentUser.role === "DEPARTMENT_HEAD" && currentUser.department !== values.department) {
                    BEMessage.error("Department head already exists for this department");
                    return;
                }
            } else {
                BEMessage.error("Department head already exists for this department");
                return;
            }
        }
        let objToSend: any = values;
        objToSend["status"] = values?.status === "Active" ? true : false;
        if (moduleCodes.length === 0) {
            BEMessage.error("Please give permissions for at least one app");
            return;
        } else {
            objToSend["module_codes"] = moduleCodes.join(",");
        }
        // objToSend["department_head"] = departmentHead;
        if (props.isEdit) {
            await dispatch(editUser(objToSend, currentUser.id));
        } else {
            // delete objToSend.is_active;
            await dispatch(addUser(objToSend));
        }
        form.resetFields();
        props.setOpen(false);
    };

    useEffect(() => {
        if (users?.status === "idle")
            dispatch(getAllUsers());
        if (moduleSubscriptions?.status === "idle")
            dispatch(getModuleSubscriptions());
    }, []);

    useEffect(() => {
        if (
            userProfile?.status === "success" &&
            moduleSubscriptions?.status === "success" &&
            props.isEdit &&
            currentUser
        ) {
            const moduleIds = currentUser?.modules?.split(",");
            if (moduleIds) {
                const modulesToSet = moduleSubscriptions?.data.filter(
                    (item) => moduleIds.includes(String(item.module)));
                setModuleCodes(modulesToSet.map((item) => item.module_code!));
            }
        }
    }, [
        moduleSubscriptions,
        currentUser
    ]);

    useEffect(() => {
        if (props?.isEdit) {
        } else {
            setModuleCodes([]);
        }
    }, [props.open]);



    useEffect(() => {
        if (props.isEdit) {
            form.setFieldsValue({
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                email: currentUser.email,
                role: currentUser.role,
                department: currentUser.department,
                status: currentUser?.status ? "Active" : "Inactive",
            });
            // setDepartmentHead(currentUser?.department_head);
        }
    }, [currentUser]);
    return (
        <div>
            <BEDrawer
                open={props.open}
                setOpen={props.setOpen}
                heading={props.isEdit ? "Edit User" : "Add User"}
                width="400"
                footer={
                    <div>
                        <BEButton
                            size="large"
                            className="primary"
                            onClick={() => {
                                form.submit();
                            }}
                            loading={addStatus === "loading"}
                        >
                            {props.isEdit ? "+ Edit User" : "+ Add User"}
                        </BEButton>
                        <BEButton
                            style={{ marginLeft: "0.5rem" }}
                            size="large"
                            onClick={() => {
                                form.resetFields();
                                props.setOpen(false);
                            }}
                        >
                            Cancel
                        </BEButton>
                    </div>
                }
            >
                <Form form={form} onFinish={onFinish}>
                    <LableRequired>First Name </LableRequired>
                    <Form.Item
                        name="first_name"
                        rules={[{ required: true, message: "Please enter first name" }]}
                    >
                        <BEInput size="large" placeholder="First name of user" />
                    </Form.Item>

                    <LableRequired>Last Name </LableRequired>
                    <Form.Item
                        name="last_name"
                        rules={[{ required: true, message: "Please enter last name" }]}
                    >
                        <BEInput size="large" placeholder="Last name of user" />
                    </Form.Item>
                    <LableRequired>Email </LableRequired>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "Please enter email" }]}
                    >
                        <BEInput size="large" placeholder="User's email ID" />
                    </Form.Item>

                    {
                        (currentUser.role !== 'SUPER_ADMIN' || !props.isEdit) &&
                        <>
                            <LableRequired>Role </LableRequired>
                            <Form.Item
                                name="role"
                                rules={[{ required: true, message: "Please enter role" }]}
                            >
                                <Select size="large" placeholder="Role of the user">
                                    {
                                        ['ADMIN', 'CONTRIBUTOR', 'AUDITOR', 'DEPARTMENT_HEAD'].map((item) => {
                                            return <Select.Option value={item}>
                                                <Space>{roleMap[item]}</Space>
                                            </Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </>
                    }

                    <BEEyeButton
                        title=""
                        discription={'Refers to the Business Function the user belongs to in your organization.'}
                    >
                        <LableRequired>Department</LableRequired>
                    </BEEyeButton>

                    <Form.Item
                        name="department"
                        rules={[{ required: true, message: "Please enter department" }]}
                    >
                        <Select size="large" placeholder="Department of the user">
                            {UserDepartments.map((item) => (
                                <Select.Option value={item.name}>
                                    <Space>{item.name}</Space>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>


                    {/* 
          <BEMultipleSelect single isDataStrArray data={UserDepartments.map((item) => item.name)} name="department" /> */}

                    {/* <Form.Item>
            <Checkbox name="department_head"
              checked={departmentHead}
              onChange={(e) => setDepartmentHead(e.target.checked)}
            >Is this the Department head</Checkbox>
          </Form.Item> */}

                    {props.isEdit && (
                        <>
                            <LableRequired>Status </LableRequired>
                            <Form.Item
                                name="status"
                                rules={[{ required: true, message: "Please enter status" }]}
                            >
                                <Select size="large" placeholder="Status of the user">
                                    <Select.Option value="Active">
                                        <Space>Active</Space>
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Space>Inactive</Space>
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    )}
                    <LableRequired>App permissions </LableRequired>
                    {
                        moduleSubscriptions?.data.map((item, index) => {
                            return <div
                                key={index}
                                style={{
                                    marginBottom: '0.5rem',
                                    border: `1px solid ${PrimaryTheme.primaryGreenLight}`,
                                    padding: '0.5rem',
                                }}
                            >
                                <Checkbox
                                    value={item.module_code}
                                    key={item.module}
                                    disabled={
                                        (currentUser.role === 'SUPER_ADMIN'
                                        ||
                                        currentUser?.id ===
                                        userProfile?.data?.id)
                                        && props.isEdit
                                    }
                                    checked={
                                        moduleCodes.includes(
                                            item.module_code
                                        )
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setModuleCodes([...moduleCodes,
                                            item.module_code!]);
                                        } else {
                                            setModuleCodes(
                                                moduleCodes.filter(
                                                    (code) => code !==
                                                        item.module_code
                                                )
                                            );
                                        }

                                    }}
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {item.module_name}
                                        <ToolTip title={item.subscription_description}>
                                            <div
                                                style={{
                                                    height: '1rem',
                                                    width: '1rem'
                                                }}>
                                                <InfoIcon
                                                    inheritSize
                                                    fill={PrimaryTheme.primaryGreyDark}
                                                />
                                            </div>
                                        </ToolTip>
                                    </span>
                                </Checkbox>
                            </div>
                        })
                    }
                </Form>
            </BEDrawer>
        </div>
    );
};

export default AddUser;
