import React, { useEffect, useState } from "react";
import { BEDrawer } from "../../../../../Components/BEDrawer";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import { addSupplierAssessmentRef, bulkUploadGlobalSuppliersByExcel, getSupplierSurveyRefs, getSuppliers } from "../../../Actions/supplierAction";
import { ColumnsType } from "antd/es/table";
import { useTypedSelector } from "../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import AddSupplier from "../AddSupplier";
import { Col, Row } from "antd";
import { BEInput } from "../../../../../Components/BEFormItems/BEInput";
import { BETable } from "../../../../../Components/BETable";
import { setSuppliers } from "../../../../../Redux/SupplierReducer";
import { useParams } from "react-router-dom";
import { BEBulkUploadDrawer } from "../../../../../Components/BEDrawer/BEBulkUploadDrawer";
interface AddSuppliersProps {
  open: boolean;
  setOpen: Function;
}

const AddSuppliers = (props: AddSuppliersProps) => {
    const dispatch = useAppDispatch();
    const {assessment_id} = useParams();
    const allSuppliers = useTypedSelector((state) => state.supplier.Suppliers);
    const currentAssessment = useTypedSelector(state => state.supplier.currentAssessment);
    const assessmentRef = useTypedSelector((state) => state.supplier.SuppliersSurveyRefs);  
    const [addSupplier, setAddSupplier] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [filteredSuppliers, setFilteredSuppliers] = useState<any>([]);
    const [openBulkUpload, setOpenBulkUpload] = useState(false);

    useEffect(() => {
      setFilteredSuppliers(allSuppliers?.data);
    }, [allSuppliers?.data]);

    const columns: ColumnsType<any> = [
      {
        title: "PARTNER NAME",
        dataIndex: "name",
      },
      {
        title: 'PCODE',
        dataIndex: 'code',
        render: (value) => !value ? "-" : value,
      },
      {
        title: 'DESCRIPTION',
        dataIndex: 'description',
        filters: filteredSuppliers?.filter((supplier: any)=> supplier.internal === false && supplier.description).map((item: any) => {
            return {
              text: item.description,
              value: item.description,
            };
        }),
        onFilter: (value: any, record: any) => record.description === value,
        render: (value) => !value ? "-" : value,
      },
      {
        title: "POC",
        dataIndex: "poc",
      },
      {
        title: "POC EMAIL",
        dataIndex: "email",
      },
      {
        title: "INDUSTRY",
        dataIndex: "industry",
      },
      // {
      //   title: "LAST COMPLIANCE",
      //   dataIndex: "last_compliance_fy",
      //   render: (value) => {
      //     return !value ? "-" : value;
      //   },
      // },
    ];

    const handleAddSuppliers = async () => {
      setLoading(true);
      const body: Array<any> = [];
      selectedSuppliers.forEach((supplier: any) => {
        body.push({
          supplier: supplier,
          assessment: Number(assessment_id ? assessment_id : currentAssessment?.id),
        });
      });
      // console.log(body)
      await dispatch(addSupplierAssessmentRef(body));
      setLoading(false);
      props.setOpen(false)
    };
  return (
    <BEDrawer
      open={props.open}
      width="60vw"
      setOpen={props.setOpen}
      heading="Add Partners"
      footer={
        <>
          <BEButton
            className="primary"
            size="large"
            onClick={handleAddSuppliers}
            loading={loading}
          >
            Save
          </BEButton>
          <BEButton size="large" onClick={() => props.setOpen(false)}>
            Cancel
          </BEButton>
        </>
      }
    >
      <div style={{ padding: "1rem" }}>
        {addSupplier && (
          <AddSupplier open={addSupplier} setOpen={setAddSupplier} />
        )}

        <Row>
          <Col span={8}>
            <BEInput search placeholder="Search for a partner" size="large" 
            onChange={(e) => {
              if (e.target.value === "") {
                setFilteredSuppliers(allSuppliers?.data);
              } else {
                setFilteredSuppliers(
                  allSuppliers?.data.filter((supplier: any) =>
                    supplier.name
                      ?.toLowerCase()
                      .includes(e.target.value?.toLowerCase())
                  )
                );
              }
            }}
            />
          </Col>
          <Col span={16} style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
            <BEButton
                size="large"
                onClick={() => setOpenBulkUpload(true)}
              >
                Bulk Upload
              </BEButton>
              <BEButton
                size="large"
                className="primary"
                onClick={() => setAddSupplier(true)}
              >
                + Add New Partner
              </BEButton>
            </div>
          </Col>
        </Row>

        <div style={{ margin: "1rem auto" }}>
          <BETable
            data={filteredSuppliers.filter((supplier: any) =>  {
              return supplier.internal === false;
            })}
            columns={columns}
            pagination
            handleRowSelection={(keys: any) => {
              setSelectedSuppliers(keys);
            }}
            rowKey="id"
            defaultSelectedRows={filteredSuppliers.filter((supplier: any) => {
              let flag = true;
              assessmentRef?.data.forEach((ref: any) => {
                if (ref.supplier === supplier.id)
                 flag = false;
              });
              return !flag;
            }).map((obj:any) => obj.id)}
            disabledRows={filteredSuppliers.filter((supplier: any) => {
              let flag = true;
              assessmentRef?.data.forEach((ref: any) => {
                if (ref.supplier === supplier.id)
                 flag = false;
              });
              return !flag;
            })}
          />
        </div>
      </div>
      <BEBulkUploadDrawer
          fileKey='SUPPLIERS'
          open={openBulkUpload}
          setOpen={setOpenBulkUpload}
          heading="Bulk Upload Partners"
          uploadFunction={bulkUploadGlobalSuppliersByExcel}
          columnArray={['name', 'poc', 'email', 'industry']}
          fileName='SuppliersTemplate'
        />
    </BEDrawer>
  );
};

export default AddSuppliers;
