import { Progress, ProgressProps } from 'antd';
import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import './style.scss';
interface IReportProgressCardProps {
	reportName: string;
	icon: string;
	percent: number;
}

export function ReportProgressCard(props: IReportProgressCardProps) {
	const conicColors: ProgressProps['strokeColor'] = {
		'0%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
		'40%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
		'85%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
		'100%': PrimaryTheme.primaryGreen,
	};
	return (
		<div className='report-progress-card'>
			<img className='icon-container' src={props.icon}/>
			<div style={{width: '100%'}}>
				<h3 style={{fontWeight:400}}>{props.reportName}</h3>
				<Progress showInfo={true} percent={Number(AddCommaToNumber(props.percent))} strokeColor={conicColors} />
			</div>
		</div>
	);
}
