import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { Col, Form, Row, Select } from 'antd';
import { selectedMetricType } from '../../../../../Redux/Types/dataManagerTypes';
import React from 'react';
import { updateTarget } from '../../../Actions/targetAction';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

export interface ISetupLimitsProps {
    open: boolean;
    setOpen: Function;
    limitData: selectedMetricType;
}

export function SetupLimits(props: ISetupLimitsProps) {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const onCancel = () => {
        props.setOpen(false)
    }

    const handleForm = async (values: any) => {
        const body = {
            min_limit: values.minValue,
            max_limit: values.maxValue,
            limit_status: true
        };
        setLoading(true);
        await dispatch(updateTarget(props.limitData.id, body));
        setLoading(false);
        onCancel()
    }

    React.useEffect(() => {
        form.resetFields(["minValue", "maxValue"]);
        if (props.limitData.limit_status) {
            form.setFieldsValue({
                minValue: props.limitData.min_limit,
                maxValue: props.limitData.max_limit
            })
        }
    }, [props.limitData]);

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading='Setup limits'
            width='fit-content'
            footer={
                <>
                    <BEButton className='primary' size='large'
                        onClick={() => {
                            form.submit()
                        }}
                        loading={loading}
                    >Set Limit</BEButton>
                    <BEButton size='large'
                        onClick={onCancel}
                    >Cancel</BEButton>
                </>
            }
        >
            <div className='add-materiality-assessment'>
                <Row className='setupHead' align="middle">
                    <Col span={11} offset={2}>
                        <b>Metric Name</b>
                    </Col>
                    <Col span={11}>
                        {props.limitData.custom_title || props.limitData.title}
                    </Col>
                </Row>
                <Form form={form} onFinish={handleForm}>
                    <Row>
                        <Col span={11}>
                            <Row>
                                <LableRequired>Min Value</LableRequired>
                            </Row>
                            <Row>
                                <Form.Item
                                    name="minValue"
                                    rules={[{ required: true, message: "Please enter limit" }]}
                                >
                                    <BEInput style={{ height: "40px" }} />
                                </Form.Item>
                            </Row>
                        </Col>
                        <Col span={11} offset={2}>
                            <Row>
                                <LableRequired>Max Value</LableRequired>
                            </Row>
                            <Row>
                                <Form.Item
                                    name="maxValue"
                                    rules={[{ required: true, message: "Please enter limit" }]}
                                >
                                    <BEInput style={{ height: "40px" }} />
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>


                </Form>

            </div >
        </BEDrawer >
    );
}

