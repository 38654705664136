import * as React from 'react';
import './style.scss';
import { graphColors4 } from '../../../../Config/Theme/graphColors';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { getScaledPercentage } from '../../../../Config/Functions/ScalingFunctions';
import ToolTip from '../../../../Components/BEToolTip';

export interface IHorizontalStackBarProps {
  data: {name: string, value: number}[];
  unit?: string;
}

export function HorizontalStackBar (props: IHorizontalStackBarProps) {
  interface IBarData {
    name: string;
    value: number;
    widthPercentage: number;
    percentageValue: number;
  }
  const [dataToDisplay, setDataToDisplay] = React.useState<IBarData[]>([]);

  React.useEffect(() => {
    if (props.data.length === 0) return;
    let data = props.data;
    let total = data.reduce((acc, item) => acc + item.value, 0);
    let scaledPercentageValues = getScaledPercentage(data.map(item => item.value));
    let dataToDisplay = data.map((item:any,index:number) => ({
      name: item.name, 
      value: item.value, 
      widthPercentage: scaledPercentageValues[index],
      percentageValue: total === 0 ? 0 :(item.value / total) * 100
    })).sort((a,b) => b.value - a.value);
    setDataToDisplay(dataToDisplay);
  }, [props.data]);

  return (
    <div className='horizontal-stack-bar'>
      <p className='title'>
        {
         AddCommaToNumber(props.data.reduce((acc, item) => acc + item.value, 0),false,true) + (props.unit ? ` ${props.unit}` : '')
        }
      </p>
      <div className='bar-wrapper'>
        {
        dataToDisplay.map((item, index) => (
          <div 
            className='bar' 
            style={{
              width: `${item.widthPercentage}%`, 
              backgroundColor: graphColors4[index%graphColors4.length]
            }}
          >
            <div className='bar-tooltip'>
              {AddCommaToNumber(item.value, false, true, true)}
              {props.unit ? ` ${props.unit}` : ''} &nbsp;
              ( {(item.percentageValue).toFixed(2)}% )
            </div>
          </div>
        ))
        }
      </div>
      <div className='label-wrapper'>
        {
          dataToDisplay.map((item, index) => (
            <div className='label-container' key={index}>
              <div className='label-color' style={{backgroundColor: graphColors4[index%graphColors4.length]}}/>
              <p className='label-name'>
                {item.name} &nbsp;
                <strong>
                  ({AddCommaToNumber(item.value, false, true, true)}{props.unit ? ` ${props.unit}` : ''})
                </strong>
              </p>
            </div>
          ))
        }
      </div>
    </div>
  );
}
