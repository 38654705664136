import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { TemplateSelectedTopicTypes } from '../../../../../Redux/Types/reportingTypes';
import { Type0ReviewTable } from '../Type0ReviewTable';
import { Type1Topic } from '../../Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type1';
import { Type2Topic } from '../../Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type2';
import { Type3Topic } from '../../Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type3';
import { Type4Topic } from '../../Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type4';
import { ReportingReviewStatusButton } from '../StatusButton';
import { TopicEvidanceTable } from '../../Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/EvidenceTable';
import { ReportingReviewEvidenceDrawer } from '../EvidenceDrawer';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { getTemplateTopics } from '../../../Actions/template';
import { setAllSelectedTemplateTopics } from '../../../../../Redux/ReportingReducer';

export interface IReviewTableProps {
    category: string;
    section: string;
    isPDFTemplate?: boolean;
}

export function ReviewTable(props: IReviewTableProps) {
    const dispatch = useAppDispatch();
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const allSelectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
    const reportData = useTypedSelector(state => state.reporting.reportData);
    const allReports = useTypedSelector(state => state.reporting.allReports);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const templateTopics = useTypedSelector(state => state.reporting.templateTopics);
    const topicStatus = useTypedSelector(state => state.reporting.topicStatus);
    const [currentUniqueCode, setCurrentUniqueCode] = React.useState<string>('');
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
    const evidence = useTypedSelector(state => state.reporting.evidence);
    
    
  const getSelectedTopics = async () => {

    if (currentReport?.template) {
        const res = await dispatch(getTemplateTopics(currentReport?.template, false))
        if (res)
            dispatch(setAllSelectedTemplateTopics({
                status: 'success',
                data: res
            }))
    }
  }

  

  React.useEffect(() => {
    if (allSelectedTopics.status === 'idle') getSelectedTopics();
  }, [currentReport]);

    return (
        <div>
            <Type0ReviewTable
                category={props.category}
                section={props.section}
                setCurrentTopic={setCurrentUniqueCode}
                setOpenDrawer={setOpenDrawer}
                isPDFTemplate={props.isPDFTemplate}
            />
            {
                Array.from(new Set(selectedTopics?.data.filter((topic: TemplateSelectedTopicTypes) => topic.section === props.section && topic.category === props.category).map((topic2: TemplateSelectedTopicTypes) => topic2.unique_code))).map((uniqueCode: string) => {
                    let topicName = selectedTopics?.data.filter((topic3: TemplateSelectedTopicTypes) => topic3.unique_code === uniqueCode)[0]!.topic;
                    let disclosureCode = selectedTopics?.data.filter((topic3: TemplateSelectedTopicTypes) => topic3.unique_code === uniqueCode)[0]!.disclosure_code;
                    return (
                        <>
                            {
                                selectedTopics?.data.filter((topic4: TemplateSelectedTopicTypes) => topic4.section === props.section && topic4.category === props.category && topic4.unique_code === uniqueCode)[0].table_type !== 0
                                &&
                                <div>
                                    <div className='topic-box'>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                                            <p>Topic{topicName}</p>
                                            {
                                                !props.isPDFTemplate &&
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                                                    {
                                                        evidence?.data.filter((evidence2: any) => evidence2.unique_code === uniqueCode)?.length > 0 &&
                                                        <div
                                                            onClick={() => setOpenDrawer(true)}
                                                            onMouseEnter={() => setCurrentUniqueCode(uniqueCode)}
                                                            style={{cursor:'pointer'}}
                                                        >
                                                            <BEStatusTag status='info' size='large' >
                                                                Evidences
                                                            </BEStatusTag>
                                                        </div>
                                                    }
                                                    <ReportingReviewStatusButton
                                                        uniqueCode={uniqueCode}
                                                        section={props.section}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <br />
                                        <div>
                                            {
                                                selectedTopics?.data.filter((topic4: TemplateSelectedTopicTypes) => topic4.section === props.section && topic4.category === props.category && topic4.unique_code === uniqueCode)[0].table_type === 1 ?
                                                    <Type1Topic uniqueCode={uniqueCode} nonEditable />
                                                    : selectedTopics?.data.filter((topic4: TemplateSelectedTopicTypes) => topic4.section === props.section && topic4.category === props.category && topic4.unique_code === uniqueCode)[0].table_type === 3 ?
                                                        <Type3Topic uniqueCode={uniqueCode} nonEditable />
                                                        : selectedTopics?.data.filter((topic4: TemplateSelectedTopicTypes) => topic4.section === props.section && topic4.category === props.category && topic4.unique_code === uniqueCode)[0].unit === 'Checkbox' ?
                                                            <Type4Topic uniqueCode={uniqueCode} nonEditable />
                                                            : <Type2Topic uniqueCode={uniqueCode} nonEditable />
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )
                })
            }
            <ReportingReviewEvidenceDrawer open={openDrawer} setOpen={setOpenDrawer} uniqueCode={currentUniqueCode} />
        </div>
    );
}
