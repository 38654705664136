import React from 'react'
import IconProps from './Interface/IconProps';

const AttentionTasksIcon = (props:IconProps) => {
  return (
	<svg
	  width={props.inheritSize ? "100%" : 20}
	  height={props.inheritSize ? "100%" : 16}
	  viewBox="0 0 20 20"
	  fill="none" xmlns="http://www.w3.org/2000/svg">
	  <path d="M4.5 20L3.5 19V15H0V13L1.5 10.35V8H0V6H9V8H7.5V10.35L9 13V15H5.5V19L4.5 20ZM2.3 13H6.7L5.5 10.9V8H3.5V10.9L2.3 13ZM16 6C15.1667 6 14.4583 5.70833 13.875 5.125C13.2917 4.54167 13 3.83333 13 3C13 2.16667 13.2917 1.45833 13.875 0.875C14.4583 0.291667 15.1667 0 16 0C16.8333 0 17.5417 0.291667 18.125 0.875C18.7083 1.45833 19 2.16667 19 3C19 3.83333 18.7083 4.54167 18.125 5.125C17.5417 5.70833 16.8333 6 16 6ZM11.1 4H0C0 3.45 0.195833 2.97917 0.5875 2.5875C0.979167 2.19583 1.45 2 2 2H11.1C11.0667 2.16667 11.0417 2.32917 11.025 2.4875C11.0083 2.64583 11 2.81667 11 3C11 3.18333 11.0083 3.35417 11.025 3.5125C11.0417 3.67083 11.0667 3.83333 11.1 4ZM18 18H10V16H18V7.575C18.4 7.39167 18.7667 7.16667 19.1 6.9C19.4333 6.63333 19.7333 6.33333 20 6V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18Z"
	  	fill={props.fill ? props.fill : "#282828"}
	  />
	  </svg>	  
  );
}

export default AttentionTasksIcon