import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { Col, Row } from 'antd';
import ToolTip from '../../../../../Components/BEToolTip';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { SupplierRiskClassificationThreshold } from '../../AllAssessments/AllAssessments';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useParams } from 'react-router-dom';
import { getSuppliersAtRiskByQuestion } from '../../../Actions/supplierAction';
import { BEMessage } from '../../../../../Components/BEMessage';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';

export interface ISupplierResultByQuestionProps {
}

export function SupplierResultByQuestion (props: ISupplierResultByQuestionProps) {

  const supplierQuestions = useTypedSelector(
    state => state.supplier.SupplierQuestions
  );
  const supplierScore = useTypedSelector(
    state => state.supplier.SupplierScore
  );
  return (
    <div className='supplier-risk-metrics'>
    <Row gutter={[20, 20]}>
    {
      supplierScore?.status !== 'loading' &&
      // Object.keys(SupplierRiskMatrixData).map((heading: any) => {
      Array.from(
        new Set(
          supplierQuestions?.data
          .filter(question => 
            supplierScore.data?.question_completion
            .some(item=> item.question === question.id)
          )
          .map((question: any) => question.attribute)
        )
      ).map((heading: any) => { return (
        <Col span={8}>
          <div className='risk-breakdown-box'>
            <div className='title'>
              {heading}
            </div>
            <div className='body'>
            {
              supplierQuestions?.data.filter((question: any) => 
              question.attribute === heading).map((data: any) => {
                let questionCompletion = supplierScore?.data.question_completion
                .find(
                  (item: any) => (
                    item.question === data.id &&
                    item.type === data.type
                  )
                );
                if(!questionCompletion) return null;
                // assuming we only show data when one supplier has answered all
                // the questions
                let percentageCorrect = (
                  questionCompletion.correct / 
                    (
                      questionCompletion.correct+questionCompletion.incorrect
                    ) * 100
                  )
                return (
                  <SingleQuestionRiskClassification
                    data={data}
                    percentageCorrect={percentageCorrect/100}
                  />
                )
              })
            }
            </div>
          </div>
        </Col>
        )
      })
    }
    </Row>
  </div>
  );
}



function SingleQuestionRiskClassification
({ data, percentageCorrect }: any) {
  const { assessment_id } = useParams();
  const dispatch = useAppDispatch();
  const riskLowPercentage = SupplierRiskClassificationThreshold.Medium;
  const riskMediumPercentage = SupplierRiskClassificationThreshold
  const suppliers = useTypedSelector(
    state => state.supplier.Suppliers
  );
  const [suppliersAtRisk, setSuppliersAtRisk] = React.useState<any>({
    loading: false,
    data: []
  });

  return (
    
    <ToolTip title={
      <div>
        <p>Partners At Risk</p>
        {
          suppliersAtRisk.loading ?
          <GlobalLoader size='small' height='2rem'/> :
          <>
          {  suppliersAtRisk?.data?.length === 0?
            <p>None</p>
          :
            suppliersAtRisk?.data?.map((supplierAtRisk: any) => {
              return (
                <div>
                  {
                    suppliers.data?.find(
                      (item: any) => item.id === supplierAtRisk
                    )?.name
                  }
                </div>
              )
            })
          }
          </>
        }
      </div>
    }>
      <div className='body-row'
        onMouseEnter={async () => {
          setSuppliersAtRisk({loading: true,data: []});
          let res = await dispatch(
            getSuppliersAtRiskByQuestion(assessment_id, data.id,data.type)
          );
          if(!res){
            setSuppliersAtRisk({loading: false,data: []});
            return BEMessage.error('Failed to fetch suppliers at risk');
          }
          setSuppliersAtRisk({loading: false,data: res});
        }}
      >

        <div className='body-row-title'>
          {data.question}
        </div>
        <div className='body-row-body'>
          <BEStatusTag status={
            percentageCorrect > riskMediumPercentage ?
             'success' :
            percentageCorrect > riskLowPercentage ?
             'warning' : 'error'
          }>
            {
              (percentageCorrect * 100).toFixed(2) + '%'
            }
          </BEStatusTag>

        </div>
      </div>
    </ToolTip>
  );
}

