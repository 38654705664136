import * as React from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getEventUsers } from '../../EventManager/Actions';
import SettingsBackButton from '../../../Settings/Components/BackButton';
import { AddEventUser } from '../Drawers/AddEventUser';
import { EVENT_OWNER, LEAD_INVESTIGATOR } from '../../../../data/eventManager';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import './styles.scss';

export interface IEventUsersProps {
}

export function EventUsers(props: IEventUsersProps) {
  const dispatch = useAppDispatch()
  const users = useTypedSelector((state) => state.eventManager.eventUsers);
  const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openEditDrawer, setOpenEditDrawer] = React.useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "NAME",
      dataIndex: "name",
      sorter: (a, b) => (a < b ? -1 : 1),
      render: (value, record) => {
        return `${record.first_name} ${record.last_name}`;
      },
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "ROLE",
      dataIndex: "event_role",
    },
    {
      title: "BUSINESS UNIT",
      dataIndex: "business_unit",
      render: (value, record) => {
        return businessUnits?.data?.find((bu) => bu.id === record.business_unit)?.name;
      },
    },
    {
      title: "EVENT CATEGORY",
      dataIndex: "event_category",
    }
  ];


  React.useEffect(() => {
    if (users?.status === "idle")
      dispatch(getEventUsers());
  }, []);

  return (
    <>
      <SettingsBackButton title='Back' style={{ marginLeft: '1.5rem' }} />

      <div className='users-table-container'>
        <AddEventUser open={openDrawer} setOpen={setOpenDrawer} />

        <div className='users-table-header'>
          <BEButton
            size="middle"
            className="primary"
            onClick={() => setOpenDrawer(true)}
          >
            + Add User(s)
          </BEButton>
        </div>
        <BETable
          className='users-table'
          data={users?.data?.filter((item) => [EVENT_OWNER, LEAD_INVESTIGATOR]?.includes(item.event_role)) as any[]}
          columns={columns}
          loading={users?.status === "loading"}
          tableProps={{ rowKey: "id" }}
          pagination
        />
      </div>
    </>
  );
}
