import * as React from 'react';
import { HorizontalBarChartType1 } from './Type1';
import { HorizontalBarChartType2 } from './Type2';

export type HorizontalBarChartTypeDataType =  {name: string, value: number} ;
export type HorizontalBarChartTypeDisplayType  = {name: string, value: number, widthPercentage: number,absoluteValue: number};


export interface IHorizontalBarChartCoreProps {
  data: HorizontalBarChartTypeDataType[];
  isPercentage?: boolean;
  onClick?: (name: any) => void;
  unit?: string;
  tooltipFormatter?: (value?: any, total?: any) => string;
  pageSizes?: number;
}


export interface IHorizontalBarChartProps extends IHorizontalBarChartCoreProps {
  type?: 1 | 2;
}

export function HorizontalBarChart (props: IHorizontalBarChartProps) {
  return (
    <>
      {
        !props.type || props.type === 1 ? 
        <HorizontalBarChartType1 {...props} /> : 
        <HorizontalBarChartType2 {...props} />
      }
    </>
  );
}
