import { AppDispatch } from "../../../Redux/store"
import { RootState } from "../../../Redux/store"
import dayjs from "dayjs";

// emmisions 
import {
    // getScope1CalculationsForMonths,
    // getScope2CalculationsForMonths
    getAllEmissionCalculations
} from "../../../Features/Emissions/Actions";

// set years
import { setDateSlicerYear as setEmmisionsYear } from "../../../Redux/EmmisionsReducer";
import { setAssessmentYear as setMaterialityAssessmentYear } from "../../../Redux/MatirialityReducer";
import { setAssessmentYear as SetSupplierAssessmentYear } from "../../../Redux/SupplierReducer";
import { setSelectedYear as setReportingYear } from "../../../Redux/ReportingReducer";
import { setDateSlicerYearDM as setDataManagerYear } from "../../../Redux/DataManagerReducer";

import {
    getAssignedDepartmentByCategory,
    getContributorsByMetrics,
    getDataLabels,
    getDerivedMetricDataByMonth,
    getDerivedMetrics,
    getMetricsStatus,
    getMyDerivedMetrics,
    getMyMetricsDataByMonth,
    getOmittedMetrics,
    getMyMetricsData,
    getMyMetricsDataBulkUploadFiles
} from "../../../Features/DataManager/Actions";
import { getAppVersionAndDate, getFinancialYears } from "../HomeActions";
import { getAllUsers, getUserProfile } from "../UsersManagementActions";
import { getFYByDate } from "../../../Config/Functions/UsefullFunctions";
import { getMyDashboardMetrics, getMyDerivedDashboardMetrics } from "../../../Features/Dashboard/Actions";
import { setFinancialYears } from "../../../Redux/CommonReducer";
import { getDerivedMetricsRepresentationGraph } from "../calculatedMetricsActions";
import { getMyDerivedMetricTargets, getMyMetricTargets } from "../../../Features/TargetsNew/Actions";
import { getAssignedDepartmentByReportTopics } from "../../../Features/Reporting/Actions/template";
import { getMetrics, getMyMetrics } from "../OnboardingStep4Actions";
import { decryptRole } from "../LoginActions";
import { setCurrentModule, setRole } from "../../../Redux/UserReducer";
import { getModules } from "../../../Features/Settings/Actions";
import { navigateTo } from "../../../Navigation/NavigationManager";


export const handleBackgroundAPICalls = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const location = window.location;
    const state = getState();
    const enryptedRole = localStorage.getItem('role');
    const currentModule = state.user.currentModule;
    let role = decryptRole(enryptedRole as string);
    if (enryptedRole) {
        dispatch(setRole(role));
    }

    let permissions = state.user.permissions;
    let thisRolePermissions: any = {}; // 1: Dashboard, 2: DataManager, 3: EntityManager, 4: Reporting, 5: Emmisions, 6: Targets, 7: Surveys,8: Settings 9:COnfiguration
    for (let i = 1; i < 10; i++) {
        thisRolePermissions[i] = permissions?.data?.find((perm: any) => perm.section === i && perm.role === role)?.permission;
    }

    if (state.settings.modules?.status === 'idle') dispatch(getModules());
    if (state.user.userProfile?.status === 'idle') dispatch(getUserProfile()).then((res) => {
        if (res && !currentModule) {
            if (res['ESG'] && location.pathname?.includes('/home')) {
                dispatch(setCurrentModule('ESG'));
            } else if (res['EVENT']) {
                dispatch(setCurrentModule('EVENT'));
                navigateTo('/events/event-manager');
            }
        }
    });

    if (currentModule === 'ESG' && location.pathname?.includes('/home')) {
    } else if (currentModule === 'EVENT') {
        navigateTo('/events/event-manager');
    }

    if (state.common.financialYears?.status === 'idle') dispatch(getFinancialYears());
    if (thisRolePermissions[8]) {
        if (state.userMgmt?.status === 'idle') dispatch(getAllUsers());
    }
    // set initial years according to company reporting year end
    await dispatch(setCurrentYears());
    if (state.common.version.number === '') dispatch(getAppVersionAndDate());

    // dashboard & data manager
    if (location.pathname?.includes('/home')) {
        if (state.onBoarding.metrics.directMetrics?.status === 'idle') await dispatch(getMetrics());
        if (state.onBoarding.metrics.derivedMetrics?.status === 'idle') await dispatch(getDerivedMetrics())
        if (state.dataManager.assignedDepartmentByCategory?.status === 'idle') dispatch(getAssignedDepartmentByCategory());
        if (state.reporting.assignedDepartmentByReportTopics?.status === 'idle') dispatch(getAssignedDepartmentByReportTopics());
        if (state.dataManager.metrics.omittedMetrics?.status === 'idle') dispatch(getOmittedMetrics());
        if (state.onBoarding.metrics.myDerivedMetrics?.status === 'idle') dispatch(getMyDerivedMetrics());
        if (state.onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit?.status === 'idle') dispatch(getDerivedMetricDataByMonth());
        if (state.onBoarding.metrics.myMetrics?.status === 'idle') dispatch(getMyMetrics());
        if (state.dataManager.metrics.metricStatus?.status === 'idle') dispatch(getMetricsStatus());
        if (state.onBoarding.metrics.myMetricsData?.status === 'idle') dispatch(getMyMetricsData());
        if (state.dataManager.myMetricDataBulkUploadFiles?.status === 'idle') dispatch(getMyMetricsDataBulkUploadFiles());
        if (state.dataManager.metrics.myDashboardMetrics?.status === 'idle') dispatch(getMyDashboardMetrics());
        if (state.dataManager.labels?.status === 'idle') dispatch(getDataLabels());
        if (state.onBoarding.metrics.myMetricsDataByMonthAndBusinessUnit?.status === 'idle') dispatch(getMyMetricsDataByMonth());
        if (state.dataManager.metrics.myDerivedDashboardMetrics?.status === 'idle') dispatch(getMyDerivedDashboardMetrics());
        if (state.settings.derivedMetricsRepresentationGraph?.status === "idle") dispatch(getDerivedMetricsRepresentationGraph());
        if (state.dataManager.contributorsByMetrics?.status === 'idle') dispatch(getContributorsByMetrics());

        if (state.targets.MyMetricTargets?.status === 'idle') dispatch(getMyMetricTargets());
        if (state.targets.MyDerivedMetricTargets?.status === 'idle') dispatch(getMyDerivedMetricTargets());

        if (thisRolePermissions[5]) {
            // emmisions
            let thisFY = await dispatch(getCurentFY());
            if (state.emissions.emissionsCalculations.scope1Calculations?.status === 'idle') dispatch(getAllEmissionCalculations(thisFY))
        }
    }
}

export const setCurrentYears = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    let currentYear = Number(localStorage.getItem('currentYear')) || await dispatch(getCurentFY());
    let years = getState().common.financialYears?.data;
    if (!years.includes(currentYear))
        dispatch(setFinancialYears(      //-- we get the FYs from selected metrics(this api takes time to load so we set the current year first to avoid undefined error)
            {
                status: 'success',
                data: [currentYear, ...years]
            }))
    // console.log('currentYear', currentYear)
    dispatch(setEmmisionsYear(currentYear));
    dispatch(setMaterialityAssessmentYear(currentYear));
    dispatch(SetSupplierAssessmentYear(currentYear));
    dispatch(setReportingYear(currentYear));
    dispatch(setDataManagerYear(currentYear));
}

export const getCurentFY = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    let state = getState();
    let YearEnd = state.companies.companyDetails?.data?.reporting_year_end;
    const year = getFYByDate(dayjs().format('YYYY-MM-DD'), YearEnd || '12-31');
    return year;
}
