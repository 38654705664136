import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { changeSnakeCaseToSentence, showFYFormatByYear } from '../../../../../Config/Functions/UsefullFunctions';
import './style.scss';
import StackedColumnCharts from '../../../../../Components/BECharts/StackedColumnCharts';
import { shortMonths } from '../../../../../Config/Data/genralData';
import { Select } from 'antd';
import { HorizontalBarChart } from '../../../../../Components/BECharts/HorizontalBarChart';

export interface IDashboardEmissionsByBuBgProps {
}

export function DashboardEmissionsByBuBg (props: IDashboardEmissionsByBuBgProps) {
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);

  const scope1Emissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const scope2Emissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const scope3Emissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope3Calculations);
  const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);
  const businessGroups = useTypedSelector((state) => state.entity.BusinessGroups);
  const buRelations = useTypedSelector((state) => state.entity.BusinessUnitRefs); //[{business_unit: 'bu1', business_group: 'bg1'}]

  const [businessUnitEmissions, setBusinessUnitEmissions] = React.useState<{name: string, value: number}[]>([]);
  const [businessGroupEmissions, setBusinessGroupEmissions] = React.useState<{name: string, value: number}[]>([]);
  const [filters, setFilters] = React.useState<{scope: string, source: string}>({scope: 'All', source: 'All'});

  React.useEffect(() => {
    if(
      scope1Emissions.status !== 'success' || 
      scope2Emissions.status !== 'success' || 
      scope3Emissions.status !== 'success' ||
      businessUnits.status !== 'success' ||
      businessGroups.status !== 'success' ||
      buRelations.status !== 'success'
    ) return;
    let tempBuEmissions:{[key: number]: number} = {};
    let tempBgEmissions:{[key: number]: number} = {};

    const combinedData = [...scope1Emissions.data, ...scope2Emissions.data, ...scope3Emissions.data];
    combinedData.forEach((item) => {
      if(filters.source !== 'All' && item.emission_category !== filters.source) return;
      if(filters.scope !== 'All' && item.scope !== filters.scope) return;

      if(tempBuEmissions[item.business_unit]) tempBuEmissions[item.business_unit] += item.tco2e;
      else tempBuEmissions[item.business_unit] = item.tco2e;

      const business_groups = buRelations.data?.filter((bg) => bg.business_unit === item.business_unit).map((item) => item.business_group);
      business_groups.forEach((bg) => {
        if(tempBgEmissions[bg]) tempBgEmissions[bg] += item.tco2e;
        else tempBgEmissions[bg] = item.tco2e;
      })
    });

    let tempBuArr = [];
    for(let key in tempBuEmissions){
      console.log(key)
      tempBuArr.push({
        name: Number(key) === 0 ? 'Unassigned' : businessUnits.data?.find((item) => Number(item.id )=== Number(key))?.name || 'Unknown',
        value: tempBuEmissions[key]
      })
    }
    setBusinessUnitEmissions(tempBuArr.sort((a,b) => b.value - a.value))

    let tempBgArr = [];
    for(let key in tempBgEmissions){
      tempBgArr.push({
        name: businessGroups.data?.find((item) => Number(item.id) === Number(key))?.name || 'Unknown',
        value: tempBgEmissions[key]
      })
    }
    setBusinessGroupEmissions(tempBgArr.sort((a,b) => b.value - a.value))
 
  }, [scope2Emissions , scope3Emissions, scope1Emissions, filters, businessUnits, businessGroups, buRelations])

  return (
      <BEWrapper type={2} title='GHG Emissions by Business Unit and Business Group'>
          <div className='dashboard-bu-bg-emissions'>
            <div className='filters'>
              <div className='filter'>
                <label>Scope:</label>
                <Select 
                  style={{width: '9rem'}}
                  value={filters.scope} 
                  onChange={(value) => setFilters({scope: value.toString(), source: 'All'})}
                >
                  {
                    ['All', 'Scope 1', 'Scope 2', 'Scope 3'].map((item) => <Select.Option value={item}>{item}</Select.Option>)
                  }
                </Select>
              </div>

              <div className='filter'>
                <label>Source:</label>
                <Select 
                  style={{width: '9rem'}}
                  value={filters.source} 
                  onChange={(value) => setFilters({...filters,source: value.toString()})}
                >
                  <Select.Option value='All'>All</Select.Option>
                  {
                    Array.from(new Set([...scope1Emissions.data, ...scope2Emissions.data, ...scope3Emissions.data]
                  .filter((item) => item.emission_category && (filters.scope === 'All' || item.scope === filters.scope))
                  .map((item) => item.emission_category )).values())
                  .map((item) => <Select.Option value={item}>{changeSnakeCaseToSentence(item,false,true)}</Select.Option>)
                  }
                </Select>
              </div>
            </div>
            <div className='bu-bg-emission-graphs'>
                <div className='bu-bg-emission-graph'>
                  <p className='title'>Highest emitters by Business Unit</p>
                  <HorizontalBarChart data={businessUnitEmissions} unit='tCO2e' type={2}/>
                </div>

                <div className='bu-bg-emission-graph'>
                  <p className='title'>Highest emitters by Business Group</p>
                  <HorizontalBarChart data={businessGroupEmissions} unit='tCO2e' type={2}/>
                </div>
            </div>
          </div>

      </BEWrapper>
  );
}
