import * as React from 'react';
import './style.scss';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { HorizontalBarChart } from '../../../../../Components/BECharts/HorizontalBarChart';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getDashboardDrilldown } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useParams } from 'react-router-dom';

export interface IDrillDownBuBgEmissionsProps {
  metric: any;
}


export function DrillDownBuBgEmissions (props: IDrillDownBuBgEmissionsProps) {
  const dispatch = useAppDispatch();
  const code = useParams<{code: string}>().code;
  const ratioUnits = ['ratio','percentage'];

  const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);
  const businessGroups = useTypedSelector((state) => state.entity.BusinessGroups);
  const buRelations = useTypedSelector((state) => state.entity.BusinessUnitRefs); //[{business_unit: 'bu1', business_group: 'bg1'}]

  const [businessUnitEmissions, setBusinessUnitEmissions] = React.useState<{name: string, value: number}[]>([]);
  const [businessGroupEmissions, setBusinessGroupEmissions] = React.useState<{name: string, value: number}[]>([]);
  const isEMissions = useTypedSelector(state => state.dashboard.drillDown.isEmission);
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType);

 
  const getMetricData = async() => {
        
    let dataType = isEMissions ? 4 : 8;
    let data:any = await dispatch(getDashboardDrilldown(dataType,1,code as string,[currentYear]))

    let MetricData:any = []
    Object.keys(data).forEach((bu) => {
      MetricData.push({
        business_unit: Number(bu),
        value: data?.[bu]?.[currentYear]
      })
    })

    let tempBuEmissions:{[key: number]: number} = {};
    let tempBgEmissions:{[key: number]: number} = {};

    MetricData?.forEach((item:any) => {
      let value = item?.value

      if(tempBuEmissions[item.business_unit]) tempBuEmissions[item.business_unit] += value;
      else tempBuEmissions[item.business_unit] = value;
    });

    let tempBuArr = [];
    for(let key in tempBuEmissions){
      if(!tempBuEmissions[key]) continue;
      tempBuArr.push({
        name: Number(key) === 0 ? 'Unassigned' : businessUnits.data.find((item) => Number(item.id )=== Number(key))?.name || 'Unknown',
        value: tempBuEmissions[key]
      })
    }
    setBusinessUnitEmissions(tempBuArr.sort((a,b) => b.value - a.value))

    let tempBgArr: { name: string; value: number; }[] = [];

    businessGroups.data.forEach((bg) => {
      let bgBus = buRelations.data.filter((buBgRef) => buBgRef.business_group === bg.id).map((item) => item.business_unit);
      //check if metric unit is ratio or percentage
        let bgValue = bgBus.reduce((acc, bu) => acc + (tempBuEmissions?.[bu] || 0), 0);
        tempBgArr.push({
          name: bg.name,
          value: ratioUnits.includes(
            (props.metric?.unit)?.toLowerCase()?.trim()
          ) ? bgValue/bgBus.length : bgValue
        })
      })
      setBusinessGroupEmissions(tempBgArr.sort((a,b) => b.value - a.value).filter((item) => item.value > 0))
  }

  React.useEffect(() => {
    if(!props.metric || isEMissions===null) return;
    getMetricData()
  }, [props.metric, isEMissions,businessGroups,buRelations])
  
  return (
          <div className='drilldown-bu-bg-emissions'>
            <div className='bu-bg-emission-graphs'>
                <div className='bu-bg-emission-graph'>
                  <p className='title'>Highest {isEMissions ? 'emitters ' : 'contributors '}
                     by Business Unit</p>
                  <HorizontalBarChart data={businessUnitEmissions}
                   unit={isEMissions ? 'tCO2e' : props.metric?.unit}
                   type={2}/>
                </div>

                <div className='bu-bg-emission-graph'>
                  <p className='title'>Highest {isEMissions ? 'emitters ' : 'contributors '}
                     by Business Group</p>
                  <HorizontalBarChart data={businessGroupEmissions} 
                  unit={isEMissions ? 'tCO2e' : props.metric?.unit}
                   type={2}/>
                </div>
            </div>
          </div>
  )
}
