import { ColumnsType } from "antd/es/table";
import { BEDrawer } from "../../../../../Components/BEDrawer";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import AddIcon from "../../../../../Components/BEIcons/AddIcon";
import { NewBETable } from "../../../../../Components/BETable/newBETable";
import DeleteIcon from "../../../../../Components/BEIcons/DeleteIcon";
import { PrimaryTheme } from "../../../../../Config/Theme/theames";
import React, { useEffect, useState } from "react";
import AssignTemplate from "../AssignTemplate";
import { AddTemplate } from "../AddTemplate";
import { EditTemplate } from "../EditTemplate";
import { deleteReportTemplate, getAllReportBusinessUnits, getAllReports, getAllTemplateDrafts } from "../../../Actions/template";
import { setCurrentAddTemplateState, setCurrentTemplate, setSelectedFramework } from "../../../../../Redux/ReportingReducer";
import { useTypedSelector } from "../../../../../Config/Hooks/useTypedSelector";
import BEStatusTag from "../../../../../Components/BEStatusTag";
import EditIcon from "../../../../../Components/BEIcons/EditIcon";
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import { Frameworks } from "../../../../../data/frameworks";
import { BEInput } from "../../../../../Components/BEFormItems/BEInput";
import BEConfirmModal from "../../../../../Components/BEConfirmModal";
import ToolTip from "../../../../../Components/BEToolTip";
import { getGresbAuthorizationCode, isGresbAuthorized } from "../../../Actions/gresbActions";
import { BEMessage } from "../../../../../Components/BEMessage";

interface AddTemplateProps {
  open: boolean;
  setOpen: Function;
}

const AddNewReport = (props: AddTemplateProps) => {
  const [openStep2, setOpenStep2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openAddTemplate, setOpenAddTemplate] = React.useState(false);
  const [openEditTemplate, setOpenEditTemplate] = React.useState(false);
  const data: never[] = [];
  const dispatch = useAppDispatch();
  const templateDrafts = useTypedSelector((state) => state.reporting.TemplateDrafts);
  const [filteredTemplateData, setFilteredTemplateData] = useState<any[]>([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [redirectGresbPortal, setRedirectGresbPortal] = React.useState(false);
  const currentTemplate = useTypedSelector(state => state.reporting.currentTemplate);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const assignRef = React.useRef<any>({ onButtonClick: () => { } })

  const handleDelete = async (id: any) => {
    await dispatch(deleteReportTemplate(id))
  }
  const handleEdit = async (template: any) => {
    dispatch(setCurrentTemplate(template))
    dispatch(setCurrentAddTemplateState(3))
    setOpenAddTemplate(true)
  }
  useEffect(() => {
    dispatch(getAllTemplateDrafts());
    dispatch(getAllReportBusinessUnits())
    dispatch(getAllReports())
  }, [openAddTemplate, openEditTemplate]);

  useEffect(() => {
    if (currentTemplate) {
      dispatch(setSelectedFramework(Frameworks?.find((framework) => framework.code === currentTemplate?.framework)))
    }
  }, [currentTemplate]);

  useEffect(() => {
    if (templateDrafts?.status === 'success')
      setFilteredTemplateData(templateDrafts?.data || [])
  }, [templateDrafts?.data]);

  const columns: ColumnsType<any> = [
    {
      title: "Template",
      dataIndex: "name",
      render: (text: any, data: any) =>
        <span
        //   style={{
        //     fontWeight: 'bold',
        //     textDecoration: 'underline',
        //     cursor: 'pointer'
        //   }}
        //   onClick={() => handleEdit(data)}
        >{text}</span>
    },
    {
      title: "Framework",
      dataIndex: "framework",
    },
    {
      title: "Status",
      dataIndex: "topicsChosen",
      render: (text, record) => (record.topicsChosen ? <BEStatusTag status="success">Complete</BEStatusTag>
        : <BEStatusTag status="warning">Draft</BEStatusTag>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "1rem", cursor:'pointer' }}>
          <div
            style={{ height: '1rem' }}
            onMouseOver={() => setSelectedRow(record)}
            onClick={() => setOpenDelete(true)}
          >
            <DeleteIcon
              inheritSize
              stroke={PrimaryTheme.primaryGray}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Assign",
      render: (text, record) => (
        <>
          {record.topicsChosen ?

            (record.framework === 'GRESB' && !isGresbAuthorized() ?
              <BEButton
                className="secondary-green"
                size="small"
                onClick={() => {
                  setRedirectGresbPortal(true);
                }}
              >
                <ToolTip title="You will be redirected to GRESB portal for the authorization.">
                  Create report
                </ToolTip>
              </BEButton>
              :
              <BEButton
                className="secondary-green"
                size="small"
                onClick={() => {
                  let presentYear = new Date().getFullYear();
                  const presentMonth = new Date().getMonth();
                  if (presentMonth < 4) presentYear = presentYear - 1;
                  if (record.framework === 'GRESB' && currentYear !== presentYear - 1) {
                    BEMessage.info(`Report for the given reporting year, cannot be created. Please create a report for reporting year for ${presentYear-1}-${presentYear}.`);
                    return;
                  }
                  dispatch(setCurrentTemplate(record));
                  setOpenStep2(true);
                }}
              >
                Create report
              </BEButton>) :
            <BEButton
              className="secondary-yellow"
              size="small"
              onClick={() => {
                dispatch(setCurrentTemplate(record));
                setOpenEditTemplate(true);
              }}
            >
              Select topics
            </BEButton>
          }
        </>
      ),
    }
  ];

  React.useEffect(() => {
    setOpenStep2(false);
  }, [props.open]);

  return (
    <BEDrawer
      width="100vw"
      open={props.open}
      setOpen={props.setOpen}
      heading="Create report"
      secondryHeading={{
        heading: openStep2 ? "Step 2: Assign template" : "Step 1: Choose your template",
        subHeading: openStep2 ? "Assign template to business groups to create a report" : "Select the template you want to use for reporting",
        theame: "light"
      }}
      footer={
        openStep2 &&
        <>
          <BEButton
            className="primary"
            size="large"
            onClick={async () => {
              if (assignRef?.current?.onButtonClick) {
                setLoading(true);
                const validated = await assignRef?.current?.onButtonClick();
                setLoading(false);
                if (validated) props.setOpen(false);
              }

            }}
            loading={loading}
          >
            + Add Report
          </BEButton>
          <BEButton size="large" onClick={() => props.setOpen(false)}>
            Cancel
          </BEButton>
        </>
      }
    >
      {openStep2 ?
        <AssignTemplate template={currentTemplate?.id} ref={assignRef} /> :
        <>
          <div>
            <BEButton className="primary" size="large"
              onClick={() => setOpenAddTemplate(true)}
              style={{ float: "right" }}
            >
              + Add new template
            </BEButton>
          </div>

          <div style={{ marginTop: "4rem" }}>
            <hr style={{ color: "rgb(242, 236, 236)", backgroundColor: "rgb(242, 236, 236)", opacity: "0.1" }} />
            <BEInput
              style={{ width: "313px", margin: "1rem 0" }}
              search
              placeholder="Search for a template"
              size="large"
              onChange={(e) => {
                const value = e.target.value?.toLowerCase();
                const filteredData = templateDrafts?.data?.filter((item: any) => {
                  return item.name?.toLowerCase().includes(value);
                });
                setFilteredTemplateData(filteredData);
              }}
            />
            <NewBETable
              loading={templateDrafts?.status === 'loading'}
              data={[...filteredTemplateData].sort((a, b) => (a.id < b.id) ? 1 : -1)}
              columns={columns}
            />
            <BEConfirmModal
              visible={openDelete}
              setVisible={setOpenDelete}
              onConfirm={async () => await handleDelete(selectedRow?.id)}
              title='Confirm Delete'
              message='Are you sure you want to delete the template?'
            />
            <BEConfirmModal
              type="success"
              visible={redirectGresbPortal}
              setVisible={setRedirectGresbPortal}
              onConfirm={async () => {const gresbToken = dispatch(getGresbAuthorizationCode(3, 0));}}
              title='Redirect to GRESB'
              message='You will be redirected to GRESB portal for the authorization.'
            />
          </div>
        </>
      }
      <AddTemplate open={openAddTemplate} setOpen={setOpenAddTemplate} />
      <EditTemplate open={openEditTemplate} setOpen={setOpenEditTemplate} />

    </BEDrawer>
  );
};

export default AddNewReport;
