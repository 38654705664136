import React from "react";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import useOutsideClick from "../../../Config/Hooks/useOutsideClick";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import UsersIcon from "../../BEIcons/UsersIcon";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import ChevronRightIcon from "../../BEIcons/ChevronRightIcon";
import { menubarWidth, menubarWidthCollapsed } from "../../../Config/Constants";
import BEConfirmModal from "../../BEConfirmModal";
import { logoutAction } from "../../../views/Actions/LoginActions";
import { ProfileModal, UserProfileModal } from "../../../views/Home/TopMenubar/Profile";
import { navigateTo } from "../../../Navigation/NavigationManager";
import Logout from "../../BEIcons/Logout";
import SupportIcon from "../../BEIcons/SupportIcon";

interface IProfileDetailsProps {
	collapsed: boolean;
}

export function ProfileDetails(props: IProfileDetailsProps) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
	const userName = userInfo?.first_name ? `${userInfo?.first_name} ${userInfo?.last_name}` : `${userInfo?.username}`
	const userRole = useTypedSelector((state) => state.user.userRole) as string;
	const companyDetails = useTypedSelector((state) => state.companies.companyDetails)
	const [showLogoutModal, setShowLogoutModal] = React.useState(false);
	const [showProfileModal, setShowProfileModal] = React.useState(false);
	const [profileDropdown, setProfileDropdown] = React.useState(false);
	const userProfile = useTypedSelector((state) => state.user.userProfile);
	const dispatch = useAppDispatch();
	const wrapperRef = React.useRef<HTMLDivElement>(null);

	useOutsideClick(wrapperRef, () => setProfileDropdown(false));
	const items = [
		{
			key: 1,
			label: 'View profile',
			icon: <UsersIcon fill={PrimaryTheme.primaryGray} inheritSize />,
			onClick: () => {
				setShowProfileModal(true);
			}
		},
		{
			key: 2,
			label: 'Help & Support',
			icon: <SupportIcon fill="black" inheritSize />,
			onClick: () => {
				navigateTo('https://breatheesg.freshdesk.com/', true, true)
			}
		},
		// {
		//   key: 3,
		//   label: 'Go to Breathe Zero',
		//   onClick:goToZero
		// },
		{
			key: 3,
			label: <p style={{ color: PrimaryTheme.secondaryRedError }}>Logout</p>,
			icon: <Logout fill={PrimaryTheme.primaryRedError} inheritSize />,
			onClick: () => {
				setShowLogoutModal(true);
			}
		}
	]
	return (
		<>
			<div className="menu-bar-btm-user-profile"
				ref={wrapperRef}
				onClick={() => setProfileDropdown((prev) => !prev)}
				style={{ position: 'relative' }}>
				<div className="user-dropdown"
					style={{
						backgroundColor: !props.collapsed ? '#2F2F2F' : '',
					}}>
					{props.collapsed ?
						<div
							className={`avatar with-image`}
						>
							{userProfile?.data?.profile_pic === null ? (
								<div className="no-profile">
									{userName.charAt(0)}
								</div>
							) : (
								<img
									src={userProfile?.data?.profile_pic}
									alt="user-image"
									className="avatar-image"
								/>
							)}
						</div>
						:
						<div
							className="dropdown-content"
						>
							<div className="user-info">
								<>
									<div className={`avatar with-image`}>
										{userProfile?.data?.profile_pic === null
											? (
												<div className="no-profile">
													{userName.charAt(0)}
												</div>
											) :
											(
												<img
													src={userProfile?.data?.profile_pic}
													alt="user-image"
													className="avatar-image"
												/>
											)}
									</div>
									<div className="user-details">
										<h3>{userName}</h3>
										<p>
											{
												userRole.charAt(0) +
												userRole.substring(1).toLowerCase()
											}
										</p>
									</div>
								</>
							</div>
							{!props.collapsed &&
								<div className="icon-container">
									<ChevronRightIcon
										fill="white"
										stroke="white"
									/>
								</div>
							}
						</div>
					}
				</div>
				{
					profileDropdown &&
					<div className="profile-dropdown"
						style={{
							left: `calc(${props.collapsed ?
								menubarWidthCollapsed : menubarWidth}
								+ 5px)`,
						}}
					>
						<div>
							<p style={{ color: PrimaryTheme.primaryGray, fontSize: '0.7rem', padding: '0.5rem' }}>SIGNED IN AS</p>
							<div className="user-info" style={{ paddingLeft: '0.5rem' }}>
								<>
									<div
										className={`avatar with-image`}
									>
										{userProfile?.data?.profile_pic === null ? (
											<div className="no-profile">
												{userName.charAt(0)}
											</div>
										) : (
											<img
												src={userProfile?.data?.profile_pic}
												alt="user-image"
												className="avatar-image"
											/>
										)}
									</div>
									<div className="user-details">
										<h3 style={{ color: 'black' }}>
											{userName}
										</h3>
										<p
											style={{
												color: PrimaryTheme.primaryGray
											}}
										>
											{companyDetails?.data?.firm_name}
										</p>
									</div>
								</>
							</div>
						</div>
						<div className="divider">
						</div>
						<div className="user-dropdown-menu">
							{
								items.map((item: any) => {
									return (
										<div
											key={item.key}
											className="user-dropdown-item"
											onClick={() => item.onClick()}
										>
											<div className="icon">
												{item.icon}
											</div>
											{item.label}
										</div>
									)
								})
							}
						</div>
					</div>
				}
			</div>
			<BEConfirmModal
				allowAuditorAccess
				title="Confirm Logout"
				message="Are you sure you want to logout?"
				visible={showLogoutModal}
				setVisible={setShowLogoutModal}
				onConfirm={async () => {
					await dispatch(logoutAction());
				}}
			/>
			<UserProfileModal
				open={showProfileModal}
				setOpen={setShowProfileModal}
			/></>
	);
}
