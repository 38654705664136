import { Checkbox, Col, Form, Row } from 'antd';
import * as React from 'react';
import './style.scss';
import { LableRequired } from '../../../../../../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../../../../../../Components/BEFormItems/BEInput';
import { BETextArea } from '../../../../../../../../../../Components/BEFormItems/BETextArea';
import FileUpload from '../../../../../../../../../../Components/BEFileUpload/FileUpload';
import { BETable } from '../../../../../../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import DeleteIcon from '../../../../../../../../../../Components/BEIcons/DeleteIcon';
import EditIcon from '../../../../../../../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../../../../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { reportDataTypes } from '../../../../../../../../../../Redux/Types/reportingTypes';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { addReportData, editReportData } from '../../../../../../../../../../Redux/ReportingReducer';
import useDebounce from '../../../../../../../../../../Config/Hooks/useDebouncedClick';
import { textDelay } from '../../../../../../../../../../Config/Constants';
import { fillReportData } from '../../../../../../../../Actions/template';

export interface IType0TopicProps {
    uniqueCode: string;
    nonEditable?: boolean;
}

export function Type0Topic(props: IType0TopicProps) {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [file, setFile] = React.useState<any>(null);
    const [currentTopicData, setCurrentTopicData] = React.useState<any>(null);
    const currrentReort = useTypedSelector(state => state.reporting.currentReport);
    const reportData = useTypedSelector(state => state.reporting.reportData?.data);
    const selectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics?.data);


    React.useEffect(() => {
        const topicId: any = selectedTopics?.find((item: any) => item.unique_code === props.uniqueCode)?.id
        let thisTopicData = reportData[topicId!] ? reportData[topicId!][0!] : null;
        if (thisTopicData)
            form.setFieldsValue({
                data: thisTopicData?.data,
                additional_information: thisTopicData?.additional_information,
            })
        else {
            form.setFieldsValue({
                data: null,
                additional_information: null,
            })
        }
        setCurrentTopicData(thisTopicData);
    }, [reportData, props.uniqueCode])

    const handleAddData = () => {
        const topicId: any = selectedTopics?.find((item: any) => item.unique_code === props.uniqueCode)?.id
        let dataToSend = form.getFieldsValue();
        if (dataToSend?.data === null && dataToSend.additional_information === null) return;
        if (dataToSend?.data === null) delete dataToSend?.data;
        if (dataToSend.additional_information === null) delete dataToSend.additional_information;
        dispatch(fillReportData({
            ...dataToSend,
            esg_report: currrentReort?.id,
            topic: topicId,
        }))
    }

    return (
        <div className='type-0'>
            <Form
                form={form}
                // onFinish={handleForm}
                style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
                {/* <Row justify={"end"}>
                    <Col style={{ fontWeight: "500", float: "right" }}>
                        <Checkbox>Topic is not applicable to the business</Checkbox>
                    </Col>
                </Row> */}
                <Row>
                    <Col span={14}>
                        {/* <Row style={{ opacity: "0.5", cursor: "not-allowed", display: "flex", flexDirection: "column", gap: "1rem" }}> */}
                        {
                            // selectedTopics?.find((item: any) => item.unique_code === props.uniqueCode)?.unit === 'Description' ?
                            // <p>Please enter the value</p> :
                            <LableRequired>Please enter the value </LableRequired>
                        }
                        <Form.Item
                            name="data"
                            className={currentTopicData?.last_autofilled ? 'autofill' : ''}
                        >
                            {/* <BEInput
                                onBlur={() => handleAddData()}
                                disabled={props.nonEditable}
                                size="large" placeholder="Value of metric here" /> */}
                            <BETextArea
                                disabled={props.nonEditable}
                                name='data'
                                onBlur={() => handleAddData()}
                                rows={3}
                                placeholder={'Value of metric here'}
                                form={form}
                            />
                        </Form.Item>
                        {/* </Row> */}
                        {/* <Row style={{ opacity: "0.8", display: "flex", flexDirection: "column", gap: "1rem" }}> */}
                        {
                            // selectedTopics?.find((item: any) => item.unique_code === props.uniqueCode)?.unit === 'Description' ?
                            // <LableRequired>Description</LableRequired> :
                            <p>Description</p>
                        }
                        <Form.Item
                            name="additional_information"
                        >
                            <BETextArea
                                disabled={props.nonEditable}
                                name='additional_information'
                                onBlur={() => handleAddData()}
                                rows={5}
                                placeholder={'Describe how the above value has been calculated'}
                                form={form}
                            />
                        </Form.Item>
                        {/* </Row> */}
                    </Col>
                    {/* <Col offset={1} span={9} style={{ opacity: "0.8", display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <LableRequired>Upload Evidence</LableRequired>
                        <FileUpload
                            width='95%'
                            handleFileChange={setFile}
                            loading={false}
                            fileType='any'
                            text='Upload Evidence'
                            hint='PDF or ZIP, Max 3MB'
                            refreshUpload={false}
                        />
                    </Col> */}
                </Row>
            </Form>
        </div>
    );
}
