import React, { useEffect, useState } from "react";
import { BEDrawer } from "../../../../../Components/BEDrawer";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import { Form } from "antd";
import { LableRequired } from "../../../../../Components/BEFormItems/LableRequired";
import { BEInput } from "../../../../../Components/BEFormItems/BEInput";
import { useTypedSelector } from "../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import { getAllIndustries } from "../../../../../views/Actions/OnboardingActions";
import BEMultipleSelect from "../../../../../Components/BEMultipleSelect";
import { addGlobalSupplier, patchGlobalSupplier } from "../../../Actions/supplierAction";
import BEConfirmModal from "../../../../../Components/BEConfirmModal";

interface AddSupplierProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
  supplier?: any;
}

const AddSupplier = (props: AddSupplierProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const allIndustries = useTypedSelector(
    (state) => state.onBoarding.industries
  );
  const [loading, setLoading] = useState(false)
  const [isNext, setIsNext] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  useEffect(() => {
    if(!props.open) return
    if(props.isEdit){
      form.setFieldsValue({
        name: props.supplier.name,
        poc: props.supplier.poc,
        email: props.supplier.email,
        industry: props.supplier.industry,
        code: props.supplier.code,
        description: props.supplier.description
      })
    }
    else form.resetFields();
  }, [props.open]);

  useEffect(() => {
    if (allIndustries?.status === "idle") dispatch(getAllIndustries());
  }, [allIndustries]);

  const onSubmit = async (values: any) => {
    if(props.isEdit) setOpenEdit(true)
    setLoading(true)
    const obj = { ...values };
    await dispatch(addGlobalSupplier([obj]))
    setLoading(false);
    if (!isNext) {
      props.setOpen(false);
    }
    form.resetFields();
  };

  return (
    <BEDrawer
      open={props.open}
      zIndex={10}
      setOpen={props.setOpen}
      heading="Add Partner"
      footer={
        <>
          <BEButton className="primary" size="large" onClick={async () => {
            if(props.isEdit) setOpenEdit(true)
            else form.submit();
          }}
            onMouseEnter={() => {
              setIsNext(false)
            }}
            loading={loading && !isNext}>
            Save
          </BEButton>
          {
            !props.isEdit &&
            <BEButton size="large" onClick={async () => {
            form.submit();
          }}
            onMouseEnter={() => {
              setIsNext(true)
            }}
            loading={loading && isNext}>
            Save & Add Another
          </BEButton>
          }
        </>
      }
    >
      <Form form={form} onFinish={onSubmit}>
        <LableRequired>Partner Name</LableRequired>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Partner Name is required" }]}
        >
          <BEInput size="large" placeholder="Name of the partner" />
        </Form.Item>
        <LableRequired>POC</LableRequired>
        <Form.Item
          name="poc"
          rules={[{ required: true, message: "POC Name is required" }]}
        >
          <BEInput size="large" placeholder="Name of the POC" />
        </Form.Item>
        <LableRequired>POC Email</LableRequired>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "POC Email is required" }]}
        >
          <BEInput size="large" placeholder="Email of the POC" />
        </Form.Item>
        <LableRequired>Industry</LableRequired>
        <Form.Item name="industry">
          <BEMultipleSelect
            data={allIndustries?.data}
            name="industry"
            labelFeild="title"
            valueFeild="title"
            placeholder="Select Industry"
            single
          />
        </Form.Item>
        <p>
          Partner code
        </p>
        <Form.Item name="code">
          <BEInput size="large" placeholder="Partner code" />
        </Form.Item>
        <p>
          Description
        </p>
        <Form.Item name="description">
          <BEInput size="large" placeholder="Description" />
        </Form.Item>
      </Form>
      <BEConfirmModal
        title="Warning:"
        message=" This action will change the details of the 
                  suppliers added in existing surveys on the platform."
        visible={openEdit}
        setVisible={setOpenEdit}
        onConfirm={async() => {
          const values = form.getFieldsValue()
          await dispatch(patchGlobalSupplier(props.supplier.id, values))
          props.setOpen(false)
        }}
        type="warning"
      />
    </BEDrawer>
  );
};

export default AddSupplier;
