import {
    POST_METRICS_DATA,
    EDIT_METRICS_DATA, DELETE_METRICS_DATA,
    GET_ALL_METRICS_DATA,
    GET_METRICS_STATUS,
    POST_METRICS_STATUS,
    PATCH_METRICS_STATUS,
    GET_DERIVED_METRICS,
    GET_MY_DERIVED_METRICS,
    DELETE_MY_DERIVED_METRICS,
    POST_MY_DERIVED_METRICS,
    GET_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    POST_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    GET_OMITTED_METRICS,
    POST_OMITTED_METRICS,
    PATCH_OMITTED_METRICS,
    DELETE_OMITTED_METRICS,
    BULK_UPLOAD_MY_METRICS_DATA,
    GET_DERIVED_METRICS_DATA_BY_MONTH,
    GET_MY_METRIC_DATA_LABELS,
    POST_MY_METRIC_DATA_LABELS,
    DELETE_MY_METRIC_DATA_LABELS,
    GET_CONTRIBUTORS_BY_METRICS,
    POST_CONTRIBUTORS_BY_METRICS,
    DELETE_CONTRIBUTORS_BY_METRICS,
    GET_MY_METRIC_DATA_BY_MONTH,
    GET_MY_METRIC_DATA_BULK_UPLOAD_FILES,
    GET_MY_METRIC_BULK_UPLOAD_DATA_BY_FILE_ID,
    PATCH_MY_METRIC_DATA_BULK_UPLOAD_FILES,
    GET_DATA_FROM_METRIC_DATA_EVIDENCE,
    PATCH_MY_DERIVED_METRICS
} from "../../../Utils/Routes/DataEntryRoutes";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    setMetricStatusDM,
    setAssignedDepartmentByCategory,
    setOmittedMetrics,
    setLabels,
    setContributorsByMetrics,
    setMyMetricDataBulkUploadFiles,
    setCurrentMyMetricDataBulkUploadFileId,
} from "../../../Redux/DataManagerReducer";
import { BusinessUnitRefType } from "../../../Redux/Types/entityTypes";
import { getLogs } from "../../Settings/Actions";
import { labelType, metricDataType, metricType } from "../../../Redux/Types/dataManagerTypes";
import dayjs from "dayjs";
import { setDerivedMetrics, setMyDerivedMetrics, setMyDerivedMetricsDataByMonthAndBusinessUnit, setMyMetricsData, setMyMetricsDataByMonthAndBusinessUnit, setNewMyMetricsData } from "../../../Redux/OnboardingReducer";
import { getCombinedSelectedMetrics, getSingleMyMetric } from "../../../views/Actions/OnboardingStep4Actions";
import exportToCSV from "../../../Utils/Download/Excell/ExportExcell";
import { showFYFormatByYear } from "../../../Config/Functions/UsefullFunctions";


export const formMetricQueryParams = (page?: number, page_size?: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup;
    const currentYear = getState().dataManager.metrics.dateSlicer.currentYear;
    const currentMonth = getState().dataManager.metrics.dateSlicer.currentMonth;
    let businessUnits: any = [];
    if (!currentBusinessUnit && currentBusinessGroup) {
        businessUnits = getState().entity.BusinessUnitRefs?.data.filter((bu: BusinessUnitRefType) => bu.business_group === currentBusinessGroup).map((bu: BusinessUnitRefType) => bu.business_unit);
    } else if (currentBusinessUnit) {
        businessUnits = [currentBusinessUnit];
    } else {
        businessUnits = null;
    }

    let queryParams: any = {
        year: currentYear,
        month: currentMonth
    }

    if (businessUnits) {
        queryParams = {
            ...queryParams,
            business_units: businessUnits?.join(",")
        }
    }

    if (page) {
        queryParams = {
            ...queryParams,
            page: page,
            page_size: page_size || 10
        }
    }
    return queryParams;
}


export const getTopicStatusDM = (id: number, businessUnit?: number, month?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    let topicsData = getState().dataManager.metrics.tableDataDM;
    let status: any = 0; //0=pending, 1=filled, 2=approved
    const myMetricdataBulkUploadFiles = getState().dataManager.myMetricDataBulkUploadFiles;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    // if (!topicsData?.hasOwnProperty(id) || topicsData[id!]?.length === 0) {
        let bulkUploadFiles = myMetricdataBulkUploadFiles.data.filter((item) =>
            item.my_metric_id === id
            && (item.business_unit_id === currentBusinessUnit || currentBusinessUnit === 0)
        );

        bulkUploadFiles.forEach((item) => {
            if (item.status == 0) {
                status = 1;
            }
        });
        // return status;
    // }
    for (let i = 0; i < topicsData[id]?.length; i++) {
        let monthOfEntry = dayjs(topicsData[id][i].date_of_entry).month() + 1;
        if (topicsData[id][i]?.status === 2 && (!businessUnit || topicsData[id][i].business_unit === businessUnit) && (!month || monthOfEntry === month)) {
            if (status === 0) {
                status = 2;
            }
        }
        if (topicsData[id][i]?.status === 1 && (!businessUnit || topicsData[id][i].business_unit === businessUnit) && (!month || monthOfEntry === month)) {
            status = 1;
        }
    }
    return status;
}

export const getTotalMetricValueDM = (metric: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const myMetricsPerYear = getState().onBoarding.metrics.myMetricsPerYear?.data;
    const totalValue = myMetricsPerYear?.find((item: any) => item?.id === metric?.id)?.total_value || 0;
    return totalValue;
}

export const getApprovedMetricValueDM = (metric: metricType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const myMetricsPerYear = getState().onBoarding.metrics.myMetricsPerYear?.data;
    const totalValue = myMetricsPerYear?.find((item: any) => item?.id === metric?.id)?.approved_value || 0;
    return totalValue;
}

// changed
export const getMyDerivedMetrics = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    // new code
    dispatch(setMyDerivedMetrics({
        status: "loading",
        data: []
    }));
    const queryParams = dispatch(formMetricQueryParams());
    const [data, error] = await handleAPICall(GET_MY_DERIVED_METRICS(queryParams));
    if (data) {
        const derivedMetrics = getState().onBoarding.metrics.derivedMetrics?.data;
        let dataArr = data?.data.map((item: any) => {
            const metricData = derivedMetrics?.find((metric) => metric.id === item.derived_metric);
            return {
                ...item,
                material_topic: metricData?.material_topic,
                pillars: metricData?.pillars,
                category: metricData?.category,
                group: metricData?.group,
                unit: metricData?.unit,
                applicable_to_all: metricData?.applicable_to_all,
                annual: metricData?.annual,
                tab: metricData?.tab,
                description: metricData?.description,
                bcode: metricData?.bcode,
                title: metricData?.title,
                condition: metricData?.condition,
                formula: metricData?.formula,
                direct: metricData?.direct,
            }
        });

        // new code
        dispatch(setMyDerivedMetrics({
            status: "success",
            data: dataArr
        }));
        return dataArr;
    }
    else {
        // new code
        dispatch(setMyDerivedMetrics({
            status: "error",
            data: []
        }));
        return null;
    }
}

export const postMyDerivedMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DERIVED_METRICS(data));
    if (dataRes) {
        await dispatch(getCombinedSelectedMetrics());
        return true;
    }
    else {
        return null
    }
}

export const patchMyDerivedMetrics = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_DERIVED_METRICS(id, data));
    if (dataRes) {
        BEMessage.success("Metric Updated Successfully");
        dispatch(getCombinedSelectedMetrics());
        return dataRes;
    }
    else {
        return null;
    }
}

export const deleteMyDerivedMetrics = (id: any, setLoading?: Function) => async (dispatch: AppDispatch) => {
    setLoading && setLoading(true);
    const [dataRes, error] = await handleAPICall(DELETE_MY_DERIVED_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getCombinedSelectedMetrics());
    }
    setLoading && setLoading(false);
}


// changed
export const getDerivedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setDerivedMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS());
    if (data) {
        let filteredDerivedMetrics: any = [];
        data?.data.forEach((d: any) => {
            if (d.pillar !== "0" && d.pillar !== null && d.pillar !== "" && d.pillar !== "nan" && d.pillar !== "undefined") {
                filteredDerivedMetrics.push(d);
            }
        });
        // new code
        dispatch(setDerivedMetrics({
            status: "success",
            data: filteredDerivedMetrics
        }));
        return filteredDerivedMetrics
    }
    else {
        // new code
        dispatch(setDerivedMetrics({
            status: "error",
            data: []
        }));
        return null;
    }
}


export const getMetricsStatus = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_METRICS_STATUS());
    if (data) {
        dispatch(setMetricStatusDM({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setMetricStatusDM({
            status: "error",
            data: []
        }));
    }
}

export const postMetricsStatus = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_METRICS_STATUS(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        dispatch(getMetricsStatus());
    }
}

export const patchMetricsStatus = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_METRICS_STATUS(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getMetricsStatus());
    }
}

export const getNewMyMetricsData = (my_metric_id?: number, page?: number, pageSize?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setNewMyMetricsData({
        status: "loading",
        data: []
    }));
    const queryParams = dispatch(formMetricQueryParams(page, pageSize));
    queryParams['ordering'] = '-timestamp';
    const [data, error] = await handleAPICall(GET_ALL_METRICS_DATA(my_metric_id, queryParams));
    if (data) {
        let dataToSet = data?.data
        // .sort((a: any, b: any) => (a.timestamp > b.timestamp) ? 1 : -1);

        // new code
        dispatch(setNewMyMetricsData({
            status: "success",
            data: dataToSet
        }));

        const totalPages = data?.total_pages;
        return [dataToSet, totalPages];
    }
    else {
        // new code
        dispatch(setNewMyMetricsData({
            status: "error",
            data: []
        }));

        return null;
    }
}

// changed
export const getMyMetricsData = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMyMetricsData({
        status: "loading",
        data: []
    }));

    const [data, error] = await handleAPICall(GET_ALL_METRICS_DATA());
    if (data) {
        let dataToSet = data?.data.sort((a: any, b: any) => (a.timestamp > b.timestamp) ? 1 : -1);

        // new code
        dispatch(setMyMetricsData({
            status: "success",
            data: dataToSet
        }));
    }
    else {
        // new code
        dispatch(setMyMetricsData({
            status: "error",
            data: []
        }));
    }
}

export const postMetricsData = (data: any) => async (dispatch: AppDispatch, setState: () => RootState) => {
    const [dataRes, error] = await handleAPICall(POST_METRICS_DATA(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        dispatch(getSingleMyMetric(data.my_metric)).then(() =>
            dispatch(getNewMyMetricsData(data.my_metric))
        );
        dispatch(getMyMetricsData());
        dispatch(getLogs());
        return dataRes;
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
        return null;
    }
}

export const editMetricsData = (id: any, data: any, my_metric_id: number) => async (dispatch: AppDispatch, setState: () => RootState) => {
    const [dataRes, error] = await handleAPICall(EDIT_METRICS_DATA(id, data));
    if (dataRes) {
        dispatch(getMyMetricsData());
        dispatch(getSingleMyMetric(my_metric_id)).then(() =>
            dispatch(getNewMyMetricsData(my_metric_id))
        );
        return dataRes;
    }
    else {
        return null;
    }
}

export const deleteMetricsData = (id: any, my_metric_id: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_METRICS_DATA(id));
    if (dataRes) {
        BEMessage.success("Data Deleted Successfully");
        dispatch(getSingleMyMetric(my_metric_id)).then(() =>
            dispatch(getNewMyMetricsData(my_metric_id))
        );
    }
}

// changed
export const getDerivedMetricDataByMonth = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMyDerivedMetricsDataByMonthAndBusinessUnit({
        status: "loading",
        data: []
    }));

    const queryParams = dispatch(formMetricQueryParams());
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA_BY_MONTH(queryParams));
    if (data) {
        // new code
        dispatch(setMyDerivedMetricsDataByMonthAndBusinessUnit({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        // new code
        dispatch(setMyDerivedMetricsDataByMonthAndBusinessUnit({
            status: "error",
            data: []
        }));
    }
}

export const getAssignedDepartmentByCategory = () => async (dispatch: AppDispatch) => {
    dispatch(setAssignedDepartmentByCategory({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_ASSIGNED_DEPARTMENT_BY_CATEGORY());
    if (data) {
        dispatch(setAssignedDepartmentByCategory({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setAssignedDepartmentByCategory({
            status: "error",
            data: []
        }));
    }
}

export const postAssignedDepartmentByCategory = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_ASSIGNED_DEPARTMENT_BY_CATEGORY(data));
    if (dataRes) {
        BEMessage.success("Department assigned successfully.");
        dispatch(getAssignedDepartmentByCategory());
    }
}

export const patchAssignedDepartmentByCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getAssignedDepartmentByCategory());
    }
}

export const deleteAssignedDepartmentByCategory = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY(id));
    if (dataRes) {
        BEMessage.success("Department removed successfully.");
        dispatch(getAssignedDepartmentByCategory());
        dispatch(getContributorsByMetrics());
    }
}

export const getOmittedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setOmittedMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_OMITTED_METRICS());
    if (data) {
        dispatch(setOmittedMetrics({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setOmittedMetrics({
            status: "error",
            data: []
        }));
    }
}

export const postOmittedMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_OMITTED_METRICS(data));
    if (dataRes) {
        BEMessage.success("Metric omitted successfully.");
        dispatch(getOmittedMetrics());
    }
}

export const patchOmittedMetrics = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_OMITTED_METRICS(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getOmittedMetrics());
    }
}

export const deleteOmittedMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_OMITTED_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric unomitted successfully.");
        dispatch(getOmittedMetrics());
    }
}

export const getDataLabels = () => async (dispatch: AppDispatch) => {
    dispatch(setLabels({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_LABELS());
    if (data) {
        dispatch(setLabels({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setLabels({
            status: "error",
            data: []
        }));
    }
}

export const postDataLabels = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_METRIC_DATA_LABELS(data));
    if (dataRes) {
        dispatch(getDataLabels());
    }
}

export const deleteDataLabels = (id?: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_METRIC_DATA_LABELS(id));
    if (dataRes) {
        BEMessage.success("Label Deleted Successfully");
        dispatch(getDataLabels());
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
    }
}

export const getContributorsByMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setContributorsByMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_CONTRIBUTORS_BY_METRICS());
    if (data) {
        dispatch(setContributorsByMetrics({
            status: "success",
            data: data?.data
        }));
        return data?.data;
    }
    else {
        dispatch(setContributorsByMetrics({
            status: "error",
            data: []
        }));
        return null;
    }
}

export const postContributorsByMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_CONTRIBUTORS_BY_METRICS(data));
    if (dataRes) {
        BEMessage.success("Contributor added successfully.");
        dispatch(getContributorsByMetrics());
        return dataRes?.data;
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
        return null;
    }
}

export const deleteContributorsByMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_CONTRIBUTORS_BY_METRICS(id));
    if (dataRes) {
        BEMessage.success("Contributor removed successfully.");
        dispatch(getContributorsByMetrics());
    }
}


// changed
export const getMyMetricsDataByMonth = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMyMetricsDataByMonthAndBusinessUnit({
        status: "loading",
        data: []
    }));

    const queryParams = dispatch(formMetricQueryParams());

    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_BY_MONTH(queryParams));
    if (data) {
        // new code
        dispatch(setMyMetricsDataByMonthAndBusinessUnit({
            status: "success",
            data: data?.data
        }));
    }
    else {
        console.log(error);
        // new code
        dispatch(setMyMetricsDataByMonthAndBusinessUnit({
            status: "error",
            data: []
        }));

    }
}

export const getMyMetricsDataBulkUploadFiles = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_BULK_UPLOAD_FILES());
    if (data) {
        dispatch(setMyMetricDataBulkUploadFiles({
            status: "success",
            data: data?.data
        }));
    }
    else {
        dispatch(setMyMetricDataBulkUploadFiles({
            status: "error",
            data: []
        }));
    }
}

export const patchMyMetricDataBulkUploadFiles = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_METRIC_DATA_BULK_UPLOAD_FILES(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        getMyMetricsDataBulkUploadFiles();
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
    }
}

export const bulk_upload_my_metric_data_api = (data: any, file_id?: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(BULK_UPLOAD_MY_METRICS_DATA(data, file_id));
    if (dataRes) {
        let task_id = null;
        if (file_id === undefined) {
            task_id = dataRes?.data?.task_id;
            return task_id;
        }
        else {
            // BEMessage.success(dataRes?.msg);
            // dispatch(getMyMetricsDataBulkUploadFiles());
            // dispatch(getMyMetricsData());
            return dataRes;
        }
    }
    else {
        console.log(error);
        BEMessage.error(error?.error || "Something went wrong, Please try again");
        return null;
    }
}

export const getMyMetricBulkUploadData = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentFileId = getState().dataManager.currentMyMetricDataBulkUploadFileId;
    if (!currentFileId) return;
    const [data, error] = await handleAPICall(GET_MY_METRIC_BULK_UPLOAD_DATA_BY_FILE_ID(currentFileId));
    if (data) {
        return data?.data;
    }
    else {
        console.log(error);
        return null;
    }
}


export const myMetricDataBulkUpload = (my_metric_id: number, status: number, file_name?: string | null, file?: any, file_id?: number, rejection_reason?: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setCurrentMyMetricDataBulkUploadFileId(null));
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    let dataToSend: any = {
        my_metric: my_metric_id,
        status: status,
        business_unit: currentBusinessUnit,
        rejection_reason: rejection_reason,
        MY_METRIC_DATA_FILE: file,
        file_name: file_name
    }
    if (!currentBusinessUnit) {
        dataToSend = {
            my_metric: my_metric_id,
            status: status,
            rejection_reason: rejection_reason,
            MY_METRIC_DATA_FILE: file,
            file_name: file_name
        }
    }
    const resp = await dispatch(bulk_upload_my_metric_data_api(dataToSend, file_id));
    return resp;
}


export const fetchDataFromEvidence = (file: any, bcode: string) => async (dispatch: AppDispatch) => {
    const data = {
        pdf_file: file,
        bcode: bcode
    }
    const [resp, error] = await handleAPICall(GET_DATA_FROM_METRIC_DATA_EVIDENCE(data));
    if (resp) {
        return resp?.body;
    }
    else {
        console.log(error);
        return null;
    }
}



// old code

// export const getMyDerivedMetricsData = (currentYear:number) => async (dispatch:AppDispatch,getState:()=>RootState) => {
//     dispatch(setMyDerivedMetricsDataDM({
//             status: "loading",
//             data: []
//         }));
//     const yearEnd = getState().companies.companyDetails?.data?.reporting_year_end;
//     const dates = getStartDateAndEndDate(currentYear,yearEnd || "12-31");
//     if(dates === null) return;
//     const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA(dates?.startDate ,dates?.endDate));
//     if (data) {
//         dispatch(setMyDerivedMetricsDataDM({
//             status: "success",
//             data: data?.data
//         }));
//     }
//     else{
//         console.log(error);
//         dispatch(setMyDerivedMetricsDataDM({
//             status: "error",
//             data: []
//         }));
//     }
// }


// export const getDerivedMetricDataById = (currentYear: number, id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
//     const yearEnd = getState().companies.companyDetails?.data?.reporting_year_end;
//     const dates = getStartDateAndEndDate(currentYear, yearEnd || "12-31");
//     if (dates === null) return;
//     const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA(dates?.startDate, dates?.endDate, id));
//     if (data) {
//         return data?.data
//     }
//     else {
//         return null;
//     }
// }
// dispatch(getNewMyMetricsData(props.metric.id, page, 10)).then((res) => 

export const getBuBgNameMaps = () => async (
dispatch: AppDispatch, getState: () => RootState) => {
    // this funstion returns maps
    // 1 - buBGMap: {business_unit_id: [business_group_names]}
    // 2 - businessUnitNameMap: {business_unit_id: business_unit_name}
    // 3 - businessGroupNameMap: {business_group_id: business_group

    const business_units = getState().entity.BusinessUnits;
    const business_groups = getState().entity.BusinessGroups;
    const business_unit_ref = getState().entity.BusinessUnitRefs;
    let buBGMap: any = {}
    let businessUnitNameMap: any = {}
    let businessGroupNameMap: any = {}
    business_units?.data?.forEach((item) => { 
        businessUnitNameMap[item.id] = item.name 
    })
    business_groups?.data?.forEach((item) => { 
        businessGroupNameMap[item.id] = item.name 
    })
    business_unit_ref?.data?.forEach((item) => {
        if (!buBGMap[item.business_unit]) buBGMap[item.business_unit] = []
        buBGMap[item.business_unit].push(
            businessGroupNameMap[item.business_group]
        )
    })
    return [buBGMap, businessUnitNameMap, businessGroupNameMap];
}

export const DownloadMetricData = (metric: any) =>
     async (dispatch: AppDispatch, getState: () => RootState) => {
    const companyDetails = getState().companies.companyDetails;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup
    const currentYear = getState().dataManager.metrics.dateSlicer.currentYear;
    const labels = getState().dataManager.labels;
    
    const [buBGMap, businessUnitNameMap, businessGroupNameMap] = await dispatch(
        getBuBgNameMaps()
    );
    
    let querry = await dispatch(formMetricQueryParams());
    const [data,error] = await handleAPICall(
        GET_ALL_METRICS_DATA(metric.id, querry)
    );
    
    const fy = showFYFormatByYear(
        currentYear, 
        companyDetails?.data?.reporting_year_end || "12-31"
    );
    const dataToDownload:any = data?.data?.map((item:any) => {

        return {
            'Business Unit': businessUnitNameMap[item.business_unit],
            'Business Groups': buBGMap[item.business_unit] ?
                    buBGMap[item.business_unit].join(', ') :
                    'Unassigned',
            'Financial Year': fy,
            'Metric Title': metric.custom_title || metric.title,
            'Date of entry': dayjs(item.date_of_entry).format('DD-MM-YYYY'),
            'Source': item.source === "nan" ? "" : item.source,
            'Value': item.value,
            'Description': item.description === "nan" ? "" : item.description,
            'Cost': item.cost,
            'Evidence': item.evidence,
            'Label': labels?.data?.filter((label: labelType) => label.my_metric_data === item.id).map((label: labelType) => label.label_name).join(', ')
        }
    });

    const entityName = !currentBusinessGroup && !currentBusinessUnit ?
     companyDetails?.data?.firm_name :
        !currentBusinessGroup ? businessUnitNameMap[currentBusinessUnit] :
            businessGroupNameMap[currentBusinessGroup];
    
    exportToCSV(
        dataToDownload,
        (metric.custom_title || metric.title) + '-' + entityName + '-' + 
        new Date().getDate() + '-' + (new Date().getMonth() + 1) + 
        '-' + new Date().getFullYear()
    );
}

export const DownloadUploadData = (file_id: number) =>
async (dispatch: AppDispatch, getState: () => RootState) => {
    const companyDetails = getState().companies.companyDetails;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup
    const currentYear = getState().dataManager.metrics.dateSlicer.currentYear;

    const [data,error] = await handleAPICall(
        GET_MY_METRIC_BULK_UPLOAD_DATA_BY_FILE_ID(file_id)
    )
    if(!data) return BEMessage.error("Error in downloading data");

    const [buBGMap, businessUnitNameMap, businessGroupNameMap] = await dispatch(
        getBuBgNameMaps()
    );

    const fy = showFYFormatByYear(
        currentYear, 
        companyDetails?.data?.reporting_year_end || "12-31"
    );
    const dataToDownload:any = data.data?.accepted_data?.map((item:any) => {
        return {
            'Business Unit': businessUnitNameMap[item.business_unit],
            'Business Groups': buBGMap[item.business_unit] ?
                    buBGMap[item.business_unit].join(', ') :
                    'Unassigned',
            'Financial Year': fy,
            'Date of entry': dayjs(item.date_of_entry).format('DD-MM-YYYY'),
            'Value': item.value,
            'Description': item.description === "nan" ? "" : item.description,
            'Cost': item.cost
        }
    });

    const entityName = !currentBusinessGroup && !currentBusinessUnit ?
     companyDetails?.data?.firm_name :
        !currentBusinessGroup ? businessGroupNameMap[currentBusinessUnit] :
            businessGroupNameMap[currentBusinessGroup];

    exportToCSV(
        dataToDownload,
        entityName + '-' +
        new Date().getDate() + '-' + (new Date().getMonth() + 1) +
        '-' + new Date().getFullYear()
    );

}
