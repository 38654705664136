import React, { useEffect } from 'react'
import { PDFDownloadLink, Document, Page, View, StyleSheet, Text, Image, Link } from '@react-pdf/renderer'
import image1 from '../../../../../assets//images/Report/report1img.jpg'
import Logo from '../../../../assets/images/Company/Logo_horizontal.png'
import { indexOf } from 'lodash';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { SupplierQuestionsTypes } from '../../../../Redux/Types/suppliersTypes';

//A4 size in pts 595 × 842

interface PropTypes {
  score: any,
  questions: any,
  answers: any,
  supplierData: any
}

const styles = StyleSheet.create(
  {
    pageStyle: {
      padding: '20pt', //remaining 555
      paddingBottom: '50pt'
    },
    blockWidth: {
      width: '33.33%',
      padding: '20pt', border: '1pt solid black',

    },
    blockWidthSmall: {
      width: '33.33%',
      padding: '10pt 20pt', border: '1pt solid black'
    },
    normalText: {
      fontSize: '10pt', fontWeight: 'bold'
    }
    , outerBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    smallText: {
      fontSize: '7pt',
      lineHeight: '1'
    },
    headingText: {
      fontSize: '12pt',
      fontWeight: 'heavy',
    },
  }

)



const PoweredBy = () => (
  <View style={{ position: 'absolute', bottom: '10px', left: '10px' }} fixed>
    <Text style={{ color: 'grey', fontSize: '10px' }}>Created By</Text>
    <Image src={Logo} style={{ width: '100px', marginTop: '1px' }} fixed />
  </View>
)

export const IndividualSupplierTemplate = (props: PropTypes) => (
  <Document>
    <Page size='A4' style={{ ...styles.pageStyle }}>
      <View style={{ display: 'flex', justifyContent: 'center', marginTop: '20pt' }}>
        <Text style={{ fontSize: '25pt', fontWeight: 'bold' }}>Value Chain Assessment Individual Response</Text>
      </View>

      <View style={{ marginTop: '30pt', ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(234, 209, 220)' }}>
          <Text style={{ ...styles.normalText }}>
            Partner Name
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            {
              props.supplierData.name
            }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(234, 209, 220)' }}>
          <Text style={{ ...styles.normalText }}>
            Partner Industry
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            {
              props.supplierData.industry
            }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(234, 209, 220)' }}>
          <Text style={{ ...styles.normalText }}>
            Email
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            {
              props.supplierData.email
            }
          </Text>
        </View>
      </View>


      <View style={{ marginTop: '30pt', ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            Total score (%)
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
            {props.score}
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            Risk classification
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
            {
              props.score < 33.33 ? 'High ' : props.score < 66.66 ? 'Medium ' : 'Low '
            }
            risk
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            Data provided
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
            {
              props.answers.filter((answer: any) => answer.additional_information !== '')?.length
            }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            Data completion (%)
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
            {
              ((props.answers.filter((answer: any) => answer.additional_information !== '')?.length /
                props.questions?.length) * 100)?.toFixed(2)
            }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            Evidence provided
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
            {
              props.answers.filter((answer: any) => answer.evidence)?.length
            }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
            Evidence completion (%)
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
            {
              ((props.answers.filter((answer: any) => answer.evidence)?.length
                / props.questions?.length) * 100)?.toFixed(2)
            }
          </Text>
        </View>
      </View>

      <View style={{ marginTop: '50pt', marginBottom: '30pt', ...styles.outerBox }}>
        <Text style={{ fontWeight: 'bold', fontSize: '20pt' }}>
          Partner Responses
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20pt',
          marginBottom: '10pt',
          paddingTop: '8pt',
          paddingBottom: '8pt',
          paddingLeft: '5pt',
          border: `1px solid ${PrimaryTheme.primaryGreyLight}`,
          backgroundColor: `${PrimaryTheme.primaryGreyLight}`
        }}
        wrap
      >
        <View style={{ width: '8%' }}>
          <Text style={{ ...styles.headingText }}>
            Sr. No

          </Text>
        </View>
        <View style={{ width: '45%' }}>
          <Text style={{ ...styles.headingText }}>
            Question
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={{ ...styles.headingText }}>
            Answer
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={{ ...styles.headingText }}>
            Value
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={{ ...styles.headingText }}>
            Evidence
          </Text>
        </View>
      </View>
      {
        props.questions.map((question: SupplierQuestionsTypes, index: number) => {
          let answer = props.answers?.find((answer: any) => 
            question.type === answer.type &&
            answer.question === question.id 
            );
          return (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20pt',
                marginBottom: '10pt',
                paddingBottom: '10pt',
                paddingLeft: '5pt',
                borderBottom: `1px solid ${PrimaryTheme.primaryGreyLight}`
              }}
              wrap
            >
              <View style={{ width: '8%' }}>
                <Text style={{ ...styles.normalText }}>
                  {index + 1}
                </Text>
              </View>
              <View style={{ width: '45%' }}>
                <Text style={{ ...styles.normalText }}>
                  {question.question}
                </Text>
              </View>
              <View style={{ width: '15%', paddingLeft: '5pt' }}>
                <Text style={{ ...styles.normalText }}>
                  {
                    answer.answer === true ? 'Yes' : 'No'
                  }
                </Text>
              </View>
              <View style={{ width: '15%' }}>
                <Text style={{ ...styles.normalText }}>
                  {
                    answer?.additional_information ? 
                    <>{answer?.additional_information} {question?.unit && question?.unit}</> :
                      answer.answer === true ? 'Not provided' : '-'
                  }
                </Text>
              </View>
              <View style={{ width: '15%' }}>
                <Text style={{ ...styles.normalText }}>
                  {
                    answer?.evidence ? <Link src={answer?.evidence}>Evidence</Link> :
                      answer.answer === true ? 'Not attached' : '-'
                  }
                </Text>
              </View>
            </View>
          )
        })
      }
      <PoweredBy />

    </Page>

  </Document>
)