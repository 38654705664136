import * as React from 'react';
import './style.scss';
import { BECollapse } from '../../../../../../../Components/BECollapse';
import { Radio } from 'antd';
import { BEInput } from '../../../../../../../Components/BEFormItems/BEInput';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { SupplierQuestionsTypes } from '../../../../../../../Redux/Types/suppliersTypes';
import { BEEyeButton } from '../../../../../../../Components/BEEyeButton';
import { UploadEvidenceSupplier } from '../UploadEvidence';
import { editSupplierAnswer } from '../../../../../Actions/supplierFormActions';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { useSearchParams } from 'react-router-dom';
import { updateSupplierAnswers } from '../../../../../../../Redux/SupplierReducer';
import TextArea from 'antd/es/input/TextArea';
import UploadCloud from '../../../../../../../Components/BEIcons/UploadCloud';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import { SupplierAssessmentQuestionType } from '..';

export interface IQuestionContainerProps {
    saveAnswer: (questionId: any,type:any, values: any) => Promise<void>;
}

export function QuestionContainer(props: IQuestionContainerProps) {
    const supplierAssessmentQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
    const supplierAssessmentAnswers = useTypedSelector(state => state.supplier.SupplierAnswers);
    const currentSection = useTypedSelector(state => state.supplier.CurrentSection);
    const [open, setOpen] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentQuestion, setCurrentQuestion] = React.useState<number | null>(null);
    const [currentQuestionType, setCurrentQuestionType] = React.useState<SupplierAssessmentQuestionType>('default');

    return (
        <div className='question-container'>
            {supplierAssessmentQuestions?.data.filter((item, index) => item.attribute === currentSection).map((item: SupplierQuestionsTypes) => {
                const questionType  = item.type
                const selectedValue = supplierAssessmentAnswers?.data?.[questionType]?.[item.id]?.answer;
                const evidence = supplierAssessmentAnswers?.data?.[questionType]?.[item.id]?.evidence;
                return (
                    <BECollapse
                        heading={
                            <BEEyeButton
                                title={'Description'}
                                discription={item?.description}
                            >
                                <div className='topic-heading'>
                                    <p>{item.question}</p>
                                </div>
                            </BEEyeButton>
                        }
                        titleWidth={selectedValue && 'calc(100% - 8rem)'}
                        headingStyle={{ fontWeight: "600" }}
                        headingRightContent={
                            <div className='right-heading' >{
                                selectedValue &&
                                <div style={{
                                    position: "absolute",
                                    right: "3rem",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "0.5rem"
                                }}>
                                    <span style={{ height: "1rem", width: "2rem" }}>
                                        <UploadCloud inheritSize stroke={PrimaryTheme.primaryGreen} />
                                    </span>
                                    <span
                                        onClick={() => {
                                            setCurrentQuestion(item.id);
                                            setCurrentQuestionType(item.type);
                                            setOpen(true);
                                        }}
                                        className='radio-list-upload'
                                    >
                                        {evidence ? 'View' : 'Upload'} Evidence
                                    </span>
                                </div>}
                            </div>
                        }
                    >
                        <Radio.Group
                            className='radio-list-container'
                            onChange={(e) => props.saveAnswer(item.id,item.type,{answer: e.target.value})}
                            value={selectedValue}
                        >
                            <div
                                className={`radio-list-option ${selectedValue ? 'selected' : ''} `}
                            >
                                <Radio value={true}>
                                    Yes
                                </Radio>
                            </div>
                            {
                                selectedValue &&
                                <div className='radio-list-child-container'>
                                    <TextArea
                                        rows={1}
                                        defaultValue={supplierAssessmentAnswers?.data?.[questionType]?.[item.id]?.additional_information}
                                        onBlur={async (e) => {
                                            const value = e.target.value;
                                            if (value) {
                                                props.saveAnswer(item.id, item.type, {additional_information: value});
                                            }
                                        }}
                                        placeholder={`Enter value ${item.unit ? `(${item.unit})` : ''}`}
                                    />
                                </div>
                            }
                            <div
                                className={`radio-list-option not-expandable`}
                            >
                                <Radio value={false}>
                                    No
                                </Radio>
                            </div>
                        </Radio.Group>
                    </BECollapse>)
            })}

            <UploadEvidenceSupplier
                questionId={currentQuestion as number}
                open={open}
                setOpen={setOpen}
                saveAnswer={props.saveAnswer}
                questionType={currentQuestionType}
            />
        </div>
    );
}
