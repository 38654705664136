import * as React from 'react';
import './style.scss';
import { graphColors5 } from '../../../../Config/Theme/graphColors';
import ToolTip from '../../../BEToolTip';
import { IHorizontalBarChartCoreProps,HorizontalBarChartTypeDataType,HorizontalBarChartTypeDisplayType } from '..';
import { getScaledPercentage } from '../../../../Config/Functions/ScalingFunctions';
import { BEPagination } from '../../../BEPagination';



export function HorizontalBarChartType1 (props: IHorizontalBarChartCoreProps) {
  const [selectedName, setSelectedName] = React.useState<string | null>(null);
  const [dataToDisplay, setDataToDisplay] = React.useState<HorizontalBarChartTypeDisplayType[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const pageSize = props.pageSizes || 5;

  React.useEffect(() => {
    if (props.data.length === 0) return;
    let data:HorizontalBarChartTypeDataType[] = props.data;
    if (props.isPercentage) {
      let total = data.reduce((acc, item) => acc + item.value, 0);
      data = data.map(item => ({name: item.name, value: item.value/total*100}));
    }
    let scaledPercentageValues = getScaledPercentage(data.map(item => item.value));
    let dataToDisplay = data
          .map((item:any,index:number) => (
              {
                name: item.name, 
                value: item.value, 
                widthPercentage: scaledPercentageValues[index],
                absoluteValue: props.data[index].value
              }
            ))
          .sort((a,b) => b.value - a.value)
          .slice((page-1)*pageSize, page*pageSize);
    setDataToDisplay(dataToDisplay);
  }, [props.data, page]);



  return (
    <div className='horizontal-bar-chart-1'>
      {dataToDisplay.map((item:HorizontalBarChartTypeDisplayType,index:number)=> (
        <div className={`bar-container ${selectedName === item.name ? 'selected' : ''}`}
         onClick={() => {
          let name = selectedName === item.name ? null : item.name; 
          props.onClick && props.onClick(name) 
          setSelectedName(name)
         }} key={index}>
          
          <p className='bar-name'>
              {item.name}
          </p>
          <div className='bar-wrapper'>
            <div className='bar' style={{width: `${item.widthPercentage}%`, backgroundColor: graphColors5[index%graphColors5.length]}}>
              {
                props.tooltipFormatter &&
                  <div className='bar-tooltip'>
                    {props.tooltipFormatter(item.absoluteValue, dataToDisplay.reduce((acc, item) => acc + item.value, 0))}
                  </div> 
              }
            </div>
            <p className='bar-value'>
              {item.value.toFixed(2)}{props.isPercentage ? '%' : ''} 
              {props.unit ? ` ${props.unit}` : ''}
            </p>
          </div>
        </div>
        ))
      }
      <div className='pagination-container'>
        <BEPagination
         pageSize={pageSize} 
         total={props.data.length} 
         current={page} 
         onChange={(page) => setPage(page)} 
        />
      </div>
    </div>
  );
}
