export const graphColors = [
  'rgba(31, 119, 180, 1)',//blue 1
  'rgba(44, 160, 44, 1)',//green 1
  'rgba(255, 127, 14, 1)',//orange 1
  'rgba(214, 39, 40, 1)',//red 1
  'rgba(148, 103, 189, 1)',//purple 1
  'rgba(140, 86, 75, 1)',//brown 1
  'rgba(227, 119, 194, 1)',//pink 1
  'rgba(127, 127, 127, 1)',//grey 1
  'rgba(188, 189, 34, 1)',//yellow 1
  'rgba(23, 190, 207, 1)',//blue 2
  'rgba(255, 187, 120, 1)',//orange 2
  'rgba(152, 223, 138, 1)',//green 2
  'rgba(197, 176, 213, 1)',//purple 2
  'rgba(196, 156, 148, 1)',//brown 2
  'rgba(247, 182, 210, 1)',//pink 2
  'rgba(199, 199, 199, 1)',//grey 2
  'rgba(219, 219, 141, 1)',//yellow 2
  'rgba(158, 218, 229, 1)',//blue 3
  'rgba(255, 152, 150, 1)',//orange 3
  'rgba(197, 176, 213, 1)',//green 3
  'rgba(196, 156, 148, 1)',//red 3
  'rgba(247, 182, 210, 1)',//purple 3
  'rgba(199, 199, 199, 1)',//brown 3
  'rgba(219, 219, 141, 1)',//pink 3
  'rgba(158, 218, 229, 1)',//grey 3
  'rgba(255, 152, 150, 1)',//yellow 3
  'rgba(158, 218, 229, 1)',//blue 4
  'rgba(255, 152, 150, 1)',//orange 4
  'rgba(197, 176, 213, 1)',//green 4
  'rgba(196, 156, 148, 1)',//red 4
  'rgba(247, 182, 210, 1)',//purple 4
  'rgba(199, 199, 199, 1)',//brown 4
  'rgba(219, 219, 141, 1)',//pink 4
  'rgba(158, 218, 229, 1)',//grey 4
  'rgba(255, 152, 150, 1)',//yellow 4
  'rgba(158, 218, 229, 1)',//blue 5
  'rgba(255, 152, 150, 1)',//orange 5
  'rgba(197, 176, 213, 1)',//green 5
]

export const graphColors1Light = graphColors.map((color) => color.replace('1)', '0.5)'))

export const graphColors2 = [
  'rgba(242, 174, 114, 1)',//brown
  'rgba(129, 199, 212, 1)',//blue1
  'rgba(109, 142, 184, 1)',//blue2
  'rgba(195, 247, 222, 1)'//blue3
]

export const graphColors3 = [
  'rgba(107, 175, 153, 1)',//green1
  'rgba(155, 223, 196, 1)',//green2
  'rgba(196, 242, 227, 1)',//green3
  'rgba(255, 204, 209, 1)',//pink
]

export const graphColors4 = [     //green theme mainly used for scope emissions
  'rgba(6, 78, 59, 1)', //green1
  'rgba(110, 231, 183, 1)', //green2
  'rgba(4, 120, 87, 1)', //green3
]

export const graphColors5 = [ //pastel colors
  'rgba(24, 52, 37, 1)', //green1
  'rgba(80, 108, 96, 1)', //gray1
  'rgba(192, 97, 69, 1)', //brown1
  'rgba(221, 191, 167, 1)', //brown2
  'rgba(44, 63, 96, 0.5)', //blue1
  'rgba(174, 125, 84, 1)', //brown3
  'rgba(153, 191, 198, 1)', //blue3
  'rgba(255, 204, 209, 1)', //pink1
  'rgba(196, 156, 148, 1)', //brown4
  'rgba(247, 182, 210, 1)', //pink2
  'rgba(199, 199, 199, 1)', //gray2
  'rgba(219, 219, 141, 1)', //yellow1
  'rgba(158, 218, 229, 1)', //blue4
  'rgba(255, 152, 150, 1)', //orange1
  'rgba(197, 176, 213, 1)', //purple1
  'rgba(196, 156, 148, 1)', //brown5
  'rgba(247, 182, 210, 1)', //pink3
  'rgba(199, 199, 199, 1)', //gray3
  'rgba(219, 219, 141, 1)', //yellow2
  'rgba(158, 218, 229, 1)', //blue5
  'rgba(255, 152, 150, 1)', //orange2
]

export const barBackgroundColor = 'rgba(246, 248, 250, 1)'

export const defaultBarGreen = 'rgba(53, 197, 156, 1)'