import * as React from 'react';
import StackedColumnCharts1 from './Type1';
import StackedColumnCharts2 from './Type2';

export interface  IStackedColumnChartCoreProps {
  data: any[];                            /// data should be in this format [{name:'name',data:[1,2,3]}]
  xAxisLabels: any[];
  chartTitle?: string;
  yAxisTitle?: string;
  height?: string;
  yAxisLabel?: string;
  averageLine?: number;
  colorTheme?: number;
  disableSort?: boolean;
}
export interface IStackedColumnChartsProps  extends IStackedColumnChartCoreProps {
  type?: 1 | 2;
}

export  function logScaleTicks(low:number, high:number, numTicks = 10) {
  // if (low <= 0 || high <= 0) {
  //     throw new Error("Values must be greater than zero for logarithmic scaling.");
  // }

  if(low === 0) low = 1/1e6;
  if(high === 0) high = 1/1e6;

  const logLow = Math.log10(low);
  const logHigh = Math.log10(high);
  
  const logTicks = Array.from({ length: numTicks }, (_, i) => 
      logLow + (i / (numTicks - 1)) * (logHigh - logLow)
  );

  const ticks = logTicks.map(tick => 
    Math.pow(10, tick).toFixed(2) 
  );
  
  return ticks;
}

export default function StackedColumnCharts (props: IStackedColumnChartsProps) {
  return (
    <>
      {
        !props.type || props.type === 1 ? 
        <StackedColumnCharts1 {...props} /> : 
        <StackedColumnCharts2 {...props} />
      }
    </>
  );
}
