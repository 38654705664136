import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { Form } from 'antd';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { EventAction } from '../../../../../../Redux/Types/eventManagerTypes';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { BEEvidenceList } from '../../../../../../Components/BEEventManagement/BEEvidenceList';

interface IActionRejectionReasonDrawerProps {
    open: boolean;
    setOpen: Function;
    action: EventAction | undefined;
}

export function ActionRejectionReasonDrawer(props: IActionRejectionReasonDrawerProps) {
    const [form] = Form.useForm();
    const eventEvidences = useTypedSelector(state => state.eventManager.eventEvidences);
    const [currentEvidences, setCurrentEvidences] = React.useState<any>([]);

    React.useEffect(() => {
        if (eventEvidences.data) {
            const filteredEvidences = eventEvidences.data.filter((evidence: any) => evidence.action === props.action?.id && evidence.event === props.action?.event);
            setCurrentEvidences(filteredEvidences);
        }
    }, [eventEvidences, props.action]);

    React.useEffect(() => {
        if (props.action?.stage === 3) {
            form.setFieldsValue({ description: props.action?.description });
            form.setFieldsValue({ summary: props.action?.summary });
        }
    }, [props.open]);

    return (
        <BEDrawer
            heading='Rejection reason'
            open={props.open}
            setOpen={props.setOpen}
            width='30%'
            footer={
                <>
                    <BEButton size='large' className='secondary' onClick={() => props.setOpen(false)}>
                        Close
                    </BEButton>
                </>}
        >
            <Form form={form}>
                <LableRequired>
                    Rejection reason
                </LableRequired>
                <Form.Item name={'summary'}>
                    <BETextArea
                        disabled={true}
                        rows={8}
                        count={{
                            show: true,
                            max: 500,
                        }}
                        maxLength={500}
                        placeholder={''}
                        name='summary'
                        form={form}
                    />
                </Form.Item>
                <LableRequired>
                    Previous Evidence
                </LableRequired>
                <BEEvidenceList
                    evidenceList={currentEvidences.map((evidence: any) => evidence.file)}
                />
                <br />
                <LableRequired>
                    Previous description
                </LableRequired>
                <Form.Item name={'description'}>
                    <BETextArea
                        disabled={true}
                        rows={8}
                        count={{
                            show: true,
                            max: 500,
                        }}
                        maxLength={500}
                        placeholder={''}
                        form={form}
                    />
                </Form.Item>
            </Form>
        </BEDrawer>
    );
}
