import API from "../../API";


export const GET_METRICS_DATA_BY_BU_ID = async (id: any) => {
    return await API.GET("/cleopatra/my-metric-data/" + id + "/");
}

export const GET_METRICS_DATA_BY_BG_ID = async (id: any) => {
    return await API.GET("/cleopatra/business-group-aggregate/" + id + "/");
}

export const GET_ALL_METRICS_DATA = async (my_metric_id?: number, queryParams?: any) => {
    if (my_metric_id) {
        return await API.GET("/cleopatra/my-metric-data/" + my_metric_id + "/", queryParams);
    } else {
        return await API.GET("/cleopatra/my-metric-data/", queryParams);
    }
}

export const POST_METRICS_DATA = async (data: any) => {
    return await API.POST("/cleopatra/my-metric-data/", data, true);
}

export const DELETE_METRICS_DATA = async (id: any) => {
    return await API.DELETE("/cleopatra/my-metric-data/" + id + "/");
}

export const EDIT_METRICS_DATA = async (id: any, data: any) => {
    return await API.PATCH("/cleopatra/my-metric-data/" + id + "/", data, true);
}

export const EDIT_MULTIPLE_METRICS_DATA = async (data: any) => {
    return await API.PATCH("/cleopatra/my-metric-data/", data);
}

export const GET_AGGREGATED_METRICS_DATA = async (year: any) => {
    return await API.GET("/cleopatra/metric-data-aggregate/" + year + "/");
}

export const GET_METRICS_STATUS = async () => {
    return await API.GET("/cleopatra/my-metric-monthly-status/");
}

export const POST_METRICS_STATUS = async (data: any) => {
    return await API.POST("/cleopatra/my-metric-monthly-status/", data);
}

export const PATCH_METRICS_STATUS = async (id: any, data: any) => {
    return await API.PATCH("/cleopatra/my-metric-monthly-status/" + id + "/", data);
}

// GET derived-metrics/
// POST/GET   my-derived-metrics/
// DELETE  my-derived-metrics/id/

export const GET_DERIVED_METRICS = async () => {
    return await API.GET("/cleopatra/derived-metrics/");
}

export const POST_MY_DERIVED_METRICS = async (data: any) => {
    return await API.POST("/cleopatra/my-derived-metrics/", data);
}

export const GET_MY_DERIVED_METRICS = async (queryParams: any) => {
    return await API.GET("/cleopatra/my-derived-metrics/", queryParams);
}

export const PATCH_MY_DERIVED_METRICS = async (id: any, data: any) => {
    return await API.PATCH("/cleopatra/my-derived-metrics/" + id + "/", data);
}

export const DELETE_MY_DERIVED_METRICS = async (id: any) => {
    return await API.DELETE("/cleopatra/my-derived-metrics/" + id + "/");
}

// export const GET_DERIVED_METRICS_DATA = async (from:string, to:string) => {
//     return await API.GET("/cleopatra/my-derived-metrics-calculation/?start_date="+from+"&end_date="+to);
// }

export const GET_DERIVED_METRICS_DATA = async (from: string, to: string, id: number) => {
    return await API.GET("/cleopatra/my-derived-metrics-cal/" + id + "?start_date=" + from + "&end_date=" + to);
}

export const GET_DERIVED_METRICS_DATA_BY_MONTH = async (queryParams: any) => {
    return await API.GET("/cleopatra/derived-metric-data-by-month/", queryParams);
}

export const GET_ASSIGNED_DEPARTMENT_BY_CATEGORY = async () => {
    return await API.GET("/cleopatra/metric-category-by-department/")
}

export const POST_ASSIGNED_DEPARTMENT_BY_CATEGORY = async (data: any) => {
    return await API.POST("/cleopatra/metric-category-by-department/", data);
}

export const PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY = async (id: any, data: any) => {
    return await API.PATCH("/cleopatra/metric-category-by-department/" + id + "/", data);
}

export const DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY = async (id: any) => {
    return await API.DELETE("/cleopatra/metric-category-by-department/" + id + "/");
}

export const GET_OMITTED_METRICS = async () => {
    return await API.GET("/cleopatra/omitted-metrics/");
}

export const POST_OMITTED_METRICS = async (data: any) => {
    return await API.POST("/cleopatra/omitted-metrics/", data);
}

export const DELETE_OMITTED_METRICS = async (id: any) => {
    return await API.DELETE("/cleopatra/omitted-metrics/" + id + "/");
}

export const PATCH_OMITTED_METRICS = async (id: any, data: any) => {
    return await API.PATCH("/cleopatra/omitted-metrics/" + id + "/", data);
}

export const BULK_UPLOAD_MY_METRICS_DATA = async (data: any, file_id?: number) => {
    if (file_id === undefined) {
        console.log("file_id", file_id);
        return await API.POST("/cleopatra/my-metric-data-bulk-upload/", data, true);
    } else {
        return await API.POST("/cleopatra/my-metric-data-bulk-upload/" + file_id + "/", data, true);
    }
}

export const GET_MY_METRIC_BULK_UPLOAD_DATA_BY_FILE_ID = async (file_id: number) => {
    return API.GET("/cleopatra/my-metric-data-bulk-upload/" + file_id + "/");
}

export const GET_MY_METRIC_DATA_BULK_UPLOAD_FILES = async () => {
    return await API.GET("/cleopatra/my-metric-data-upload-files/");
}

export const DELETE_MY_METRIC_DATA_BULK_UPLOAD_FILES = async (id: any) => {
    return await API.DELETE("/cleopatra/my-metric-data-upload-files/" + id + "/");
}

export const PATCH_MY_METRIC_DATA_BULK_UPLOAD_FILES = async (id: any, data: any) => {
    return await API.PATCH("/cleopatra/my-metric-data-upload-files/" + id + "/", data);
}

export const GET_MY_METRIC_DATA_LABELS = async () => {
    return await API.GET("/cleopatra/my-metric-data-labels/");
}

export const POST_MY_METRIC_DATA_LABELS = async (data: any) => {
    return await API.POST("/cleopatra/my-metric-data-labels/", data);
}

export const DELETE_MY_METRIC_DATA_LABELS = async (id?: number) => {
    return await API.DELETE("/cleopatra/my-metric-data-labels/" + id + "/");
}

export const GET_CONTRIBUTORS_BY_METRICS = async () => {
    return await API.GET("/cleopatra/contributors-by-metrics/");
}

export const POST_CONTRIBUTORS_BY_METRICS = async (data: any) => {
    return await API.POST("/cleopatra/contributors-by-metrics/", data);
}

export const DELETE_CONTRIBUTORS_BY_METRICS = async (id: any) => {
    return await API.DELETE("/cleopatra/contributors-by-metrics/" + id + "/");
}

export const GET_MY_METRIC_DATA_BY_MONTH = async (queryParams: any) => {
    return await API.GET("/cleopatra/my-metric-data-by-month/", queryParams);
}

export const GET_DATA_FROM_METRIC_DATA_EVIDENCE = async (data: any) => {
    return await API.POST("/developer/ocr/", data, true);
}