import React, { useEffect, useState } from "react";
import "./styles.scss";
import { PrimaryTheme } from "../../Config/Theme/theames";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "../../assets/images/Company/Logo_horizontal_white.png";
import CompanyLogoCollapsed from "../../assets/images/Company/Logo.png";
import ChevronFilled from "../BEIcons/ChevronFilled";
import CalenderCheckIcon from "../BEIcons/CalenderCheckIcon";
import Dashboard from "../BEIcons/Dashboard";
import DataManager from "../BEIcons/DataManager";
import Targets from "../BEIcons/Targets";
import Emissions from "../BEIcons/Emissions";
import Reporting from "../BEIcons/Reporting";
import Materiality from "../BEIcons/Materiality";
import Suppliers from "../BEIcons/Suppliers";
import SettingsIcon from "../BEIcons/SettingsIcon";
import { setSelectedMenuItem, setShowEventsMenu } from "../../Redux/CommonReducer";
import { useAppDispatch } from "../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import { getNewToken } from "../../views/Actions/LoginActions";
import { setMenuCollapsed, increaseMenuClicks } from "../../Redux/CommonReducer";
import ToolTip from "../BEToolTip";
import dayjs from "dayjs";
import { menubarWidth, menubarWidthCollapsed } from "../../Config/Constants";
import ZeroIcon from "../BEIcons/ZeroIcon";
import GreyZeroIcon from "../BEIcons/GreyZeroIcon";
import { navigateTo } from "../../Navigation/NavigationManager";
import { setCurrentModule } from "../../Redux/UserReducer";
import { ProfileDetails } from "./ProfileDetails";


export interface IBEMenuBarProps {
}



const BEMenuBar = ({ }: IBEMenuBarProps) => {
  const role = useTypedSelector((state) => state.user.userRole);
  const showEventsMenu = useTypedSelector(state => state.common.showEventsMenu);
  const currentModule = useTypedSelector(state => state.user.currentModule);
  const [displayMenuItems, setDisplayMenuItems] = useState([] as any[]);
  const clicks = useTypedSelector((state) => state.common.menuClicks);
  const [showList, setShowList] = useState(false);
  const [menuItems, setMenuItems] = useState([] as any[]);
  const collapsed = useTypedSelector((state) => state.common.menuCollapsed);
  const version = useTypedSelector((state) => state.common.version);
  const activeMenuItem = useTypedSelector((state) => state.common.menuBar.activeMenuItem);
  const userPermissions = useTypedSelector((state) => state.user.permissions);
  const userProfile = useTypedSelector((state) => state.user.userProfile);
  const [activeModuleIndex, setActiveModuleIndex] = useState(0);
  const modules = useTypedSelector((state) => state.settings.modules);
  const [moduleMenu, setModuleMenu] = useState([] as any[]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const eventsMenu = [
    {
      label: "Event Management",
      icon: CalenderCheckIcon,
      link: "/events/event-manager",
      section: 2
    },
    {
      label: "Settings",
      icon: SettingsIcon,
      link: "/events/settings",
      section: 8
    },
  ];

  const esgMenu =
    [
      {
        label: "Dashboard",
        icon: Dashboard,
        link: "/home/dashboard",
        section: 1
      },
      {
        label: "Data Manager",
        icon: DataManager,
        link: "/home/data-manager",
        section: 2
      },
      // {
      //   label: "Entity Manager",
      //   icon: EntityManager,
      //   link: "/home/entity-manager",
      //   section: 3
      // },
      {
        label: "Targets",
        icon: Targets,
        link: "/home/targets",
        section: 6
      },
      {
        label: "Reporting",
        icon: Reporting,
        link: "/home/reporting/reports",
        section: 4
      },
      {
        label: "Emissions",
        icon: Emissions,
        link: "/home/emissions",
        section: 5
      },
      // {
      //   label: "Analytics",
      //   icon: Analytics,
      //   link: "/home/analytics",
      // },
      {
        label: "Materiality",
        icon: Materiality,
        link: "/home/materiality/assessments",
        section: 7
      },
      {
        label: "Value Chain",
        icon: Suppliers,
        link: "/home/suppliers/assessments",
        section: 7
      },
      //  {
      //   label: "Internal",
      //   icon: InternalIcon,
      //   link: "/home/internal/assessments",
      // },
      {
        label: "Settings",
        icon: SettingsIcon,
        link: "/home/settings",
        section: 8
      },
    ];


  const selectMenuItemFromPathname = () => {
    displayMenuItems.forEach((item, ind) => {
      if (location.pathname.includes(item.link)) {
        dispatch(setSelectedMenuItem(ind));
        return;
      }
    });
  };
  const goToZero = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
    const token = await dispatch(getNewToken());
    if (!token) return;
    const querryString = `?token=${JSON.stringify(token)}&user_info=${JSON.stringify(userInfo)}`
    const esgUrl = process.env.REACT_APP_ZERO_URL;
    window.open(`${esgUrl}/login${querryString}`, '_blank');
  }

  useEffect(() => {
    if (showEventsMenu) {
      setMenuItems(eventsMenu);
    } else {
      setMenuItems(esgMenu);
    }
  }, [showEventsMenu]);


  useEffect(() => {
    let tempMenuItems: any = menuItems?.slice((role === 'CONTRIBUTOR' && !showEventsMenu) ? 1 : 0, 2);
    if (showEventsMenu && menuItems.length > 1) {
      tempMenuItems = menuItems?.slice(0, 1);
      if (role === 'ADMIN' || role === 'SUPER_ADMIN' || role === 'ESG_CONSULTANT'){
        tempMenuItems.push(menuItems[1]);
      }
    }
    for (let i = 2; i < menuItems?.length; i++) {
      if (userPermissions?.data?.find((perm) => perm.section === menuItems[i].section && perm.role === role)?.permission) {
        tempMenuItems.push(menuItems[i]);
      }
    }
    setDisplayMenuItems(tempMenuItems);
  }, [userPermissions, menuItems, showEventsMenu]);

  useEffect(() => {
    selectMenuItemFromPathname();
    if (clicks === 0 && location.pathname === '/home/dashboard') dispatch(setMenuCollapsed(false));
    if (clicks === 1 && location.pathname !== '/home/dashboard') dispatch(setMenuCollapsed(true))
    dispatch(increaseMenuClicks());
  }, [location.pathname]);

  useEffect(() => {
    if (collapsed) {
      setShowList(false)
    }
    else
      setTimeout(() => {
        setShowList(true)
      }, 400)
  }, [collapsed])

  useEffect(() => {
    if (modules.data.length > 0) {
      let moduleCodes = modules.data.map((module: any) => module.module_code).filter(
        (module_code: string) => currentModule !== module_code
      );
      let myModuleCodes: string[] = [];
      for (let key in userProfile.data) {
        if (moduleCodes.includes(key)) {
          myModuleCodes.push(key);
        }
      }
      let tempModuleMenu: any = [];
      modules.data.forEach((module: any) => {
        if (myModuleCodes.includes(module.module_code)) {
          tempModuleMenu.push({
            label: module.module_name,
            code: module.module_code,
          });
        }
      });
      setModuleMenu(tempModuleMenu);
    }
  }, [modules, userProfile, currentModule]);

  const handleMenuClick = (link: string) => {
    navigate(link);
  }

  return (
    <div
      className="menu-bar"
      style={{
        minHeight: "100vh",
        background: PrimaryTheme.secondaryBlack,
        position: "sticky",
        top: 0,
        color: "#ffffff",
        alignSelf: "flex-start",
        width: collapsed ? menubarWidthCollapsed : menubarWidth,
        paddingTop: "1rem",
      }}
    >
      <div
        className="collapse-btn"
        style={collapsed ? {} : { transform: "rotateZ(-180deg)" }}
        onClick={() => {
          dispatch(setMenuCollapsed(!collapsed));
        }}
      >
        <ChevronFilled />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          className="logo"
          onClick={() => navigate("/home/dashboard")}
        >
          <img
            src={!showList ? CompanyLogoCollapsed : CompanyLogo}
            height={"22px"}
          />{" "}
        </div>
      </div>

      <div className="menu-list">
        {

          displayMenuItems.length > 0 && displayMenuItems.map((item, ind) => {
            return collapsed ? (
              <ToolTip key={ind} title={item.label} placement="right">
                <div
                  key={ind}
                  style={{
                    color:
                      activeMenuItem === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight,
                  }}
                  className="list-itm"
                  onClick={() => handleMenuClick(item.link)}
                >
                  <item.icon
                    stroke={
                      activeMenuItem === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight
                    }
                  />
                </div>
              </ToolTip>
            ) : (
              <div
                key={ind}
                style={{
                  color:
                    activeMenuItem === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight,
                }}
                className="list-itm"
                onClick={() => handleMenuClick(item.link)}
              >
                <item.icon
                  stroke={
                    activeMenuItem === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight
                  }
                />

                {showList && <span style={{ width: "80%" }}>{item.label}</span>}
              </div>
            );
          })
        }
        {/* {
          moduleMenu?.length > 0 &&
          moduleMenu?.map((item: any, ind: number) => {
            return collapsed ? (
              <ToolTip key={ind} title={item.label} placement="right">
                <div
                  key={ind}
                  style={{
                    color:
                      activeModuleIndex === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight,
                  }}
                  className="list-itm"
                  onMouseEnter={() => setActiveModuleIndex(ind)}
                  onMouseLeave={() => setActiveModuleIndex(-1)}
                  onClick={() => {
                    if (item.code === 'ZERO') {
                      goToZero();
                    } else if (item.code === 'EVENT') {
                      dispatch(setShowEventsMenu(true));
                      dispatch(setCurrentModule('EVENT'));
                      navigateTo('/events/event-manager');
                    } else if (item.code === 'ESG') {
                      dispatch(setShowEventsMenu(false));
                      dispatch(setCurrentModule('ESG'));
                      navigateTo('/home/dashboard');
                    }
                  }}
                >
                  {activeModuleIndex === ind ? <ZeroIcon /> : <GreyZeroIcon />}
                </div>
              </ToolTip>
            ) : (
              <div
                key={ind}
                style={{
                  color:
                    activeModuleIndex === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight,
                }}
                className="list-itm"
                onMouseEnter={() => setActiveModuleIndex(ind)}
                onMouseLeave={() => setActiveModuleIndex(-1)}
                onClick={() => {
                  if (item.code === 'ZERO') {
                    goToZero();
                  } else if (item.code === 'EVENT') {
                    dispatch(setShowEventsMenu(true));
                    dispatch(setCurrentModule('EVENT'));
                    navigateTo('/events/event-manager');
                  } else if (item.code === 'ESG') {
                    dispatch(setShowEventsMenu(false));
                    dispatch(setCurrentModule('ESG'));
                    navigateTo('/home/dashboard');
                  }
                }}
              > */}
                {/* {
                  activeModuleIndex === ind ?
                    <ZeroIcon />
                    :
                    <GreyZeroIcon />
                } */}

                {/* {showList && <span style={{ width: "80%", fontSize: '0.8rem' }}>{item.label}</span>} */}
              {/* </div> */}
            {/* ); */}
          {/* } */}
          {/* ) */}
        {/* } */}
        {/* {userProfile?.data?.zero && !showEventsMenu &&
          <div
            onClick={goToZero}
            onMouseEnter={() => setHoverBreatheZeroIcon(true)}
            onMouseLeave={() => setHoverBreatheZeroIcon(false)}
          >
            {collapsed ? <ToolTip title='BREATHE ZERO' placement="right">
              <div className="list-itm ">
                {
                  showList ? (
                    hoverBreatheZeroIcon ? (
                      <img src={ZeroLogoColored} height={"22px"} />
                    ) : (
                      <img src={GreyZeroLogo} height={'22px'} />
                    )
                  ) : (
                    hoverBreatheZeroIcon ? <ZeroIcon /> : <GreyZeroIcon />
                  )
                }
              </div>
            </ToolTip>
              : <div className="list-itm logo ">
                {
                  showList ? (
                    hoverBreatheZeroIcon ? (
                      <>
                        <ZeroIcon />
                        <span style={{ width: '80%', fontSize: '0.8rem' }}>BREATHE ZERO</span>
                      </>
                    ) : (
                      <>
                        <GreyZeroIcon />
                        <span style={{ width: '80%', fontSize: '0.8rem' }}>BREATHE ZERO</span>
                      </>
                    )
                  ) : (
                    hoverBreatheZeroIcon ? <ZeroIcon /> : <GreyZeroIcon />
                  )
                }
              </div>}
          </div>
        } */}

        {/* {!showEventsMenu &&
          <div
            onClick={() => navigateTo('/events/event-manager', true)}
            onMouseEnter={() => setHoverBreatheEventsIcon(true)}
            onMouseLeave={() => setHoverBreatheEventsIcon(false)}
          >
            {collapsed ? <ToolTip title='EVENT MANAGER' placement="right">
              <div className="list-itm ">
                {
                  showList ? (
                    hoverBreatheEventsIcon ? (
                      <img src={ZeroLogoColored} height={"22px"} />
                    ) : (
                      <img src={GreyZeroLogo} height={'22px'} />
                    )
                  ) : (
                    hoverBreatheEventsIcon ? <ZeroIcon /> : <GreyZeroIcon />
                  )
                }
              </div>
            </ToolTip>
              : <div className="list-itm logo ">
                {
                  showList ? (
                    hoverBreatheEventsIcon ? (
                      <>
                        <ZeroIcon />
                        <span style={{ width: '80%', fontSize: '0.8rem' }}>BREATHE EVENTS</span>
                      </>
                    ) : (
                      <>
                        <GreyZeroIcon />
                        <span style={{ width: '80%', fontSize: '0.8rem' }}>BREATHE EVENTS</span>
                      </>
                    )
                  ) : (
                    hoverBreatheEventsIcon ? <ZeroIcon /> : <GreyZeroIcon />
                  )
                }
              </div>}
          </div>
        }
        {showEventsMenu &&
          <div
            onClick={() => navigateTo('/home/dashboard', true)}
            onMouseEnter={() => setHoverBreatheEventsIcon(true)}
            onMouseLeave={() => setHoverBreatheEventsIcon(false)}
          >
            {collapsed ? <ToolTip title='BREATHE ESG' placement="right">
              <div className="list-itm ">
                {
                  showList ? (
                    hoverBreatheEventsIcon ? (
                      <img src={ZeroLogoColored} height={"22px"} />
                    ) : (
                      <img src={GreyZeroLogo} height={'22px'} />
                    )
                  ) : (
                    hoverBreatheZeroIcon ? <ZeroIcon /> : <GreyZeroIcon />
                  )
                }
              </div>
            </ToolTip>
              : <div className="list-itm logo ">
                {
                  showList ? (
                    hoverBreatheEventsIcon ? (
                      <>
                        <ZeroIcon />
                        <span style={{ width: '80%', fontSize: '0.8rem' }}>BREATHE ESG</span>
                      </>
                    ) : (
                      <>
                        <GreyZeroIcon />
                        <span style={{ width: '80%', fontSize: '0.8rem' }}>BREATHE ESG</span>
                      </>
                    )
                  ) : (
                    hoverBreatheZeroIcon ? <ZeroIcon /> : <GreyZeroIcon />
                  )
                }
              </div>}
          </div>
        } */}
        {/* <div
          onClick={() => setShowLogoutModal(true)}
          className="list-itm-red"
        // style={{ color: PrimaryTheme.secondaryRedError }}
        >

          <Logout />

          {showList && <span style={{ width: "80%", opacity: "0.9" }}>Logout</span>}
        </div> */}
      </div>
      {/* <BEConfirmModal
        title="Confirm Logout"
        message="Are you sure you want to logout?"
        visible={showLogoutModal}
        setVisible={setShowLogoutModal}
        onConfirm={async () => {
          await dispatch(logoutAction());
        }}
      /> */}
      <div style={{
        position: 'absolute',
        bottom: '20px',
        width:'100%'
      }}>
        <ProfileDetails
          collapsed={collapsed}
        />
      </div>

     { /*
        !collapsed &&
        <p className="version">
           //this should be the next version of the ESG platform.
          {
            `${version.number}.${version.date !== '' &&
            dayjs(version.date).format('DDMMYY')
            }`
          }
        </p>
      */}
    </div>
  );
};

export default BEMenuBar;