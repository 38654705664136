import * as React from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { settableDataDM, setFullYearTableDataDM, setFilteredLogsDM, setTableDerivedMetricsDataDM, setFullYearDerivedMetricsDataDM } from '../../../../Redux/DataManagerReducer';
import { getFYByDate, getMonthsByQuarter } from '../../../../Config/Functions/UsefullFunctions';
import { setCombinedSelectedMetricsPerYear, setMyDerivedMetricsPerYear, setMyMetricsPerYear } from '../../../../Redux/OnboardingReducer';
import { getAssignedDepartmentByCategory, getDataLabels, getDerivedMetricDataByMonth, getDerivedMetrics, getMetricsStatus, getMyDerivedMetrics, getMyMetricsData, getOmittedMetrics } from '../../Actions';
import { derivedMetricDataTypeByMonth } from '../../../../Redux/Types/dataManagerTypes';
import { getLogs } from '../../../Settings/Actions';
import { getCombinedSelectedMetrics, getMetrics, getMyMetrics } from '../../../../views/Actions/OnboardingStep4Actions';

export interface ISetTableAndGraphDataDMProps {
}

export function SetTableAndGraphDataDM(props: ISetTableAndGraphDataDMProps) {
    const dispatch = useAppDispatch();
    const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const yearEnd = useTypedSelector(state => state.companies.yearEnd);
    const businessUnitsRefs = useTypedSelector(state => state.entity.BusinessUnitRefs?.data);
    const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const allLogs = useTypedSelector(state => state.settings.logs);
    const reportingYearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
    const metricStatus = useTypedSelector(state => state.dataManager.metrics.metricStatus);
    const departmentbyCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
    const omittedMetrics = useTypedSelector(state => state.dataManager.metrics.omittedMetrics);
    const allLabels = useTypedSelector(state => state.dataManager.labels);
    


    const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
    const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
    const myMetricsData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
    const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics);
    const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetrics);
    const myDerivedMetricsDataByMonthAndBusinessUnit = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit);
    const combinedSelectedMetrics = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetrics);

    React.useEffect(() => {
        // ******************* setting the selected metrics and selected derived metrics for the current year from the all metrics *******************
        if (myMetrics?.status === 'success') {
            dispatch(setMyMetricsPerYear({
                data: myMetrics?.data.filter((item) => item.year === currentYear),
                status: 'success'
            }))
        }
        if (myDerivedMetrics?.status === 'success') {
            dispatch(setMyDerivedMetricsPerYear({
                data: myDerivedMetrics?.data.filter((item) => item.year === currentYear),
                status: 'success'
            }))
        }

        if (combinedSelectedMetrics?.status === 'success') {
            dispatch(setCombinedSelectedMetricsPerYear({
                data: combinedSelectedMetrics?.data.filter((item) => item.year === currentYear),
                status: 'success'
            }))
        }

        // if (myDerivedMetricsDataByMonthAndBusinessUnit?.status === 'idle') {
        //     dispatch(getDerivedMetricDataByMonth());
        // }
        
    }, [myMetrics, currentYear, myDerivedMetrics, myDerivedMetricsDataByMonthAndBusinessUnit, combinedSelectedMetrics]);

    React.useEffect(() => {
        if (myMetricsData?.status === 'success') {
            const tempTableData: any = {}
            const tempFullYearTableData: any = {}

            let filteredData: any = [];
            let buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element: any) => element.business_unit);

            if (currentBusinessGroup !== 0) {
                filteredData = myMetricsData?.data.filter((element: any) => buBgs.includes(element.business_unit));
            }
            else if (currentBusinessUnit !== 0) {
                filteredData = myMetricsData?.data.filter((element: any) => element.business_unit === currentBusinessUnit);
            }
            else {
                filteredData = myMetricsData?.data;
            }

            Array.from(new Set(filteredData.map((item: any) => item.my_metric))).map((metricItem: any) => {
                let data: any = [];
                let fullYearData: any = [];

                filteredData.map((item: any) => {
                    if (item.my_metric === metricItem) {
                        let itemMonth = new Date(item.date_of_entry).getMonth() + 1;
                        if (getFYByDate(item.date_of_entry, reportingYearEnd || '') === currentYear) {
                            const monthsByQuarter = getMonthsByQuarter(currentMonth, yearEnd);
                            if (itemMonth === currentMonth || currentMonth === 0 || monthsByQuarter.includes(itemMonth)) data.push(item);
                            fullYearData.push(item);
                        }
                    }
                })
                tempTableData[metricItem] = data;
                tempFullYearTableData[metricItem] = fullYearData;
            })

            dispatch(settableDataDM(
                tempTableData
            ));

            dispatch(setFullYearTableDataDM(
                tempFullYearTableData
            ));
        }
    }, [myMetricsData, currentMonth, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit]);


    // filter logs for current year and year end and business unit and business group
    React.useEffect(() => {
        if (allLogs?.status === 'success') {
            let filteredLogsByDate: any = [];

            filteredLogsByDate = allLogs?.data?.filter((item: any) => {
                // let date = new Date(item.month_of_entry);
                // let year = date.getFullYear();
                // let month = date.getMonth() + 1;
                // if (month > yearEnd && year === currentYear && (currentMonth === 0 || month === currentMonth))
                //     return true;
                // if (month <= yearEnd && year === currentYear + 1 && (currentMonth === 0 || month === currentMonth))
                if (getFYByDate(item.month_of_entry, reportingYearEnd || '') === currentYear)
                    return true;
                return false;
            });

            let FIlteredLogsByBUBG: any = [];
            if (currentBusinessUnit !== 0) {
                FIlteredLogsByBUBG = filteredLogsByDate.filter((item: any) => item.business_unit === currentBusinessUnit);
            }
            else if (currentBusinessGroup !== 0) {
                const buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element: any) => element.business_unit);
                FIlteredLogsByBUBG = filteredLogsByDate.filter((item: any) => buBgs.includes(item.business_unit));
            }
            else FIlteredLogsByBUBG = filteredLogsByDate;

            dispatch(setFilteredLogsDM({
                status: 'success',
                data: FIlteredLogsByBUBG
            }));
        }
    }, [allLogs, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit, currentMonth]);


    // For derived metrics data by month
    React.useEffect(() => {
        if (myDerivedMetricsDataByMonthAndBusinessUnit?.status === 'success') {

            dispatch(setTableDerivedMetricsDataDM({
                status: 'loading',
                data: []
            }));

            let tempTableData: any = {};
            let tempFullYearTableData: any = {};
            let filteredData: derivedMetricDataTypeByMonth[] = [];
            let buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element) => element.business_unit);

            if (currentBusinessGroup !== 0) {
                filteredData = myDerivedMetricsDataByMonthAndBusinessUnit?.data.filter((element) => element.bu && buBgs.includes(element.business_unit));
            }
            else if (currentBusinessUnit !== 0) {
                filteredData = myDerivedMetricsDataByMonthAndBusinessUnit?.data.filter((element) => element.bu && element.business_unit === currentBusinessUnit);
            }
            else {
                filteredData = myDerivedMetricsDataByMonthAndBusinessUnit?.data.filter((element) => !element.bu);
            }

            Array.from(new Set(filteredData?.map((item) => item.my_derived_metric))).map((metricItem) => {
                let data: any = [];
                let fullYearData: any = [];

                filteredData.map((item) => {
                    if (item.my_derived_metric === metricItem) {
                        let itemMonth = item.month;
                        if (item.year === currentYear) {
                            const monthsByQuarter = getMonthsByQuarter(currentMonth, yearEnd);
                            if (itemMonth === currentMonth || monthsByQuarter.includes(itemMonth)) data.push(item);
                            fullYearData.push(item);
                        }
                    }
                })
                tempTableData[metricItem] = data;
                tempFullYearTableData[metricItem] = fullYearData;
            })

            dispatch(setTableDerivedMetricsDataDM({
                status: 'success',
                data: tempTableData
            }));
            dispatch(setFullYearDerivedMetricsDataDM({
                status: 'success',
                data: tempFullYearTableData
            })
            )
        }
    }, [myDerivedMetricsDataByMonthAndBusinessUnit, currentMonth, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit]);


    React.useEffect(() => {
        if (metrics?.status === 'idle')
            dispatch(getMetrics());
        if (derivedMetrics?.status === 'idle')
            dispatch(getDerivedMetrics());
        if (metricStatus?.status === 'idle')
            dispatch(getMetricsStatus());
        if (myMetricsData?.status === 'idle')
            dispatch(getMyMetricsData());
        if (allLogs?.status === 'idle')
            dispatch(getLogs());
        if (combinedSelectedMetrics?.status === 'idle')
            dispatch(getCombinedSelectedMetrics());
        if (departmentbyCategory?.status === 'idle')
            dispatch(getAssignedDepartmentByCategory());
        if (omittedMetrics?.status === 'idle')
            dispatch(getOmittedMetrics());
        if (allLabels?.status === 'idle')
            dispatch(getDataLabels());
    }, []);

    React.useEffect(() => {
        if (currentYear !== 0)
            dispatch(getMyDerivedMetrics());
        if (currentYear !== 0)
            dispatch(getMyMetrics());
        if (currentYear !== 0)
            dispatch(getCombinedSelectedMetrics());
        if (currentYear !== 0)
            dispatch(getDerivedMetricDataByMonth());
    }, [currentYear, currentBusinessGroup, currentBusinessUnit, currentMonth]);

    return (
        <div>

        </div>
    );
}
