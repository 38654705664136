import React from 'react'
import { TopSectionDM } from './Components/TopSectionDM';
import './style.scss';
import Tracker from './Tracker';
import { DataEntry } from './Components/DataEntry';
import { SetTableAndGraphDataDM } from './Components/SetTableAndGraphDataFunctionDM';
import { TopTabSearch } from './Components/DataEntry/TopTabsDM/TopTabSearch';
import { StatusFilter } from './Components/DataEntry/TopTabsDM/StatusFilter';
import { useTypedSelector } from '../../Config/Hooks/useTypedSelector';
import { BEButton } from '../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../Config/Hooks/useAppDispatch';
import { getDerivedMetricDataByMonth, getMyDerivedMetrics } from './Actions';
import ToolTip from '../../Components/BEToolTip';

const DataManager = () => {
    const [section, setSection] = React.useState<'dataEntry' | 'tracker'>('dataEntry');
    const [loading, setLoading] = React.useState(false);
    const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetricsPerYear);
    const dispatch = useAppDispatch();
    const role = useTypedSelector((state) => state.user.userRole);
    return (
        <div className="data-manager">
            <TopSectionDM setSection={setSection} section={section} />
            {section === 'dataEntry' && myMetrics?.status === 'success' &&
                <div className='filter-section'>
                    <TopTabSearch />
                    <StatusFilter />
                    {role !== 'AUDITOR' && role !== 'CONTRIBUTOR' &&
                        <BEButton
                            loading={loading}
                            style={{ marginLeft: 'auto', marginRight: '1.25rem' }}
                            className='primary'
                            size='large'
                            onClick={() => {
                                setLoading(true);
                                dispatch(getMyDerivedMetrics()).then(() =>
                                    dispatch(getDerivedMetricDataByMonth()).then(() => setLoading(false))
                                );
                            }}
                        >
                            <ToolTip title='Run calculations'>
                                Calculate
                            </ToolTip>
                        </BEButton>}
                </div>
            }
            {
                section === 'dataEntry' ?
                    <DataEntry />
                    :
                    <Tracker />
            }
            <SetTableAndGraphDataDM />
        </div>
    );
}

export default DataManager;