import * as React from 'react';
import { Frameworks, ItemProps } from '../../../../../data/frameworks';
import { ReportProgressCard } from '../../../Components/ReportProgressCard';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getAllReports } from '../../../../Reporting/Actions/template';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { BEPagination } from '../../../../../Components/BEPagination';
import ReportStatusIcon from '../../../../../Components/BEIcons/ReportStatusIcon';

interface IReportsStatusProps {
}

export function ReportsStatus(props: IReportsStatusProps) {
	const dispatch = useAppDispatch();
	const allReports = useTypedSelector(state => state.reporting.allReports);
	const [filteredReports, setFilteredReports] = React.useState<any[]>([]);
	const [page, setPage] = React.useState(1);

	React.useEffect(() => {
		if (allReports.status === "idle") {
			dispatch(getAllReports());
		}
	}, []);

	React.useEffect(() => {
		const data = allReports?.data?.map((report: any) => {
			const framework = Frameworks.find((framework: ItemProps) => framework.code === report.framework)
			if (framework) {
				return ({
					id: report.id,
					published: report.published_on,
					reportName: report.name,
					icon: framework.img,
					percent: report.progress
				})
			}
		}
		)
		setFilteredReports(data)
	}, [allReports]);

	return (
		<div>
			<BEWrapper
				style={{ padding: '0rem' }}
				type={2}
				icon={<ReportStatusIcon inheritSize />}
				title='Reports completion status'
			>
				{
					allReports.status === 'loading' ? <GlobalLoader height='50vh' /> :
						<div className='report-completion-status'>
							{
								filteredReports.sort((a, b) => b.published - a.published).slice((page - 1) * 5, page * 5).
									map((report: any) => (
										<ReportProgressCard
											reportName={report.reportName}
											icon={report.icon}
											percent={report.percent}
										/>
									))
							}
						</div>
				}
				<div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
					<BEPagination total={filteredReports?.length} pageSize={5} onChange={(page) => setPage(page)} />
				</div>
			</BEWrapper>
		</div>
	);
}
