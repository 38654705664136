import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { Form } from 'antd';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { editMetricsData } from '../../../Actions';
import { myMetricType } from '../../../../../Redux/Types/dataManagerTypes';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';

export interface IRejectedEntryDrawerProps {
    open: boolean;
    setOpen: Function;
    changeStatus: Function;
    metric: myMetricType;
    viewOnly?: boolean;
}

export function RejectedEntryDrawer(props: IRejectedEntryDrawerProps) {
    const myMetricsData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const role = useTypedSelector((state) => state.user.userRole);
    const onFinish = async () => {
        const description = form.getFieldValue('description');
        const myMetricDataId = myMetricsData?.data?.find((data) => data.my_metric === props.metric.id)?.id;
        await dispatch(editMetricsData(myMetricDataId, { rejected_entry_description: description }, props.metric.id));
        await props.changeStatus(3);
        props.setOpen(false);
    }

    React.useEffect(() => {
        if (props.viewOnly)
            form.setFieldValue('description', myMetricsData?.data?.find((data) => data.my_metric === props.metric.id)?.rejected_entry_description);
    }, [props.open, props.metric]);

    return (
        <BEDrawer
            heading='Rejection summary'
            open={props.open}
            setOpen={props.setOpen}
            footer={
                !props.viewOnly &&
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', gap:"1rem" }}>
                    <BEButton
                        className='primary'
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);
                            form.submit();
                            setLoading(false);
                        }}
                    >
                        Reject and Close
                    </BEButton>
                    <BEButton
                        onClick={() => props.setOpen(false)}
                    >
                        Cancel
                    </BEButton>
                </div>
            }
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <LableRequired>
                    Details
                </LableRequired>
                {props.viewOnly ? <p style={{ color: PrimaryTheme.secondaryRedError, fontSize: '0.8rem' }}>{myMetricsData?.data?.find((data) => data.my_metric === props.metric.id)?.rejected_entry_description}</p> :
                <Form.Item name={'description'} rules={[{ required: true, message: 'Details is required' },]}>
                    <BETextArea
                        value={form.getFieldValue('description')}
                        placeholder={'Type here'}
                        form={form}
                        name={'description'}
                        showCount
                        maxLength={1000}
                        rows={6}
                        onChange={(e: any) => {
                            form.setFieldValue('description', e.target.value);
                        }}
                        disabled={props.viewOnly} 
                    />
                </Form.Item>}
            </Form>
        </BEDrawer>
    );
}
