import { Row, Col } from 'antd';
import * as React from 'react';
import { BEEventSummaryPanel } from '../../../../../Components/BEEventManagement/BEEventSummaryPanel';
import { BEEvidenceList } from '../../../../../Components/BEEventManagement/BEEvidenceList';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getEventEvidences } from '../../Actions';
import { getBusinessUnits } from '../../../../../views/Actions/BusinessUnitActions';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { EventSeverityMapping } from '../EventList';
import './styles.scss';
interface IEventInfoProps {
}

export function EventInfo(props: IEventInfoProps) {
	const dispatch = useAppDispatch();
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const eventUsers = useTypedSelector(state => state.eventManager.eventUsers);
	const eventEvidences = useTypedSelector(state => state.eventManager.eventEvidences);
	const [eventSummary, setEventSummary] = React.useState<{ title: string, value: string | React.ReactNode }[]>([]);
	const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);

	React.useEffect(() => {
		dispatch(getEventEvidences(Number(currentEvent?.id)));
	}, [currentEvent]);

	React.useEffect(() => {
		if (currentEvent) {
			setEventSummary([
				{
					title: 'Event ID',
					value: currentEvent?.id.toString()
				},
				{
					title: 'Location',
					value: String(businessUnits.data?.find(bu => Number(bu.id) === (currentEvent?.business_unit))?.name)
				},
				{
					title: 'Specific area',
					value: currentEvent?.specific_location
				},
				{
					title: 'Event Date',
					value: currentEvent?.date
				},
				{
					title: 'Reporting Date',
					value: currentEvent?.timestamp?.split('T')[0]
				},
				{
					title: 'Reported By',
					value:
						eventUsers?.data?.find(user => user.id === currentEvent?.reported_by)?.first_name + ' ' +
						eventUsers?.data?.find(user => user.id === currentEvent?.reported_by)?.last_name

				},
				{
					title: 'Event type',
					value: currentEvent?.event_type
				},
				{
					title: 'Event Category',
					value: currentEvent?.event_group
				},
				{
					title: 'Event Severity',
					value: <>
						{currentEvent.actual_severity && <BEStatusTag status={EventSeverityMapping[currentEvent?.actual_severity]?.status} style={(currentEvent?.actual_severity === 1 || currentEvent?.actual_severity === 2) ? { color: PrimaryTheme.primaryVividBlue, backgroundColor: PrimaryTheme.primaryVividBlueLight } : undefined}>
							Level {currentEvent?.actual_severity}
						</BEStatusTag>}
					</>
				}
			]);
		}
	}, [currentEvent, businessUnits, eventUsers]);

	return (
		<div className='event-info-modal-body'>
			{
				currentEvent?.stage === 4 && currentEvent?.conclusion && (
					<div className='event-closed-conclusion'>
						<h3>Event Closed</h3>
						<br />
						<p>
							{currentEvent?.conclusion}
						</p>
					</div>
				)
			}
			<BEEventSummaryPanel eventSummary={eventSummary} />
			<br />
			<Row>
				<Col span={11}>
					<BEEvidenceList
						height='250px'
						evidenceList={eventEvidences?.data?.filter(
							evidence => Number(evidence?.action) === 0
						)?.map(evidence => (evidence?.file)) || []}
					/>
				</Col>
				<Col offset={1} span={12}>
					<div className='event-info-modal-body-summary'>
						<p className='event-info-modal-body-summary-title'>
							Summary
						</p>
						<br />
						<p>
							{currentEvent?.description}
						</p>
					</div>
				</Col>
			</Row>
		</div>
	);
}
