import * as React from 'react';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { getMetricsChildrenFromBEcode } from '../../../../../../views/Actions/calculatedMetricsActions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { GlobalLoader } from '../../../../../../Components/GlobalLoader';
import ToolTip from '../../../../../../Components/BEToolTip';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { Dropdown, MenuProps } from 'antd';
import MenuIcon from '../../../../../../Components/BEIcons/MenuIcon';
import DeleteIcon from '../../../../../../Components/BEIcons/DeleteIcon';
import DragIcon from '../../../../../../Components/BEIcons/DragIcon';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { getDashboardDrilldown } from '../../../../Actions';

export interface ICalculatedDashboardMetricsProps {
    data:any;
}

export function CalculatedCustomStackedGraph(props: ICalculatedDashboardMetricsProps) {
    const dispatch = useAppDispatch();
    const combinedSelectedMetricsPerYear = useTypedSelector(
        state => state.onBoarding.metrics.combinedSelectedMetricsPerYear
    );
    const [graphData, setGraphData] = React.useState<any>([]);
    const yearEnd = useTypedSelector(state => state.companies.yearEnd);
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const getChartData = async() => {
        setLoading(true);
        let tempData:any = await dispatch(getDashboardDrilldown(
            9,1,props.data.bcode as string,[props.data.year]
        ));
        if(!tempData) return;
        let data:any = {};
        Object.keys(tempData).map((key) => {
            // empty array of 12 months
            data[key] = Array(12).fill(0);
            tempData[key].map((item:any) => {
                data[key][item.month-1] = item.value;
            })
        })

        let chartData:any = [];
        Object.keys(data).map((key) => {
            let _metric = combinedSelectedMetricsPerYear.data
                        .find((item) => item.bcode === key);
            let name = _metric?.custom_title || _metric?.title;
            chartData.push({
                name: name,
                data: rotateArray(data[key], yearEnd,true)
            })
         })
        setGraphData(chartData);
        setLoading(false);
    }

    
    React.useEffect(() => {
    if(!props.data) return;
    getChartData();
    }, []);


    return (
        <div>
            <StackedColumnCharts
            data={graphData}
            xAxisLabels={rotateArray(shortMonths,yearEnd,true)}  
            yAxisLabel={props.data?.unit}
            />
        </div>
    );
}
