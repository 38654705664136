import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { Form } from 'antd';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { patchEventAction } from '../../../Actions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { EventAction } from '../../../../../../Redux/Types/eventManagerTypes';

interface IRejectActionDrawerProps {
	open: boolean;
	setOpen: Function;
	action: EventAction | undefined;
}

export function RejectActionDrawer(props: IRejectActionDrawerProps) {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = React.useState(false);

	const handleClose = async (values: any) => {
        if (props.action) {
            setLoading(true);
            await dispatch(patchEventAction(props.action.id, {
                stage: 3,
                summary: values.comments,
            }));
            setLoading(false);
            props.setOpen(false);
        }
	}

	return (
		<BEDrawer
			heading="Reject action"
			open={props.open}
			setOpen={props.setOpen}
			footer={
				<>
					<BEButton loading={loading} className='primary-red' onClick={() => form.submit()}>
						Reject
					</BEButton>
					<BEButton className='secondary-green' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>
			}
		>
			<Form form={form} onFinish={handleClose}>
				<LableRequired>
					Comments
				</LableRequired>
				<Form.Item name={'comments'} rules={[{ required: true, message: 'Please enter comments' }]}>
					<BETextArea
						name="comments"
						form={form}
						placeholder="Write here"
						rows={5}
						maxLength={200}
						count={{
							show: true,
							max: 200,
						}}
					/>
				</Form.Item>
			</Form>

		</BEDrawer>
	);
}
