import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import {
  getSupplierStatus, 
  getBRSRRiskMetrics, 
  getSupplierQuestions,
  getSuppliers,
  downloadResultExcelled
} from '../../Actions/supplierAction';
import TrackerCard from '../../../../Components/Cards/TrackerCard';
import AssignmentIcon from '../../../../Components/BEIcons/AssignmentIcon';
import PendingResponseIcon from '../../../../Components/BEIcons/PendingResponseIcon';
import TrackerIcon from '../../../../Components/BEIcons/TrackerIcon';
import BEBackButton from '../../../../Components/BEBackButton';
import './style.scss';
import { Row, Col, Card } from 'antd';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import BECardPagination from '../../../../Components/BECardsPagination';
import { BEStatusPoint } from '../../../../Components/BEStatusTag';
import { SupplierScoreTypes } from '../../../../Redux/Types/suppliersTypes';
import BEStatusTag from '../../../../Components/BEStatusTag';
// import { SupplierRiskMatrixData } from '../../../../Config/Data/SupplierRiskMetrics';
import ToolTip from '../../../../Components/BEToolTip';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSupplierSurveys } from '../../Actions/supplierAction';
import SurveyTopNav from '../../../Materiality/Views/SurveyResponse/SurveyTopNav';
import { getCompanyDetails } from '../../../../views/Actions/OnboardingActions';
import { SupplierRiskClassificationThreshold } from '../AllAssessments/AllAssessments';
import { SupplierTopNav } from '../SupplierForm/MainSupplierForm/SupplierTopNav';
import { setCurrentAssessment } from '../../../../Redux/SupplierReducer';
import { SupplierResultByQuestion } from './SupplierResultByQuestion';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';


export function SupplierResult() {

  const { assessment_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const supplierStatus = useTypedSelector(state => state.supplier.SupplierStatus);
  // const supplierRiskMetrics = useTypedSelector(state => state.supplier.SuppliersRiskMetrics);
  // const [metricsIndexGap, setMetricsIndexGap] = React.useState<any>(null)
  const supplierQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const suppliers = useTypedSelector(state => state.supplier.Suppliers);
  const supplierScore = useTypedSelector(state => state.supplier.SupplierScore);
  const allAssessments = useTypedSelector(state => state.supplier.SuppliersSurveys);
  const currentAssessment = useTypedSelector(
    state => state.supplier.currentAssessment
  );
  const company = useTypedSelector(state => state.companies.companyDetails);
  const SupplierScore = useTypedSelector(state => state.supplier.SupplierScore);
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [riskClassification, setRiskClassification] = React.useState<any>({
    high: null,
    medium: null,
    low: null,
    total: 0,
    loading: true
  });
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (allAssessments?.status === 'success') {
      dispatch(
        setCurrentAssessment(
          allAssessments?.data?.find(
            (assessment: any) => assessment.id === Number(assessment_id)
          )
        )
      )
    }
  }, [allAssessments,assessment_id]);

  React.useEffect(() => {
    setRiskClassification({
      high: null,
      medium: null,
      low: null,
      loading: true
    })
    if(supplierQuestions?.status !== 'success')
      dispatch(getSupplierQuestions());
    if(supplierStatus?.status !== 'success')
      dispatch(getSupplierStatus(assessment_id));
    if(supplierScore?.status !== 'success')
      dispatch(getBRSRRiskMetrics(assessment_id));
    if(allAssessments?.status !== 'success')
      dispatch(
        getSupplierSurveys(Number(searchParams.get('year')))
      )
    if(company?.status !== 'success')
      dispatch(getCompanyDetails());
    if(suppliers.status!== 'success')
      dispatch(getSuppliers())
    // dispatch(getSupsplierRiskMetrics(assessment_id));
  }, []);

  React.useEffect(() => {
    if (supplierScore?.status === 'success') {
      let highRisk = 0;
      let mediumRisk = 0;
      let lowRisk = 0;
      let totalPossibleScore = supplierScore.data?.total_score
      if(totalPossibleScore === 0) return 
      supplierScore?.data?.supplier_scores.forEach((data: any) => {
        if (
          (data.score / totalPossibleScore) * 100 < 
          SupplierRiskClassificationThreshold.Medium
        ) {
          highRisk++;
        } else if (
          (data.score / totalPossibleScore) * 100 <
          SupplierRiskClassificationThreshold.Low) {
          mediumRisk++;
        }
        else {
          lowRisk++;
        }
      })
      setRiskClassification({
        high: highRisk,
        medium: mediumRisk,
        low: lowRisk,
        loading: false
      })
    }
  }, [supplierScore?.status]);



  return (
    <>
      <SupplierTopNav />
      <div style={{ height:"79px" }}>
      </div>
      <div className='supplier-result'>
        
      <BEButton
      disabled={!currentAssessment || supplierScore?.status !== 'success'}
      style={{ float: 'right' }} 
        onClick={async() => {
          setLoadingDownload(true);
          await dispatch(downloadResultExcelled(assessment_id));
          setLoadingDownload(false);
        }} 
        size='large' 
        className='primary'
        loading={loadingDownload}
      >
        Download Results
      </BEButton>
        <h2>
          View Results: {
            allAssessments?.data?.find((assessment: any) => assessment.id === Number(assessment_id))?.title
          }
        </h2>
        <div className='top-cards'>

          <TrackerCard
            title='AVERAGE SCORE'
            body={
              currentAssessment?.percentage_score?.toFixed(2) + '%'
            }
            icon={<AssignmentIcon />}
            loading={
              !currentAssessment
            }
          />

          <TrackerCard
            title='RISK CLASSIFICATION'
            body={
              supplierScore?.status === 'success' ?
                <div style={{
                  color: 
                   (currentAssessment?.percentage_score || 0) < 
                   SupplierRiskClassificationThreshold.Medium ? 
                      PrimaryTheme.secondaryRedError : 
                   (currentAssessment?.percentage_score || 0) < 
                    SupplierRiskClassificationThreshold.Low ? 
                      PrimaryTheme.amber : 
                      PrimaryTheme.primaryGreen
                  
                }}>
                  {
                    supplierStatus?.data?.filter(
                      data => data.status===3
                    ).length > 0?
                      (
                        (currentAssessment?.percentage_score || 0) < 
                        SupplierRiskClassificationThreshold.Medium ? 
                          'High Risk' : 
                        (currentAssessment?.percentage_score || 0) < 
                        SupplierRiskClassificationThreshold.Low ? 
                          'Medium Risk' : 
                          'Low Risk'
                      )
                    : '-'
                  }
                </div>
                : '-'
            }
            icon={<PendingResponseIcon />}
            loading={!currentAssessment}
          />

          <TrackerCard
            title='NO. OF SUPPLIERS ENGAGED'
            body={
              supplierStatus?.status === 'success' ? 
              supplierStatus?.data?.length : 
              '-'
            }
            icon={<TrackerIcon />}
            loading={supplierStatus?.status === 'loading'}
          />
        </div>
        <br />
        <h2>Partner Breakdown by Risk Classification</h2>
        <div className='breakdown-cards'>
          <Card className='breakdown-card' hoverable style={{ width: '200px',padding: '1rem' }}
            loading={riskClassification.loading}>
            <BEStatusPoint childrenColored style={{ fontSize: '1rem' }} status='error'>
              High
            </BEStatusPoint>
            <p className='body'>{
              (riskClassification.high + riskClassification.medium + riskClassification.low) === 0 ? 0 :
                (riskClassification.high / (riskClassification.high + riskClassification.medium + riskClassification.low) * 100).toFixed(2)
            }%</p>
            <p className='footer'>No.of Partners: {
              riskClassification.high
            }</p>
          </Card>
          <Card className='breakdown-card' hoverable style={{ width: '200px',padding: '1rem' }}
            loading={riskClassification.loading}
          >
            <BEStatusPoint childrenColored style={{ fontSize: '1rem' }} status='warning'>
              Medium
            </BEStatusPoint>
            <p className='body'>{(riskClassification.high + riskClassification.medium + riskClassification.low) === 0 ? 0 :
              (riskClassification.medium / (riskClassification.high + riskClassification.medium + riskClassification.low) * 100).toFixed(2)}%</p>
            <p className='footer'>No.of Suppliers: {
              riskClassification.medium
            }</p>
          </Card>
          <Card className='breakdown-card' hoverable style={{ width: '200px',padding: '1rem' }}
            loading={riskClassification.loading}>
            <BEStatusPoint childrenColored style={{ fontSize: '1rem' }} status='success'>
              Low
            </BEStatusPoint>
            <p className='body'>{(riskClassification.high + riskClassification.medium + riskClassification.low) === 0 ? 0 :
              (riskClassification.low / (riskClassification.high + riskClassification.medium + riskClassification.low) * 100).toFixed(2)}%</p>
            <p className='footer'>No.of Suppliers: {
              riskClassification.low
            }</p>
          </Card>
        </div>
        <h2 style={{ margin: '1rem 0' }}>Supplier Risk Score</h2>

          <SupplierResultByQuestion/>

      </div>
    </>
  );
}
