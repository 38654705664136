import { BEMessage } from "../../../../Components/BEMessage";
import { handleAPICall } from "../../../../Config/Functions/HandleAPICall";
import { setEventActions, setAllEvents, setEventEvidences, setEventUsers, setAllActions } from "../../../../Redux/EventManagerReducer";
import { AppDispatch, RootState } from "../../../../Redux/store";
import { EventAction } from "../../../../Redux/Types/eventManagerTypes";
import { GET_EVENT_ACTIONS, GET_ALL_EVENTS, GET_EVENT_EVIDENCES, POST_EVENT_ACTION, POST_EVENT_EVIDENCE, POST_EVENT, PATCH_EVENT, GET_EVENT_USERS, PATCH_EVENT_ACTION, DELETE_EVENT_ACTION, GET_ALL_ACTIONS, POST_EVENT_USER, PUNCH_EVENT_EVIDENCES, DELETE_EVENT_EVIDENCE } from "../../../../Utils/Routes/EventManagementRoutes";

export const getAllEvents = (user_id: number) => async (dispatch: AppDispatch) => {
    dispatch(setAllEvents({ status: 'loading', data: null }));
    const [dataRes, error] = await handleAPICall(GET_ALL_EVENTS(user_id));
    if (dataRes) {
        dispatch(setAllEvents({ status: 'success', data: dataRes?.data }));
    } else {
        dispatch(setAllEvents({ status: 'error', data: null }));
    }
}

export const getEventUsers = () => async (dispatch: AppDispatch) => {
    dispatch(setEventUsers({ status: 'loading', data: null }));
    const [dataRes, error] = await handleAPICall(GET_EVENT_USERS());
    if (dataRes) {
        dispatch(setEventUsers({ status: 'success', data: dataRes?.data }));
    } else {
        dispatch(setEventUsers({ status: 'error', data: null }));
    }

}

export const getAllActions = () => async (dispatch: AppDispatch) => {
    dispatch(setAllActions({ status: 'loading', data: null }));
    const [dataRes, error] = await handleAPICall(GET_ALL_ACTIONS());
    if (dataRes) {
        dispatch(setAllActions({ status: 'success', data: dataRes?.data }));
    } else {
        dispatch(setAllActions({ status: 'error', data: null }));
    }
}

export const getEventActions = (currentEventId: number) => async (dispatch: AppDispatch) => {
    dispatch(setEventActions({ status: 'loading', data: null }));
    const [dataRes, error] = await handleAPICall(GET_EVENT_ACTIONS(currentEventId));
    if (dataRes) {
        dispatch(setEventActions({ status: 'success', data: dataRes?.data }));
    } else {
        dispatch(setEventActions({ status: 'error', data: null }));
    }
}

export const getEventEvidences = (currentEventId: number) => async (dispatch: AppDispatch) => {
    dispatch(setEventEvidences({ status: 'loading', data: null }));
    const [dataRes, error] = await handleAPICall(GET_EVENT_EVIDENCES(currentEventId));
    if (dataRes) {
        dispatch(setEventEvidences({ status: 'success', data: dataRes?.data }));
    } else {
        dispatch(setEventEvidences({ status: 'error', data: null }));
    }
}

export const punchEvent = (data: any, secret: string) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_EVENT(data, secret));
    if (dataRes) {
        // dispatch(getAllEvents());
        BEMessage.success('Event created successfully');
        return dataRes;
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const punchEventEvidences = (event_id: number, data: any, secret: string) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PUNCH_EVENT_EVIDENCES(event_id, data, secret));
    if (dataRes) {
        BEMessage.success('Evidence uploaded successfully');
        return true;
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const postEventAction = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_EVENT_ACTION(data));
    if (dataRes) {
        BEMessage.success('Action created successfully');
        dispatch(getEventActions(data.event));
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const postEventEvidence = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_EVENT_EVIDENCE(data));
    if (dataRes) {
        BEMessage.success('Evidence uploaded successfully');
        dispatch(getEventEvidences(data.event));
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const postEventUser = (data: any, fetchUsers: boolean = true) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_EVENT_USER(data));
    if (dataRes) {
        if (fetchUsers) dispatch(getEventUsers());
        return dataRes;
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const patchEvent = (id: number, data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const user_id = getState().user.userProfile?.data?.user as number;
    const [dataRes, error] = await handleAPICall(PATCH_EVENT(id, data));
    if (dataRes) {
        dispatch(getAllEvents(user_id));
        return true;
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const patchEventAction = (id: number, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_EVENT_ACTION(id, data));
    if (dataRes) {
        dispatch(getEventActions(dataRes?.data.event));
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const deleteEventEvidence = (id: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_EVENT_EVIDENCE(id));
    if (dataRes) {
        dispatch(getEventEvidences(dataRes?.data?.event));
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const deleteEventAction = (id: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_EVENT_ACTION(id));
    console.log(dataRes);
    if (dataRes) {
        dispatch(getEventActions(dataRes?.data.event));
    } else {
        if (error?.errors) BEMessage.error(error?.errors);
        console.error(error);
    }
}

export const checkTakeAction = (event_id: number, allActions: EventAction[]) => {
    const eventActionStages = allActions?.filter(action => action?.event === event_id)?.map(action => action?.stage);
    if (eventActionStages?.includes(0) || eventActionStages?.includes(3)) {
        return true;
    }
    return false;
}
