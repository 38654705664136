import * as React from 'react';
import { HorizontalBarChart } from '../../../../../Components/BECharts/HorizontalBarChart';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getDashoardInsights, getMyDashboardInsightsComparisonProgress } from '../../../Actions';
import { Select } from 'antd';
import { ComparisonCard } from '../../../Components/ComparisonCard';
import '../style.scss';
import { setCurrentInsightPillar, setCurrentInsightsCompareYear } from '../../../../../Redux/DashboardReducer';
import { showFYFormatByYear } from '../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../Config/Data/genralData';
import ESGScoreIcon from '../../../../../Components/BEIcons/ESGScoreIcon';

interface IESGScoreProps {
}

const NameCodeMap: {
	[key: string]: 'O' | 'E' | 'S' | 'G'
} = {
	'Overall': 'O',
	'Environmental': 'E',
	'Social': 'S',
	'Governance': 'G'
}
export function ESGScore(props: IESGScoreProps) {
	const [progressData, setProgressData] = React.useState<any>([]);
	const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
	const currentInsightsCompareYear = useTypedSelector(state => state.dashboard.currentInsightsCompareYear);
	const currentInsightPillar = useTypedSelector(state => state.dashboard.currentInsightPillar);
	const compareInsightsProgress = useTypedSelector(state => state.dashboard.compareInsightsProgress);
	const recentYears = useTypedSelector(state => state.common.financialYears?.data)
	const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
	const monthIndex = new Date().getMonth();
	const month = shortMonths[monthIndex];
	const [loading, setLoading] = React.useState(false);
	const dispatch = useAppDispatch();

	const getInsightsData = async (year: number) => {
		setLoading(true);
		await dispatch(getDashoardInsights(year)).then((data) => {
			const esgProgressData = [
				{ name: "Overall", value: data?.total_progress },
				{ name: "Environmental", value: data?.env_progress },
				{ name: "Social", value: data?.social_progress },
				{ name: "Governance", value: data?.gov_progress },
				// { title: "Target", percent: 25 }
			];
			setProgressData(esgProgressData);
			setLoading(false)
		}).catch(() => {
			setLoading(false)
		});
	}
	React.useEffect(() => {
		if (!currentYear) return;
		const comparisonYear = currentInsightsCompareYear ? currentInsightsCompareYear : currentYear - 1;
		if (currentInsightPillar === "O") {
			dispatch(getMyDashboardInsightsComparisonProgress(currentYear, comparisonYear));
		} else if (["E", "S", "G"].includes(currentInsightPillar as string)) {
			dispatch(getMyDashboardInsightsComparisonProgress(currentYear, comparisonYear, currentInsightPillar as "E" | "S" | "G"))
		} else {
			dispatch(getMyDashboardInsightsComparisonProgress(currentYear, comparisonYear));
		}
	}, [currentYear, currentInsightsCompareYear, currentInsightPillar]);

	React.useEffect(() => {
		if (currentYear)
			getInsightsData(currentYear);
	}, [currentYear]);

	return (
		<div>
			<BEWrapper type={2} icon={<ESGScoreIcon inheritSize/>} title='ESG Score'>
				{
					// loading ? <GlobalLoader height='31vh' /> :
						<div className='comparison-wrapper'>
							{loading ? <GlobalLoader height='31vh' /> :
								<HorizontalBarChart
									type={1}
									data={progressData}
									unit='%'
									onClick={
										(name: string) => {
											dispatch(setCurrentInsightPillar(NameCodeMap[name]));
										}
									}

								/>
							}
							<BEWrapper>
								<div className='details'>
									<div className='year-selector'>
										<p>Compare with:</p>
										<Select
											placeholder='Select year'
											style={{ width: 200 }}
											value={currentInsightsCompareYear ? currentInsightsCompareYear : currentYear - 1}
											onChange={(value) => dispatch(setCurrentInsightsCompareYear(value))}
										>
											{recentYears?.filter((item: any) => item !== currentYear).
												map((item: any) => <Select.Option key={item} value={item}>{showFYFormatByYear(item, yearEnd || '12-10')}</Select.Option>)}
										</Select>
									</div>
									{
										compareInsightsProgress.status === 'loading' ? <GlobalLoader height='14.5rem' /> :
											<ComparisonCard
												data={[
													{ title: 'Compared to last month', value: compareInsightsProgress.data?.current_month_progress, previousValue: compareInsightsProgress.data?.last_month_progress },
													{ title: `Compared to ${month} ${currentYear - 1} VS ${month} ${currentYear}`, value: compareInsightsProgress.data?.current_month_progress, previousValue: compareInsightsProgress.data?.comparison_year_month_progress },
													{ title: 'Compared to last year', value: compareInsightsProgress.data?.current_year_progress, previousValue: compareInsightsProgress.data?.comparison_year_progress },
												]}
												unit='%'
											/>
									}
								</div>

							</BEWrapper>
						</div>
				}
			</BEWrapper>
		</div>
	);
}
