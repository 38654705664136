import * as React from 'react';
import { BETable } from '../../../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import DataEntryIcon from '../../../../../../../Components/BEIcons/DataEntryIcon';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import { NewDataEntry } from '../../../Drawers/NewDataEntry';
import { Form } from 'antd';
import { MyMetricData, myMetricType } from '../../../../../../../Redux/Types/dataManagerTypes';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { AddCommaToNumber, ChangeDateFormat } from '../../../../../../../Config/Functions/UsefullFunctions';
import { deleteMetricsData, deleteOmittedMetrics, DownloadMetricData, editMetricsData, getApprovedMetricValueDM, getMyMetricsData, getNewMyMetricsData, getTotalMetricValueDM, postOmittedMetrics } from '../../../../../Actions';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import CheckIcon from '../../../../../../../Components/BEIcons/CheckIcon';
import CrossIcon from '../../../../../../../Components/BEIcons/CrossIcon';
import MoreVertIcon from '../../../../../../../Components/BEIcons/MoreVertIcon';
import { patchMetricsStatus, postMetricsStatus } from '../../../../../Actions';
import { MenuProps, Dropdown } from 'antd';
import EditIcon from '../../../../../../../Components/BEIcons/EditIcon';
import DeleteIcon from '../../../../../../../Components/BEIcons/DeleteIcon';
import BEStatusTag from '../../../../../../../Components/BEStatusTag';
import { BEMessage } from '../../../../../../../Components/BEMessage';
import BEConfirmModal from '../../../../../../../Components/BEConfirmModal';
import { RejectedEntryDrawer } from '../../../Drawers/EntryRejectionDrawer';
import { BEBulkUploadDrawer } from '../../../../../../../Components/BEDrawer/BEBulkUploadDrawer';
import exportToCSV from '../../../../../../../Utils/Download/Excell/ExportExcell';
import { AssignMetricToContributor } from '../../../Drawers/AssignMetricToContributor';
import { filterDataByMonthOrBU } from '../../../../../../Emissions/Actions';
import { SCOPE_2_BCODES } from '../../../../../Data/data';
import { GlobalLoader } from '../../../../../../../Components/GlobalLoader';

export interface IMEtricDataTableProps {
    metric: myMetricType
}

export function MEtricDataTable(props: IMEtricDataTableProps) {
    const dispatch = useAppDispatch()
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const business_units = useTypedSelector(state => state.entity.BusinessUnits);
    const allLabels = useTypedSelector(state => state.dataManager.labels);
    const [visible, setVisible] = React.useState(false);
    const [editVisible, setEditVisible] = React.useState(false);
    const [form] = Form.useForm();
    const labels = useTypedSelector(state => state.dataManager.labels);
    // const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
    const [currentRow, SetCurrentRow] = React.useState<any>({})
    const companyDetails = useTypedSelector(state => state.companies.companyDetails);
    const metricsStatus = useTypedSelector(state => state.dataManager.metrics.metricStatus);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);
    const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const newMyMetricsData = useTypedSelector(state => state.onBoarding.metrics.newMyMetricsData);
    const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup);
    const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
    const role = useTypedSelector((state) => state.user.userRole);
    const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [singleMetricData, setSingleMetricData] = React.useState<any[]>([]);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [totalValue, setTotalValue] = React.useState<number>(0);
    const [approvedValue, setApprovedValue] = React.useState<number>(0);
    const fullYearTableData = useTypedSelector(state => state.dataManager.metrics.fullYearTableDataDM);
    const [isOmitted, setIsOmitted] = React.useState(false);
    const omittedMetrics = useTypedSelector(state => state.dataManager.metrics.omittedMetrics);
    const [omitLoad, setOmitLoad] = React.useState(false);
    const [viewOnly, setViewOnly] = React.useState(false);
    const [departmentAssigned, setDepartmentAssigned] = React.useState<boolean>(false);
    const [showRejectedEntryDrawer, setShowRejectedEntryDrawer] = React.useState(false);
    const [openBulkUploadDrawer, setOpenBulkUploadDrawer] = React.useState<boolean>(false);
    const [assignContributorDrawer, setAssignContributorDrawer] = React.useState<boolean>(false);
    const [containsEmission, setContainsEmission] = React.useState<boolean>(false);
    const [emissionValue, setEmissionValue] = React.useState<string | any[]>([0, 0]); //location based, market based
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [bulkUploadData, setBulkUploadData] = React.useState<any>({
        fileKey: 'MY_METRIC_DATA_FILE',
        fileName: 'MetricDataTemplate',
        columnArray: ["value", "date_of_entry", "source", "description", "cost"]
    });
    const [loadingDownloadData, setLoadingDownloadData] = React.useState(false);

    const ChangeStatus = async (status: number) => {
        await dispatch(editMetricsData(currentRow.id, { status: status }, props.metric.id));
        if (status === 3) {
            let currentRowYear = currentYear;
            let currentRowMonth = currentMonth;
            let currentMetricStatus = metricsStatus?.data.filter((item: any) => item.my_metric === props.metric.id && item.year === currentRowYear && item.month === currentRowMonth && item.business_unit === currentRow.business_unit)[0];
            if (currentMetricStatus) {
                if (currentMetricStatus?.status !== 3) await dispatch(patchMetricsStatus(currentMetricStatus?.id, {
                    status: 3
                }));
            } else {
                let obj = {
                    metric_id: props.metric.id,
                    year: currentRow.year,
                    month: Number(currentRow.date_of_entry.split('-')[1]),
                    business_unit: currentRow.business_unit,
                    status: 3,
                    metric: props.metric.metricId,
                    my_metric: props.metric.id
                }
                await dispatch(postMetricsStatus(obj));
            }
        }
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div onClick={() => {
                setEditVisible(true); form.setFieldsValue(currentRow)
            }} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}> Edit  </div>
        },
        {
            key: '2',
            label: <div onClick={() => setShowDeleteModal(true)} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>Delete </div>
        }
    ]

    const itemsRejected: MenuProps['items'] = [
        {
            key: '1',
            label: <div onClick={() => {
                setViewOnly(true);
                setShowRejectedEntryDrawer(true);
            }} style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}> View reason  </div>
        }
    ]
    const aggregateColumns: ColumnsType<any> = [
        {
            title: 'S No.',
            render: (text: any, record: any, index: any) => index + 1 + '.',
            width: '10%'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text: any, record: any) => <>
                {
                    <p>{text ? AddCommaToNumber(Number(text), false, true, true) : 0} {props.metric.unit}</p>
                }
            </>
        },
        {
            title: 'Business Unit',
            dataIndex: 'business_unit',
            render: (text: any) => business_units?.data?.find((item: any) => item.id === text)?.name
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: any) => text === "nan" ? '-' : text
        },
        {
            title: 'Date',
            dataIndex: 'date_of_entry',
            render: (text: any) => ChangeDateFormat(text).slice(3, 11),
            // hidden: props.metric.annual
        }, {
            title: 'Cost',
            dataIndex: 'cost',
            render: (text: any) => {
                if (text !== '0.00') return (
                    <p>{AddCommaToNumber(Number(text), false, true)} {companyDetails?.data?.currency}</p>
                )
            }
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            render: (text: any, record: any) => text === "nan" ? '-' : text
        },
        // {
        //     title: 'Label',
        //     dataIndex: 'label',
        //     key: 'label',
        //     render: (text: any, record: any) => {
        //         return record.id ? allLabels?.data?.filter(
        //             (item) => item.my_metric_data === record.id
        //         ).map((item) => item.label_name).join(', ') : '-';
        //     }
        // },
        {
            title: 'Evidence',
            dataIndex: 'evidence',
            key: 'evidence',
            render: (text: any) => text ? <a href={text} target='_blank'>View</a> : ''
        },
        // {
        //     title: 'Label',
        //     dataIndex: 'label',
        //     key: 'label',
        //     render: (text: any, record: any) => {
        //         const labelName =  labels.data.find((label) => label.my_metric_data === record.id)?.label_name;
        //         return labelName ? labelName : '-';
        //     }
        // },
        {
            title: 'Actions',
            render: (text: any, data: any) =>
                <div

                >
                    {(data?.status === 1 && role !== 'CONTRIBUTOR') ?
                        <div onMouseEnter={() => SetCurrentRow(data)}
                            style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', cursor: 'pointer' }}>
                            <div style={{ height: '1rem' }}
                                onClick={() => {
                                    setViewOnly(false);
                                    setShowRejectedEntryDrawer(true);
                                }}
                            >
                                <CrossIcon inheritSize stroke={PrimaryTheme.primaryRedError} />
                            </div>
                            <div style={{ height: '1rem', padding: '0.2rem 0.5rem', cursor: 'pointer' }}
                                onClick={() => ChangeStatus(2)}
                            >
                                <CheckIcon inheritSize stroke={PrimaryTheme.primaryGreen} />
                            </div>
                            <div style={{ cursor: 'pointer' }}>
                                <Dropdown
                                    trigger={['click']}
                                    menu={{ items }}
                                >
                                    <div><MoreVertIcon /></div>
                                </Dropdown>
                            </div>
                        </div> :
                        <div
                            onMouseEnter={() => SetCurrentRow(data)}
                        >
                            {((data?.status === 2 && role !== "CONTRIBUTOR") || (data?.status === 1 && role === "CONTRIBUTOR")) ?
                                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                                >
                                    <div
                                        style={{
                                            height: '1rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setEditVisible(true);
                                        }}
                                    >
                                        <EditIcon inheritSize stroke={PrimaryTheme.primaryGreyDark} />
                                    </div>



                                    <div
                                        style={{
                                            height: '1rem',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGreyDark} />
                                    </div>

                                </div>
                                : data?.status === 3 &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <BEStatusTag status='error'><p>REJECTED</p></BEStatusTag>
                                    <div style={{ cursor: 'pointer' }}>
                                        <Dropdown
                                            trigger={['click']}
                                            menu={{ items: itemsRejected }}
                                        >
                                            <div><MoreVertIcon /></div>
                                        </Dropdown>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
        }
    ];
    // .filter(item => !item.hidden);

    const getTotalValue = async () => {
        let totalValue: number = await dispatch(getTotalMetricValueDM(props.metric));
        let approvedValue: number = await dispatch(getApprovedMetricValueDM(props.metric));
        setTotalValue(totalValue);
        setApprovedValue(approvedValue);
    }

    const getEmissionValue = async () => {
        let res = await dispatch(filterDataByMonthOrBU(true, true));
        const scope1Data = res?.scope1Data;
        const scope2Data = res?.scope2Data;
        const scope3Data = res?.scope3Data;
        const bcode = props?.metric?.bcode;
        const data = [...scope1Data, ...scope2Data, ...scope3Data];
        const filteredData = data.filter((item) => item.bcode === bcode)
        if (filteredData?.length === 0) {
            setContainsEmission(false);
            setEmissionValue([0, 0]);
            return;
        }
        // const value = filteredData.reduce((acc, item) => acc + item.tco2e, 0);
        var value_1: any = filteredData.reduce((acc, item) => item.emission_type === 1 ? acc + item.tco2e : acc, 0);
        var value_2: any = filteredData.reduce((acc, item) => item.emission_type === 2 ? acc + item.tco2e : acc, 0);
        if (value_1 !== undefined || value_1 !== null || value_2 !== undefined || value_2 !== null) {
            setContainsEmission(true);
            // const emissionValue = AddCommaToNumber(Number(value), false, true, true, 3);
            value_1 = value_1 ? value_1 : 0;
            value_2 = value_2 ? value_2 : 0;
            const emissionValue1: any = AddCommaToNumber(Number(value_1), false, true, true, 3);
            const emissionValue2: any = AddCommaToNumber(Number(value_2), false, true, true, 3);
            setEmissionValue([emissionValue1, emissionValue2]);
        } else {
            setContainsEmission(false);
            setEmissionValue([0, 0]);
        }
    }

    const getSingleMetricData = async (page?: any) => {
        setTableLoading(true);
        await dispatch(getNewMyMetricsData(props.metric.id, page, 10)).then((res) => {
            const [data, total]: any = res;
            setTotalPages(total);
            setTableLoading(false);
        }).catch((err) => {
            console.log(err);
            setTableLoading(false);
        });
    }

    React.useEffect(() => {
        //set total value
        getTotalValue();
        //set emission value
        getEmissionValue();
    }, [singleMetricData, props.metric, currentYear]);

    // For fetching data on page load
    React.useEffect(() => {
        getSingleMetricData(currentPage);
    }, [
        currentBusinessUnit,
        currentYear, currentMonth,
        currentBusinessGroup,
        currentPage
    ]);

    React.useEffect(() => {
        if (newMyMetricsData?.data?.length > 0) {
            if (props.metric.id === newMyMetricsData?.data[0]?.my_metric) {
                setSingleMetricData(newMyMetricsData?.data);
            }
        }
    }, [newMyMetricsData]);


    React.useEffect(() => {
        setDepartmentAssigned(assignedDepartmentByCategory?.data.filter((item) => item.category === currentGroup)?.length !== 0);
    }, [assignedDepartmentByCategory, currentGroup]);

    React.useEffect(() => {
        setIsOmitted(omittedMetrics?.data.some((item) => item.my_metric_id === props.metric.metric && item.year === currentYear && item.business_unit === currentBusinessUnit))
    }, [omittedMetrics, props.metric])

    return (
        <div className='metric-data'>
            <BEConfirmModal title='Delete Entry?' visible={showDeleteModal} setVisible={setShowDeleteModal}
                onConfirm={async () => await dispatch(deleteMetricsData(currentRow.id, props.metric.id))}
                message='Are you sure you want to delete this data entry?'
            />
            {
                singleMetricData?.length > 0 ?
                    <div style={{ padding: '1rem' }}>
                        {/* {currentBusinessGroup === 0 && !props.metric.annual && !props.metric?.hasOwnProperty('direct') &&
                            <BEButton
                                size='large'
                                onClick={() => {
                                    const bulkUploadData = {
                                        fileKey: currentBusinessUnit !== 0 ? 'MY_METRIC_DATA_FILE' : 'MY_METRIC_DATA_ALL_BU_FILE',
                                        fileName: currentBusinessUnit !== 0 ? 'MetricDataTemplate' : 'MetricDataTemplateAllBU',
                                        columnArray: currentBusinessUnit !== 0 ? ["value", "date_of_entry", "source", "description", "cost"] :
                                            ["value", "date_of_entry", "source", "description", "cost", "business_unit_name"],
                                    }
                                    setBulkUploadData(bulkUploadData);
                                    setOpenBulkUploadDrawer(true);
                                }}
                                style={{ marginRight: "1rem" }}
                            >
                                Bulk upload data
                            </BEButton>
                        } */}

                        <BEButton style={{ float: 'right', marginBottom: '1rem' }} className='primary' size='large'
                            onClick={() => {
                                // if (currentBusinessUnit === 0){ 
                                //     BEMessage.error('Please select a business unit to fill data')
                                //     return
                                // }
                                // else {
                                let hqBu: any = businessUnits?.data?.find((unit: any) => unit.is_headquarter === true)
                                if (props.metric?.applicable_to_all === true || hqBu?.id === currentBusinessUnit) {
                                    setVisible(true)
                                }
                                else {
                                    if (hqBu) BEMessage.error(`Please select the HQ location  ${hqBu?.name} to enter the data for this metric`)
                                    else BEMessage.error('Please mark a business unit as headquarter to fill data');
                                }
                                // }
                            }}
                            disabled={false}
                        /*props.metric.annual && Array.from(new Set(fullYearTableData[props.metric.id].map((item) => item.business_unit)))?.length ===
                            (props.metric.applicable_to_all ?
                                (!currentBusinessUnit && !currentBusinessGroup ? business_units?.data?.length :
                                    (!currentBusinessUnit ? business_unit_ref?.data.filter((item) => item.business_group === currentBusinessGroup)?.length : 1))
                                : 1)*/

                        >+ New Data Entry
                        </BEButton>
                        {
                            !props.metric.hasOwnProperty('direct') && role !== 'CONTRIBUTOR' && departmentAssigned && currentBusinessUnit !== 0 &&
                            <BEButton style={{ float: 'right', marginBottom: '1rem', marginRight: "1rem" }} size='large'
                                onClick={() => {
                                    departmentAssigned ?
                                        setAssignContributorDrawer(true) :
                                        BEMessage.error(`Please assign a department to the "${currentGroup.toLowerCase()}" section to access this.`)
                                }}
                            >
                                Assign metrics
                            </BEButton>
                        }
                        {
                            currentBusinessUnit !== 0 && !props.metric.annual && !props.metric?.hasOwnProperty('direct') && role !== 'CONTRIBUTOR' &&
                            <div style={{ float: 'right', marginBottom: '1rem' }}>
                                <BEButton
                                    loading={omitLoad}
                                    size='large'
                                    style={{ marginRight: '1rem' }}
                                    onClick={async () => {
                                        setOmitLoad(true);
                                        const data = {
                                            my_metric_id: props.metric.metric,
                                            year: currentYear,
                                            business_unit: currentBusinessUnit
                                        }
                                        const omittedMetric = omittedMetrics?.data?.find((item) => item.my_metric_id === props.metric.metric && item.year === currentYear && item.business_unit === currentBusinessUnit)
                                        if (isOmitted) {
                                            await dispatch(deleteOmittedMetrics(omittedMetric?.id!));
                                        } else {
                                            await dispatch(postOmittedMetrics([data]));
                                        }
                                        setOmitLoad(false);
                                    }}
                                >
                                    {isOmitted ? 'Activate' : 'Omit'}
                                </BEButton>
                            </div>
                        }
                        {!props.metric.annual && !props.metric?.hasOwnProperty('direct') &&
                            <BEButton
                                loading={loadingDownloadData}
                                size='large'
                                onClick={async() => {
                                    setLoadingDownloadData(true);
                                    await dispatch(
                                        DownloadMetricData(props.metric)
                                    );
                                    setLoadingDownloadData(false);
                                }}
                            >
                                Download data
                            </BEButton>
                        }

                        <BETable
                            loading={tableLoading}
                            rowClassName={(record: any, index: any) => record?.status === 3 ? 'rejected-row' : (record?.status === 1 ? 'pending-row' : '')}
                            columns={currentBusinessUnit !== 0 ? [...aggregateColumns.slice(0, 2), ...aggregateColumns.slice(3, 9)]
                                : aggregateColumns}
                            data={
                                (props.metric.annual ? fullYearTableData[props.metric.id] : singleMetricData)
                            }
                            pagination
                            totalPages={
                                props.metric.annual ?
                                undefined :
                                totalPages
                            }
                            handleChangePage={(page: any) => {
                                setCurrentPage(page);
                                console.log(page);
                            }}
                        />
                        <br />
                        {/* <div className='values'>
                            <br />
                            <p style={{ color: 'black' }}>Total Computed Value: {AddCommaToNumber(Number(totalValue), false, true)}
                                {' '}
                                {
                                    props.metric.unit
                                }
                            </p>
                        </div> */}

                        {/* <div className='values'>
                            <br />
                            <p style={{ color: 'black' }}>Approved Value: {AddCommaToNumber(Number(approvedValue), false, true)}
                                {' '}
                                {
                                    props.metric.unit
                                }
                            </p>
                        </div> */}
                        <div className='value-box'>
                            <div className='values'>
                                <p><b>Total Computed Value</b></p>
                                <p>{AddCommaToNumber(Number(totalValue), false, true)} {props.metric.unit}</p>
                            </div>
                            <div className='values' style={{ borderLeft: `1px solid ${PrimaryTheme.primaryGray}` }}>
                                <p><b>Approved Value</b></p>
                                <p>{AddCommaToNumber(Number(approvedValue), false, true)} {props.metric.unit}</p>
                            </div>
                            {containsEmission &&
                                SCOPE_2_BCODES.includes(props.metric.bcode) ?
                                <>
                                    <div className='values' style={{ borderLeft: `1px solid ${PrimaryTheme.primaryGray}` }}>
                                        <p><b>Location Based Emissions</b></p>
                                        <p>{emissionValue[0]} {' tCO2e'}</p>
                                    </div>
                                    <div className='values' style={{ borderLeft: `1px solid ${PrimaryTheme.primaryGray}` }}>
                                        <p><b>Market Based Emissions</b></p>
                                        <p>{emissionValue[1]} {' tCO2e'}</p>
                                    </div>
                                </>
                                :
                                <div className='values' style={{ borderLeft: `1px solid ${PrimaryTheme.primaryGray}` }}>
                                    <p><b>Total Emissions</b></p>
                                    <p>{emissionValue[0]} {' tCO2e'}</p>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    !tableLoading ?
                        <div className='empty-state'>
                            {
                                !props.metric?.hasOwnProperty('direct') &&
                                <span style={{ width: "100%" }} >
                                    {role !== 'CONTRIBUTOR' && departmentAssigned && currentBusinessUnit !== 0 &&
                                        <BEButton style={{ float: 'right', marginBottom: '1rem', marginRight: "1rem" }} size='large'
                                            onClick={() => {
                                                departmentAssigned ?
                                                    setAssignContributorDrawer(true) :
                                                    BEMessage.error(`Please assign a department to the "${currentGroup.toLowerCase()}" section to access this.`)
                                            }}
                                        >
                                            Assign metrics
                                        </BEButton>}
                                    {!props.metric.annual && <>
                                        {currentBusinessUnit !== 0 && role !== 'CONTRIBUTOR' && <BEButton
                                            loading={omitLoad}
                                            style={{ float: 'right', marginBottom: '1rem', marginRight: '1rem' }}
                                            size='large'
                                            onClick={async () => {
                                                setOmitLoad(true);
                                                const data = {
                                                    my_metric_id: props.metric.metric,
                                                    year: currentYear,
                                                    business_unit: currentBusinessUnit
                                                }
                                                const omittedMetric = omittedMetrics?.data?.find((item) => item.my_metric_id === props.metric.metric && item.year === currentYear && item.business_unit === currentBusinessUnit)
                                                if (isOmitted) {
                                                    await dispatch(deleteOmittedMetrics(omittedMetric?.id!));
                                                } else {
                                                    await dispatch(postOmittedMetrics([data]));
                                                }
                                                setOmitLoad(false);
                                            }}
                                        >
                                            {isOmitted ? 'Activate' : 'Omit'}
                                        </BEButton>}
                                        {/* {currentBusinessGroup === 0 && <BEButton
                                        style={{ marginLeft: '1rem' }}
                                        size='large'
                                        onClick={() => {
                                            const bulkUploadData = {
                                                fileKey: currentBusinessUnit !== 0 ? 'MY_METRIC_DATA_FILE' : 'MY_METRIC_DATA_ALL_BU_FILE',
                                                fileName: currentBusinessUnit !== 0 ? 'MetricDataTemplate' : 'MetricDataTemplateAllBU',
                                                columnArray: currentBusinessUnit !== 0 ? ["value", "date_of_entry", "source", "description", "cost"] :
                                                    ["value", "date_of_entry", "source", "description", "cost", "business_unit_name"],
                                            }
                                            setBulkUploadData(bulkUploadData);
                                            setOpenBulkUploadDrawer(true);
                                        }}
                                    >
                                        Bulk upload data
                                    </BEButton>} */}
                                    </>
                                    }
                                </span>
                            }
                            <div><div className='imgs'><DataEntryIcon fill={PrimaryTheme.primaryGreen} inheritSize /></div></div>
                            <p style={{ color: PrimaryTheme.primaryGreyDark }}>No data has been entered for this metric</p>
                            <BEButton className='primary' size='large'
                                onClick={() => {
                                    // if (currentBusinessUnit === 0){ 
                                    //     BEMessage.error('Please select a business unit to fill data')
                                    //     return
                                    // }
                                    // else {
                                    let hqBu: any = businessUnits?.data?.find((unit: any) => unit.is_headquarter === true)
                                    if (props.metric?.applicable_to_all === true || hqBu?.id === currentBusinessUnit) {
                                        setVisible(true)
                                    }
                                    else {
                                        if (hqBu) BEMessage.error(`Please select the HQ location  ${hqBu?.name} to enter the data for this metric`)
                                        else BEMessage.error('Please mark a business unit as headquarter to fill data');
                                    }
                                    // }
                                }}
                            >+ New Data Entry</BEButton>
                        </div>
                        :
                        <GlobalLoader height='20vh' />
            }
            <NewDataEntry
                my_metrics={props.metric.id}
                metric={props.metric}
                open={visible}
                setOpen={setVisible}
            />
            <NewDataEntry
                my_metrics={props.metric.id}
                metric={props.metric}
                open={editVisible}
                setOpen={setEditVisible}
                isEdit
                values={currentRow}
            />
            <RejectedEntryDrawer
                open={showRejectedEntryDrawer}
                setOpen={setShowRejectedEntryDrawer}
                changeStatus={ChangeStatus}
                metric={props.metric}
                viewOnly={viewOnly}
            />
            {/*<BEBulkUploadDrawer
                uploadFunction={bulkUploadMetricData}
                open={openBulkUploadDrawer}
                setOpen={setOpenBulkUploadDrawer}
                heading={'Bulk upload metric data'}
                columnArray={bulkUploadData.columnArray}
                fileName={bulkUploadData.fileName}
                fileKey={bulkUploadData.fileKey}
                uploadFunctionParams={[currentBusinessUnit, props.metric.id]}
            />*/}
            <AssignMetricToContributor
                open={assignContributorDrawer}
                setOpen={setAssignContributorDrawer}
                myMetricId={props.metric.id}
            />
        </div>
    );
}
