import * as React from 'react';
import { BEDrawer } from '../../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import FileUpload from '../../../../../../../Components/BEFileUpload/FileUpload';
import DeleteIcon from '../../../../../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import { ColumnType } from 'antd/es/table';
import { BETable } from '../../../../../../../Components/BETable';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { editSupplierAnswer } from '../../../../../Actions/supplierFormActions';
import { useSearchParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { BEEyeButton } from '../../../../../../../Components/BEEyeButton';
import { SupplierAssessmentQuestionType } from '..';

export interface IUploadEvidenceSupplierProps {
    open: boolean;
    setOpen: Function;
    questionId: number;
    questionType: SupplierAssessmentQuestionType;
    saveAnswer: (questionId: any,type:any, values: any) => Promise<void>;
}

export function UploadEvidenceSupplier(props: IUploadEvidenceSupplierProps) {
    const [file, setFile] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [evidenceList, setEvidenceList] = React.useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const supplierAssessmentAnswers = useTypedSelector(state => state.supplier.SupplierAnswers);

    const columns: ColumnType<any>[] = [
        {
            title: 'Evidence',
            key: 'hyperlink',
            render: (text: any, record: any) => (
                <a href={record.hyperlink} target='_blank' rel='noreferrer'>Link</a>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => (
                <div
                    onClick={async () => {
                        setLoading(true);
                        await props.saveAnswer(props.questionId, props.questionType, { evidence: null })
                        setLoading(false);
                        props.setOpen(false);
                    }}
                    style={{ width: "1rem", cursor: 'pointer' }}
                >
                    <DeleteIcon
                        stroke={PrimaryTheme.primaryGray}
                        inheritSize
                    />
                </div>
            )
        }
    ];

    // set evidence list
    React.useEffect(() => {
        if (supplierAssessmentAnswers?.data?.[props.questionType]?.[props.questionId]?.evidence) {
            setEvidenceList([{
                key: 1,
                hyperlink: supplierAssessmentAnswers?.data?.[props.questionType]?.[props.questionId]?.evidence
            }]);
        } else {
            setEvidenceList([]);
        }
    }, [props.open, supplierAssessmentAnswers]);

    return (
        <BEDrawer
            width='fit-content'
            open={props.open}
            setOpen={props.setOpen}
            heading={`${evidenceList?.length > 0 ? 'View' : 'Upload'} Evidence`}
            footer={
                <>
                    <BEButton size='large' className='primary' loading={loading}
                        onClick={async () => {
                            setLoading(true);
                            if (file) {
                                await props.saveAnswer(props.questionId, props.questionType, { evidence: file });
                            }
                            setLoading(false);
                            props.setOpen(false);
                        }}>
                        Save
                    </BEButton>
                    <BEButton
                        size='large'
                        onClick={() => props.setOpen(false)}
                    >
                        Cancel
                    </BEButton>
                </>
            }
        >
            {
                evidenceList?.length > 0 &&
                <BETable
                    columns={columns}
                    data={evidenceList}
                />
            }

            <br />
            <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
                <BEEyeButton discription={"Uploading new evidence will replace existing one."} title={undefined}>
                    Upload evidence
                </BEEyeButton>
            </p>

            <FileUpload
                width='350px'
                handleFileChange={(file: any) => setFile(file)} refreshUpload={props.open}
                fileType='any'
                hint='File can be in any format up to 5MB in size'
                fileSize={5000000}
            />
        </BEDrawer>
    );
}
