import type { StatusType } from "../Types/userTypes";
import { SuppliersState } from "../Types/suppliersTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: SuppliersState = {
    SuppliersSurveys: {
        status: "idle",
        data: [],
    },
    Suppliers: {
        status: "idle",
        data: [],
    },
    SuppliersSurveyRefs: {
        status: "idle",
        data: [],
    },
    SupplierOptions: {
        status: "idle",
        data: [],
    },
    SupplierQuestions: {
        status: "idle",
        data: [],
    },
    SupplierAnswers: {
        status: "idle",
        data: {
          'custom':{},
          'default':{}
        },
    },
    SupplierStatus: {
        status: "idle",
        data: [],
    },
  currentAssessment: null,
  createSurveyStep: 1,
  AssessmentStatus: null,
  CoSuppliers: {
    status: "idle",
    data: [],
  },
  CurrentSection: '',
  AssessmentYear: new Date().getFullYear(),
  SupplierScore: {
    status: "idle",
    data: {
      supplier_scores: [],
      question_completion: [],
      total_score: 0,
      engaged_suppliers: 0,
      total_questions: 0
    }
  },
  lastAddedSupplier: null
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
      setSuppliersSurveys: (state, action) => {
        state.SuppliersSurveys = action.payload;
      },
      setSuppliers: (state, action) => {
        state.Suppliers = action.payload;
      },
      setSuppliersSurveyRefs: (state, action) => {
        state.SuppliersSurveyRefs = action.payload;
      },
      setSupplierOptions: (state, action) => {
        state.SupplierOptions = action.payload;
      },
      setSupplierQuestions: (state, action) => {
        state.SupplierQuestions = action.payload;
      },
      updateSupplierQuestions: (state, action) => {
        let my_question_id = action.payload.my_question_id;
        let data = action.payload.data;
        let index = state.SupplierQuestions.data.findIndex(
          (item) => item.my_question_id === my_question_id
        );
        if(index !== -1){
          state.SupplierQuestions.data[index] = {
            ...state.SupplierQuestions.data[index],
            ...data
          }
        }
      },
      setSupplierAnswers: (state, action) => {
        state.SupplierAnswers = action.payload;
      },
      updateSupplierAnswers: (state, action) => {
          if(action.payload.type=='custom'){
            state.SupplierAnswers.data.custom = {
              ...state.SupplierAnswers.data.custom,
              ...action.payload.data
            }
          }
          else{
            state.SupplierAnswers.data.default = {
              ...state.SupplierAnswers.data.default,
              ...action.payload.data
            }
          }
      },
      setSupplierStatus: (state, action) => {
        state.SupplierStatus = action.payload;
      },
      setCurrentAssessment: (state, action) => {
        state.currentAssessment = action.payload;
      },
      setCreateSurveyStep: (state, action) => {
        state.createSurveyStep = action.payload
      },
      setAssessmentStatus: (state, action) => {
        state.AssessmentStatus = action.payload;
      },
      setCoSuppliers: (state, action) => {
        state.CoSuppliers = action.payload;
      },
      setCurrentSection: (state, action) => {
        state.CurrentSection = action.payload;
      },
      setAssessmentYear: (state, action) => {
        state.AssessmentYear = action.payload;
      },
      setSupplierScore: (state, action) => {
        state.SupplierScore = action.payload;
      },
      setLastAddedSupplier: (state, action) => {
        state.lastAddedSupplier = action.payload
      }
    }
});

export const {
  setSuppliersSurveys,
  setSuppliers,
  setSuppliersSurveyRefs,
  setSupplierOptions,
  setSupplierQuestions,
  updateSupplierQuestions,
  setSupplierAnswers,
  setSupplierStatus,
  setCurrentAssessment,
  setCreateSurveyStep,
  setAssessmentStatus,
  setCoSuppliers,
  setCurrentSection,
  setAssessmentYear,
  updateSupplierAnswers,
  setSupplierScore,
  setLastAddedSupplier,
} = userSlice.actions;

const supplierReducer: Reducer<SuppliersState> = userSlice.reducer;

export default supplierReducer;
