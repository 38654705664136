// import { CompanyState } from "../Types/companyTypes";
import { clearStore } from "../ExtraReducrActions";
import { onboardingState, stage1Interface } from "../Types/onBoarding";

import { Reducer, createSlice } from "@reduxjs/toolkit";

const initialState: onboardingState = {
  stage: 0,
  industries: {
    status: "idle",
    data:[]
  },
  stage1Data: {
    status: 'idle',
    data: null
  },
  metrics: {
    directMetrics: {
      status: "idle",
      data: []
    },
    myMetrics: {
      status: "idle",
      data: []
    },
    myMetricsPerYear: {
      status: "idle",
      data: []
    },
    myMetricsData: {
      status: "idle",
      data: []
    },
    newMyMetricsData: {
      status: "idle",
      data: []
    },
    myMetricsDataByMonthAndBusinessUnit: {
      status: "idle",
      data: []
    },
    derivedMetrics: {
      status: "idle",
      data: []
    },
    myDerivedMetrics: {
      status: "idle",
      data: []
    },
    myDerivedMetricsDataByMonthAndBusinessUnit: {
      status: "idle",
      data: []
    },
    myDerivedMetricsPerYear: {
      status: "idle",
      data: []
    },
    combinedSelectedMetrics: {
      status: "idle",
      data: []
    },
    combinedSelectedMetricsPerYear: {
      status: "idle",
      data: []
    },
    currentPillar: '',
    currentCategory: '',
    currentGroup: '',
  },
  frameworks: {
    selectedFrameworks: []
  }
};

export const onBoardingSlice = createSlice({
  name: "onboarding",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setOnBoardingStep1: (state, action) => {
      state.stage = 1;
      state.stage1Data = action.payload;
    },
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
    setCurrentPillar: (state, action) => {
      state.metrics.currentPillar = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.metrics.currentCategory = action.payload;
    },
    setCurrentGroup: (state, action) => {
      state.metrics.currentGroup = action.payload;
    },
    setSelectedFrameworks: (state, action) => {
      state.frameworks.selectedFrameworks = action.payload
    },

    setMetrics: (state, action) => {
      state.metrics.directMetrics = action.payload;
    },

    setMyMetrics: (state, action) => {
      state.metrics.myMetrics = action.payload;
    },

    setMyMetricsPerYear: (state, action) => {
      state.metrics.myMetricsPerYear = action.payload;
    },

    setMyMetricsData: (state, action) => {
      state.metrics.myMetricsData = action.payload;
    },
    setNewMyMetricsData: (state, action) => {
      state.metrics.newMyMetricsData = action.payload;
    },
    setMyMetricsDataByMonthAndBusinessUnit: (state, action) => {
      state.metrics.myMetricsDataByMonthAndBusinessUnit = action.payload;
    },

    setDerivedMetrics: (state, action) => {
      state.metrics.derivedMetrics = action.payload;
    },

    setMyDerivedMetrics: (state, action) => {
      state.metrics.myDerivedMetrics = action.payload;
    },

    setMyDerivedMetricsDataByMonthAndBusinessUnit: (state, action) => {
      state.metrics.myDerivedMetricsDataByMonthAndBusinessUnit = action.payload;
    },

    setMyDerivedMetricsPerYear: (state, action) => {
      state.metrics.myDerivedMetricsPerYear = action.payload;
    },

    setCombinedSelectedMetrics: (state, action) => {
      state.metrics.combinedSelectedMetrics = action.payload;
    },

    setCombinedSelectedMetricsPerYear: (state, action) => {
      state.metrics.combinedSelectedMetricsPerYear = action.payload
    }

  },
});

export const {
  setOnBoardingStep1,
  setIndustries,
  setCurrentPillar,
  setCurrentCategory,
  setCurrentGroup,
  setSelectedFrameworks,
  setMetrics,
  setMyMetrics,
  setMyMetricsPerYear,
  setMyMetricsData,
  setNewMyMetricsData,
  setMyMetricsDataByMonthAndBusinessUnit,
  setDerivedMetrics,
  setMyDerivedMetrics,
  setMyDerivedMetricsDataByMonthAndBusinessUnit,
  setMyDerivedMetricsPerYear,
  setCombinedSelectedMetrics,
  setCombinedSelectedMetricsPerYear
} = onBoardingSlice.actions;

const onBoadingReducer: Reducer<onboardingState> = onBoardingSlice.reducer;

export default onBoadingReducer;