import React from 'react'
import IconProps from './Interface/IconProps';

const WebIcon = (props: IconProps) => {
	return (
		<svg
			width={props.inheritSize ? "100%" : 20}
			height={props.inheritSize ? "100%" : 20}
			viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8 0.6875C6.55373 0.6875 5.13993 1.11637 3.9374 1.91988C2.73486 2.72339 1.7976 3.86544 1.24413 5.20163C0.690668 6.53781 0.545857 8.00811 0.828011 9.4266C1.11017 10.8451 1.80661 12.148 2.82928 13.1707C3.85196 14.1934 5.15492 14.8898 6.57341 15.172C7.99189 15.4541 9.46219 15.3093 10.7984 14.7559C12.1346 14.2024 13.2766 13.2651 14.0801 12.0626C14.8836 10.8601 15.3125 9.44628 15.3125 8C15.3103 6.06129 14.5391 4.20262 13.1683 2.83175C11.7974 1.46087 9.93871 0.689733 8 0.6875ZM14.1875 8C14.188 8.57063 14.1092 9.13856 13.9534 9.6875H11.2456C11.4181 8.56911 11.4181 7.43089 11.2456 6.3125H13.9534C14.1092 6.86144 14.188 7.42937 14.1875 8ZM6.17188 10.8125H9.82813C9.46793 11.9928 8.84261 13.075 8 13.9766C7.15771 13.0748 6.53245 11.9926 6.17188 10.8125ZM5.89766 9.6875C5.70454 8.57079 5.70454 7.42921 5.89766 6.3125H10.108C10.3011 7.42921 10.3011 8.57079 10.108 9.6875H5.89766ZM1.8125 8C1.81201 7.42937 1.89081 6.86144 2.04664 6.3125H4.75438C4.58188 7.43089 4.58188 8.56911 4.75438 9.6875H2.04664C1.89081 9.13856 1.81201 8.57063 1.8125 8ZM9.82813 5.1875H6.17188C6.53208 4.00724 7.15739 2.92497 8 2.02344C8.84229 2.92521 9.46756 4.0074 9.82813 5.1875ZM13.5076 5.1875H11.0031C10.6874 4.02939 10.1554 2.94149 9.43508 1.98125C10.3053 2.19031 11.1201 2.58508 11.8235 3.13849C12.527 3.69191 13.1024 4.39088 13.5104 5.1875H13.5076ZM6.56492 1.98125C5.84464 2.94149 5.31262 4.02939 4.99696 5.1875H2.48961C2.89762 4.39088 3.47305 3.69191 4.17647 3.13849C4.87989 2.58508 5.69466 2.19031 6.56492 1.98125ZM2.48961 10.8125H4.99696C5.31262 11.9706 5.84464 13.0585 6.56492 14.0188C5.69466 13.8097 4.87989 13.4149 4.17647 12.8615C3.47305 12.3081 2.89762 11.6091 2.48961 10.8125ZM9.43508 14.0188C10.1554 13.0585 10.6874 11.9706 11.0031 10.8125H13.5104C13.1024 11.6091 12.527 12.3081 11.8235 12.8615C11.1201 13.4149 10.3053 13.8097 9.43508 14.0188Z"
				fill={props.stroke ? props.stroke : "#747474"}
			/>
		</svg>

	);
}

export default WebIcon