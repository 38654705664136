import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { Dropdown, MenuProps } from 'antd';
import { patchReportTemplate, patchReportingTopicStatus } from '../../../Actions/template';
import SwitchIcon from '../../../../../Components/BEIcons/SwitchIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import './style.scss';
import { RejectTopicDrawer } from '../RejectTopicDrawer';
import { TemplateSelectedTopicTypes } from '../../../../../Redux/Types/reportingTypes';

export interface IReportingReviewStatusButtonProps {
    uniqueCode: string;
    section?: string;
}

const statusMap: { [key: number]: 'error' | 'warning' | 'success' | 'info' } = {
    0: 'info', // omitted
    1: 'warning', //compleated
    2: 'success', // approved
    3: 'error',  //rejected
}

const statusTextMap = {
    0: 'Omitted',
    1: 'Not Approved',
    2: 'Approved',
    3: 'Rejected'
}
type statusType = 0 | 1 | 2 | 3;
export function ReportingReviewStatusButton(props: IReportingReviewStatusButtonProps) {
    const dispatch = useAppDispatch();
    const topicStatus = useTypedSelector(state => state.reporting.topicStatus);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const omittedReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    const allReports = useTypedSelector(state => state.reporting.allReports);
    const [currentStatus, setCurrentStatus] = React.useState<statusType>(1);
    const [openRejectTopicDrawer, setOpenRejectTopicDrawer] = React.useState<boolean>(false);
    const changeStatus = (status: number) => {
        let thisTopicStatus = topicStatus?.data?.find((item) => item.unique_code === props.uniqueCode)
        if (thisTopicStatus) {
            dispatch(patchReportingTopicStatus(thisTopicStatus?.id, { status: status, esg_report: thisTopicStatus?.esg_report }));
            let thisReport = allReports?.data?.find((item) => item.id === thisTopicStatus?.esg_report);
            console.log(thisReport);
            if (status === 3) {
                if (thisReport?.stage !== 6) dispatch(patchReportTemplate(thisReport?.id, { stage: 6 }, true));
            }
            else if (status === 2 && thisReport?.stage !== 5) {
                let allTopicsExceptThis = topicStatus?.data.filter((item) => item.unique_code !== props.uniqueCode);
                let allTopicsExceptThisApproved = allTopicsExceptThis.filter((item) => item?.status === 2);
                console.log(allTopicsExceptThisApproved?.length, allTopicsExceptThis?.length);
                if (allTopicsExceptThisApproved?.length === allTopicsExceptThis?.length) dispatch(patchReportTemplate(thisReport?.id, { stage: 5 }, true));
            }
        }
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Approve',
            onClick: () => changeStatus(2)
        },
        {
            key: '2',
            label: 'Reject',
            onClick: () => setOpenRejectTopicDrawer(true)
        }
    ];

    React.useEffect(() => {
        let temp = topicStatus?.data?.find((item) => item.unique_code === props.uniqueCode)?.status
        let status: statusType = !temp ? 1 : temp === 1 ? 1 : temp === 2 ? 2 : 3;
        let checked = omittedReportTopics?.data?.find(
            (item) => item.topic_id === selectedTopics?.data?.find(
                (item: TemplateSelectedTopicTypes) =>
                    item.unique_code === props.uniqueCode
            )?.id && item.report === currentReport?.id &&
            item.section === props?.section
        ) ? true : false;

        status = checked ? 0 : status;
        setCurrentStatus(status);
    }, [topicStatus?.data, props.uniqueCode]);
    return (
        <>
            <div className='reporting-review-status-btn'>
                <Dropdown menu={{ items }} disabled={currentStatus === 0}>
                    <div>
                        <BEStatusTag size='large' status={
                            statusMap[currentStatus]
                        }>
                            <div className='inner-box'>
                                <p>
                                    {
                                        statusTextMap[currentStatus]
                                    }
                                </p>

                                <div >
                                    <SwitchIcon
                                        stroke={PrimaryTheme.secondaryGray}
                                    />
                                </div>
                            </div>
                        </BEStatusTag>
                    </div>
                </Dropdown>
            </div>
            <RejectTopicDrawer
                open={openRejectTopicDrawer}
                setOpen={setOpenRejectTopicDrawer}
                onClick={() => changeStatus(3)}
                uniqueCode={props.uniqueCode}
            />
        </>
    );
}
