import * as React from 'react';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { AddCommaToNumber } from '../../../../../Config/Functions/UsefullFunctions';
import './style.scss'
export interface IProgressBarProps {
  progressPercentage: number;
}

export function ProgressBar (props: IProgressBarProps) {
  return (
      <div className="be-progress-bar" >	
          <div className="progress-bar__wrapper"
            style={{
              backgroundColor: props.progressPercentage >= 0 ? PrimaryTheme.primaryGreenLight : PrimaryTheme.primaryRedLight,
            }}
          >
            <div className="progress-bar__progress" 
            style={{
              width:`${props.progressPercentage>0 ? props.progressPercentage : 0}%`,
              backgroundColor: props.progressPercentage >= 0 ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryRedError
            }}/>
          </div>
          <p className="progress-bar__percentage">
            {props.progressPercentage>0 ? AddCommaToNumber(props.progressPercentage) : 0}%
          </p>
        </div>
  );
}
