import * as React from 'react';
import './style.scss';
import { AddTrends } from '../Drawers/AddTrends';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { DashboardCustomGraph } from './CustomGraph';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { patchMyDerivedDashboardMetrics,patchMyDashboardMetrics, generatePDF } from '../../Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setDashboardGraphPositions } from '../../../../Redux/DataManagerReducer';
import BEConfirmModal from '../../../../Components/BEConfirmModal';

export interface IDashboardCustomProps {
}

export function DashboardCustom (props: IDashboardCustomProps) {
  const dispatch = useAppDispatch();
  const [openAddTrends, setOpenAddTrends] = React.useState<boolean>(false);
  const [GraphMetrics, setGraphMetrics] = React.useState<any>([]);
  const myDerivedDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDerivedDashboardMetrics);
  const myDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDashboardMetrics);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const [selectedMetrics, setSelectedMetrics] = React.useState<any>({});
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const [loadingDownloadPDF, setLoadingDownloadPDF] = React.useState<boolean>(false);

  const removeMetric = async () => {
    const data =  {graph: false}
    if (selectedMetrics.isDerived)
      await dispatch(patchMyDerivedDashboardMetrics(selectedMetrics.dashboardMetricId, data))
    else
      await dispatch(patchMyDashboardMetrics(selectedMetrics.dashboardMetricId, data))
  }

  React.useEffect(() => { 
    if(myDashboardMetrics.status !== 'success' || myDerivedDashboardMetrics.status !== 'success' || combinedSelectedMetricsPerYear.status !== 'success') return;

    const metricData = myDashboardMetrics?.data
    .filter((item) => item.graph)
    .map((item) => {
      const selectedMetric = combinedSelectedMetricsPerYear?.data?.find((met: any) => item?.my_metric === met.id)
      const newAllMetricData = {
        ...selectedMetric,
        "dashboardMetricId": item.id,
        "selectedMetricId": selectedMetric?.metric,
        "selecTedMyMetricId": item.my_metric,
        "isDerived": false,
        "position_graph": item.position_graph
      }
      return selectedMetric ? newAllMetricData : null;
    }).filter((item) => item !== null);

    const derivedMetricgraphData = myDerivedDashboardMetrics?.data
    .filter((item) => item.graph)
    .map((item) => {
      const derivedMetric = combinedSelectedMetricsPerYear?.data?.find((met) => item.my_derived_metric === met.id);
      const newAllMetricData = {
        ...derivedMetric,
        "dashboardMetricId": item.id,
        "selectedMetricId": derivedMetric?.derived_metric,
        "selecTedMyMetricId": item.my_derived_metric,
        "isDerived": true,
        "position_graph": item.position_graph
      }
      return derivedMetric ? newAllMetricData : null;
    }).filter((item) => item !== null);

    // we sort the by following order: position_graph, dashboardMetricId, isDerived
    let tempGraphMetrics = [...metricData, ...derivedMetricgraphData].sort((a:any, b:any) => 
      a?.position_graph !== b?.position_graph ? a?.position_graph > b?.position_graph ? 1 : -1 : 
      a?.dashboardMetricId !== b?.dashboardMetricId ? a?.dashboardMetricId > b?.dashboardMetricId ? 1 : -1 : 
      a?.isDerived ? 1 : -1
    ); 
    setGraphMetrics(tempGraphMetrics)
  }, [myDashboardMetrics, myDerivedDashboardMetrics, combinedSelectedMetricsPerYear]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );
  

  const handleDragEnd = async(event: any) => {

    const { active, over } = event;
    if(!over || active.id === over.id) return;

    let [activeType, activeId] = active.id.split('-');
    activeId = parseInt(activeId);
    let [overType, overId] = over.id.split('-');
    overId = parseInt(overId);

    let oldIndex = GraphMetrics.findIndex((item:any) => item.dashboardMetricId === activeId && (item.isDerived ? 'derived' : 'metric') === activeType);
    let newIndex = GraphMetrics.findIndex((item:any) => item.dashboardMetricId === overId && (item.isDerived ? 'derived' : 'metric') === overType);

    let newGraphMetrics = arrayMove(GraphMetrics, oldIndex, newIndex);
    let newPositions = newGraphMetrics.map((item:any, index:number) => ({
      isDerived: item.isDerived,
      id: item.dashboardMetricId,
      position_graph: index
    }));
    dispatch(setDashboardGraphPositions(newPositions));
    
    Promise.all(newGraphMetrics.filter((item) => item.dashboardMetricId !== undefined)
    .map((item:any, index:number) => {
      if(item.isDerived){
        return dispatch(patchMyDerivedDashboardMetrics(item.dashboardMetricId, {position_graph: index},true))
      }else{
        return dispatch(patchMyDashboardMetrics(item.dashboardMetricId, {position_graph: index},true))
      }
    }))

  }

  // Helper function to reorder array (you might have this utility function elsewhere in your codebase)
  const arrayMove = (array: any[], from: number, to: number) => {
    const newArray = [...array];
    const startIndex = to < 0 ? newArray?.length + to : to;
    const item = newArray.splice(from, 1)[0];
    newArray.splice(startIndex, 0, item);
    return newArray;
  };

  function GraphSortableItem({ id, data }: { id: string, data: any }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <DashboardCustomGraph data={data}  setSelectedMetrics={setSelectedMetrics} setOpenDelete={setOpenDelete}/>
      </div>
    );
  }

  if(myDashboardMetrics.status === 'loading' || myDerivedDashboardMetrics.status === 'loading' || combinedSelectedMetricsPerYear.status === 'loading') return <GlobalLoader height='60vh'/>
    
  return (
    <div className="dashboard-custom">
      <div className="dashboard-custom__header">
        <BEButton size='large' className="primary"
          loading={loadingDownloadPDF}
          onClick={async()=> await dispatch(
            generatePDF(setLoadingDownloadPDF, 'custom-container')
          )}
        >
          Download PDF
        </BEButton>
        <BEButton onClick={() => setOpenAddTrends(true)} size='large' className="primary">Add Trends</BEButton>
      </div>
      <AddTrends open={openAddTrends} setOpen={setOpenAddTrends} addGraphs/>
      <div  id="custom-container">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={
          GraphMetrics.map((item:any) => (item.isDerived ? `derived-${item.dashboardMetricId}` : `metric-${item.dashboardMetricId}`))
        } strategy={rectSortingStrategy}>
          <div className='dashboard-custom__graphs'>
            {
              GraphMetrics.map((item:any) => {
                return (
                  <GraphSortableItem
                    key={item.isDerived ? `derived-${item.dashboardMetricId}` : `metric-${item.dashboardMetricId}`}
                    id={item.isDerived ? `derived-${item.dashboardMetricId}` : `metric-${item.dashboardMetricId}`}
                    data={item} 
                  />
                )
              })
            }
          </div>
        </SortableContext>
      </DndContext>
      </div>
    <BEConfirmModal
        title="Confirm Delete"
        message={`Are you sure you want to delete ${selectedMetrics?.title}?`}
        visible={openDelete}
        setVisible={setOpenDelete}
        onConfirm={async () => {
          await removeMetric();
          setOpenDelete(false);
        }}
      />
    </div>
  );
}
{/* <CalculatedDashboardMetrics bcode={data.bcode} /> :
<DashboardLineChart metricName={data.title} dashboardMetricId={data.dashboardMetricId} metricId={data.id} /> */}