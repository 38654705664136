import * as React from 'react';
import { BEDrawer } from '../../../../../../../Components/BEDrawer';
import { Form, Select } from 'antd';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { LableRequired } from '../../../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../../../Components/BEFormItems/BEInput';
import { patchCustomQuestions, postCustomQuestion } from '../../../../../Actions/supplierAction';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { BEInfoBox } from '../../../../../../../Components/BEInfoBox';
import { BETextArea } from '../../../../../../../Components/BEFormItems/BETextArea';
import BEMultipleSelect from '../../../../../../../Components/BEMultipleSelect';

export interface  AddCustomQuestionDrawerProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
  question?: any;
}

export function AddCustomQuestionDrawer (props:  AddCustomQuestionDrawerProps) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const questions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const [attrValue, setAttrValue] = React.useState<any>(null);
  
  React.useEffect(() => {
    if(props.open && props.isEdit){  
      form.setFieldsValue({
        question: props.question.question,
        description: props.question.description,
        pillar: props.question.pillar,
        attribute: props.question.attribute,
        tag: props.question.tag,
      })
      setAttrValue(props.question.attribute);
    }
    else form.resetFields();
  },[props.open])

  const onFinish = async(values: any) => {
    setLoading(true);
    if(props.isEdit)
      await dispatch(patchCustomQuestions(props?.question?.id,values));
    else
      await dispatch(postCustomQuestion(values));
    setLoading(false);
    props.setOpen(false);
  };

  return (
    <BEDrawer
      heading="Add Custom Question"
      open={props.open}
      setOpen={props.setOpen}
      width="400"
      footer={
        <>
          <BEButton
            className="primary"
            size="large"
            onClick={() => form.submit()}
            loading={loading}
          >
            Save
          </BEButton>

          <BEButton
            size="large"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </BEButton>
        </>
      }
    >
      <BEInfoBox 
        title={"Note"}
        description={
          <p>
            Please frame your question so that it can be answered with 
            "Yes" or "No."
          </p>
        }
      />
      <Form form={form} onFinish={onFinish}>
        <LableRequired > Question </LableRequired>
        <Form.Item
          name="question"
          rules={[{ required: true, message: 'Please enter Question' }]}
          >
            <BEInput maxLength={1024} placeholder="Enter Question" />
        </Form.Item>

        <p>Description</p>
        <Form.Item
          name="description"
          >
            <BETextArea form={form} placeholder="Enter Description" />
        </Form.Item>

        <p>Pillar</p>

        <BEMultipleSelect 
          name='pillar' 
          single
          placeholder="Select Pillar" 
          rules={[{ required: true, message: 'Please select Pillar' }]}
          isDataStrArray 
          data={['Environment','Social','Governance']}
        />

        <p>Attribute</p>
        <Form.Item
          name="attribute"
          >
            <Select 
              placeholder="Select Attribute"
              allowClear
              onSearch={(value) => setAttrValue(value)}
              searchValue={attrValue}
              showSearch
              optionFilterProp="children"
            >
              {
                !attrValue ||
                Array.from(new Set(
                  questions.data.map((item: any) => item.attribute)
                )).includes(attrValue) ? null :
                <Select.Option key={attrValue} value={attrValue}>
                  {attrValue}
                </Select.Option>
              }
              {
                Array.from(new Set(
                  questions.data.map((item: any) => item.attribute)
                )).map((item: any) => 
                  <Select.Option key={item} value={item?.trim()}>
                    {item?.trim()}
                  </Select.Option>
                )
              }
            </Select>
        </Form.Item>

        <p>Tag</p>
        <Form.Item
          name="tag"
          >
            <BEInput placeholder="Enter Tag" />
        </Form.Item>
      </Form>
        
    </BEDrawer>
  );
}
