import * as React from 'react';
import { BEEventSummaryPanel } from '../../../../../../../Components/BEEventManagement/BEEventSummaryPanel';
import { BEEvidenceList } from '../../../../../../../Components/BEEventManagement/BEEvidenceList';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { InvestigationForm } from './InvestigationForm';
import { Row, Col, FormInstance } from 'antd';
import './styles.scss';
import { EVENT_OWNER, LEAD_INVESTIGATOR } from '../../../../../../../data/eventManager';
import EmptyEmissions from '../../../../../../../assets/images/OnboardingImages/EmptyDashboard.png';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';

export interface IEventInvestigationProps {
  form: FormInstance<any>
  showInvestigationForm: boolean;
}

export function EventInvestigation(props: IEventInvestigationProps) {
  const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
  const eventEvidences = useTypedSelector(state => state.eventManager.eventEvidences);
  const eventUsers = useTypedSelector(state => state.eventManager.eventUsers);
  const users = useTypedSelector(state => state.userMgmt);
  const [investigationTeam, setInvestigationTeam] = React.useState<any[]>([]);

  React.useEffect(() => {
    const investigationTeam = currentEvent?.investigation_team?.split(',');
    if (investigationTeam && investigationTeam?.length > 0) {
      const team = eventUsers?.data?.filter((user: any) => investigationTeam?.includes(String(user.id)));
      if (team) {
        setInvestigationTeam(team);
      }
    } else {
      setInvestigationTeam([]);
    }
  }, [currentEvent, eventUsers]);


  return (
    <div className='event-investigation'>
      {
        <>
          {
            ((!(currentEvent?.root_cause) || currentEvent?.stage === 6) && currentEvent?.event_user_role === LEAD_INVESTIGATOR) ?
              <>
                {!props.showInvestigationForm && currentEvent?.rejection_reason ?
                  <div style={{ margin: '1rem' }}>
                    <h2 style={{ color: PrimaryTheme.primaryRedError }}>Rejection Reason</h2>
                    <p style={{ marginTop: '1rem' }}>{currentEvent?.rejection_reason}</p>
                  </div>
                  : 
                  <InvestigationForm form={props.form} />
                }
              </>
              : (currentEvent?.event_user_role === EVENT_OWNER && currentEvent?.stage === 6) ?
                <div className='rejected-investigation' >
                  <h3>Report Rejected</h3>
                  <br />
                  <p style={{color:PrimaryTheme.primaryGray}}>
                    {currentEvent?.rejection_reason}
                  </p>
                </div>
                :
                (currentEvent?.event_user_role === EVENT_OWNER || currentEvent?.event_user_role === LEAD_INVESTIGATOR) ?
                  <>
                    {!currentEvent?.root_cause && currentEvent?.event_user_role === EVENT_OWNER
                      ?
                      <div className='investigation-details-container'>
                        <div style={{ marginTop: '1.2rem' }}>
                          <BEEventSummaryPanel
                            width='max-content'
                            eventSummary={[
                              {
                                title: 'Investigation Lead',
                                value: users?.data?.filter((user) => user.id === currentEvent?.lead_investigator).map((user) => user?.first_name + ' ' + user.last_name)
                              },
                              {
                                title: 'Investigation Deadline',
                                value: currentEvent.deadline
                              }
                            ]}
                          />
                        </div>
                        <div className='investigation-team-container'>
                          <p style={{ color: PrimaryTheme.primaryGray }}>Team</p>
                          <div className='investigation-team' >
                            {
                              investigationTeam.map((user) => {
                                return (
                                  <div className='team-member' >
                                    {user.first_name} {user.last_name}
                                  </div>)
                              })
                            }
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    {!currentEvent?.root_cause ?
                      <div className='empty-investigation-container'>
                        <img height={'150px'} width={'150px'} src={EmptyEmissions} alt="" />
                        <p style={{ marginTop: '1rem' }}>The investigation documents are not uploaded yet</p>
                      </div>
                      :
                      <>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                          <BEEventSummaryPanel
                            width='20%'
                            eventSummary={[
                              {
                                title: 'Investigation Lead', value: String(users.data.filter((user) => user.id === currentEvent?.lead_investigator).map((user) => {
                                  return user?.first_name + ' ' + user.last_name
                                }
                                ))
                              },
                              {
                                title: 'Investigation Deadline',
                                value: String(currentEvent?.deadline)
                              },
                              {
                                title: 'Team members',
                                value: String(currentEvent?.investigation_team?.split(',').map((id) => {
                                  const user = eventUsers.data?.find((user) => user.id === Number(id))
                                  if(!user) return ''
                                  return user?.first_name + ' ' + user?.last_name
                                }))
                              },

                            ]}
                          />
                          <BEEvidenceList
                            width='80%'
                            evidenceList={currentEvent?.investigation_report ? [currentEvent?.investigation_report] : []}
                          />
                        </div>
                        <br />
                        <Row>
                          <Col span={11}>
                            <div className='investigation-body-summary'>
                              <p className='investigation-body-summary-title'>
                                Root cause
                              </p>
                              <br />
                              <p>
                                {currentEvent?.root_cause}
                              </p>
                            </div>
                          </Col>
                          <Col offset={1} span={12}>
                            <div className='investigation-body-summary'>
                              <p className='investigation-body-summary-title'>
                                Contributory cause
                              </p>
                              <br />
                              <p>
                                {currentEvent?.contributing_factors}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </>
                    }
                  </>
                  :
                  null
          }
        </>
      }
    </div>
  );
}
