import * as React from 'react';
import './style.scss';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import DragIcon from '../../../../../Components/BEIcons/DragIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import { MetricIconNameList } from '../../../../../views/Onboarding/Step4/SideFilter/SIdeItem';
import { CalculatedCustomStackedGraph } from './CalculatedCustomStackedGraph';
import { Select } from 'antd';
import { DashboardCustomBarGraph } from './BarGraph';

export interface IDashboardCustomGraphProps {
  data:any;
  setSelectedMetrics: Function;
  setOpenDelete: Function;
}



export function DashboardCustomGraph (props: IDashboardCustomGraphProps) {
    const [icon, setIcon] = React.useState<any>(null);
    const [graphType, setGraphType] = React.useState<string>('Bar Graph');
    const isRatioUnit = (unit:string) =>{
      let ratioUnits = ['percentage','ratio']
      if(ratioUnits.includes( unit.toLowerCase().trim() )) return true;
      return false;
    }

    React.useEffect(() => {
      if(!props.data) return;
      let TempIcon  = MetricIconNameList.find((item) => item.name === props.data.category)?.icon || MetricIconNameList[0]?.icon;
      setIcon(<TempIcon stroke={'black'} inheritSize/>)
    }, [props.data]);


  
    return (
      
    <div className='dashboard-custom-graph'>
      <div className='custom-graph-header'>
        <div className='custom-graph-delete-icon'
          onMouseDown={() => {
            props.setSelectedMetrics(props.data);
            props.setOpenDelete(true);
          }}
        >
          <DeleteIcon stroke={PrimaryTheme.primaryGray} inheritSize/>
        </div>
        <div className='custom-graph-drag-icon'>
          <DragIcon fill={PrimaryTheme.primaryGray} inheritSize/>
        </div>
      </div>
      <BEWrapper type={2} title={props.data?.custom_title || props.data.title} icon={icon}>
       <div className='custom-graph-type'>
          <Select
            value={graphType}
            disabled={!props.data?.isDerived || isRatioUnit(props.data?.unit)}
            onChange={(value) => {
              console.log(value)
              setGraphType(value)}}
          >
          {
            ['Bar Graph','Stacked Bar Graph'].map((item) => {
              return <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            })
          }
          </Select>
       </div>
        {
          graphType === 'Stacked Bar Graph' ?
            <CalculatedCustomStackedGraph data={props?.data}/>
          :
            <DashboardCustomBarGraph data={props?.data}/>
        } 
            </BEWrapper>

    </div>
  );
}
