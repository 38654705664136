import * as React from 'react';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getDashoardInsightsAlertMessages } from '../../../Actions';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { BEPagination } from '../../../../../Components/BEPagination';
import AttentionTasksIcon from '../../../../../Components/BEIcons/AttentionTasksIcon';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

interface IAlertMessagesProps {
}

export function AlertMessages(props: IAlertMessagesProps) {
	const dispatch = useAppDispatch();
	const [alertMessages, setAlertMessages] = React.useState<any[]>([])
	const userRole = useTypedSelector(state => state.user.userRole);
	const [loading, setLoading] = React.useState(false);
	const [page, setPage] = React.useState(1);

	const getAlertMessages = async () => {
		setLoading(true)
		const dataResponse = await dispatch(getDashoardInsightsAlertMessages());
		if (dataResponse.data) {
			const messages = dataResponse.data.map((item: any) => ({
				message: item.message,
				status: item.status,
				resolve: item.user_to_remind ? true : false
			})).filter((item: any) => item.resolve)
			setAlertMessages(messages)
		}
		setLoading(false)
	}

	React.useEffect(() => {
		getAlertMessages()
	}, [])

	if(userRole === "AUDITOR") return null;
	return (
		<div>
			<BEWrapper
				style={{ padding: '0rem' }}
				type={2}
				icon={<AttentionTasksIcon inheritSize/>}
				title='Tasks that need your attention'
			>
				{
					loading ? <GlobalLoader height='50vh' /> :
						<div className='alert-message-container'>
							{
								alertMessages.slice((page - 1) * 5, page * 5).
								map((item: any) => (
									<div className={'alert-message'}>
										<div className='message'>
											<p>{item.message}</p>
										</div>
										<BEButton
											size='middle'
											className='secondary-green'
										>
											Resolve
										</BEButton>
									</div>
								))
							}
						</div>
				}
					  <div style={{display:'flex',justifyContent:'center',padding:'1rem',}}>
						  <BEPagination total={alertMessages?.length} pageSize={5} onChange={(page) => setPage(page)} />
					  </div>
			</BEWrapper>
		</div>
	);
}
