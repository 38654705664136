import * as React from 'react';
import './style.scss';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector'
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import {
  getSuppliers,
  deleteGlobalSupplier,
  bulkUploadGlobalSuppliersByExcel
} from '../../Actions/supplierAction';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { Col, Row } from 'antd';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import AddSupplier from '../Drawers/AddSupplier';
import InviteSuppliers from '../Drawers/InviteSuppliers';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import Upload from "antd/es/upload/Upload";
import { bulkUploadSuppliers } from '../../Actions/supplierAction';
import DownloadTemplate from "../../../../Utils/Download/Excell/DownloadTemplate";
import { BEBulkUploadDrawer } from '../../../../Components/BEDrawer/BEBulkUploadDrawer';
import { BEPagination } from '../../../../Components/BEPagination';
import { BEDrawer } from '../../../../Components/BEDrawer';
import InfoIcon from '../../../../Components/BEIcons/InfoIcon';
import EditIcon from '../../../../Components/BEIcons/EditIcon';

export interface IAllSuppliersProps {
}


export function AllSuppliers(props: IAllSuppliersProps) {
  const dispatch = useAppDispatch();
  const suppliers = useTypedSelector(state => state.supplier.Suppliers);
  const [openAddSupplier, setOpenAddSupplier] = React.useState(false);
  const [openBulkUpload, setOpenBulkUpload] = React.useState(false);
  const [openInvite, setOpenInvite] = React.useState(false)
  const [filteredSuppliers, setFilteredSuppliers] = React.useState<any>([])
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = React.useState(false)
  const [selectedSupplier, setSelectedSupplier] = React.useState<any>(null)
  const [page, setPage] = React.useState(1);
  const [openPartnerDescriptionDrawer, setOpenPartnerDescriptionDrawer] = React.useState(false);
  const [partnerDescription, setPartnerDescription] = React.useState({ title: '', desc: '' });
  const [currentSupplier, setCurrentSupplier] = React.useState<any>(null);
  const [openEditSupplier, setOpenEditSupplier] = React.useState(false);

  const bulkUpload = async (file: any) => {
    const formData = {
      SUPPLIERS: file,
    }
    await dispatch(bulkUploadSuppliers(formData));
  }

  React.useEffect(() => {
    dispatch(getSuppliers());
  }, []);

  React.useEffect(() => {
    if (suppliers?.status === 'success') {
      setFilteredSuppliers(suppliers?.data)
    }
  }, [suppliers])

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Point of Contact',
      dataIndex: 'poc',
      key: 'poc',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Partner Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
          <div 
            style={{ height: '1rem'}} 
            onMouseEnter={() => setCurrentSupplier(record)}
            onClick={() => {
              setOpenEditSupplier(true);
            }}
          >
            <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
          </div>
          <div style={{ height: '1rem'}} onClick={() => setOpenDeleteConfirmationModal(true)} onMouseEnter={() => setSelectedSupplier(record)}>
            <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
          </div>
          <div style={{ height: '1rem'}} onClick={() => {
            setPartnerDescription({ title: '', desc: record.last_compliance_fy });
            setOpenPartnerDescriptionDrawer(true);
          }}>
            <InfoIcon inheritSize fill={PrimaryTheme.primaryGray} />
          </div>
        </div>
      )
    }

  ];

  return (
    <div className="all-suppliers">
      <Row style={{ margin: '0 auto' }}>
        <Col span={8}><BEInput search size="large" placeholder="Search for partners" onChange={(e) => {
          const filtered = suppliers?.data.filter((sup) => sup.name?.toLowerCase().includes(e.target.value?.toLowerCase()))
          setFilteredSuppliers(filtered)
        }} /></Col>
        <Col span={8}></Col>
        <Col span={8} style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
          {/* <BEButton size="large" onClick={() => setOpenInvite(true)} style={{marginRight: '1rem'}}>
            + Invite to Assessment
          </BEButton> */}
          <BEButton className="primary" size="large" onClick={() => setOpenAddSupplier(true)}>
            + Add Partner(s)
          </BEButton>

          {/* <BEButton size="large" className="primary"
              onClick={()=>{
                DownloadTemplate(['name','poc','email','industry', 'internal', 'last_compliance_year'])
              }}
            >
              Download Template
          </BEButton>

          <Upload
                beforeUpload={(e) => {
                  bulkUpload(e);
                  return false;
                }}
                action={undefined}
                showUploadList={false}
                multiple={false}
                fileList={[]}
              >
                {" "}
                <BEButton size="large" className="primary">
                 Bulk Upload
                </BEButton>
            </Upload> */}
          <BEButton size="large" className="primary"
            onClick={() => setOpenBulkUpload(true)}
          >Bulk Upload</BEButton>

        </Col>
      </Row>

      <AddSupplier open={openAddSupplier} setOpen={setOpenAddSupplier} />
      <InviteSuppliers open={openInvite} setOpen={setOpenInvite} />
      <BETable
        columns={columns}
        data={filteredSuppliers?.slice((page-1) * 10, page * 10).filter((sup: any) => { return sup.internal === false })}
        loading={suppliers?.status === "loading"}
      />
      <div style={{display:'flex',justifyContent:'center',padding:'1rem'}}>
          <BEPagination total={filteredSuppliers?.length} pageSize={10} onChange={(page) => setPage(page)} />
      </div>
      <BEConfirmModal
        title="Delete Supplier"
        message="Are you sure you want to delete this partner?"
        visible={openDeleteConfirmationModal}
        setVisible={setOpenDeleteConfirmationModal}
        onConfirm={async () => { await dispatch(deleteGlobalSupplier(selectedSupplier.id)) }}

      ></BEConfirmModal>
      <BEBulkUploadDrawer
        fileKey='SUPPLIERS'
        open={openBulkUpload}
        setOpen={setOpenBulkUpload}
        heading="Bulk Upload Partners"
        uploadFunction={bulkUploadGlobalSuppliersByExcel}
        columnArray={['name', 'poc', 'email', 'industry']}
        fileName='SuppliersTemplate'
      />
      <BEDrawer width='500px' heading={'Last compliance FY'} open={openPartnerDescriptionDrawer} setOpen={setOpenPartnerDescriptionDrawer}>
        <p style={{ fontWeight: '600', fontSize: '1rem' }}>
          {partnerDescription.title}
        </p>
        <p style={{ fontSize: '0.9rem', marginTop: '1rem' }}>{partnerDescription.desc}</p>
      </BEDrawer>
      <AddSupplier
        open={openEditSupplier}
        setOpen={setOpenEditSupplier}
        isEdit={true}
        supplier={currentSupplier}
      />
    </div>
  );
}
