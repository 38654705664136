import ArrowDownIcon from '../../../../Components/BEIcons/ArrowDownIcon';
import ToolTip from '../../../../Components/BEToolTip';
import { getUnitShortForm } from '../../../../Config/Data/genralData';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { ProgressBar } from './ProgressBar';
import './style.scss'

interface TargetProgressBarProps {
	targetType: string;
	progress: number;
	baseLineValue: number;
	targetValue: number;
	currentValue: number;
	unit?: string;
}
const TargetProgressBar = (props: TargetProgressBarProps) => {
	const isTargetTypeAC = props.targetType === 'A' || props.targetType === 'C';
	const newPercentage = Math.max(0, Math.min(100, (props.progress))) || 0;


	return (
		<div className="progress-bar-container">
			<div>
				{/* <p className='small-txt'> Target: {AddCommaToNumber(props.targetValue, false, true, true)} {props.unit ? getUnitShortForm(props.unit) : ''}</p> */}
				<p className='small-txt'>
					{
						newPercentage > 0 ?
							(newPercentage === 100 ? 
									<>Target met</>:
									<>Increasing towards</>
							): 
							<>Moving away from </>
							}
							{' : '}
							{AddCommaToNumber(props.currentValue, false, true, true)} {props.unit ? getUnitShortForm(props.unit) : ''}
				</p>
			</div>
			<ToolTip title={
				<div>
					<p style={{ fontSize: 'small' }}>Baseline value : {AddCommaToNumber(props.baseLineValue,false,true,true)}</p>
					<p style={{ fontSize: 'small' }}>Target value : {AddCommaToNumber(props.targetValue,false,true,true)}</p>
					<p style={{ fontSize: 'small' }}>Current value : {AddCommaToNumber(props.currentValue,false,true,true)}</p>
				</div>
			}>
				<div className='progress-bar-progress-container'>
					<ProgressBar progressPercentage={newPercentage} />
					<div className={`progress-bar-arrow ${isTargetTypeAC?'up':''}`} >
						<ArrowDownIcon fill={newPercentage>0?PrimaryTheme.primaryGreen:PrimaryTheme.primaryRedError} inheritSize />
					</div>
				</div>
			</ToolTip>
		</div>
	)
}

export default TargetProgressBar