import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import SettingsIcon from '../../../../Components/BEIcons/SettingsIcon';
import Layers from '../../../../Components/BEIcons/Layers';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import IntegrationIcon from '../../../../Components/BEIcons/IntegrationIcon';
import NotificationIcon from '../../../../Components/BEIcons/NotificationIcon';
import LabelIcon from '../../../../Components/BEIcons/labelcon';
import DownloadIcon from '../../../../Components/BEIcons/DownloadIcon';
import { useLocation } from 'react-router-dom';
import LimitsIcon from '../../../../Components/BEIcons/LimitsIcon';
import { YearSelectOptions } from '../../../../Components/BEMultipleSelect/YearSelectOptions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setDateSlicerYearDM } from '../../../../Redux/DataManagerReducer';
import { BENotify } from '../../../../Components/BENotify';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import EntityManager from '../../../../Components/BEIcons/EntityManager';
import { MonthsSlicer } from '../../../DataManager/Components/TopSectionDM/MonthsSlicer';

export interface ITopSectionProps {
}

export function TopSection(props: ITopSectionProps) {
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const year = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const settingsTabs = [
        {
            name: "General Settings",
            icon: (
                <SettingsIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/general-settings'
        },
        {
            name: "Users",
            icon: (
                <UsersIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/users'
        },
        {
            name: "Vault",
            icon: (
                <Layers
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/vault'
        },
        {
            name: "Logs",
            icon: (
                <IntegrationIcon
                    fill={PrimaryTheme.primaryGreen}
                // inheritSize
                />
            ),
            path: '/home/settings/logs'
        },
        {
            name: "Notifications",
            icon: (
                <NotificationIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/notifications'
        },
        {
            name: "Permissions",
            icon: (
                <IntegrationIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/permissions'
        },
        {
            name: "Labels",
            icon: (
                <LabelIcon
                    fill={PrimaryTheme.primaryGreen}
                // inheritSize
                />
            ),
            path: '/home/settings/labels'
        },
        {
            name: "Data Download",
            icon: (
                <DownloadIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/data-download'
        },
        {
            name: "Limits",
            icon: (
                <LimitsIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/limits'
        },
        {
            name: "Entity Manager",
            icon: (
                <EntityManager
                    stroke={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/entity-manager'
        },
        {
            name: "Integrations",
            icon: (
                <IntegrationIcon
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings/integrations'
        },
        {
            name: "Overview",
            icon: (
                <Layers
                    fill={PrimaryTheme.primaryGreen}
                    inheritSize
                />
            ),
            path: '/home/settings'
        }
    ];

    React.useEffect(() => {
        const path = location.pathname;
        const tab = settingsTabs?.find(tab => path.includes(tab.path));
        if (tab) {
            setActiveTab(settingsTabs.indexOf(tab));
        }
    }, [location]);

    return (
        <div className='global-top-section' style={{ paddingBottom: '1.6rem' }}>
            <div
                style={{
                    fontSize: "1rem",
                    color: PrimaryTheme.primaryGreen,
                    paddingLeft: "1.5rem",
                    height: "1.25rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem"
                }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {settingsTabs[activeTab].icon}
                </span>
                <span>
                    {settingsTabs[activeTab].name.toUpperCase()}
                </span>
            </div>
            {(
                location.pathname.includes('/home/settings/limits') ||
                location.pathname.includes('/home/settings/labels')
            ) &&
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        marginRight: "1.5rem",
                        gap: "0.5rem",
                        height: '1rem',
                    }}
                >
                    <YearSelectOptions onChange={(value: any) => {
                        dispatch(setDateSlicerYearDM(value))
                        BENotify.success(`${value}`, `You will now be viewing data for FY${value}.`, () => { }, false);
                    }} value={year} />

                </div>
            }
            {(
                location.pathname.includes('/home/settings/data-download')
            ) &&
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                        marginRight: "1.5rem",
                        gap: "0.5rem",
                        height: '1rem',
                    }}
                >
                    <MonthsSlicer />
                </div>
            }
        </div>
    );
}
