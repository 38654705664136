import { 
  //types
  BUSINESS_STAY, 
  BUSINESS_TRANSPORT, 
  DOWNSTREAM_LOGISTICS, 
  END_OF_LIFE_WASTE_TREATMENT,
  UPSTREAM_LOGISTICS, 
  WASTE_GENERATED,
  ASSET_BASED,
  UPSTREAM_LEASED_ASSET,
  DOWNSTREAM_LEASED_ASSET,
  FUEL_AND_ENERGY_RELATED,
  //basedOns
  PASSENGER_DISTANCE_BASED, 
  PROCUREMENT, QUANTITY_BASED, 
  SPEND_BASED,
  FREIGHT_DISTANCE_BASED,
  ENERGY_QUANTITY_BASED,
  ROOM_NIGHT_BASED, 
  FUEL_BASED,
  HYBRID_BASED,
  FRANCHISES,
  FRANCHISE_BASED,
  PURCHASED_ENERGY,
  USE_OF_SOLD_PRODUCTS
} from "./constants";

//scope 3 names
export const TypeData: any = {
  [PROCUREMENT]: { 
    code: 1,
    feildName: 'procurement',
   },
  [BUSINESS_TRANSPORT]: { 
    code: 2,
    feildName: 'business_transport',
   },
  [BUSINESS_STAY]: { 
    code: 3,
    feildName: 'business_stay',
   },
  [UPSTREAM_LOGISTICS]: { 
    code: 4,
    feildName: 'upstream_logistics',
   },
  [DOWNSTREAM_LOGISTICS]: { 
    code: 5,
    feildName: 'downstream_logistics',
   },
  [WASTE_GENERATED]:{
    code:6,
    feildName: 'waste_generated',
  },
  [END_OF_LIFE_WASTE_TREATMENT]:{
    code:7,
    feildName: 'end_of_life_waste_treatment',
  },
  [UPSTREAM_LEASED_ASSET]:{
    code:8,
    feildName: 'upstream_leased_assets',
  },
  [DOWNSTREAM_LEASED_ASSET]:{
    code:9,
    feildName: 'downstream_leased_assets',
  },
  [FUEL_AND_ENERGY_RELATED]:{
    code:10,
    feildName: 'fuel_and_energy_related',
  },
  [FRANCHISES]:{
    code:11,
    feildName: 'franchises',
  },
  [USE_OF_SOLD_PRODUCTS]:{
    code:12,
    feildName: 'use_of_sold_products',
  }
}

//scope 1 and 2
export const namesToShow:any = {
  'Scope 1 stationary combustion emissions':'Stationary Combustion',
  'Scope 1 mobile combustion':'Mobile Combustion',
  'Scope 1 fugitive emissions':'Fugitive Emissions',
  'Scope 2 emissions':PURCHASED_ENERGY,
  'Scope 2 Emissions (Market-based)':PURCHASED_ENERGY,
  'scope_1_2':'Scope 1 + 2',
  'scope_1_2_3':'Scope 1 + 2 + 3',
  'Electricity':PURCHASED_ENERGY,
}

export const TypeNameMap: any = {}
Object.keys(TypeData).map((key) => {
  TypeNameMap[TypeData[key].feildName] = key
})

export const getEmissionCategoryNameToShow = (name:string,isScope2MarketBased?:boolean) => {
  if(namesToShow[name]){
     if(isScope2MarketBased !== undefined && namesToShow[name] === PURCHASED_ENERGY){
       return isScope2MarketBased ? PURCHASED_ENERGY + ' (Market-based)' : PURCHASED_ENERGY + ' (Location-based)';
     }
     return namesToShow[name];
  }
  if(TypeNameMap[name]) return TypeNameMap[name];
  return name;
}