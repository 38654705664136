import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import StackedColumnCharts from '../../../../../Components/BECharts/StackedColumnCharts';
import { rotateArray } from '../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../Config/Data/genralData';

export interface IDashboardMonthlySourceEmissionsProps {
}

export function DashboardMonthlySourceEmissions (
    props: IDashboardMonthlySourceEmissionsProps
) {
    const [graphData, setgraphData] = React.useState<any>([])
    const scope1FilteredEmissions = useTypedSelector(
        (state) => state.emissions.filteredEmissionCalculations.scope1Calculations
    );
    const scope2FilteredEmissions = useTypedSelector(
        (state) => state.emissions.filteredEmissionCalculations.scope2Calculations
    );
    const scope3FilteredEmissions = useTypedSelector(
        (state) => state.emissions.filteredEmissionCalculations.scope3Calculations
    );
    const yearEnd = useTypedSelector(
        (state) => state.companies.companyDetails.data?.reporting_year_end
    );  
    const yearEndMonth = useTypedSelector(state => state.companies.yearEnd);

    React.useEffect(() => {
        if (
            scope1FilteredEmissions.status !== 'success' ||
            scope2FilteredEmissions.status !== 'success' ||
            scope3FilteredEmissions.status !== 'success'
        )  return;
        let tempData: any = {};
        const combinedData = [
        ...scope1FilteredEmissions.data,
        ...scope2FilteredEmissions.data,
        ...scope3FilteredEmissions.data,
        ];
        // segrigate the data by emission_category and month

        combinedData.forEach((item) => {
        if (!tempData[item.emission_category]) {
            tempData[item.emission_category] = {
            name: item.emission_category,
            data: Array(12).fill(0),
            };
        }
        tempData[item.emission_category].data[item.month - 1] += item.tco2e;
        });

        let tempGraphData = [];
        for (let key in tempData) {
        tempGraphData.push({
            name: key,
            data: rotateArray(tempData[key].data,yearEndMonth,true),
            });
        }
        setgraphData(tempGraphData);
    },[scope1FilteredEmissions, scope2FilteredEmissions, scope3FilteredEmissions])

    return (
        <div className='dashboard-monthly-source-emissions'>
            <BEWrapper type={2} title='GHG Emissions by Source and Month'>
                <StackedColumnCharts
                    colorTheme={5}
                    xAxisLabels={rotateArray(shortMonths, yearEndMonth,true)}
                    yAxisLabel='tCO2e'
                    data={graphData}
                />
            </BEWrapper>
        </div>
    );
}
