import * as React from 'react';
import CheckIcon from '../../../../../Components/BEIcons/CheckIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';

interface IStep3Props {
	businessUnit: string | null
	handleAddNewEvent: () => void
}

export function Step3(props: IStep3Props) {
	const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
	return (
		<div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90dvh' }}>
			<div style={{ height: '5rem', width: '5rem', backgroundColor: PrimaryTheme.primaryGreenLight, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CheckIcon stroke={PrimaryTheme.primaryGreen} />
			</div>
			<p style={{ fontSize: '1rem', fontWeight: '400', marginTop: '1rem' }}>
				Event punched successfully {businessUnits.data?.find(bu => bu.id === props.businessUnit)?.name}
			</p>
			<h3>{props.businessUnit ? businessUnits.data?.find(bu => bu.id == props.businessUnit)?.name : ''}</h3>
			<BEButton
				className='secondary-green'
				style={{ marginTop: '1rem' }}
				onClick={props.handleAddNewEvent}
			>
				Add new event
			</BEButton>
		</div>
	);
}
