import * as React from 'react';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { navigateTo } from '../../../Navigation/NavigationManager';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import './styles.scss';
import { EventUsers } from './EventUsers';
import UsersIcon from '../../../Components/BEIcons/UsersIcon';

export interface IEventManagementSettingsProps {
}

export function EventManagementSettings(props: IEventManagementSettingsProps) {
  // const activeState = useTypedSelector((state) => state.settings.activeTab);
  const role = useTypedSelector((state) => state.user.userRole);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [activeKey, setActiveKey] = React.useState<number>(0);

  React.useEffect(() => {
    if (window.location.pathname === '/events/settings') {
      // dispatch(setSettingsActiveTab(0));
      setActiveKey(0);
    }
  }, [window.location.pathname])

  const settingsTabs = [
    {
      key: 1,
      name: "Users",
      icon: (
        <UsersIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/events/settings/users',
      description: "Manage platform users with ease. Add new users individually or through bulk upload for efficient onboarding."
    }
  ];

  return (
    <div className="settings">
      {/*<TopSection />*/}
      {activeKey === 1 && <EventUsers />}
      {activeKey === 0 &&
        <div>
          {/* <TopSection/> <br/> */}
          <div style={{ display: 'flex' }}>
            <BEInput
              style={{ width: '313px', margin: '0 0 1rem 1.5rem' }}
              search size='large' placeholder='Search here' value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="settings-container">
            {
              settingsTabs.
                map((tab, index) => (
                  <div
                    key={index}
                    className='new-folder'
                    onClick={() => {
                      // dispatch(setSettingsActiveTab(tab.key));
                      setActiveKey(tab.key);
                      navigateTo(tab.path);
                    }}
                  >
                    <div className='circle'>
                      <div className='settings-icon'>
                        {tab.icon}
                      </div>
                    </div>
                    <span style={{ fontSize: '14px', lineHeight: "21px", fontWeight: "600" }}>{tab.name}</span>
                    <span style={{ fontSize: '13px', color: PrimaryTheme.primaryGray }}>{tab.description}</span>
                  </div>
                ))
            }

          </div>
        </div>
      }
    </div>
  );
}
