import React from 'react'
import IconProps from './Interface/IconProps';

const MailIcon = (props: IconProps) => {
	return (
		<svg
			width={props.inheritSize ? "100%" : 20}
			height={props.inheritSize ? "100%" : 20}
			viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M19 0.5H1C0.801088 0.5 0.610322 0.579018 0.46967 0.71967C0.329018 0.860322 0.25 1.05109 0.25 1.25V14C0.25 14.3978 0.408035 14.7794 0.68934 15.0607C0.970644 15.342 1.35218 15.5 1.75 15.5H18.25C18.6478 15.5 19.0294 15.342 19.3107 15.0607C19.592 14.7794 19.75 14.3978 19.75 14V1.25C19.75 1.05109 19.671 0.860322 19.5303 0.71967C19.3897 0.579018 19.1989 0.5 19 0.5ZM17.0716 2L10 8.48281L2.92844 2H17.0716ZM18.25 14H1.75V2.95531L9.49281 10.0531C9.63118 10.1801 9.81217 10.2506 10 10.2506C10.1878 10.2506 10.3688 10.1801 10.5072 10.0531L18.25 2.95531V14Z"
				fill={props.fill ? props.fill : 'white'}
			/>
		</svg>

	);
}

export default MailIcon