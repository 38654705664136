import * as React from 'react';
import useOutsideClick from '../../../../Config/Hooks/useOutsideClick';
import Logo from '../../../../assets/images/Company/ESG_LOGO_WHITE.svg';
import ZeroLogo from '../../../../assets/images/Company/BZ_LOGO_WHITE.svg';
import SAFELOGO from '../../../../assets/images/Company/BS_LOGO_WHITE.svg';
import AllAppsIcon from '../../../../Components/BEIcons/AllAppsIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import WebIcon from '../../../../Components/BEIcons/WebIcon';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getNewToken } from '../../../Actions/LoginActions';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { setShowEventsMenu } from '../../../../Redux/CommonReducer';
import { setCurrentModule } from '../../../../Redux/UserReducer';

interface IAppsDetailProps {
}

export function AppsDetail(props: IAppsDetailProps) {
	const [showAppsDropdown, setShowAppsDropdown] = React.useState(false);
	const modules = useTypedSelector((state) => state.settings.modules);
	const currentModule = useTypedSelector(state => state.user.currentModule);
	const dispatch = useAppDispatch();
	const [userModules, setUserModules] = React.useState<any[]>([]);
	const userProfile = useTypedSelector((state) => state.user.userProfile);
	const wrapperRef = React.useRef(null);
	
	React.useEffect(() => {
		if (modules.data.length > 0) {
			let moduleCodes = modules.data.map((module: any) => module.module_code);
			let myModuleCodes: string[] = [];
			for (let key in userProfile.data) {
				if (moduleCodes.includes(key)) {
					myModuleCodes.push(key);
				}
			}
			let tempModuleMenu: any = [];
			modules.data.forEach((module: any) => {
				if (myModuleCodes.includes(module.module_code)) {
					tempModuleMenu.push(module.module_code);
				}
			});
			setUserModules(tempModuleMenu);
		}
	}, [modules, userProfile, currentModule]);

	useOutsideClick(wrapperRef, () => setShowAppsDropdown(false));

	const goToZero = async () => {
		const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
		const token = await dispatch(getNewToken());
		if (!token) return;
		const querryString = `?token=${JSON.stringify(token)}&user_info=${JSON.stringify(userInfo)}`
		const esgUrl = process.env.REACT_APP_ZERO_URL;
		window.open(`${esgUrl}/login${querryString}`, '_blank');
	  }

	const items = [
		{
			key: 'ESG',
			label: 'ESG',
			icon: Logo,
			onClick: () => {
				dispatch(setShowEventsMenu(false));
				dispatch(setCurrentModule('ESG'));
				navigateTo('/home/dashboard');
			}
		},
		{
			key: 'ZERO',
			label: 'ZERO',
			icon: ZeroLogo,
			onClick: () => {
				goToZero();
			}
		},
		{
			key: 'EVENT',
			label: 'SAFE',
			icon: SAFELOGO,
			onClick: () => {
				dispatch(setCurrentModule('EVENT'));
				dispatch(setShowEventsMenu(true));
				navigateTo('/events/event-manager');
			}
		},
		{
			key: 4,
			label: 'ACADEMY',
			icon: ZeroLogo,
			onClick: () => {
				// console.log('Logout')
			}
		}
	]
	return (
		<div 
			style={{ 
				marginLeft: 'auto',
				position: 'relative' 
			}}
			ref={wrapperRef}
		>
			<div
				style={{ 
					backgroundColor: showAppsDropdown ? 
						PrimaryTheme.primaryGreyLight : '' 
				}}
			 	className='all-apps-icon'
				onClick={() => setShowAppsDropdown(!showAppsDropdown)} >
				<AllAppsIcon stroke='black' />
			</div>
			{
				showAppsDropdown &&
				<div className='all-apps-drop-down'>
					<>
						<p>Your apps</p>
						<div className='app-items'>
							{items.filter((item) => 
								userModules.includes(item.key)).map((item) => (
								<div
									className='app-item'
									key={item.key}
									onClick={item.onClick}>
									<img
										className='app-image'
										height={'21rem'}
										width={'21rem'}
										src={item.icon ? String(item.icon) : ""} 
										alt=""
									/>
									<p>
										BREATHE {item.label}
									</p>
								</div>
							))}
						</div>
						<div className='web-link'>
							<WebIcon />
							<p>
							Check our <a 
									target='_blank'
									href='https://www.breatheesg.com/'
								>
									 website
								</a> for more details about products
							</p>
						</div>
					</>
				</div>
			}
		</div>
	);
}
