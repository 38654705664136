import * as React from 'react';
import { PrimaryTheme } from '../../Config/Theme/theames';
import IndustryIcon from '../BEIcons/IndustryIcon';

export interface IBEWrapperProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  type?: 1 | 2; //1 means simple border, 2 means bcakground color and header
  title?: string;
  icon?: React.ReactNode;
}

export function BEWrapper (props: IBEWrapperProps) {
  return (
    <>
      {
        !props.type || props.type === 1 ? 
        <BEWrapper1 {...props} /> : 
        <BEWrapper2 {...props} />
      }
    </>
  );
}

function BEWrapper1 (props: IBEWrapperProps) {
  return (
    <div style={{border:`1px solid ${PrimaryTheme.primaryGreenLight}`,
      borderRadius:'10px',}}>
      <div style={{
        padding: '1rem',
        width:'calc(100% - 2rem)',
        ...props.style
      }}>
        {props.children}
      </div>
    </div>
  );
}

function BEWrapper2 (props: IBEWrapperProps) {
  return (
    <div style={{
      backgroundColor:`${PrimaryTheme.primaryGreyLight}`,
      borderRadius:'10px',
      padding: '1rem',
      }}>
        {
          props.title &&
          <div style={{display:'flex',gap:'0.5rem'}}>
            <div style={{height:'1.5rem'}}>
              {
                props.icon ?
                props.icon :
                <IndustryIcon fill='black' inheritSize/>
              }
            </div>
            <p style={{fontSize:'1.2rem',marginBottom:'1rem'}}>
              {props.title}
            </p>
          </div>
        }
      <div style={{
        backgroundColor: 'white',
        padding: '1rem',
        borderRadius:'10px',
        ...props.style
      }}>
        {props.children}
      </div>
    </div>
  );
}