
import { BETabs } from "../../../../../../../../../../Components/BETabs";
import { PrimaryTheme } from "../../../../../../../../../../Config/Theme/theames";
import BulkUploadDonutChart from "./DonutChart";
import { BETable } from "../../../../../../../../../../Components/BETable";
import { ColumnsType } from "antd/es/table";
import { BEButton } from "../../../../../../../../../../Components/BEFormItems/BEButton";
import React, { ForwardRefRenderFunction } from "react";
import { UploadWarningModal } from "./UploadWarningModal";
import { useTypedSelector } from "../../../../../../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../../../../../../Config/Hooks/useAppDispatch";
import { getMyMetricBulkUploadData, getMyMetricsData, getMyMetricsDataBulkUploadFiles, myMetricDataBulkUpload } from "../../../../../../../../Actions";
import exportToCSV from "../../../../../../../../../../Utils/Download/Excell/ExportExcell";
import { checkCeleryTaskStatusPeriodically, getCeleryTaskResult } from "../../../../../../../../../../views/Actions/HomeActions";
import { BEMessage } from "../../../../../../../../../../Components/BEMessage";
import { GlobalLoader } from "../../../../../../../../../../Components/GlobalLoader";
import { RejectedDataWarningModal } from "./RejectedDataWarningModal";

interface BulkUploadStep2Props {
	setStep: Function;
	my_metric_id: number;
	setOpen: Function;
	setDisplaySaveButton: Function;
}
const BulkUploadStep2: ForwardRefRenderFunction<{ onButtonClick: () => void }, BulkUploadStep2Props> = (props, ref) => {
	const [errorModal, setErrorModal] = React.useState<boolean>(false);
	const [acceptedData, setAcceptedData] = React.useState<any[]>([]);
	const [rejectedData, setRejectedData] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [activeTab, setActiveTab] = React.useState<string>('Accepted Entries');
	const [acceptedDataCount, setAcceptedDataCount] = React.useState<number>(0);
	const [rejectedDataCount, setRejectedDataCount] = React.useState<number>(0);
	const [errorPercent, setErrorPercent] = React.useState<number>(0);
	const currentFileId = useTypedSelector(state => state.dataManager.currentMyMetricDataBulkUploadFileId);
	const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
	const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
	const role = useTypedSelector((state) => state.user.userRole);
	const dispatch = useAppDispatch();
	const column: ColumnsType<any> = [
		{
			title: 'S.No',
			dataIndex: 'index',
			key: 'index',
			render: (text, record, index) => index + 1,
		},
		{
			title: 'VALUE',
			dataIndex: 'value',
			key: 'value',
			render: (text, record) => {
				return <p>{text}</p>
			}
		},
		{
			title: 'DESCRIPTION',
			dataIndex: 'description',
			key: 'description',
			render: (text, record) => {
				return <p>{text}</p>
			}
		},
		{
			title: 'DATE',
			dataIndex: 'date_of_entry',
			key: 'date_of_entry',
			render: (text, record) => {
				return <p>{text.split('T')[0]}</p>
			}
		},
		{
			title: 'COST',
			dataIndex: 'cost',
			key: 'cost',
			render: (text, record) => {
				return <p>{text}</p>
			}
		},
		{
			title: 'Business unit',
			dataIndex: 'business_unit_name',
			key: 'business_unit_name',
			render: (text, record) => {
				return <p>{text}</p>
			},
			hidden: currentBusinessUnit !== 0

		},
		{
			title: 'REMARKS',
			dataIndex: 'error',
			key: 'error',
			render: (text, record) => {
				return <p>{text}</p>
			},
			hidden: activeTab === 'Accepted Entries'
		}
	];

	// Function to download accepted and rejected data
	const dataDownload = () => {

		let dataToDownloadRejected: any = [];
		let dataToDownloadRAccepted: any = [];

		dataToDownloadRAccepted = acceptedData.map((item, index) => {
			let business_unit_name =  !currentBusinessUnit ?
				item.business_unit_name :
				businessUnits.data.find(
					(unit) => Number(unit.id) === Number(currentBusinessUnit)
				)?.name;
			return {
				'value': item.value,
				'date_of_entry(DD-MM-YYYY)': item.date_of_entry.split('T')[0],
				'description': item.description,
				'cost': item.cost,
				'business_unit_name': business_unit_name,
				'Status': 'Accepted',
				'Remarks': '-'
			}
		})
		dataToDownloadRejected = rejectedData.map((item, index) => {
				let business_unit_name =  !currentBusinessUnit ?
				item.business_unit_name :
				businessUnits.data.find(
					(unit) => Number(unit.id) === Number(currentBusinessUnit)
				)?.name;
				console.log('item', currentBusinessUnit, item.business_unit_id, business_unit_name)	
			return {
				'value': item.value,
				'date_of_entry(DD-MM-YYYY)': item.date_of_entry.split('T')[0],
				'description': item.description,
				'cost': item.cost,
				'business_unit_name': business_unit_name,
				'Status': 'Rejected',
				'Remarks': item.error
			}
		})


		let dataToDownload: any = [...dataToDownloadRAccepted, ...dataToDownloadRejected];
		exportToCSV(dataToDownload, 'Bulk_Upload_Data');
		setErrorModal(false);
		props.setOpen(false);
	}

	React.useEffect(() => {
		const totalDataCount = acceptedDataCount + rejectedDataCount;
		setErrorPercent((rejectedDataCount / totalDataCount) * 100);
	}, [acceptedDataCount, rejectedDataCount])

	const getFiledata = async () => {
		setLoading(true);
		const data = await dispatch(getMyMetricBulkUploadData());
		setAcceptedData(data.accepted_data);
		setRejectedData(data.rejected_data);
		setAcceptedDataCount(data.accepted_row);
		setRejectedDataCount(data.rejected_row);
		if (data.rejected_row > 0) {
			setErrorModal(true);
			props.setDisplaySaveButton(false);
		}
		setLoading(false);
	}

	React.useEffect(() => {
		if (currentFileId) {
			getFiledata();
		}
	}, [currentFileId]);


	const onButtonClick = async () => {
		setLoading(true);
		if (role !== 'CONTRIBUTOR') {
			const res = await dispatch(myMetricDataBulkUpload(props.my_metric_id, 1, null, null, currentFileId as number)).then(() => {
				dispatch(getMyMetricsDataBulkUploadFiles());
				dispatch(getMyMetricsData());
				BEMessage.success('Data saved successfully.')
				props.setOpen(false);
				setLoading(false);
			})

		} else {
			dispatch(getMyMetricsDataBulkUploadFiles());
			props.setOpen(false);
			setLoading(false);
		}
	}

	React.useImperativeHandle(ref, () => ({ onButtonClick }), []);
	return (
		<>{
			loading ?
				<GlobalLoader height="50vh" /> :
				<>
					<h3 style={{ fontSize: '1rem' }}>All the data uploaded can be submitted</h3>
					<BulkUploadDonutChart acceptedDataCount={acceptedDataCount} rejectedDataCount={rejectedDataCount} />
					<div style={{ marginTop: '1rem' }}>
						<BETabs
							activeKey={activeTab}
							onChange={(key) => setActiveTab(key)}
							type="card"
							tabBarStyle={{ paddingLeft: '1.5rem', paddingTop: '2rem', backgroundColor: PrimaryTheme.primaryBlueLight }}
							items={[
								{
									label: 'Accepted Entries',
									children: <BETable columns={column} data={acceptedData} pagination />,
									key: 'Accepted Entries'
								},
								{
									label: 'Rejected Entries',
									children: <>
										<div style={{ display: 'flex', justifyContent: 'end', margin: '1rem' }}>
											<BEButton
												onClick={() => {
													let dataToDownload: any = [];
													if (currentBusinessUnit) {
														dataToDownload = rejectedData.map((item, index) => {
															return {
																'value': item.value,
																'date_of_entry(DD-MM-YYYY)': item.date_of_entry.split('T')[0],
																'description': item.description,
																'cost': item.cost,
																'Remarks': item.error
															}
														})
													} else {
														dataToDownload = rejectedData.map((item, index) => {
															return {
																'value': item.value,
																'date_of_entry(DD-MM-YYYY)': item.date_of_entry.split('T')[0],
																'description': item.description,
																'cost': item.cost,
																'business_unit_name': item.business_unit_name,
																'Remarks': item.error
															}
														})
													}
													exportToCSV(dataToDownload, 'Rejected_Entries');
												}}
												style={{ borderColor: PrimaryTheme.primaryGreen, color: PrimaryTheme.primaryGreen }}
											>
												Download rejected rows
											</BEButton>
										</div>
										<BETable columns={column} data={rejectedData} pagination />
									</>
									, key: 'Rejected Entries'
								}
							]}
						/>
					</div>
					<RejectedDataWarningModal
						errorPercent={Number(errorPercent.toFixed(2))}
						open={errorModal}
						onCancel={() => {props.setOpen(false); setErrorModal(false)}}
						onDownload={() => dataDownload()}
					/>
				</>
		}
		</>)
}
export default React.forwardRef(BulkUploadStep2)