import { BECollapse } from '../../../../../Components/BECollapse';
import { Row, Timeline } from 'antd';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { useEffect, useState } from 'react';
import './style.scss';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { options } from '../../../../../Config/Data/genralData';
import TargetProgressBar from '../../../Components/TargetProgressBar';

export interface IViewTargetsProps {
    startYear: number;
    endYear: number;
    baseLineValue: number;
    baseLineYear: number;
    targetType: string;
    targetValue: number;
    achievedValue: number;
    metricId: number;
    open: boolean;
    setOpen: Function;
    businessUnit: number | null;
    progress: number;
    isDerived: boolean;
}

export function ViewTargets(props: IViewTargetsProps) {
    const targets = useTypedSelector(state => state.targets.MyMetricTargets);
    const derivedTargets = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
    const reportingYearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
    const [items, setItems] = useState<any[]>([]);

    const accumulateTimeLineItems = () => {
        let items = [];
        const reportingMonth = Number(reportingYearEnd?.split('-')[1]);
        const ongoingMonth = new Date().getMonth() + 1;
        const ongoingYear = new Date().getFullYear();
        let currentYear = ongoingYear;
        if (reportingMonth === 12) {
        } else {
            if (ongoingMonth > reportingMonth) {
                currentYear = ongoingYear;
            } else {
                currentYear = ongoingYear - 1;
            }
        }
        for (let i = props.startYear; i <= props.endYear; i++) {
            let target = null;
            if (props.isDerived) {
                target = derivedTargets.data.find((target) =>
                    target.derived_metric === props.metricId &&
                    target.start_year === i &&
                    target.end_year === i &&
                    target.business_unit === props.businessUnit
                );
            } else {
                target = targets.data.find((target) =>
                    target.metric === props.metricId &&
                    target.start_year === i &&
                    target.end_year === i &&
                    target.business_unit === props.businessUnit
                );
            }
            const currentValue = target?.current_value;
            const targetValue = target?.target_value;
            const baselineValue = i === props.startYear ? props.baseLineValue : 0;
            const label = i === currentYear ? '(Ongoing year)' : i > currentYear ? '(Upcoming year)' :
                i === props.startYear ? '(Start year)' : i === props.endYear ? '(End year)' : '';

            items.push({
                color: 'green',
                children: <div>
                    <h3 className='timeline-year'>{`${i} ${label}`}</h3>
                    {baselineValue ?
                        <div className='progress-row'>
                            <span className='progress-label'>
                                Baseline value
                            </span>
                            <span className='progress-value'>
                                {baselineValue}
                            </span>
                        </div>
                        :
                        null
                    }
                    {currentValue ?
                        <div className='progress-row'>
                            <span className='progress-label'>
                                Current value
                            </span>
                            <span className='progress-value'>
                                {currentValue}
                            </span>
                        </div>
                        :
                        null
                    }
                    {targetValue ?
                        <div className='progress-row'>
                            <span className='progress-label'>
                                Target value
                            </span>
                            <span className='progress-value'>
                                {targetValue}
                            </span>
                        </div>
                        :
                        null
                    }
                </div>
            });
        }
        setItems(items);
    }

    useEffect(() => {
        accumulateTimeLineItems();
    }, [props.open]);


    return (
        <BEDrawer
            heading="View targets"
            open={props.open}
            setOpen={props.setOpen}
            width='30vw'
            footer={
                <BEButton
                    onClick={() => props.setOpen(false)}
                    size='large'
                >
                    Close
                </BEButton>
            }
        >
            <div>
                <BECollapse
                    headingStyle={{ height: '50px' }}
                    heading={<h3>Overall progress</h3>}
                >
                    <div className='overall-progress-container'>
                        <Row className='progress-row'>
                            <span className='progress-label'>
                                Start year
                            </span>
                            <span className='progress-value'>
                                {props.startYear}
                            </span>
                        </Row>
                        <Row className='progress-row'>
                            <span className='progress-label'>
                                End year
                            </span>
                            <span className='progress-value'>
                                {props.endYear}
                            </span>
                        </Row>
                        <Row className='progress-row'>
                            <span className='progress-label'>
                                Base line year
                            </span>
                            <span className='progress-value'>
                                {props.baseLineYear}
                            </span>
                        </Row>
                        <Row className='progress-row'>
                            <span className='progress-label'>
                                Base line value
                            </span>
                            <span className='progress-value'>
                                {props.baseLineValue}
                            </span>
                        </Row>
                        <Row className='progress-row'>
                            <span className='progress-label'>
                                Target type
                            </span>
                            <span className='progress-value'>
                                {options.find((option) => option.value === props.targetType)?.label}
                            </span>
                        </Row>
                        <Row className='progress-row'>
                            Overall progress
                        </Row>
                        <Row>
                            <TargetProgressBar
                                targetType={props.targetType}
                                progress={props.progress}
                                baseLineValue={props.baseLineValue}
                                targetValue={props.targetValue}
                                currentValue={props.achievedValue}
                            />
                        </Row>
                    </div>

                </BECollapse>
                <br />
                <hr />
                <br />

                <h2>
                    Target time line
                </h2>
                <br />
                <Timeline
                    items={items}
                />


            </div>
        </BEDrawer>
    );
};
