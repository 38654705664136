import type { StatusType } from "../Types/userTypes";

import {
    Reducer,
    createAction,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";
import { DashboardState } from "../Types/dashboardTypes";




const initialState: DashboardState = {
    compareAnalytics:{
        status: 'idle',
        data: {
            current_year_data: 0,
            comparison_year_data: 0,
            average_monthly_data: 0,
        }
    },
    currentAnalyticsSource: null,
    currentAnalyticsCompareYear: null,
    drillDown: {
        isEmission: null,
    },
    compareInsightsProgress: {
        status: 'idle',
        data: {
            current_month_progress: 0,
            last_month_progress: 0,
            current_year_progress: 0,
            comparison_year_progress: 0,
            comparison_year_month_progress: 0,
        }
    },
    currentInsightPillar: null,
    currentInsightsCompareYear: null,
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setDashboardCompareAnalytics: (state, action) => {
            state.compareAnalytics = action.payload;
        },
        setCurrentAnalyticsSource: (state, action) => {
            state.currentAnalyticsSource = action.payload;
        },
        setCurrentAnalyticsCompareYear: (state, action) => {
            state.currentAnalyticsCompareYear = action.payload
        },
        setDrillDownEmissionType: (state, action) => {
            state.drillDown.isEmission = action.payload;
        },
        setCompareInsightsProgress: (state, action) => {
            state.compareInsightsProgress = action.payload;
        },
        setCurrentInsightPillar: (state, action) => {
            state.currentInsightPillar = action.payload;
        },
        setCurrentInsightsCompareYear: (state, action) => {
            state.currentInsightsCompareYear = action.payload;
        }
    }
});

export const {
    setDashboardCompareAnalytics,
    setCurrentAnalyticsSource,
    setCurrentAnalyticsCompareYear,
    setDrillDownEmissionType,
    setCompareInsightsProgress,
    setCurrentInsightPillar,
    setCurrentInsightsCompareYear 
} = userSlice.actions;

const dashboardReducer: Reducer<DashboardState> = userSlice.reducer;

export default dashboardReducer;
