import React, { useState, useEffect } from 'react'
import { IndividualSupplierTemplate } from '../../../../../Utils/Download/PDF/Templates/SupplierAssessmentTemplate';
import { usePDF } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Spin } from 'antd';
import DownloadIcon from '../../../../../Components/BEIcons/DownloadIcon';
import { useSelector } from 'react-redux';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import ExportExcelIcon from '../../../../../Components/BEIcons/ExportIcons/ExportExcelIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import ExportPDFIcon from '../../../../../Components/BEIcons/ExportIcons/ExportPDFIcon';
import { getSupplierAnswers } from '../../../Actions/supplierAction';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useParams } from 'react-router-dom';
import { getSupplierAssessmentAnswers } from '../../../Actions/supplierFormActions';
import { BEMessage } from '../../../../../Components/BEMessage';

interface PropTypes {
  supplierDetails: any,
}


export default function DownloadSupplierResponse(props: PropTypes) {
  const dispatch = useAppDispatch();
  const { assessment_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [instance, update] = usePDF({
    document: <></>
  })
  const questions = useTypedSelector(
    state => state.supplier.SupplierQuestions.data
  );

  const handleDownload = async () => {
    setLoading(true);
    const answers = await dispatch(getSupplierAnswers(assessment_id,props.supplierDetails.id))
    if(!answers){
      setLoading(false);
      return BEMessage.error('Error while downloading the PDF');
    }
    update(<IndividualSupplierTemplate 
      supplierData={props.supplierDetails}
      questions={
        questions.filter((question: any) => 
          answers.find((answer: any) => (
            answer.question === question.id &&
            answer.type === question.type
          ))
        )
      }
      answers={answers}
      score={((props.supplierDetails.score/props.supplierDetails.total_questions)*100).toFixed(2)}
    />);
  }

  useEffect(() => {
    if (loading && instance.loading === false) {
      saveAs(instance.blob || '', `supplierAssessment-${props.supplierDetails.name}.pdf`);
      setLoading(false);
      update(<></>);
    }
  }, [instance.loading])

  return (
    <div>
      {
        loading ? <Spin /> :
          <div onClick ={handleDownload} style={{ cursor: 'pointer', height: '1rem' }}>
            <ExportPDFIcon inheritSize fill={PrimaryTheme.primaryGreen}/>
          </div>
      }
    </div>
  )
}