
import React from 'react'
import IconProps from './Interface/IconProps';

const SupportIcon = (props: IconProps) => {
	return (
		<svg width={props.inheritSize ? "100%" : 10}
			height={props.inheritSize ? "100%" : 10} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.9945 3.05053C13.816 1.86449 12.3119 1.05507 10.6728 0.724874C9.03376 0.39468 7.33356 0.55858 5.78775 1.1958C4.24194 1.83302 2.92014 2.91486 1.98989 4.30418C1.05965 5.69351 0.56286 7.32775 0.5625 8.99975V13.3747C0.5625 13.9549 0.792968 14.5113 1.2032 14.9215C1.61344 15.3318 2.16984 15.5622 2.75 15.5622H4C4.58016 15.5622 5.13656 15.3318 5.5468 14.9215C5.95703 14.5113 6.1875 13.9549 6.1875 13.3747V10.2497C6.1875 9.66959 5.95703 9.11319 5.5468 8.70295C5.13656 8.29272 4.58016 8.06225 4 8.06225H2.50312C2.72938 6.50043 3.51053 5.07233 4.70361 4.03937C5.89669 3.0064 7.42188 2.43766 9 2.43725H9.05C10.6211 2.44397 12.1372 3.01613 13.3211 4.04904C14.5049 5.08196 15.2773 6.50657 15.4969 8.06225H14C13.4198 8.06225 12.8634 8.29272 12.4532 8.70295C12.043 9.11319 11.8125 9.66959 11.8125 10.2497V13.3747C11.8125 13.9549 12.043 14.5113 12.4532 14.9215C12.8634 15.3318 13.4198 15.5622 14 15.5622H15.5312C15.4592 15.9152 15.2674 16.2324 14.9884 16.4602C14.7094 16.6879 14.3602 16.8123 14 16.8122H9.625C9.37636 16.8122 9.1379 16.911 8.96209 17.0868C8.78627 17.2627 8.6875 17.5011 8.6875 17.7497C8.6875 17.9984 8.78627 18.2368 8.96209 18.4127C9.1379 18.5885 9.37636 18.6872 9.625 18.6872H14C14.9114 18.6862 15.7851 18.3237 16.4295 17.6793C17.074 17.0349 17.4365 16.1611 17.4375 15.2497V8.99975C17.4418 7.89614 17.2282 6.80253 16.809 5.78164C16.3898 4.76074 15.7732 3.83263 14.9945 3.05053ZM4 9.93725C4.08288 9.93725 4.16237 9.97017 4.22097 10.0288C4.27958 10.0874 4.3125 10.1669 4.3125 10.2497V13.3747C4.3125 13.4576 4.27958 13.5371 4.22097 13.5957C4.16237 13.6543 4.08288 13.6872 4 13.6872H2.75C2.66712 13.6872 2.58763 13.6543 2.52903 13.5957C2.47042 13.5371 2.4375 13.4576 2.4375 13.3747V9.93725H4ZM13.6875 13.3747V10.2497C13.6875 10.1669 13.7204 10.0874 13.779 10.0288C13.8376 9.97017 13.9171 9.93725 14 9.93725H15.5625V13.6872H14C13.9171 13.6872 13.8376 13.6543 13.779 13.5957C13.7204 13.5371 13.6875 13.4576 13.6875 13.3747Z"
				fill={props.stroke ? props.stroke : "#747474"}
			/>
		</svg>
	);
}

export default SupportIcon