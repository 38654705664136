import API from "../../API";

export const GET_TEMPLATE_DRAFTS = async () => {
  return await API.GET("/enterprise/template-drafts/");
};

export const POST_TEMPLATE_DRAFT = async (body:any) => {
  return await API.POST("/enterprise/template-drafts/",body);
}

export const PATCH_TEMPLATE_DRAFT = async (id:any, body:any) => {
  return await API.PATCH(`/enterprise/template-drafts/${id}/`,body);
}

export const GET_TEMPLATE_TOPICS = async (framework: string) => {
  return await API.GET(`/enterprise/esg-report-topics/?framework=${framework}`)
}

export const POST_TEMPLATE_TOPICS = async (body: any) => {
  return await API.POST("/enterprise/template-report-topics/", body);
}

export const GET_TOPICS_OF_TEMPLATE = async (id: any) => {
  return await API.GET(`/enterprise/template-report-topics/${id}/`);
}

export const DELETE_TEMPLATE_TOPIC = async (id: any) => {
  return await API.DELETE(`/enterprise/template-report-topics/${id}/`);
}

export const POST_REPORT_TEMPLATE = async (body: any) => {
  return await API.POST("/enterprise/esg-report/", body);
}

export const EDIT_REPORT_TEMPLATE = async (id: any, body: any) => {
  return await API.PATCH(`/enterprise/esg-report/${id}/`, body);
}

export const PATCH_REPORT_TEMPLATE = async (id: any, body: any) => {
  return await API.PATCH(`/enterprise/esg-report/${id}/`, body);
}

export const DELETE_REPORT_TEMPLATE = async (id: any) => {
  return await API.DELETE(`/enterprise/template-drafts/${id}/`);
}

export const DELETE_REPORT = async (id: any) => {
  return await API.DELETE(`/enterprise/esg-report/${id}/`);
}

export const POST_REPORT_BUS = async (body: any) => {
  return await API.POST("/enterprise/esg-report-business-units/", body);
}

export const DELETE_REPORT_BUS = async (id: any) => {
  return await API.DELETE(`/enterprise/esg-report-business-units/${id}/`);
}

export const GET_REPORT_BUSINESS_UNITS = async (id:any) => {
  return await API.GET(`/enterprise/esg-report-business-units/${id}/`);
}


export const GET_REPORTS = async () => {
  return await API.GET("/enterprise/esg-report/");
}

export const GET_ALL_REPORT_BUS = async () => {
  return await API.GET(`/enterprise/esg-report-business-units/`)
}



export const POST_REPORT_DATA = async (body: any) => {
  return await API.POST("/enterprise/esg-report-topic-data/", body);
}

export const GET_REPORT_DATA = async (id: any) => {
  return await API.GET(`/enterprise/esg-report-topic-data/${id}/`)
}

export const PATCH_REPORT_DATA = async (id: any, body: any) => {
  return await API.PATCH(`/enterprise/esg-report-topic-data/${id}/`, body);
}

export const DELETE_REPORT_DATA = async (id: any) => {
  return await API.DELETE(`/enterprise/esg-report-topic-data/${id}/`);
}

export const REPORT_AUTO_FILL = async (data: any,id:number) => {
  return await API.POST("/enterprise/esg-report-auto-fill/"+id+"/", data);
}

export const GET_REPORT_EVEDENCE = async (id: any) => {
  return await API.GET(`/enterprise/esg-report-evidences/${id}/`);
}

export const POST_REPORT_EVEDENCE = async (body: any) => {
  return await API.POST("/enterprise/esg-report-evidences/", body, true);
}

export const DELETE_REPORT_EVEDENCE = async (id: any) => {
  return await API.DELETE(`/enterprise/esg-report-evidences/${id}/`);
}

export const GET_REPORT_TOPIC_STATUS = async (id: any) => {
  return await API.GET(`/enterprise/report-topic-approval/${id}/`);
}

export const POST_REPORT_TOPIC_STATUS = async (body: any) => {
  return await API.POST("/enterprise/report-topic-approval/", body);
}

export const PATCH_REPORT_TOPIC_STATUS = async (id: any, body: any) => {
  return await API.PATCH(`/enterprise/report-topic-approval/${id}/`, body);
}

export const GET_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION = async () => {
  return await API.GET("/enterprise/report-section-by-department/")
}

export const POST_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION = async (data: any) => {
  return await API.POST("/enterprise/report-section-by-department/", data);
}

export const PATCH_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION = async (id: any, data: any) => {
  return await API.PATCH("/enterprise/report-section-by-department/" + id + "/", data);
}

export const DELETE_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION = async (id: any) => {
  return await API.DELETE("/enterprise/report-section-by-department/" + id + "/");
}

export const GET_OMITTED_REPORT_TOPICS = async () => {
  return await API.GET("/enterprise/omit-report-topic/")
}

export const POST_OMITTED_REPORT_TOPICS = async (data: any) => {
  return await API.POST("/enterprise/omit-report-topic/", data);
}

export const PATCH_OMITTED_REPORT_TOPICS = async (id: any, data: any) => {
  return await API.PATCH("/enterprise/omit-report-topic/" + id + "/", data);
}

export const DELETE_OMITTED_REPORT_TOPICS = async (id: any) => {
  return await API.DELETE("/enterprise/omit-report-topic/" + id + "/");
}

export const GET_REPORT_PDF = async (id: any,format:string) => {
  return await API.GET(`/enterprise/report-into-pdf/${id}/?type=${format}`);
}

export const POST_GRESB_AUTH = async (data: any) => {
  return await API.POST(`/enterprise/gresb-auth/`, data);
}

export const GET_GRESB_ASSETS = async () => {
  return await API.GET(`/enterprise/gresb-asset/`);
}

export const POST_GRESB_ASSETS = async (data: any) => {
  return await API.POST(`/enterprise/gresb-asset/`, data);
}

export const PATCH_GRESB_ASSETS = async (id: any, data: any) => {
  return await API.PATCH(`/enterprise/gresb-asset/${id}/`, data);
}

export const DELETE_GRESB_ASSETS = async (id: any) => {
  return await API.DELETE(`/enterprise/gresb-asset/${id}/`);
}

export const GET_GRESB_ENTITIES = async () => {
  return await API.GET(`/enterprise/gresb-entity/`);
}

export const POST_GRESB_ENTITIES = async (data: any) => {
  return await API.POST(`/enterprise/gresb-entity/`, data);
}

export const PATCH_GRESB_ENTITIES = async (id: any, data: any) => {
  return await API.PATCH(`/enterprise/gresb-entity/${id}/`, data);
}

export const DELETE_GRESB_ENTITIES = async (id: any) => {
  return await API.DELETE(`/enterprise/gresb-entity/${id}/`);
}

// For interaction with gresb portal via backend
export const GRESB_SLUG_DATA = async (data: any) => {
  return await API.POST(`/enterprise/gresb-dropdown-data/`, data);
}

export const GRESB_PORTAL_ENTITY = async (data: any) => {
  return await API.POST(`/enterprise/gresb-portal-entity/`, data);
}

export const POST_GRESB_PORTAL_ASSET_DATA = async (entity_id: number, asset_id: number, body:any) => {
  return await API.POST(`/enterprise/gresb-portal-asset/${entity_id}/${asset_id}/`, body);
}

export const PATCH_GRESB_PORTAL_ASSET_DATA = async (entity_id: number, asset_id: number | string, body:any) => {
  return await API.PATCH(`/enterprise/gresb-portal-asset/${entity_id}/${asset_id}/`, body);
}

export const POST_GRESB_PORTAL_ENTITY_DATA = async (body:any) => {
  return await API.POST(`/enterprise/gresb-portal-entity-data/`, body);
}

export const GET_GRESB_PORTAL_ENTITY_DATA = async (report_id: number, body: any) => {
  return await API.PATCH(`/enterprise/gresb-portal-entity-data/${report_id}/`, body);
}

export const POST_GRESB_ENTITY_EVIDENCE = async (body: any) => {
  return await API.POST(`/enterprise/gresb-entity-evidence/`, body, true);
}

export const DELETE_GRESB_ENTITY_EVIDENCE = async (token: any, id: any) => {
  return await API.DELETE(`/enterprise/gresb-entity-evidence/${token}/${id}/`);
}

export const GET_GRESB_ENTITY_EVIDENCE = async (id: any) => {
  return await API.GET(`/enterprise/gresb-entity-evidence/${id}/`);
}

export const PATCH_GRESB_ENTITY_EVIDENCE = async (id: any, body: any) => {
  return await API.PATCH(`/enterprise/gresb-entity-evidence/${id}/`, body);
}

export const POST_REPORT_CHATS = async (body: any) => {
  return await API.POST(`/enterprise/report-chats/`,body);
}

export const GET_REPORT_CHATS = async () => {
  return await API.GET(`/enterprise/report-chats/`);
}

export const GET_REPORT_ACTIVITY_ACTIONS = async () => {
  return await API.GET(`/enterprise/report-activity-logs/`);
}

export const POST_ASSIGN_REPORT_TO_USER = async (body: any) => {
  return await API.POST(`/enterprise/assign-report-to-user/`, body);
}

export const GET_ASSIGN_REPORT_TO_USER = async () => {
  return await API.GET(`/enterprise/assign-report-to-user/`);
}

export const DELETE_ASSIGN_REPORT_TO_USER = async (id: any) => {
  return await API.DELETE(`/enterprise/assign-report-to-user/${id}/`);
}
