import * as React from 'react';
import './style.scss';
import BEBackButton from '../../../../Components/BEBackButton';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BEWrapper } from '../../../../Components/BEWrapper';
import { BETabs } from '../../../../Components/BETabs';
import StackedColumnCharts from '../../../../Components/BECharts/StackedColumnCharts';
import { shortMonths } from '../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getDashboardDrilldown } from '../../Actions';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { DrillDownBuBgEmissions } from './BuBgEmissions';
import useOutsideClick from '../../../../Config/Hooks/useOutsideClick';
import SwitchIcon from '../../../../Components/BEIcons/SwitchIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Checkbox } from 'antd';
import { rotateArray, showFYFormatByYear } from '../../../../Config/Functions/UsefullFunctions';
import { ToggleButton } from '../../Components/ToggleButton';
import { setDrillDownEmissionType } from '../../../../Redux/DashboardReducer';
import { BENoData } from '../../../../Components/BENoData';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { BEMessage } from '../../../../Components/BEMessage';
import { MetricIconNameList } from '../../../../views/Onboarding/Step4/SideFilter/SIdeItem';

export interface IDashboardDrillDownProps {
}

export function DashboardDrillDown (props: IDashboardDrillDownProps) {
  const dispatch = useAppDispatch();
  const code = useParams<{code: string}>().code;
  const [icon, setIcon] = React.useState<React.ReactNode>(null);
  const [selectedTab, setSelectedTab] = React.useState<number>(1);
  const [metric, setMetric] = React.useState<any>(null);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
  const [chartData1,setChartData1] = React.useState<any>([]);
  const [chartData2,setChartData2] = React.useState<any>([]);
  const [chartData3,setChartData3] = React.useState<any>([]);
  const [chartData4,setChartData4] = React.useState<any>([]);
  const [chart4Years,setChart4Years] = React.useState<any | null>([]);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [loading, setLoading] = React.useState<boolean>(false);
  const recentYears = useTypedSelector(state => state.common.financialYears)
  const [selectedChart3Years, setSelectedChart3Years] = React.useState<any>([]);
  const [openChart3YearSelector, setOpenChart3YearSelector] = React.useState<boolean>(false);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const isEMissions = useTypedSelector(state => state.dashboard.drillDown.isEmission);
  const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType);
  const yearEndMonth = useTypedSelector(state => state.companies.yearEnd);

  const getUnitToDisplay = (unit:string) => {
    if(isEMissions) return 'tCO2e';
    const unitsToIgnore:any[] = [] //'number','percentage','ratio'
    if(unitsToIgnore.includes(unit)) return '';
    return unit;
  }
  const wrapperRef = React.useRef(null);
  useOutsideClick(wrapperRef, () => setOpenChart3YearSelector(false));

  React.useEffect(() => {
    if(!metric) return;
    if(metric.type === 'derived' && metric?.condition == 'tco2e' ) dispatch(setDrillDownEmissionType(true))
    else dispatch(setDrillDownEmissionType(false))
    return () => {
      dispatch(setDrillDownEmissionType(null))
    }
  }, [metric])

  React.useEffect(() => {
    if(!code || combinedSelectedMetricsPerYear.status !== 'success' || myMetricsPerYear.status !== 'success') return;
    let tempMetric:any = combinedSelectedMetricsPerYear.data.find((metric:any) => metric.bcode === code);

    if(!tempMetric) {
      tempMetric = myMetricsPerYear.data.find((metric:any) => metric.bcode === code);
      tempMetric = {...tempMetric, derived: false}
    }
    else tempMetric = {...tempMetric, derived: true}
    setMetric(tempMetric)
  }, [code, combinedSelectedMetricsPerYear, myMetricsPerYear])

  const getGraphData = async() => {
    setLoading(true);
    let type = selectedTab === 2 ? 2 : selectedTab === 4 ? 3 : 1;
    if(!isEMissions) type += 4;
    let years = selectedTab === 2 ? recentYears.data : selectedTab === 1 ? [currentYear] :  selectedChart3Years;
    let tempData:any = await dispatch(getDashboardDrilldown(type,1,code as string,years));
    if(!tempData && selectedTab!==4){
       setLoading(false)
       return;
      }

    if(selectedTab==2){
      let tempChartData2:any = [];
      [...recentYears.data].sort((a,b) => a>b ? 1 : -1).forEach((year) => {
        tempChartData2.push(tempData[year])
      })
      console.log(tempChartData2)
      setChartData2([{name: metric.title, data: tempChartData2}])
    }
    else if(selectedTab === 3){
      let tempChartData:any = [];
      selectedChart3Years.sort((a:any,b:any) => a>b ? 1 : -1).forEach((year:any) => {
        if(!tempData[year]) return;
        tempChartData.push({name: String(year), data: rotateArray(tempData[year],yearEndMonth,true)})
      });setChartData3(tempChartData)
    }
    else if(selectedTab === 4) {
      if(!tempData) {
        setChartData4(null)
        setLoading(false)
        return
      }
  
      setChart4Years(tempData.years)
      let tempChartData4:any = [];
      ['actual','target'].forEach((key) => {
        tempChartData4.push({name: key, data: tempData.data[key]})
      })
      setChartData4(tempChartData4)
    }
    else setChartData1([{
        name: metric.title,
        data: rotateArray(tempData[currentYear],yearEndMonth,true)
      }])
    setLoading(false);
  }

  React.useEffect(() => {
    if(!metric || recentYears.status !== 'success' || isEMissions == null) return;
    if(isEMissions && metric.type === 'direct' && selectedTab === 4) {
      setSelectedTab(1)
      return;
    }
    getGraphData()
  }, [selectedTab,recentYears, selectedChart3Years,currentBusinessUnit,currentBusinessGroup,isEMissions])
   
  React.useEffect(() => {
    if(recentYears.status !== 'success') return;
    let last2Years = [...recentYears.data].sort((a,b) => a<b ? 1 : -1).slice(0,2);
    setSelectedChart3Years(last2Years)
  }, [recentYears])

  React.useEffect(() => {
      let TempIcon  = MetricIconNameList.find((item) => item.name === metric?.category)?.icon || MetricIconNameList[0]?.icon;
      setIcon(<TempIcon stroke={'black'} inheritSize/>)
  }, [metric?.category]);

  return (
    <div className="dashboard-drill-down">
      <div className='dashboard-drill-down-header'>
        <BEBackButton title='Back' />
        <div className='dashboard-drill-down-header-right'>
          {
            isEMissions && metric?.type !== 'derived' &&
            <ToggleButton
              switchProps={{disabled:true}}
              value={scope2EmissionType === 2}
              onChange={(value:boolean) => {}}
              text={{left: 'Location based', right: 'Market based'}}
            />
          }

          <ToggleButton 
            switchProps={{
              disabled: metric?.type === 'derived'  || !['Refrigerants', 'Energy','Refrigerants ',].includes(metric?.category)}}
            text={{left: 'Value', right: 'Emissions'}} 
            onChange={() => {
              dispatch(setDrillDownEmissionType(!isEMissions))
            }}
            value={isEMissions || false}
          />
        </div>
      </div>
      <BEWrapper type={2} icon={icon} title={metric?.title} >
        <BEWrapper>
          <div className='dashboard-drill-down-content'>
            <BETabs 
              onChange={(key) => setSelectedTab(Number(key))}
              activeKey={String(selectedTab)}
              items={
                [
                { key: '1',label: 'Current year',children: <></> },
                { key: '2',label: 'Year on year',children:  <></> },
                { key: '3',label: 'Month on month',children: <></> },
                { key: '4',label: 'Target VS Actual',children: <></> },
                ].slice(0,(metric?.type === 'direct' && isEMissions)? 3 : 4)
            }/>
            {
              loading ? <GlobalLoader height='25rem'/> : 
              <div>
                {
                  selectedTab === 1 &&
                  <StackedColumnCharts 
                  yAxisLabel={getUnitToDisplay(metric?.unit)}
                  xAxisLabels={rotateArray(shortMonths,yearEndMonth,true)} type={2}  data={chartData1} colorTheme={4}/>
                }
                {
                  selectedTab === 2 &&
                  <StackedColumnCharts  yAxisLabel={getUnitToDisplay(metric?.unit)} 
                    xAxisLabels={[...recentYears.data].sort((a,b) => a>b ? 1 : -1).map((year) => showFYFormatByYear(year,yearEnd || ''))} 
                    type={2}  data={chartData2} colorTheme={4}
                  />
                }
                {
                  selectedTab === 3 &&
                    <div>
                      <div className='chart3-year-selector' >
                        <div className='year-selector' ref={wrapperRef}>
                          <div className='year-selector-lable' onClick={() => setOpenChart3YearSelector(!openChart3YearSelector)} >
                            <p>Years</p>
                            <div className='switch-icon'><SwitchIcon stroke={PrimaryTheme.primaryGray} inheritSize/></div>
                          </div>
                          
                          {
                            openChart3YearSelector &&
                            <div className='year-selector-dropdown'>
                              {
                                recentYears.data.map((year) => 
                                  <div className='year-selector-item'>
                                    <Checkbox 
                                      checked={selectedChart3Years.includes(year)}
                                      disabled={
                                        (selectedChart3Years.length >= 3 && !selectedChart3Years.includes(year)) ||
                                        (selectedChart3Years.includes(year) && selectedChart3Years.length === 1)
                                      } 
                                      onChange={(e) => {
                                        if(selectedChart3Years.includes(year)) setSelectedChart3Years(selectedChart3Years.filter((item:any) => item !== year))
                                        else setSelectedChart3Years([...selectedChart3Years,year])
                                      }}
                                    />
                                    <p>{showFYFormatByYear(year,yearEnd || '')}</p>
                                  </div>
                                )
                              }
                            </div>
                          }

                        </div>
                      </div>
                      <StackedColumnCharts  yAxisLabel={getUnitToDisplay(metric?.unit)} xAxisLabels={rotateArray(shortMonths,yearEndMonth,true)} type={2}  data={chartData3} colorTheme={4}/>
                    </div>
                }
                {
                  selectedTab === 4 &&
                  <div>
                    {
                      chartData4 == null ? 
                      <div className='no-target-data'>
                        <BENoData description='To set target for this metric go to targets' size='large' 
                        onClick={() => navigateTo('/home/targets')} buttonLabel='Set Target'/>
                      </div>
                      :
                      <StackedColumnCharts  yAxisLabel={getUnitToDisplay(metric?.unit)} xAxisLabels={chart4Years} type={2}  data={chartData4} colorTheme={4}/>
                    } 
                  </div>
                }
              </div>            
            }
          </div>
        </BEWrapper>
      <DrillDownBuBgEmissions metric={metric}/>
      </BEWrapper>
    </div>
  );
}
