import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { DashboardMetricCard } from '../../../Components/DashboardMetricCard';
import './style.scss';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';

export interface IDashboaredDataAndTargetCardsProps {
}

export function DashboaredDataAndTargetCards (props: IDashboaredDataAndTargetCardsProps) {

  const myDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDashboardMetrics);
  const myDerivedDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDerivedDashboardMetrics);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const myMetricsTarget = useTypedSelector(state => state.targets.MyMetricTargets);
  const myDerivedMetricsTarget = useTypedSelector(state => state.targets.MyDerivedMetricTargets);
  
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);

  const [cardData, setCardData] = React.useState<any>(null);

  React.useEffect(() => {
    if(myDashboardMetrics.status !== 'success' || myDerivedDashboardMetrics.status !== 'success' || combinedSelectedMetricsPerYear.status !== 'success' || myMetricsTarget.status !== 'success' || myDerivedMetricsTarget.status !== 'success') return;
    let tempData:any = [];

    const dashboardMetrics = [myDashboardMetrics, myDerivedDashboardMetrics];
    const metricsTarget = [myMetricsTarget, myDerivedMetricsTarget];
    const metricsName = ['metric', 'derived_metric'];
    const myMetricsName = ['my_metric', 'my_derived_metric'];
    for(let i = 0; i < 2; i++){
       const _dashboardMetrics = dashboardMetrics[i];
        const _metricsTarget:any = metricsTarget[i];
        const _metricsPerYear:any = combinedSelectedMetricsPerYear;
        const _metricsName:any = metricsName[i];
        const _myMetricsName:any = myMetricsName[i];

        _dashboardMetrics.data.forEach((item:any) => {
          if(!item.card) return; 
          
          const metricData = _metricsPerYear.data?.find((metric:any) => metric.id === item[_myMetricsName as any]) || null;
          if (!metricData) return;

          let target:any = null
          if(!currentBusinessGroup) 
            target = _metricsTarget.data.find((_target:any) => 
              _target[_metricsName] === metricData[_metricsName] &&
              (currentBusinessUnit ? _target.business_unit === currentBusinessUnit : true) &&
              _target.start_year === currentYear &&
              _target.end_year === currentYear
            );

         
          tempData.push({
              bcode: metricData.bcode,
              metricName: metricData.custom_title ? metricData.custom_title : metricData.title,
              metricCategory: metricData.category,
              metricUnit: metricData.unit,
              currentValue: metricData?.value || metricData?.approved_value,
              baselineValue: target ? target.baseline : null,
              targetType: target ? target.target_type : null,
              targetValue: target ? target.target_value : null,
              progress: target ?target.progress : null,
          })
        })
    }
    setCardData(tempData);

  }, [myDashboardMetrics, myDerivedDashboardMetrics, combinedSelectedMetricsPerYear,  myMetricsTarget, myDerivedMetricsTarget]);

  if(cardData === null) return <GlobalLoader height='40rem'/>
  return (
    <div className='dashboard-target-data-cards'>
      {
        cardData.map((item:any, index:any) => {
          return (
            <DashboardMetricCard key={index} {...item}/>
          )
        })
      }
    </div>
  );
}
