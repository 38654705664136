import * as React from 'react';
import { NewBETable } from '../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import ExportExcelIcon from '../../../Components/BEIcons/ExportIcons/ExportExcelIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { PlatformDataDownload } from '../Actions';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import './style.scss';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { SetTableAndGraphDataDM } from '../../DataManager/Components/SetTableAndGraphDataFunctionDM';
import { getGlobalStakeholders, getMaterialitySurveys } from '../../Materiality/Actions/matirialityActions';
import { getSupplierSurveys, getSuppliers } from '../../Suppliers/Actions/supplierAction';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';
import { getAllReports, getAllTemplateDrafts } from '../../Reporting/Actions/template';
import { getAllUsers } from '../../../views/Actions/UsersManagementActions';
import { getBusinessUnits } from '../../../views/Actions/BusinessUnitActions';
import { getBusinessGroups } from '../../../views/Actions/BusinessGroupActions';
import { getDerivedMetricDataByMonth, getDerivedMetrics, getMyDerivedMetrics, getMyMetricsData } from '../../DataManager/Actions';

export interface IDataDownloadProps {
}

export function DataDownload(props: IDataDownloadProps) {
    const dispatch = useAppDispatch();
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const allSurveys = useTypedSelector(state => state.matiriality.AllSurveys);
    const allStakeHolders = useTypedSelector(state => state.matiriality.globalStakeholders);
    const supllierAssessments = useTypedSelector(state => state.supplier.SuppliersSurveys);
    const Suppliers = useTypedSelector(state => state.supplier.Suppliers);
    const businessGroups = useTypedSelector(state => state.entity.BusinessUnits);
    const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
    const derivedMetrics = useTypedSelector(state => state.onBoarding.metrics.derivedMetrics);
    const directMetricsData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
    const derivedMetricsData = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit);
    const allFullMyDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetrics);
    const allReports = useTypedSelector(state => state.reporting.Reports);
    const allUsers = useTypedSelector(state => state.userMgmt);
    const templates = useTypedSelector(state => state.reporting.TemplateDrafts);

    React.useEffect(() => {
        if (supllierAssessments?.status === 'idle') dispatch(getSupplierSurveys());
        if (Suppliers?.status === 'idle') dispatch(getSuppliers());
        if (allReports?.status === 'idle') dispatch(getAllReports());
        if (allUsers?.status === 'idle') dispatch(getAllUsers());
        if (allSurveys?.status === 'idle') dispatch(getMaterialitySurveys());
        if (allStakeHolders?.status === 'idle') dispatch(getGlobalStakeholders());
        if (businessGroups?.status === 'idle') dispatch(getBusinessGroups());
        if (businessUnits?.status === 'idle') dispatch(getBusinessUnits());
        if (derivedMetrics?.status === 'idle') dispatch(getDerivedMetrics());
        if (directMetricsData?.status === 'idle') dispatch(getMyMetricsData());
        if (derivedMetricsData?.status === 'idle') dispatch(getDerivedMetricDataByMonth());
        if (allFullMyDerivedMetrics?.status === 'idle') dispatch(getMyDerivedMetrics());
        if (allReports?.status === 'idle') dispatch(getAllReports());
        if (allUsers?.status === 'idle') dispatch(getAllUsers());
        if (templates?.status === 'idle') dispatch(getAllTemplateDrafts());
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: "S.No",
            dataIndex: "sno.",
            render: (value, record, index) => index + 1,

        },
        {
            title: "Section",
            dataIndex: "section_name",
            key: "section_name",
        },
        {
            title: "Download",
            dataIndex: "download",
            key: "download",
            render: (value, record) =>
                <div style={{ cursor: "pointer" }} onClick={async () => {
                    dispatch(PlatformDataDownload(record.key));
                }}
                >
                    <ExportExcelIcon fill={PrimaryTheme.primaryGreen} />
                </div>

        }
    ];
    const data = [
        {
            key: 1,
            section_name: "Data Manager",
            download: "Download"
        },
        /*{
            key: 3,
            section_name: "Entity Manager(Business groups)",
            download: "Download"
        },
        {
            key: 4,
            section_name: "Entity Manager(Business Units)",
            download: "Download"
        },
        {
            key: 7,
            section_name: "Reporting",
            download: "Download"
        },
        {
            key: 8,
            section_name: "Materiality",
            download: "Download"
        },
        {
            key: 9,
            section_name: "Stakeholders",
            download: "Download"
        },
        {
            key: 10,
            section_name: "Supplier Assessment",
            download: "Download"
        },
        {
            key: 11,
            section_name: "Suppliers",
            download: "Download"
        }*/
       {
            key: 12,
            section_name: "Emissions",
            download: "Download"
        },
    ]

    return (
        <>
            <TopSection />
            <div className='data-download'>
                <SettingsBackButton title='Back' style={{ margin: '1.5rem 0' }} />
                <NewBETable
                    data={data}
                    columns={columns}
                    search
                />

                <SetTableAndGraphDataDM />

            </div>
        </>
    );
}