import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import AddIcon from '../../../../../Components/BEIcons/AddIcon';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { MyMetric } from '../../../../../Redux/Types/dataManagerTypes';
import { patchMyDashboardMetrics, patchMyDerivedDashboardMetrics, postMyDashboardMetrics, postMyDerivedDashboardMetrics } from '../../../Actions';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { BEMessage } from '../../../../../Components/BEMessage';
import CheckIcon from '../../../../../Components/BEIcons/CheckIcon';

export interface IAddTrendsProps {
  open: boolean;
  setOpen: Function;
  load: boolean;
  type: string;
  selectedMetricsId: number[];
  maxGraphPosition: number;
}

export function SetMetrics(props: IAddTrendsProps) {

  const dispatch = useAppDispatch();
  const myDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDashboardMetrics);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const [selectedMetrics, setSelectedMetrics] = React.useState<any[]>([]);
  const [filteredMetrics, setFilteredMetrics] = React.useState<any[]>([]);
  const [addedDasboardMetric, setAddedDasboardMetric] = React.useState<any>(null);
  const myDerivedDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDerivedDashboardMetrics);
  const [load, setLoad] = React.useState<boolean>(props.load);
  const columns: ColumnsType<any> = [
    {
      title: 'Sr. No.',
      key: 'sno',
      render: (text, object, index) => index + 1,
      sorter: (a, b) => (props.selectedMetricsId.includes(a.id) ? 1 : -1),
      sortOrder: "descend",
    },
    {
      title: 'Metric Name',
      dataIndex: 'title',
      render: (text, record) =>
      {
        const title = record.custom_title ? record.custom_title : record.title;
        return title
      }
    },
    {
      title: 'Category',
      dataIndex: 'category'
    },
    {
      title: 'Unit',
      dataIndex: 'unit'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => <>
        {props.selectedMetricsId.includes(record.id) ?
          <BEButton
            style={{
              display: "flex", flexDirection: "row", justifyContent: "center",
              color: PrimaryTheme.primaryGreen, fontSize: "12px", border: "none",
              alignItems: "center", width: "100px", backgroundColor: PrimaryTheme.primaryGreenLight
            }}
          >
            {props.type?.toUpperCase()}
          </BEButton>
          :
          (
            record !== addedDasboardMetric ?
              <BEButton
                onClick={() => { !addedDasboardMetric && setAddedDasboardMetric(record) }}
                style={{
                  display: "flex", flexDirection: "row", justifyContent: "center",
                  color: PrimaryTheme.primaryGray, fontSize: "12px",
                  alignItems: "center", width: "100px",
                  cursor: addedDasboardMetric ? "not-allowed" : "pointer",
                  opacity: addedDasboardMetric ? "0.5" : "1"
                }}
                icon={
                  <div
                    style={{ height: "0.7rem" }}>
                    <AddIcon
                      inheritSize
                      stroke={PrimaryTheme.primaryGray}
                    />
                  </div>}
              >
                Select
              </BEButton> :
              <BEButton
                onClick={() => { setAddedDasboardMetric(null) }}
                style={{
                  display: "flex", flexDirection: "row", justifyContent: "center",
                  color: PrimaryTheme.primaryGreen, fontSize: "12px", border: "none",
                  alignItems: "center", width: "100px", backgroundColor: PrimaryTheme.primaryGreenLight
                }}
                icon={
                  <div
                    style={{ height: "0.7rem" }}>
                    <CheckIcon
                      inheritSize
                      stroke={PrimaryTheme.primaryGreen}
                    />
                  </div>}
              >
                Selected
              </BEButton>
          )}</>

    }
  ];

  const onCancel = () => {
    props.setOpen(false)
  }

  const addMetric = async (record: any) => {
    if (record === null) {
      BEMessage.error("Please select a metric to add");
      return;
    }
    setLoad(true);
    if (record?.hasOwnProperty('direct')) {
      // code for derived dashboard metrics
      const dashboardDerivedMetric = myDerivedDashboardMetrics?.data?.find((item) => item.my_derived_metric === record.id)
      if (dashboardDerivedMetric) {
        const data = props.type === "card" ? {
          card: true
        } :
          {
            position_graph: props.maxGraphPosition + 1,
            graph: true
          };
        await dispatch(patchMyDerivedDashboardMetrics(dashboardDerivedMetric.id, data));
        setLoad(false);
      } else {
        const data = props.type === "card" ? [{
          card: true,
          my_derived_metric: record.id,
          graph: false
        }] :
          [{
            graph: true,
            my_derived_metric: record.id,
            card: false,
            position_graph: props.maxGraphPosition + 1,
          }];
        await dispatch(postMyDerivedDashboardMetrics(data));
      }
    } else {
      const dashboardMetric = myDashboardMetrics?.data?.find((item) => item.my_metric === record.id)
      if (dashboardMetric) {
        const data = props.type === "card" ? {
          card: true
        } :
          {
            graph: true,
            position_graph: props.maxGraphPosition + 1,
          };
        await dispatch(patchMyDashboardMetrics(dashboardMetric.id, data));
        setLoad(false);
      } else {
        const data = props.type === "card" ? [{
          card: true,
          my_metric: record.id,
          graph: false
        }] :
          [{
            graph: true,
            my_metric: record.id,
            card: false,
            position_graph: props.maxGraphPosition + 1,
          }];
        await dispatch(postMyDashboardMetrics(data));
      }
    }
    setLoad(false);
    onCancel();
  }

  React.useEffect(() => {
    setAddedDasboardMetric(null);
    setFilteredMetrics(combinedSelectedMetricsPerYear?.data);
  }, [combinedSelectedMetricsPerYear, currentYear, props.open])



  React.useEffect(() => {
    const selectedMetricData = filteredMetrics.map((item) => {
      const newMetricData = {
        ...item,
        "unit": item?.unit,
        "category": item?.category
      }
      return newMetricData
    });
    setSelectedMetrics(selectedMetricData);
  }, [filteredMetrics])


  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      heading='Select Metric'
      width='fit-content'
      footer={
        <>
          <BEButton size='large' className='primary'
            loading={load}
            onClick={() => { addMetric(addedDasboardMetric) }}
          >
            Save and Next
          </BEButton>
          {' '}
          <BEButton size='large'
            onClick={onCancel}
          >
            Close
          </BEButton>
        </>
      }
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
        <div style={{ border: "1px solid rgba(243, 243, 243, 1)", padding: "1rem", borderRadius: "8px" }}>
          <BEInput
            style={{ width: "313px", marginBottom: "1rem" }}
            search
            placeholder="Search Metrics"
            size="large"
            onChange={(e) => {
              const value = e.target.value?.toLowerCase();
              const filteredData = combinedSelectedMetricsPerYear?.data?.filter((item: any) => {
                return item.title?.toLowerCase().includes(value) || item.custom_title?.toLowerCase().includes(value);
              });
              setFilteredMetrics(filteredData);
            }}
          />
          <BETable
            loading={load}
            columns={columns}
            data={selectedMetrics}
            rowClassName={(record: any, index: any) => record.title === "Empty" ? 'empty-row' : ''}
            pagination
          />
        </div>
      </div>
    </BEDrawer>
  );
}
