import { ColumnsType } from 'antd/es/table';
import { NewBETable } from '../../../Components/BETable/newBETable';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import React from 'react';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import BEConfirmModal from '../../../Components/BEConfirmModal';
import DeleteIcon from '../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { deleteDataLabels, getDataLabels } from '../../DataManager/Actions';
import { TopSection } from '../Components/TopSection';
import SettingsBackButton from '../Components/BackButton';
import EditIcon from '../../../Components/BEIcons/EditIcon';
import { AddLabels } from './Drawers/AddLabels';
import { BEButton } from '../../../Components/BEFormItems/BEButton';

export interface ILabelsProps {
}

export function Labels(props: ILabelsProps) {
    const allLabels = useTypedSelector((state) => state.dataManager.labels);
    const myMetricsPerYear = useTypedSelector((state) => state.onBoarding.metrics.myMetricsPerYear);
    const year = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [labelToDelete, setLabelToDelete] = React.useState<any>({});
    const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const columns: ColumnsType<any> = [
        {
            title: "Sr No.",
            dataIndex: "sr_no",
            render: (value, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Metric name",
            dataIndex: "my_metric",
            render: (value, record) => {
                const myMetric = myMetricsPerYear?.data?.find((metric) => metric.id === value);
                return myMetric?.custom_title || myMetric?.title;
            }
        },
        {
            title: "Number of labels",
            dataIndex: "number_of_labels",
        },
        {
            title: "Action",
            dataIndex: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div
                        style={{
                            cursor: record.id < 0 ?
                                "not-allowed" :
                                "pointer",
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "center",
                            flexDirection: "row"
                        }}
                    >
                        <p
                            style={{
                                height: "1rem",
                                width: "1rem",
                            }}
                            onClick={() => {
                                if (record.id < 0) {
                                    return;
                                }
                                setIsEdit(true);
                                setShowEditModal(true);
                            }}
                            onMouseEnter={() => {
                                if (record.id < 0) {
                                    return;
                                }
                                console.log("record", record);
                                setLabelToDelete(record);
                            }}
                        >
                            <EditIcon
                                inheritSize
                                stroke={PrimaryTheme.primaryGray}
                            />
                        </p>
                        <p
                            style={{
                                height: "1rem",
                                width: "1rem",
                            }}
                            onClick={() => {
                                if (record.id < 0) {
                                    return;
                                }
                                setShowDeleteModal(true);
                            }}
                            onMouseEnter={() => {
                                if (record.id < 0) {
                                    return;
                                }
                                setLabelToDelete(record);
                            }}
                        >
                            <DeleteIcon
                                inheritSize
                                stroke={PrimaryTheme.primaryGray}
                            />
                        </p>
                    </div>
                );
            }

        }
    ];

    const getTableData = () => {
        let myMetricSet = new Set<number>();
        let tableData: any[] = []
        allLabels?.data?.filter(
            (label) => label?.my_metric_data === null &&
                label?.business_unit === null
        )?.map((label, index) => {
            if (!myMetricSet.has(label?.my_metric as number)) {
                myMetricSet.add(label?.my_metric as number);
                let data = {
                    my_metric: label.my_metric,
                    number_of_labels: 1,
                    ids: [label.id]
                }
                tableData.push(data);
            } else {
                tableData.map((data) => {
                    if (data.my_metric === label.my_metric) {
                        data.number_of_labels += 1;
                        data?.ids?.push(label.id);
                    }
                })
            }
        })
        return tableData;
    }

    React.useEffect(() => {
        if (allLabels?.status === "idle") {
            dispatch(getDataLabels());
        }
    }, []);

    React.useEffect(() => {
        if (allLabels?.status === "success") {
            const data = getTableData();
            setTableData(data);
        }

    }, [allLabels])


    return (
        <>
            <TopSection />
            <div style={{ padding: "0 1.5rem" }}>
                <SettingsBackButton title="Back" style={{ margin: '1.5rem 0' }} />
                {/* <div style={{ float: "right", padding: "1rem 0" }}>
                    <YearSelectOptions
                        onChange={(value: number) => dispatch(setDateSlicerYearDM(value))}
                        value={year}
                    />
                </div> */}
                <NewBETable
                    data={tableData}
                    columns={columns}
                    loading={loading}
                    search
                    pagination
                    TableTopRightComponents={
                        <BEButton
                            onClick={() => {
                                setIsEdit(false);
                                setShowEditModal(true);
                            }}
                            className="primary"
                        >
                            Add new label
                        </BEButton>
                    }
                />
                <BEConfirmModal
                    title="Confirm Delete"
                    message={`Please note, deleting this label will impact ${labelToDelete?.ids?.count} entries.`}
                    visible={showDeleteModal}
                    setVisible={setShowDeleteModal}
                    onConfirm={async () => {
                        setLoading(true);
                        await Promise.all(labelToDelete?.ids.map((id: number) => {
                            dispatch(deleteDataLabels(id));
                        }));
                        setLoading(false);
                        setShowDeleteModal(false);
                    }}
                />
                <AddLabels
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    isEdit={isEdit}
                    currentMetric={isEdit ? labelToDelete.my_metric : null}
                />
            </div>
        </>
    );
}
