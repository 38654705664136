import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { rotateArray, showFYFormatByYear } from '../../../../../Config/Functions/UsefullFunctions';
import './style.scss';
import StackedColumnCharts from '../../../../../Components/BECharts/StackedColumnCharts';
import { shortMonths } from '../../../../../Config/Data/genralData';
import FactoryIcon from '../../../../../Components/BEIcons/FactoryIcon';

export interface IDashboardEmissionsByScopeProps {
}

export function DashboardEmissionsByScope (props: IDashboardEmissionsByScopeProps) {
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);

  const scope1Emissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const scope2Emissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const scope3Emissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope3Calculations);
  const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);
  const businessGroups = useTypedSelector((state) => state.entity.BusinessGroups);
  const buRelations = useTypedSelector((state) => state.entity.BusinessUnitRefs); //[{business_unit: 'bu1', business_group: 'bg1'}]
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);

  const [totalEmissions, setTotalEmissions] = React.useState<number>(0);
  const [businessUnitEmissions, setBusinessUnitEmissions] = React.useState<{business_unit: string, emissions: number}[]>([]);
  const [businessGroupEmissions, setBusinessGroupEmissions] = React.useState<{business_group: string, emissions: number}[]>([]);
  const [graphData, setGraphData] = React.useState<any>([]);
  const yearEndMonth = useTypedSelector(state => state.companies.yearEnd);

  React.useEffect(() => {
    if(scope1Emissions.status !== 'success' || scope2Emissions.status !== 'success' || scope3Emissions.status !== 'success') return;
    let tempTotal = 0;
    let tempBuEmissions:{[key: number]: number} = {};
    let tempBgEmissions:{[key: number]: number} = {};
    let tempGraphData:any = {};
    ['Scope 1', 'Scope 2', 'Scope 3'].forEach((scope) => {
      tempGraphData[scope] = {
        name: scope,
        data: Array(12).fill(0)
      }
    })

    const combinedData = [...scope1Emissions.data, ...scope2Emissions.data, ...scope3Emissions.data];
    combinedData.forEach((item) => {

      tempTotal += item.tco2e;

      tempGraphData[item.scope].data[item.month - 1] += item.tco2e;

      if(tempBuEmissions[item.business_unit]) tempBuEmissions[item.business_unit] += item.tco2e;
      else tempBuEmissions[item.business_unit] = item.tco2e;

      const business_groups = buRelations.data?.filter((bg) => bg.business_unit === item.business_unit).map((item) => item.business_group);
      business_groups.forEach((bg) => {
        if(tempBgEmissions[bg]) tempBgEmissions[bg] += item.tco2e;
        else tempBgEmissions[bg] = item.tco2e;
      })
    })

    const tempGraphDataArr = [];
    for(let key in tempGraphData){
      tempGraphDataArr.push({
        name: tempGraphData[key].name,
        data: rotateArray(tempGraphData[key].data, yearEndMonth, true)
      })
    }
    setGraphData(tempGraphDataArr);

    let tempBuArr = [];
    for(let key in tempBuEmissions){
      console.log(key)
      tempBuArr.push({
        business_unit: Number(key) === 0 ? 'Unassigned' : businessUnits.data?.find((item) => Number(item.id )=== Number(key))?.name || 'Unknown',
        emissions: tempBuEmissions[key]/tempTotal * 100
      })
    }
    setBusinessUnitEmissions(tempBuArr.sort((a,b) => b.emissions - a.emissions).slice(0,3))

    let tempBgArr = [];
    for(let key in tempBgEmissions){
      tempBgArr.push({
        business_group: businessGroups.data?.find((item) => Number(item.id) === Number(key))?.name || 'Unknown',
        emissions: tempBgEmissions[key]/tempTotal * 100
      })
    }
    setBusinessGroupEmissions(tempBgArr.sort((a,b) => b.emissions - a.emissions).slice(0,3))
    setTotalEmissions(tempTotal);  
  }, [scope2Emissions , scope3Emissions, scope1Emissions])

  return (
    <div className='dashboard-scope-emissions'>
      <BEWrapper type={2} title='GHG Emissions by Scope and Month' icon={<FactoryIcon inheritSize fill={'black'}/>}>

            {/* <div className='emissions-details'>

              <div className='detail-box' style={{alignItems:'center'}}>
                <div className='detail-box-container'>
                  <span className='total-value'>{totalEmissions.toFixed(2)}</span>
                  <span className='detail-box-heading'>tco2e</span>
                  <p className='detail-box-content'>Total emissions in {showFYFormatByYear(currentYear, yearEnd||"01-01")}</p>
                </div>
              </div>


              <div className='detail-box'>
                <div className='detail-box-container '>
                  <p className='detail-box-heading'>Highest emitters by Business Unit</p>
                  <div className='detail-content-container'>
                    {businessUnitEmissions.map((item, index) => (
                      <>
                        <p className='detail-box-content'>{
                          item.business_unit
                        }</p>
                        <span className='detail-box-content'><strong>{item.emissions.toFixed(2)}%</strong></span>
                      </>
                    ))}
                  </div>
                </div>  
              </div>

              <div className='detail-box'>
                <div className='detail-box-container '>
                  <p className='detail-box-heading'>Highest emitters by Business Group</p>
                  <div className='detail-content-container'>
                    {businessGroupEmissions.map((item, index) => (
                      <>
                        <p className='detail-box-content'>{item.business_group}</p>
                        <span className='detail-box-content'><strong>{item.emissions.toFixed(2)}%</strong></span>
                      </>
                    ))}
                  </div>
                </div>
              </div>

            </div> */}
            <br/><br/>
            <StackedColumnCharts 
              data={graphData} 
              xAxisLabels={rotateArray(shortMonths,yearEndMonth,true)} 
              colorTheme={4}
              yAxisLabel='tCO2e'
            />
            
      </BEWrapper>
    </div>
  );
}
