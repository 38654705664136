import * as React from 'react';
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBELineWithTextProps {
  text: string;
  style?: React.CSSProperties;
}

export function BELineWithText(props: IBELineWithTextProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',...props.style }}>
      <hr style={{ width: '100%', flexGrow: 1, borderColor: PrimaryTheme.primaryGreyLight }} />
      <span style={{ padding: '0 10px', whiteSpace: 'nowrap', color:PrimaryTheme.secondaryGray }}>{props.text}</span>
      <hr style={{ width: '100%', flexGrow: 1, borderColor: PrimaryTheme.primaryGreyLight }} />
    </div>
  );
}
