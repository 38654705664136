import * as React from 'react';
import { Form, FormInstance, Select } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import BEDatePicker from '../../../../../Components/BEDatePicker';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { EVENT_GROUP_CHOICES, EVENT_TYPE_CHOICES } from '../../../../../data/eventManager';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { BEEvidenceList } from '../../../../../Components/BEEventManagement/BEEvidenceList';

interface IStep1Props {
	formData: any
	form: FormInstance<any>
	setFile: (value: any) => void
	eviList: string[]
	evidenceList: string[]
	setEvidenceList: (value: string[]) => void
}

export function Step1(props: IStep1Props) {
	return (
		<>
			<div>
				<LableRequired>
					Upload evidences
				</LableRequired>
				<BEEvidenceList
					uploadEvidence
					evidenceList={props.eviList}
					evidenceFiles={props.evidenceList}
					setEvidenceList={props.setEvidenceList}
					setFile={props.setFile}
					disableOnClick
				/>
			</div>
			<br />
			<Form form={props.form} style={{ color: PrimaryTheme.primaryGray }} initialValues={props.formData}>
				<LableRequired>
					Event Title
				</LableRequired>
				<Form.Item name={'eventTitle'} rules={[{ required: true, message: 'Please enter event title' }]}>
					<BEInput size='large' />
				</Form.Item>
				<LableRequired>
					First Name
				</LableRequired>
				<Form.Item name={'firstName'} rules={[{ required: true, message: 'Please enter first name' }]}>
					<BEInput size='large' />
				</Form.Item>
				<LableRequired>
					Last Name
				</LableRequired>
				<Form.Item name={'lastName'} rules={[{ required: true, message: 'Please enter last name' }]}>
					<BEInput size='large' />
				</Form.Item>
				<LableRequired>
					Email
				</LableRequired>
				<Form.Item name={'email'} rules={[{ required: true, type: 'email', message: 'Please enter email' }]}>
					<BEInput size='large' />
				</Form.Item>
				<LableRequired>
					Specific area
				</LableRequired>
				<Form.Item name={'specificArea'} rules={[{ required: true, message: 'Please enter specific area' }]}>
					<BEInput size='large' />
				</Form.Item>
				<LableRequired>
					Event date
				</LableRequired>
				<Form.Item name={'eventDate'} rules={[{ required: true, message: 'Please enter event date' }]}>
					<BEDatePicker
						form={props.form}
						format='DD/MM/YYYY'
						field='eventDate'
					/>
				</Form.Item>
				<LableRequired>
					Event summary
				</LableRequired>
				<Form.Item name={'eventSummary'} rules={[{ required: true, message: 'Please enter event summary' }]}>
					<BETextArea
						count={{
							show: true,
							max: 500
						}}
						form={props.form}
						rows={8}
						placeholder=''
						maxLength={500}
					/>
				</Form.Item>
				<LableRequired>
					Event type
				</LableRequired>
				<Form.Item name={'eventType'} rules={[{ required: true, message: 'Please select event type' }]}>
					<Select>
						{EVENT_TYPE_CHOICES.map(option => (
							<Select.Option key={option.value} value={option.value}>
								{option.label}
							</Select.Option>
						))
						}
					</Select>
				</Form.Item>
				<LableRequired>
					Event category
				</LableRequired>
				<Form.Item name={'eventCategory'} rules={[{ required: true, message: 'Please select event category' }]}>
					<Select>
						{EVENT_GROUP_CHOICES.map(option => (
							<Select.Option key={option.value} value={option.value}>
								{option.label}
							</Select.Option>
						))
						}
					</Select>
				</Form.Item>
			</Form>
		</>
	);
}

