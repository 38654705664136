import * as React from 'react';
import { BESectionDropDown } from '../../../../../../Components/BESectionDropDown';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { setCurrentInvestigationReportSection } from '../../../../../../Redux/EventManagerReducer';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { EVENT_OWNER, LEAD_INVESTIGATOR } from '../../../../../../data/eventManager';

export interface ILeftSectionProps {
}

export function LeftSection(props: ILeftSectionProps) {
    const dispatch = useAppDispatch()
    const [data, setData] = React.useState<any>([])
    const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
    const currentEvidences = useTypedSelector(state => state.eventManager.eventEvidences);
    const currentInvestigationReportSection = useTypedSelector(state => state.eventManager.currentInvestigationReportSection);

    const getFilteredData = async () => {
        const SectionData = [
            {
                section: 'Event Details',
                onClick: () => { dispatch(setCurrentInvestigationReportSection('Details')) },
                current: currentInvestigationReportSection === 'Details'
            },
            {
                section: 'Investigation Details',
                onClick: () => { dispatch(setCurrentInvestigationReportSection('Investigation')) },
                current: currentInvestigationReportSection === 'Investigation'
            },
            {
                section: 'List of Actions',
                onClick: () => { dispatch(setCurrentInvestigationReportSection('Actions')) },
                current: currentInvestigationReportSection === 'Actions',
                disabled: ((currentEvent?.event_user_role === EVENT_OWNER || 
                    currentEvent?.event_user_role === LEAD_INVESTIGATOR) && (!(currentEvent.investigation_report)) ? true : false) && 
                    (currentEvent && !['Unsafe Act', 'Unsafe Condition'].includes(currentEvent.event_type))
            }
        ];
        setData(SectionData);
    };

    React.useEffect(() => {
        // if (data.length === 0)
        getFilteredData();
    }, [currentEvidences, currentEvent, currentInvestigationReportSection]);

    return (
        <div>
            <BESectionDropDown isDefaultSelected data={data} />
        </div>
    );
}
