import * as React from 'react';
import { BEDrawerType1 } from '../../../../../Components/BEDrawer/BEDrawerType1';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LeftSection } from './LeftSection';
import { InvestigationReportRightSection } from './RightSection';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getEventActions, getEventEvidences, patchEvent } from '../../Actions';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { EVENT_OWNER, LEAD_INVESTIGATOR } from '../../../../../data/eventManager';
import { BEMessage } from '../../../../../Components/BEMessage';
import { Form } from 'antd';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { CloseInvestigationReport } from '../Drawers/CloseInvestigationReportDrawer';
import { setCurrentInvestigationReportSection } from '../../../../../Redux/EventManagerReducer';

export interface IInvestigationReportProps {
    open: boolean;
    setOpen: Function;
    heading: string;
}

export function InvestigationReport(props: IInvestigationReportProps) {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
    const eventActions = useTypedSelector(state => state.eventManager.eventActions);
    const currentInvestigationReportSection = useTypedSelector(state => state.eventManager.currentInvestigationReportSection);
    const [loading, setLoading] = React.useState(false);
    const [investgitaionRejectionDrawer, setInvestigationRejectionDrawer] = React.useState(false);
    const [showInvestigationForm, setShowInvestigationForm] = React.useState(false);
    const [isReject, setIsReject] = React.useState(true);

    React.useEffect(() => {
        if (currentEvent) {
            dispatch(getEventActions(currentEvent?.id as number));
            dispatch(getEventEvidences(currentEvent?.id));
        }
    }, [currentEvent]);

    return (
        <>
            <BEDrawerType1
                open={props?.open}
                setOpen={props?.setOpen}
                heading={props?.heading}
                footerBackgroundColour={'white'}
                footerBorderTop={`1px solid ${PrimaryTheme.primaryGreyLight}`}
                leftBody={
                    <LeftSection />
                }
                rightBody={
                    <InvestigationReportRightSection form={form} showInvestigationForm={showInvestigationForm} />
                }
                leftFooter={
                    currentEvent?.event_user_role === EVENT_OWNER ?
                        <>
                            {(currentInvestigationReportSection === 'Investigation') ?
                                <>
                                    {currentEvent?.stage === 3 && currentEvent?.root_cause && <div style={{ display: 'flex', gap: '1rem' }}>
                                        <BEButton
                                            className='primary'
                                            size='large'
                                            loading={loading}
                                            onClick={async () => {
                                                if (currentEvent) {
                                                    setLoading(true);
                                                    await dispatch(patchEvent(currentEvent?.id, {
                                                        stage: 5,
                                                    })).then((status) => 
                                                        status ? 
                                                            BEMessage.success('Investigation report approved successfully')
                                                        : BEMessage.error('Failed to approve event')
                                                    );
                                                    setLoading(false);
                                                }
                                            }}
                                        >
                                            Approve
                                        </BEButton>
                                        <BEButton
                                            className='secondary'
                                            size='large'
                                            loading={loading}
                                            onClick={() => {
                                                setIsReject(true);
                                                setInvestigationRejectionDrawer(true);
                                            }}
                                        >
                                            Reject
                                        </BEButton>
                                    </div>
                                    }
                                </>
                                :
                                <>
                                    {eventActions.status === 'loading' ? <GlobalLoader height='1rem' /> : null}
                                    {eventActions.status === 'success' && currentInvestigationReportSection === 'Actions' && currentEvent.stage !== 4 &&
                                        <BEButton
                                            className='primary'
                                            size='middle'
                                            loading={loading}
                                            onClick={() => {
                                                if (eventActions?.data?.length !== 0 && eventActions?.data?.filter(action => action.stage !== 2).length === 0) {
                                                    if (currentEvent) {
                                                        setIsReject(false);
                                                        setInvestigationRejectionDrawer(true);
                                                    }
                                                } else {
                                                    BEMessage.warning('All actions should be completed before closing the event.');
                                                }
                                            }}
                                        >
                                            Close Event
                                        </BEButton>}
                                </>
                            }
                            {
                                currentEvent.stage === 4 &&
                                <BEButton
                                    className='primary'
                                    size='middle'
                                    loading={loading}
                                    onClick={() => {
                                        if (currentEvent) {
                                            setLoading(true);
                                            if(currentEvent?.lead_investigator){
                                                dispatch(patchEvent(currentEvent?.id, {
                                                    stage: 3,
                                                }));
                                            }else{
                                                dispatch(patchEvent(currentEvent?.id, {
                                                    stage: 1,
                                                }));
                                            }
                                            setLoading(false);
                                        }
                                    }}
                                >
                                    Reopen
                                </BEButton>
                            }
                        </>
                        :
                        (currentEvent?.event_user_role === LEAD_INVESTIGATOR &&
                            currentInvestigationReportSection === 'Investigation') ?
                            <>
                                {(!(currentEvent?.root_cause) || (currentEvent?.stage === 6 && showInvestigationForm)) &&
                                    <>
                                        <div style={{ display: 'flex', gap: '1rem' }}>
                                            <BEButton size='large' className='primary' onClick={() => { form.submit() }}>
                                                Save
                                            </BEButton>
                                            {/* <BEButton size='large' className='secondary-green'>
                                    Save & add action
                                </BEButton> */}
                                        </div>

                                    </>
                                }
                                {currentEvent?.stage === 6 && currentEvent?.rejection_reason && !showInvestigationForm &&
                                    <BEButton className='primary' onClick={() => setShowInvestigationForm(true)}>
                                        Investigate
                                    </BEButton>
                                }
                            </>
                            : null
                }

            />
            <CloseInvestigationReport
                open={investgitaionRejectionDrawer}
                setOpen={setInvestigationRejectionDrawer}
                isReject={isReject}
            />
        </>
    );
}
