import * as React from 'react';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { StepCollapse } from '../../../../../../Components/BECollapse/StepCollapse';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { setCreateSurveyStep } from '../../../../../../Redux/MatirialityReducer';
import { getSupplierSelectedQuestions } from '../../../../Actions/supplierFormActions';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../../../Components/BETable';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import { updateSupplierQuestions } from '../../../../../../Redux/SupplierReducer';
import { patchSupplierQuestions } from '../../../../Actions/supplierAction';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import './style.scss';
import { SupplierAssessmentWeitageInputBox } from './WeightageinputBox';

export interface ISupplierAssessmentAddWeightageProps {
  open: boolean;
  setOpen: Function;
  onCancle: Function;
}

export function SupplierAssessmentAddWeightage (
  props: ISupplierAssessmentAddWeightageProps
) {
  const dispatch = useAppDispatch();
  const supplierQuestions = useTypedSelector(
    state => state.supplier.SupplierQuestions
  );
  const supplierCreateSurveyStep = useTypedSelector(
    state =>state.supplier.createSurveyStep
  );
  const currentAssessment = useTypedSelector(
    state =>state.supplier.currentAssessment
  );
  const createSurveyStep = useTypedSelector(
    state => state.supplier.createSurveyStep
  );

  React.useEffect(() => {
    if(props.open) dispatch(getSupplierSelectedQuestions(
      undefined,currentAssessment?.id,true
    ))
  },[props.open,currentAssessment, createSurveyStep])

  const columns: ColumnsType<any> = [
    {
      title: 'S No',
      render: (value, record, index) => index + 1,
      width: '5%',
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: '50%',
    },
    {
      title: 'Marks',
      key: 'marks',
      width: '15%',
      render: (value, record) => {
        return (
          <SupplierAssessmentWeitageInputBox record={record} type='marks'/>
        )
      }
    },
    {
      title: 'Score Weightage(%)',
      key: 'weightage',
      width: '15%',
      render: (value, record) => {
        return (
          <SupplierAssessmentWeitageInputBox record={record} type='weight'/>
        )
      },
    },
    {
      title: 'Include in scoring',
      key: 'include',
      render: (value, record) => {
        return (
          <SupplierAssessmentWeitageInputBox record={record} type='include'/>
        )
      },
    }
  ]

  return (
      <StepCollapse
        step={3}
        heading="Set Weightage and Marks"
        subheading="
          Set the weightage and marks for each question
        "
        open={props.open}
        setOpen={() => {
          dispatch(setCreateSurveyStep(
            supplierCreateSurveyStep === 3 ? 0 : 3
          ))
        }}
      >
        
    <div className='supplier-assessment-add-weightage'>
        <BETable
          pagination
          columns={columns}
          data={supplierQuestions.data}
          loading={supplierQuestions.status === 'loading'}
        />

        <div className='footer-actions'>
          <BEButton size='large' className='primary' onClick={() => dispatch(setCreateSurveyStep(4))}>
            Save & Next
          </BEButton>
          <BEButton size='large' onClick={() => props.onCancle()}>
            Cancel
          </BEButton>
        </div>
      </div>
    </StepCollapse>
  );
}
