import * as React from 'react';
import './styles.scss';
import { Form, Steps } from 'antd';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { punchEvent, postEventUser, punchEventEvidences } from '../../../Features/EventManager/EventManager/Actions';
import { useLocation, useSearchParams } from 'react-router-dom';
import { BEMessage } from '../../../Components/BEMessage';
import dayjs from 'dayjs';
import CurrentIcon from '../../../Components/BEIcons/StepCurrentIcon';
import SuccessIcon from '../../../Components/BEIcons/StepSuccessIcon';
import PendingIcon from '../../../Components/BEIcons/StepPendingIcon';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';

interface IPunchEventDrawerProps {
}

const stepNames = ['Mandatory', 'Optional'];
export function PunchEventDrawer(props: IPunchEventDrawerProps) {
	const location = useLocation();
	const [file, setFile] = React.useState<any>(null);
	const [eviList, setEviList] = React.useState<string[]>([]);
	const [evidenceList, setEvidenceList] = React.useState<string[]>([]);

	const [step, setStep] = React.useState(1);
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = React.useState(false);
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [formData, setFormData] = React.useState<any>({});

	React.useEffect(() => {
		if (location.pathname.includes('/punch-event')) {
			if(window.FreshworksWidget) {
				window?.FreshworksWidget?.("hide");
			}
		}
		return () => {
			if(window.FreshworksWidget) {
				window?.FreshworksWidget?.("show");
			}
		}
	}, [location.pathname]);
	
	const handleAddEvent = async (values: any) => {
		const date = values.eventDate.format('YYYY-MM-DD');
		let time: any = null;
		if (values.eventTime) {
			time = values.eventTime.format('HH:mm:ss');
		}
		let bu_id: any = searchParams.get('bu_id');
		let org_id: any = searchParams.get('org_id');
		bu_id = bu_id ? parseInt(bu_id) : null;
		org_id = org_id ? parseInt(org_id) : null;

		if (evidenceList?.length === 0) {
			BEMessage.error('Please upload at least evidence');
			return;
		}

		if (!bu_id || !org_id) {
			BEMessage.error('Please scan the QR code again');
			return;
		}

		const eventUserData = {
			email: values.email,
			first_name: values.firstName,
			last_name: values.lastName,
			org: org_id,
			event_role: "EVENT_REPORTER"
		}
		setLoading(true);
		await dispatch(postEventUser(eventUserData, false)).then(async (res) => {
			console.log(res);
			const eventData = {
				org: org_id,
				business_unit: bu_id,
				reported_by: res?.data?.id,
				title: values.eventTitle,
				date: date,
				time: time,
				actual_severity: values.actualSeverity,
				potential_severity: values.potentialSeverity,
				actual_impact: values.actualImpact,
				potential_impact: values.potentialImpact,
				event_type: values.eventType,
				event_group: values.eventCategory,
				description: values.eventSummary,
				specific_location: values.specificArea,
				immediate_action: values.immediateAction,
				immediate_reason: values.immediateReasons
			}
			await dispatch(punchEvent(eventData, res?.data?.secret))
				.then(async (res1: any) => {
					const eventId = res1.data.id;
					Promise.all(evidenceList.map(async (file: any) =>
						await dispatch(punchEventEvidences(eventId, { file: file }, res?.data?.secret)))).then(() => {
							setLoading(false);
							setStep(3);
						}).catch((err) => {
							console.log(err);
							BEMessage.error('Something went wrong');
							setLoading(false);
							setStep(1);
						});
				}).catch((err) => {
					console.log(err);
					BEMessage.error('Something went wrong');
					setLoading(false);
					setStep(1);
				});
		}).catch((err) => {
			console.log(err);
			BEMessage.error('Something went wrong');
			setLoading(false);
			setStep(1);
		});
	}

	const handleFinish = () => {
		form2.validateFields().then((values) => {
			setFormData({ ...formData, ...values });
			handleAddEvent({ ...formData, ...values });
		}).catch((err) => {
			console.log(err);
		});
	}

	const handleBack = () => {
		setStep(1);
	}

	const handleAddNewEvent = () => {
		setFormData({});
		form1.resetFields();
		form2.resetFields();
		setEvidenceList([]);
		setEviList([]);
		setStep(1);
	}

	const customDot = (dot: any, { status, index }: { status: any, index: any }) => {
		if (status === 'process') {
			return (
				<CurrentIcon />
			);
		} else if (status === 'finish') {
			return (
				<SuccessIcon />
			);
		} else {
			return <PendingIcon />
		}
	};

	React.useEffect(() => {
		const currentDate = dayjs();
		form1.setFieldsValue({
			eventDate: currentDate
		});
	}, []);

	React.useEffect(() => {
		if (file) {
			setEvidenceList([...evidenceList, file]);
			setEviList([...eviList, file?.name]);
		}
	}, [file]);

	const handleNext = () => {
		if (step === 1) {
			if (evidenceList?.length === 0) {
				BEMessage.error('Please upload at least evidence');
				return;
			}
			form1.validateFields().then((values) => {
				setFormData({ ...formData, ...values });
				setStep(2);
			}).catch((err) => {
				console.log(err);
			});
		}
	}

	return (
		// <BEDrawer
		// 	heading='Punch Event'
		// 	isMobile
		// 	open={true}
		// 	setOpen={() => { }}
		// 	closeIcon={true}
		// 	width='100%'
		// 	footer={null}
		// >
		<div style={{ margin: '1rem' }}>
			<div>
				<h2 style={{ marginBottom: '1rem' }}>Punch Event</h2>

			</div>
			{step !== 3 && <div className='punch-steps' style={{ margin: '2rem' }}>
				<Steps
					size='small'
					direction='horizontal'
					responsive={false}
					progressDot={customDot}
					labelPlacement='vertical'
					current={step - 1}
					items={stepNames.map((name) => {
						return {
							title: name,
						}
					})}
				/>
			</div>}
			{step === 1 &&
				<Step1
					form={form1}
					formData={formData}
					setFile={setFile}
					eviList={eviList}
					evidenceList={evidenceList}
					setEvidenceList={setEvidenceList}
				/>}
			{step === 2 &&
				<Step2
					form={form2}
					formData={formData}
				/>}
			{
				step === 3 &&
				<Step3
					businessUnit={searchParams.get('bu_id')}
					handleAddNewEvent={handleAddNewEvent}
				/>
			}
			<>
				{step === 1 &&
					<BEButton
						style={{ width: '100%' }}
						loading={loading}
						className='primary'
						size='large'
						onClick={() => handleNext()}
					>
						Next
					</BEButton>
				}
				{step === 2 &&
					<div style={{ display: 'flex', gap: '1rem' }}>
						<BEButton
							style={{ width: '50%' }}
							className='secondary-green'
							size='large'
							onClick={() => handleBack()}
						>
							Back
						</BEButton>
						<BEButton
							style={{ width: '50%' }}
							loading={loading}
							className='primary'
							size='large'
							onClick={() => handleFinish()}
						>
							Add event
						</BEButton>
					</div>
				}
			</>
			{/* <BEConfirmModal
				title={'Event registered successfully'}
				message={'Event has been registered successfully. Please close this tab and scan the QR code again if you want to add more events.'}
				visible={eventPunched}
				setVisible={setEventPunched}
				type='success'
				onConfirm={() => { window.close() }}
				hideButtons
			/> */}
		</div>
		// </BEDrawer>
	);
}
