import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../../Components/BETable';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { ReportingReviewStatusButton } from '../StatusButton';

export interface IType0ReviewTableProps {
    category: string;
    section: string;
    setOpenDrawer: Function;
    setCurrentTopic: Function;
    isPDFTemplate?: boolean;
}

export function Type0ReviewTable(props: IType0ReviewTableProps) {
    const dispatch = useAppDispatch();
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const reportData = useTypedSelector(state => state.reporting.reportData);
    const evidence = useTypedSelector(state => state.reporting.evidence);
    const [tableData, setTableData] = React.useState<any>([]);

    const columns: ColumnsType<any> = [
        {
            title: 'Disclosure Code',
            dataIndex: 'disclosure_code',
            key: 'disclosure_code',
        }, {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            render: (text: string, record: any) => {
                return <p style={{ maxWidth: '20rem' }}>{record.topic}</p>
            }
        }, {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string, record: any) => {
                return <p style={{ maxWidth: '10rem' }}>{record.description}</p>
            }
        },
        {
            title: 'Evidence',
            render: (text: string, record: any) => {
                if (evidence?.data.filter((evidence2: any) => evidence2.unique_code === record.unique_code)?.length > 0)
                    return (
                        <div
                            onMouseEnter={() => props.setCurrentTopic(record.topic)}
                            onClick={() => props.setOpenDrawer(true)}>
                            <BEStatusTag size='large' status='info' >

                                Evidence
                            </BEStatusTag>
                        </div>
                    );
            }
        }, {
            title: 'Status',
            render: (text: string, record: any) => {
                return <ReportingReviewStatusButton
                    uniqueCode={record.unique_code}
                    section={props.section}
                />
            }
        }
    ]

    React.useEffect(() => {
        if (selectedTopics?.status === 'success' && reportData?.status === 'success') {
            const dataToUse = selectedTopics?.data.filter((topic) => topic.section === props.section && topic.category === props.category && topic.table_type === 0);
            let tempData: any = [];
            dataToUse.forEach((item) => {
                tempData.push({
                    key: item.id,
                    disclosure_code: item.disclosure_code,
                    topic: item.topic,
                    value: reportData?.data[item.id]?.[0]?.data,
                    evidence: 'View',
                    status: 'Approved',
                    unique_code: item.unique_code,
                    description: reportData?.data[item.id]?.[0]?.additional_information
                })
                setTableData(tempData);
            })
        }
    }, [selectedTopics, reportData, props.section, props.category]);
    return (
        <div className='type0-table'>
            {
                tableData?.length > 0 &&
                <BETable
                    data={tableData}
                    columns={props.isPDFTemplate ? columns.slice(0, 4) : columns}
                />
            }
        </div>
    );
}
