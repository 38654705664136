import * as React from 'react';
import { useParams } from 'react-router-dom';
import './style.scss';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getAllReports, getReportData, getReportingEvidence, getReportingTopicStatus, getTemplateTopics, patchReportTemplate, patchReportingTopicStatus } from '../../Actions/template';
import { setCurrentReport, setSelectedTemplateTopics } from '../../../../Redux/ReportingReducer';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { TemplateSelectedTopicTypes } from '../../../../Redux/Types/reportingTypes';
import { ReviewTable } from './ReviewTable';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { BEMessage } from '../../../../Components/BEMessage';

export interface IApproveReportProps {
  isPDFTemplate?: boolean;
}


export function ApproveReport(props: IApproveReportProps) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const reportID = Number(params.report_id);
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  const reportData = useTypedSelector(state => state.reporting.reportData);
  const allReports = useTypedSelector(state => state.reporting.allReports);
  const topicsStatus = useTypedSelector(state => state.reporting.topicStatus);
  const reportEvidence = useTypedSelector(state => state.reporting.evidence);
  const [loadingApproveAll, setLoadingApproveAll] = React.useState(false);

  React.useEffect(() => {
    if(props.isPDFTemplate) return;
    if (allReports?.status === 'idle') dispatch(getAllReports());
    dispatch(getReportData(reportID));
    dispatch(getReportingTopicStatus(reportID));
    dispatch(getReportingEvidence(reportID));
  }, [])

  React.useEffect(() => {
    if (allReports?.status === 'success' && !props.isPDFTemplate) {
      getSelectedTopicsFun();
    }
  }, [reportID, allReports])


  const getSelectedTopicsFun = async () => {
    const thisReport = allReports?.data.filter((report: any) => report.id === reportID)[0];
    dispatch(setCurrentReport(thisReport));
    const dataToSet = await dispatch(getTemplateTopics(thisReport.template, true));
    if (dataToSet) {
      dispatch(setSelectedTemplateTopics({
        status: 'success',
        data: dataToSet.sort((a: TemplateSelectedTopicTypes, b: TemplateSelectedTopicTypes) => a.unique_code.localeCompare(b.unique_code))
      }))
    }
  }

  const handleApproveAll = async () => {
    setLoadingApproveAll(true);
    await Promise.all([
      ...topicsStatus?.data.map(async (topic) => {
        if (topic?.status !== 2)
          await dispatch(patchReportingTopicStatus(topic.id, { status: 2 }, true))
      }),
      await dispatch(patchReportTemplate(reportID, { stage: 5 },true))
    ])
    setLoadingApproveAll(false);
    BEMessage.success('All topics approved successfully');
  }

  if (selectedTopics?.status !== 'success' || reportData?.status !== 'success') return <>
    {
      props.isPDFTemplate ? null :
        <GlobalLoader />
    }
  </>
  if (props.isPDFTemplate) return <Screen isPDFTemplate={true}/>
  return (
    <BEDrawer
      theme='dark'
      heading={'Approve - ' + allReports?.data.filter((report: any) => report.id === reportID)[0]?.name}
      footer={
        <>
          <BEButton className='primary' size='large'
            onClick={async () => {
              let stage = allReports?.data.filter((report: any) => report.id === reportID)[0]?.stage
              if( stage !== 4){
                BEMessage.success(`Report ${stage === 5 ? 'approved' : 'rejected'} successfully`);
                navigateTo('./home/reporting/reports');
              }
              else BEMessage.error('Please approve or reject all topics first');
            }}
          >
            Save
          </BEButton>
          <BEButton size='large' onClick={async () => {
            await handleApproveAll()
            dispatch(getReportingTopicStatus(reportID));
          }}
            loading={loadingApproveAll}
          >
            Approve All
          </BEButton>
          <BEButton 
            disabled={allReports?.data.filter((report: any) => report.id === reportID)[0]?.stage !== 5}
            size='large' 
            onClick={async () => {
              await dispatch(patchReportTemplate(reportID, 
              { 
                stage: 7 ,
                published_on: new Date()
              },true))
            }}
          >
            Publish
          </BEButton>
        </>
      }
      width='100%'
      open={true}
      setOpen={(e: any) => { !e && navigateTo('./home/reporting/reports') }}
    >
      <Screen />
    </BEDrawer>
  );
}

function Screen(props:IApproveReportProps) {
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  const reportData = useTypedSelector(state => state.reporting.reportData);
  return (
    <div className='approve-report-box' id='componentToCapture'>
      <div className='approve-report'>
        {
          Array.from(new Set(selectedTopics?.data.map((topic: TemplateSelectedTopicTypes) => topic.section))).map((section: string) => {
            return (
              <div className='section-box'>
                <p>{section}</p>
                <div>
                  { // array of catagories of topics
                    Array.from(new Set(selectedTopics?.data.filter((topic2: TemplateSelectedTopicTypes) => topic2.section === section).map((topic3: TemplateSelectedTopicTypes) => topic3.category))).map((category: string) => {
                      return (
                        <div className='catagory-box'>
                          <p>Category: {category}</p>
                          <div>
                            <ReviewTable section={section} category={category} isPDFTemplate={props.isPDFTemplate}/>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
