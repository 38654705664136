import * as React from 'react';
import { BEInput } from '../../../../../../../Components/BEFormItems/BEInput';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { updateSupplierQuestions } from '../../../../../../../Redux/SupplierReducer';
import { patchSupplierQuestions } from '../../../../../Actions/supplierAction';
import BESwitch from '../../../../../../../Components/BEFormItems/BESwitch';
import { BEMessage } from '../../../../../../../Components/BEMessage';

export interface ISupplierAssessmentWeitageInputBoxProps {
  record: any;
  type: 'weight' | 'marks' | 'include';
}

export function SupplierAssessmentWeitageInputBox (
  props: ISupplierAssessmentWeitageInputBoxProps
) {
  const dispatch = useAppDispatch()
  const fieldToUpdate = props.type === 'marks' ? 'marks' : 'weightage';
  const [value, setValue] = React.useState(props.record?.marks);

  React.useEffect(() => {
    setValue(props.record?.[fieldToUpdate])
  }, [props.record]);

  const handleBlur = () => {
    if(props.type === 'marks') {
      // check if it is a positive number
      if(Number(value) > 0) {
        dispatch(updateSupplierQuestions(
          {
            my_question_id: props.record?.my_question_id,
            data: {
              marks: Number(value)
            }
          }
        ))
      } 
      else{
        setValue(props.record?.marks)
        return BEMessage.error('Marks should be a positive number')
      }
    }
    else if(props.type === 'weight') {
      // check if it is a positive number <= 100
      if(Number(value) >= 0 && Number(value) <= 100) {
        dispatch(updateSupplierQuestions(
          {
            my_question_id: props.record?.my_question_id,
            data: {
              weightage: Number(value)
            }
          }
        ))
      } 
      else{
        setValue(props.record?.weightage)
        return BEMessage.error('Weightage should be a positive number less than or equal to 100')
      }
    }
    dispatch(patchSupplierQuestions(
      props.record?.my_question_id,
      {
        [fieldToUpdate]: Number(value) || 0
      }
    ))
  }

  if(props.type === 'include') return(
      <BESwitch
        loading={false} 
        checked={props.record?.weightage > 0}
        onClick={(checked) => {
          dispatch(updateSupplierQuestions(
              {
                my_question_id: props.record?.my_question_id,
                data: {weightage: !checked ? 100 : 0}
              })
          )
          dispatch(patchSupplierQuestions(
            props.record?.my_question_id,
            {weightage: !checked ? 100 : 0}
          ))
        }}
      />
  )
  return (
        <BEInput
            type='number'
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              // dispatch(
              //   updateSupplierQuestions(
              //     {
              //       id: props.record?.my_question_id,
              //       data: {
              //         [fieldToUpdate]: e.target.value
              //       }
              //     }
              //   )
              // )
            }}
            onBlur={() => {
              handleBlur()
            }}
          />
  );
}
