import * as React from 'react';
import FilledArrow from '../../../../Components/BEIcons/FilledArrowIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import './style.scss';
import BEStatusTag from '../../../../Components/BEStatusTag';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';

export interface IComparisonCardProps {
  data: {title: string, value: number|null, previousValue?: number}[];
  unit?: string;
}

const IncreaseSymbol = () => {
  return <div className='increase-symbol'>
    <div><FilledArrow fill={PrimaryTheme.primaryGreen} inheritSize/></div>
  </div>
}

const DecreaseSymbol = () => {
  return <div className='decrease-symbol'>
    <div><FilledArrow fill={PrimaryTheme.primaryRedError} inheritSize/></div>
  </div>
}

export function ComparisonCard (props: IComparisonCardProps) {
  return (
    <div className='comparison-card'>
      {
        props.data.map((item, index) => {
          const change = (item.value || 0) - (item.previousValue || 0);
          return (
            <div key={index} className='comparison-card-item'>
              {
                change >= 0 ? <IncreaseSymbol/> : <DecreaseSymbol/>
              }
              <div className='comparison-card-item-content'>
                <p className='title'>{item.title}</p>

                <div className='value-change'>
                  <p className='value'>
                    {
                      item.value === null ? 'N/A' :
                      <>
                        {AddCommaToNumber(item.value,false,true,true)}  {' '}
                        {props.unit ? props.unit : ''}
                      </>
                    }
                  </p>
                  {
                  item.previousValue !== undefined &&
                  <>
                    <BEStatusTag  
                      size='small'
                      status={
                      change >= 0 ? 'success' : 'error'
                      }
                    >
                      {item.previousValue === 0 ? '':change >= 0 ? '+ ' : '- '} 
                      {
                        item.previousValue === 0 ? 'N/A':
                        Math.abs(change/item.previousValue*100).toFixed(2)+'%'
                      }
                    </BEStatusTag>
                    <p className='change'>
                      {
                      item.previousValue === 0 ?
                        '':
                          change >= 0 ? 'increased' : 'decreased'
                      }
                    </p>
                  </>
                  }
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  );
}
