import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import AddIcon from '../../../../../Components/BEIcons/AddIcon';
import CheckIcon from '../../../../../Components/BEIcons/CheckIcon';
import { SetupTarget } from '../SetupTargets';
import { myMetricType } from '../../../../../Redux/Types/dataManagerTypes';
import { MyMetricTargetsType } from '../../../../../Redux/Types/targetTypes';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import ToolTip from '../../../../../Components/BEToolTip';
import { BEMessage } from '../../../../../Components/BEMessage';

export interface ISelectMetricProps {
    open: boolean;
    setOpen: Function;
    // type: string;
    level: string;
}

export function SelectMetric(props: ISelectMetricProps) {

    const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
    const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const [tableData, setTableData] = React.useState<any>([]);
    const [selectedMetric, setSelectedMetric] = React.useState<any>(null);
    const [openSetupTarget, setOpenSetupTarget] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const columns: ColumnsType<any> = [
        {
            title: 'Sr. No.',
            key: 'sno',
            render: (text, object, index) => index + 1
        },
        {
            title: 'Metric Name',
            dataIndex: 'title',
            render: (text, record) =>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    {record.custom_title ? record.custom_title : record.title}
                    {
                        record.type === 'derived' &&
                        <ToolTip title='The values for C metrics are calculated.'>
                            <BEStatusTag status='success'>C</BEStatusTag>
                        </ToolTip>
                    }
                </div>
        },
        {
            title: 'Category',
            dataIndex: 'category'
        },
        {
            title: 'Unit',
            dataIndex: 'unit'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => <>
                {record !== selectedMetric ?
                    <BEButton
                        onClick={() => { !selectedMetric && setSelectedMetric(record) }}
                        style={{
                            display: "flex", flexDirection: "row", justifyContent: "center",
                            color: PrimaryTheme.primaryGray, fontSize: "12px",
                            alignItems: "center", width: "100px",
                            cursor: selectedMetric ? "not-allowed" : "pointer",
                            opacity: selectedMetric ? "0.5" : "1"
                        }}
                        icon={
                            <div
                                style={{ height: "0.7rem" }}>
                                <AddIcon
                                    inheritSize
                                    stroke={PrimaryTheme.primaryGray}
                                />
                            </div>}
                    >
                        Select
                    </BEButton> :
                    <BEButton
                        onClick={() => { setSelectedMetric(null) }}
                        style={{
                            display: "flex", flexDirection: "row", justifyContent: "center",
                            color: PrimaryTheme.primaryGreen, fontSize: "12px", border: "none",
                            alignItems: "center", width: "100px", backgroundColor: PrimaryTheme.primaryGreenLight
                        }}
                        icon={
                            <div
                                style={{ height: "0.7rem" }}>
                                <CheckIcon
                                    inheritSize
                                    stroke={PrimaryTheme.primaryGreen}
                                />
                            </div>}
                    >
                        Selected
                    </BEButton>
                }</>
        }
    ];

    React.useEffect(() => {
        setTableData(combinedSelectedMetricsPerYear?.data);
        setLoading(combinedSelectedMetricsPerYear?.status === 'loading' || metrics?.status === 'loading');
    }, [currentYear, combinedSelectedMetricsPerYear, metrics, props.open]);

    React.useEffect(() => {
        if (props.open) {
            setSelectedMetric(null);
        }
    }, [props.open]);

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading='Select metric'
            width='50vw'
            footer={
                <>
                    <BEButton size='large' className='primary'
                        onClick={() => {
                            if(selectedMetric){
                                setOpenSetupTarget(true);
                                props.setOpen(false);
                            }else{
                                BEMessage.error('Please select a metric to add')
                            }
                        }}
                    >
                        Save and next
                    </BEButton>
                    <BEButton size='large'
                        onClick={() => props.setOpen(false)}
                    >
                        Close
                    </BEButton>
                </>
            }
        >
            {loading ?
                <GlobalLoader height='50vh' /> :
                <NewBETable
                    data={tableData}
                    columns={columns}
                    pagination
                    search
                />}
            <SetupTarget
                open={openSetupTarget}
                setOpen={setOpenSetupTarget}
                selectedMetric={selectedMetric}
                // type={props.type}
                level={props.level}
            />
        </BEDrawer>
    );
}