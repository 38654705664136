import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { Form } from 'antd';

interface IAddNotesProps {
	open: boolean;
	setOpen: Function;
	uniqueCode: string;
}

export function AddNotes (props: IAddNotesProps) {
	const [form] = Form.useForm();
	return (
	  <BEDrawer
		setOpen={props.setOpen}
		heading="Add Notes"
		open={props.open}
		footer={
			<>
				
			</>
		}
	  >
		<p>Notes</p>
		<BETextArea 
			placeholder={'Enter here'}
			form={form}
			showCount
			maxLength={256}
			rows={5}
		/>
	  </BEDrawer>
	);
}
