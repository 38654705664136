import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { AppDispatch, RootState } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import {
    GET_ALL_METRICS,
    ADD_METRICS, GET_SELECTED_METRICS,
    DELETE_METRICS, PATCH_SELECTED_METRICS_STATUS,
    GET_METRICS_BY_FRAMEWORK,
    GET_SINGLE_MY_METRIC
} from "../../Utils/Routes/Onboarding";
import {
    setCombinedSelectedMetrics,
    setMetrics,
    setMyMetrics

} from "../../Redux/OnboardingReducer";
import { formMetricQueryParams, getMyDerivedMetrics } from "../../Features/DataManager/Actions";
import { Metric, MyMetric } from "../../Redux/Types/dataManagerTypes";

// changed
export const getCombinedSelectedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setCombinedSelectedMetrics({
        data: [],
        status: 'loading'
    }));
    // const [metrics, derivedMetrics]: any = await Promise.all([dispatch(getMyMetrics()), dispatch(getMyDerivedMetrics())]);
    const metrics = await dispatch(getMyMetrics()) 
    const derivedMetrics = await dispatch(getMyDerivedMetrics());
    if(metrics === null || derivedMetrics === null) return (
        dispatch(setCombinedSelectedMetrics({
            data: [],
            status: 'error'
        }))
    )
    let dataArr: any[] = [];
    dataArr = [...dataArr, ...derivedMetrics?.map((item: any) => {
        return {
            ...item,
            type: 'derived'
        }
    })];

    dataArr = [...dataArr, ...metrics?.map((item: any) => {
        return {
            ...item,
            type: 'direct'
        }
    })];

    dispatch(setCombinedSelectedMetrics({
        data: [...dataArr],
        status: 'success'
    }));
}

export const getMetricsByFramework = (metric: string) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_METRICS_BY_FRAMEWORK(metric));
    if (data) {
        return [data.direct_metrics.filter((item: any) => item.pillars !== "nan" && item.pillars !== undefined), data.derived_metrics]
    }
    else {
        console.log(error);
        return null;
    }
}

// changed
export const getMetrics = () => async (dispatch: AppDispatch) => {
    // new code
    dispatch(setMetrics({
        data: [],
        status: 'loading'
    }));
    const [data, error] = await handleAPICall(GET_ALL_METRICS());
    if (data) {
        let filteredMetrics = data?.data.filter((item: any) => item.pillars !== "nan" && item.pillars !== undefined);
        dispatch(setMetrics({
            data: data?.data,
            status: 'success'
        }));

        return filteredMetrics;
    }
    else {
        console.log(error);
        dispatch(setMetrics({
            data: [],
            status: 'error'
        }));
        return null;
    }
}

export const getSingleMyMetric = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const queryParams = dispatch(formMetricQueryParams());
    const [data, error] = await handleAPICall(GET_SINGLE_MY_METRIC(id, queryParams));
    if (data) {
        let myMetrics = getState().onBoarding.metrics.myMetrics.data;
        let metricData = myMetrics?.filter((item: MyMetric) => item.id !== id);
        metricData = [...metricData, ...data.data];
        dispatch(setMyMetrics({
            data: metricData,
            status: 'success'
        }));
    }
    else {
        console.log(error);
        return null;
    }
}

export const getMyMetrics = () => async (dispatch: AppDispatch, getState: () => RootState) => {

    // new code
    dispatch(setMyMetrics({
        data: [],
        status: 'loading'
    }));

    const queryParams = dispatch(formMetricQueryParams());

    const [data, error] = await handleAPICall(GET_SELECTED_METRICS(queryParams));
    if (data) {

        const metrics = getState().onBoarding.metrics.directMetrics?.data;
        let dataArr = data?.data.map((item: any) => {
            const metricData = metrics?.find((metric: Metric) => metric.id === item.metric);
            return {
                ...item,
                material_topic: metricData?.material_topic,
                pillars: metricData?.pillars,
                category: metricData?.category,
                group: metricData?.group,
                unit: metricData?.unit,
                applicable_to_all: metricData?.applicable_to_all,
                annual: metricData?.annual,
                tab: metricData?.tab,
                description: metricData?.description,
            }
        });
        dispatch(setMyMetrics({
            data: dataArr,
            status: 'success'
        }));
        return dataArr;
    }
    else {
        console.log(error);
        dispatch(setMyMetrics({
            data: [],
            status: 'error'
        }));
        return null;
    }
}

export const patchSelectedMetricsStatus = (id: number, data: any) => async (dispatch: AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_SELECTED_METRICS_STATUS(id, data));
    if (res) {
        dispatch(getCombinedSelectedMetrics());
        BEMessage.success('Status updated successfully');
        return true;
    }
    else {
        console.log(error);
        return false;
    }
}

export const deleteMetrics = (id: string, setLoading: Function) => async (dispatch: AppDispatch) => {
    setLoading(true);
    const [data, error] = await handleAPICall(DELETE_METRICS(id));
    if (data) {
        dispatch(getCombinedSelectedMetrics());
        BEMessage.success('Metric deleted successfully');
    }
    else {
        console.log(error);
    }
    setLoading(false);
}

export const addMetrics = (data: any, setLoading?: Function, setClose?: Function) => async (dispatch: AppDispatch) => {
    setLoading && setLoading(true);
    const [res, error] = await handleAPICall(ADD_METRICS(data));
    if (res) {
        dispatch(getCombinedSelectedMetrics());
        setClose && setClose();
        setLoading && setLoading(false);
        return true
    }
    else {
        console.log(error);
        // BEMessage.error('Failed to add metrics, Please try again later');
        setLoading && setLoading(false);
        return null
    }

}