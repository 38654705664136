import React from 'react'
import TopSection from '../TopSection';
import { Navigate, Outlet } from 'react-router-dom';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { SetTableAndGraphDataDM } from '../../../DataManager/Components/SetTableAndGraphDataFunctionDM';
import { SetFilteredData } from '../../../Emissions/Components/SetFilteredData';
import { getAllEmissionCalculations, getScope3EmissionCalculations } from '../../../Emissions/Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getDerivedMetricDataByMonth } from '../../../DataManager/Actions';

const DashboardLayout = () => {
  const dispatch = useAppDispatch();
  const scope1Emissions = useTypedSelector((state) => state.emissions.emissionsCalculations.scope1Calculations);  
  const scope2Emissions = useTypedSelector((state) => state.emissions.emissionsCalculations.scope2Calculations);
  const scope3Emissions = useTypedSelector((state) => state.emissions.emissionsCalculations.scope3Calculations);  
  const myDerivedMetricsDataByMonthAndBusinessUnit = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsDataByMonthAndBusinessUnit);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    
  React.useEffect(() => {
    if(scope1Emissions.status !== 'loading' && scope2Emissions.status !== 'loading' )
      dispatch(getAllEmissionCalculations(currentYear));
    if(scope3Emissions.status !== 'loading')
      dispatch(getScope3EmissionCalculations(currentYear));
    if(myDerivedMetricsDataByMonthAndBusinessUnit.status !== 'loading')
      dispatch(getDerivedMetricDataByMonth()); 
  }, [currentYear])

  const role = useTypedSelector((state) => state.user.userRole);
  if(role === 'CONTRIBUTOR') return <Navigate to="/home/data-manager" />

  
  return (
    <div style={{padding: "0 1.5rem"}}>
      <TopSection />
      <Outlet />
      
      <SetTableAndGraphDataDM />
      <SetFilteredData/>
    </div>
  );
}

export default DashboardLayout