import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import AssignTemplate from '../AssignTemplate';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';

interface IReportDetailsProps {
	open: boolean;
	setOpen: Function;
}

export function ReportDetails (props: IReportDetailsProps) {
	const currentReport = useTypedSelector(state => state.reporting.currentReport);
	const currentTemplate = useTypedSelector(state => state.reporting.currentTemplate);
	const assignRef = React.useRef<any>({ onButtonClick: () => { } })
	const [loading, setLoading] = React.useState(false);
	return (
	  <BEDrawer
		width='700px'
		heading='Report details'
		open={props.open}
		setOpen={props.setOpen}
		footer={
			<>
				<BEButton
					className="primary"
					size="large"
					disabled={currentReport?.stage === 4 || currentReport?.stage === 5}
					onClick={async () => {
					if (assignRef?.current?.onButtonClick) {
						setLoading(true);
						await assignRef?.current?.onButtonClick();
						setLoading(false);
						props.setOpen(false);
					}

					}}
					loading={loading}
          		>
          			 Save
         		</BEButton>
				<BEButton 
					size='large'
					className='secondary'
					onClick={()=> props.setOpen(false)}
					disabled={currentReport?.stage === 4 || currentReport?.stage === 5}
				>
					Cancel
				</BEButton>
			</>
		}
		>
		<AssignTemplate 
			ref={assignRef} 
			report={currentReport}
			template={currentTemplate?.id}
			disabled={currentReport?.stage === 4 || currentReport?.stage === 5 || currentReport?.stage === 7}
		/>
	  </BEDrawer>
	);
}