import * as React from 'react';
import './style.scss';
import { barBackgroundColor, defaultBarGreen} from '../../../../Config/Theme/graphColors';
import ToolTip from '../../../BEToolTip';
import { IHorizontalBarChartCoreProps,HorizontalBarChartTypeDataType,HorizontalBarChartTypeDisplayType } from '..';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { getScaledPercentage } from '../../../../Config/Functions/ScalingFunctions';
import { BEPagination } from '../../../BEPagination';

export function HorizontalBarChartType2 (props: IHorizontalBarChartCoreProps) {
  const [selectedName, setSelectedName] = React.useState<string|null>(null);
  const [dataToDisplay, setDataToDisplay] = React.useState<HorizontalBarChartTypeDisplayType[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const pageSize = props.pageSizes || 5;

  React.useEffect(() => {
    let data:HorizontalBarChartTypeDataType[] = props.data;
    if (props.isPercentage) {
      let total = data.reduce((acc, item) => acc + item.value, 0);
      data = data.map(item => ({name: item.name, value: item.value/total*100}));
    }
    let total = data.reduce((acc, item) => acc + item.value, 0);
    let scaledPercentageValues = getScaledPercentage(data.map(item => item.value));
    let dataToDisplay = data
          .map((item:any,index:number) => (
            {
              name: item.name, 
              value: item.value, 
              widthPercentage: scaledPercentageValues[index],
              absoluteValue: props.data[index].value,
              percentageValue: item.value/total*100
            }))
          .sort((a,b) => b.value - a.value)
          .slice((page-1)*pageSize, page*pageSize);
    setDataToDisplay(dataToDisplay);
  }, [props.data, page]);

  return (
    <div className='horizontal-bar-chart-2'>
      {dataToDisplay.map((item:HorizontalBarChartTypeDisplayType,index:number)=> (
        <div className={`bar-container`}
         onClick={() =>{
                        let name = selectedName === item.name ? null : item.name; 
                        props.onClick && props.onClick(name) 
                        setSelectedName(name)
                      }
                    } key={index}>
          
          <ToolTip title={item.name} >
            <p className='bar-name'>
                {item.name}
            </p>
          </ToolTip>
          <div className='bar-wrapper'
              style={{gridTemplateColumns: `  ${props.unit ? '8fr 2fr' : '9fr 1fr'}`}}
            >
            <div className='bar-wrapper-inner' style={{backgroundColor: barBackgroundColor}}>
              <div className='bar' style={{width: `${item.widthPercentage}%`, backgroundColor: defaultBarGreen}}/>
            </div>
            <p className='bar-value'>
              {
                AddCommaToNumber(item.value,false,true,true,3)
              }{props.isPercentage ? '%' : null} 
              {props.unit ? ` ${props.unit}` : null}
              {
                !props.isPercentage ?
               <span> ({item.widthPercentage.toFixed(2)}%)</span>
                : null
              }
            </p>
          </div>
        </div>
        ))
      }
      <div className='pagination-container'>
        <BEPagination
          total={props.data.length}
          pageSize={pageSize}
          current={page}
          onChange={(page) => setPage(page)}
        />
      </div>
    </div>
  );
}
