import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { Form, Select, Space } from 'antd';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { BEEyeButton } from '../../../../../Components/BEEyeButton';
import { EVENT_GROUP_CHOICES, EVENT_OWNER, LEAD_INVESTIGATOR } from '../../../../../data/eventManager';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { postEventUser } from '../../../EventManager/Actions';
import { getBusinessUnits } from '../../../../../views/Actions/BusinessUnitActions';

export interface IAddEventUserProps {
    open: boolean;
    setOpen: Function;
    isEdit?: boolean;
}

export function AddEventUser(props: IAddEventUserProps) {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const businessUnits = useTypedSelector((state) => state.entity.BusinessUnits);
    const [currentEventRole, setCurrentEventRole] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values: any) => {
        const eventUserData = {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            event_role: values.event_role,
            business_unit: values.business_unit,
            event_category: values.category,
        }
        setLoading(true);
        await dispatch(postEventUser(eventUserData, true)).then(() => {
            setLoading(false);
            form.resetFields();
            props.setOpen(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    React.useEffect(() => {
        if (businessUnits?.status === "idle") {
            dispatch(getBusinessUnits());
        }
    }, [props.open]);

    return (
        <div>
            <BEDrawer
                open={props.open}
                setOpen={props.setOpen}
                heading={props.isEdit ? "Edit User" : "Add User"}
                width="400"
                footer={
                    <div>
                        <BEButton
                            size="large"
                            className="primary"
                            onClick={() => {
                                form.submit();
                            }}
                            loading={loading}
                        >
                            {props.isEdit ? "+ Edit User" : "+ Add User"}
                        </BEButton>
                        <BEButton
                            style={{ marginLeft: "0.5rem" }}
                            size="large"
                            onClick={() => {
                                form.resetFields();
                                props.setOpen(false);
                            }}
                        >
                            Cancel
                        </BEButton>
                    </div>
                }
            >
                <Form form={form} onFinish={onFinish}>

                    <LableRequired>
                        Email
                    </LableRequired>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "Please enter email" }]}
                    >
                        <BEInput size="large" placeholder="User's email ID" />
                    </Form.Item>

                    <LableRequired>
                        First Name
                    </LableRequired>
                    <Form.Item
                        name="first_name"
                        rules={[{ required: true, message: "Please enter first name" }]}
                    >
                        <BEInput size="large" placeholder="First name of user" />
                    </Form.Item>

                    <LableRequired>
                        Last Name
                    </LableRequired>
                    <Form.Item
                        name="last_name"
                        rules={[{ required: true, message: "Please enter last name" }]}
                    >
                        <BEInput size="large" placeholder="Last name of user" />
                    </Form.Item>

                    <LableRequired>
                        Business Unit
                    </LableRequired>
                    <Form.Item
                        name="business_unit"
                        rules={[{ required: true, message: "Please enter business unit" }]}
                    >
                        <Select size="large" placeholder="Business unit">
                            {businessUnits?.data?.map((item) => (
                                <Select.Option value={item.id}>
                                    <Space>{item.name}</Space>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <LableRequired>Role </LableRequired>
                    <Form.Item
                        name="event_role"
                        rules={[{ required: true, message: "Please enter role" }]}
                    >
                        <Select
                            onChange={
                                (value: string) => {
                                    setCurrentEventRole(value);
                                }
                            }
                            size="large"
                            placeholder="Role of the user"
                        >
                            {
                                [EVENT_OWNER, LEAD_INVESTIGATOR].map((item) => {
                                    return <Select.Option value={item}>
                                        <Space>{item}</Space>
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    {currentEventRole === EVENT_OWNER &&
                        <>
                            <BEEyeButton
                                title=""
                                discription={'Refers to the category of the event'}
                            >
                                <LableRequired>Event category</LableRequired>
                            </BEEyeButton>

                            <Form.Item
                                name="category"
                                rules={[{ required: true, message: "Please enter category" }]}
                            >
                                <Select size="large" placeholder="Event category">
                                    {EVENT_GROUP_CHOICES.map((item) => (
                                        <Select.Option value={item.value}>
                                            <Space>{item.label}</Space>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>}


                    {/* 
            <BEMultipleSelect single isDataStrArray data={UserDepartments.map((item) => item.name)} name="department" /> */}

                    {/* <Form.Item>
              <Checkbox name="department_head"
                checked={departmentHead}
                onChange={(e) => setDepartmentHead(e.target.checked)}
              >Is this the Department head</Checkbox>
            </Form.Item> */}

                    {/* {props.isEdit && (
                        <>
                            <LableRequired>Status </LableRequired>
                            <Form.Item
                                name="status"
                                rules={[{ required: true, message: "Please enter status" }]}
                            >
                                <Select size="large" placeholder="Status of the user">
                                    <Select.Option value="Active">
                                        <Space>Active</Space>
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Space>Inactive</Space>
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    )} */}
                </Form>
            </BEDrawer>
        </div>
    );
}
