import exp from "constants";

export const dashboardMetricsCardList = [
  "Total volume of water withdrawn (uncategorised)",
  "Total waste generated (uncategorised)",
  "Purchased grid electricity (uncategorised)",
];

export const dashboardMetricsGraphList = [
  "Purchased grid electricity (uncategorised)",
  "Purchased renewable electricity (uncategorised)",
  "Organic waste generated in offices",
  "Total Plastic Waste generated",
  "Amount of water recycled/re-used"
];

export const electricityBcodes = ["BE008.14", "BE026.10", "BE008.21", "BE026.6"];

export const wasteBcodes = ["BE014.13", "BE014.6", "BE014.25", "BE014.12"];

export const waterBcodes = ["BE033.1", "BE033.5", "BE033.3", "BE033.4", "BE033.6", "BE033.12"];

export const defaultMetrics = [];

export const defaultDerivedMetrics = ["EN001.1", "EN001.2", "EN001.4", "EN002.2", "EN001.7", "EN002.3",
  "EN001.8", "EN001.3", "EN001.5", "EN002.1", "C015.41", "C015.42", "C015.43", "C015.44", "C015.45", "C015.46",
  "C015.12", "C015.10", "C015.11", "C015.38", "C015.39", "C015.40", "C015.1", "C015.2", "C015.7", "C015.4"];

export const graphStrokeColorArray = [
  "#1F77B4", "#FF7F0E", "#2CA02C", "#D62728", "#9467BD", "#8C564B", "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF"
];

export const graphFillColorArray = [
  "#1F77B4", "#FF7F0E", "#2CA02C", "#D62728", "#9467BD", "#8C564B", "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF"
];

