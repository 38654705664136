import * as React from 'react';
import './styles.scss';
import PDF from '../../../assets/images/FilesIcons/event-pdf.png';
import { BEBulkUploadDrawer } from '../../BEDrawer/BEBulkUploadDrawer';
import FileUpload from '../../BEFileUpload/FileUpload';
import BEFileUploadMobile from '../../../mobile-comp/MobileComponents/BEFileUploadMobile';
import { PrimaryTheme } from '../../../Config/Theme/theames';

interface IBEEvidenceListProps {
	width?: string;
	height?: string;
	evidenceList: string[];
	imageHeight?: string;
	imageWidth?: string;
	uploadEvidence?: boolean;
	setFile?: Function;
	disableOnClick?: boolean;
	evidenceFiles?: any;

	setEvidenceList?: Function;
}


export function BEEvidenceList(props: IBEEvidenceListProps) {
	return (
		<div className='evidence-list'
			style={{ width: props.width, height: props.height }}
		>
			{props?.uploadEvidence &&
				<div className={`evidence-item  ${props.uploadEvidence ? 'upload-evidence' : ''}`} >
					<BEFileUploadMobile
						handleFileChange={(file: any) => props?.setFile && props?.setFile(file)}
						fileType={['image/jpeg', 'image/png', 'application/pdf']}
						fileSize={10000000}
					/>
				</div>
			}
			{props.evidenceFiles && props.evidenceFiles.map((evidence: any, index: number) => {
				let extension = evidence?.name?.split('.').pop()
				// extension = extension?.name?.split('?')[0]; //remove query params
				const previewImage = URL.createObjectURL(evidence);
				return <div style={{ position: 'relative' }} className={`evidence-item ${extension === 'pdf' ? 'pdf' : ''}  ${props.uploadEvidence ? 'upload-evidence' : ''}`}
					key={index}
					onClick={() => {
						if (!props?.disableOnClick) {
							window.open(evidence, '_blank');
						}
					}}>
					{props.setEvidenceList && <span
						style={{ position: 'absolute', right: 0, top: 0, padding: '0.3rem', backgroundColor: PrimaryTheme.primaryGreyDark, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10px', width: '10px', color:'white' }}
						onClick={() => {
							props.setEvidenceList && props.setEvidenceList((prev: any) => (prev.filter((item: any) => item.name != evidence.name)))
						}}>x</span>}
					<img height={props.imageHeight ? props.imageHeight : '70px'} width={props.imageWidth ? props.imageWidth : '50px'} src={extension === 'pdf' ? PDF : !props?.disableOnClick ? previewImage : previewImage} alt='icon' />
				</div>
			}
			)}
			{!props.evidenceFiles && props.evidenceList.map((evidence, index) => {
				let extension = evidence?.split('.').pop()
				extension = extension?.split('?')[0]; //remove query params
				return <div className={`evidence-item ${extension === 'pdf' ? 'pdf' : ''}  ${props.uploadEvidence ? 'upload-evidence' : ''}`}
					key={index}
					onClick={() => {
						console.log('evidence', evidence);
						if (!props?.disableOnClick) {
							window.open(evidence, '_blank');
						}
					}}>
					<img height={props.imageHeight ? props.imageHeight : '70px'} width={props.imageWidth ? props.imageWidth : '50px'} src={extension === 'pdf' ? PDF : !props?.disableOnClick ? evidence : PDF} alt='icon' />
				</div>
			}
			)}
		</div>
	);
}
