import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getCompanyDetails } from '../../../Actions/OnboardingActions';

interface ICompanyTopNavProps {
}

export function CompanyTopNav (props: ICompanyTopNavProps) {
	const dispatch = useAppDispatch();
	const companyDetails = useTypedSelector((state) => state.companies.companyDetails)

	React.useEffect(() => {
		if (companyDetails?.status === 'idle') dispatch(getCompanyDetails());
	}, [companyDetails]);

	return (
	  <div className='company-top-nav'>
		<div className='company-icon'>
        <img
          className='company-logo'
          src={
            companyDetails?.data?.logo
              ? companyDetails?.data?.logo
              : "https://placehold.co/400?text=Company+Logo"
          }
          alt=""
        />
      </div>
      <h3>{companyDetails?.data?.firm_name}</h3>
	  </div>
	);
}
