import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { Form, Select } from 'antd';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { deleteDataLabels, postDataLabels } from '../../../DataManager/Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { BEMessage } from '../../../../Components/BEMessage';

export interface IAddLabelsProps {
    open: boolean;
    setOpen: Function;
    isEdit?: boolean;
    currentMetric?: number;
}

export function AddLabels(props: IAddLabelsProps) {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentMetric, setCurrentMetric] = React.useState<number |
        undefined>(undefined);
    const myMetricsPerYear = useTypedSelector(state =>
        state.onBoarding.metrics.myMetricsPerYear
    );
    const [selectedLabelData, setSelectedLabelData] = React.useState<string[]>([]);
    const [labelChange, setLabelChange] = React.useState<string>("");
    const [optionLabels, setOptionLabels] = React.useState<string[]>([]);
    const allLabels = useTypedSelector((state) => state.dataManager.labels);
    const [existingMetrics, setExistingMetrics] = React.useState<number[]>([]);
    const [labelData, setLabelData] = React.useState<string[]>([]);

    React.useEffect(() => {
        let labels: any = [];
        if (props.isEdit) {
            setCurrentMetric(props.currentMetric);
            form.setFieldsValue({
                my_metric: props.currentMetric
            });
            labels = Array.from(new Set(allLabels?.data?.filter((item) =>
                item.my_metric === currentMetric && item.my_metric_data === null
                && item.business_unit === null
            )?.map((item) => item.label_name)));
            setSelectedLabelData(labels);
        } else {
            labels = Array.from(new Set(allLabels?.data?.filter((item) =>
                item.my_metric_data === null && item.business_unit === null
            )?.map((item) => item.label_name)));
            setExistingMetrics(Array.from(new Set(allLabels?.data?.filter((item) =>
                item.my_metric_data === null && item.business_unit === null
            )))?.map((item: any) => item.my_metric));
            setLabelData(labels);
            form.setFieldsValue({
                label: selectedLabelData
            });
            setCurrentMetric(undefined);
        }
        setOptionLabels(labels);
    }, [props.open, allLabels, currentMetric, props.isEdit]);

    React.useEffect(() => {
        setLabelData(Array.from(new Set([...labelData, ...selectedLabelData].map((item) => item))));
        form.setFieldsValue({
            label: selectedLabelData
        });
    }, [selectedLabelData, props.open]);

    React.useEffect(() => {
        if (!props.isEdit) {
            setLabelData([]);
            setOptionLabels([]);
            setSelectedLabelData([]);
            form.resetFields();
        }
    }, [props.isEdit, props.open]);

    const onFinish = async (values: any) => {
        if (props.isEdit) {
            const newLabels = selectedLabelData.filter((item) =>
                !optionLabels.includes(item));
            const removedLabels = labelData.filter((item) =>
                !selectedLabelData.includes(item));

            const removedLabelIds = allLabels?.data?.filter((item) =>
                removedLabels.includes(item.label_name) &&
                item.my_metric_data === null && item.business_unit === null &&
                item.my_metric === currentMetric
            )?.map((item) => item.id);

            let data: any = [];
            newLabels.map((item) => {
                data.push({
                    label_name: item,
                    my_metric: currentMetric
                });
            });
            setLoading(true);
            await dispatch(postDataLabels(data)).then(async () => {
                Promise.all(removedLabelIds?.map((id) => {
                    dispatch(deleteDataLabels(id));
                }));
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
            props.setOpen(false);
        } else {
            if (!form.getFieldValue("my_metric")) {
                BEMessage.error("Please select metric");
                return;
            }
            let data: any = [];
            selectedLabelData.map((item) => {
                data.push({
                    label_name: item,
                    my_metric: form.getFieldValue("my_metric")
                });
            });
            setLoading(true);
            await dispatch(postDataLabels(data)).then(() => {
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
            props.setOpen(false);
        }
    }

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading={props.isEdit ? "Edit labels" : "Add new label"}
            footer={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem",
                    }}
                >
                    <BEButton
                        onClick={() => form.submit()}
                        className="primary"
                        loading={loading}
                    >
                        {"Save"}
                    </BEButton>
                    <BEButton
                        onClick={() => props.setOpen(false)}
                        className="secondary"
                        loading={loading}
                    >
                        Cancel
                    </BEButton>
                </div>
            }
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Select metric"
                    name="my_metric"
                    rules={[{ required: true, message: "Please select metric" }]}
                >
                    <Select
                        showSearch
                        optionFilterProp='children'
                        placeholder="Select metric"
                        value={form.getFieldValue("my_metric")}
                        disabled={props.isEdit || loading}
                    >
                        {myMetricsPerYear?.data?.filter((metric) =>
                            !existingMetrics.includes(metric.id)
                        )?.map((metric) => (
                            <Select.Option
                                key={metric.id}
                                value={metric.id}
                            >
                                {metric?.custom_title || metric?.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label='Add labels'
                    name='label'
                    rules={[{ required: true, message: "Please select labels" }]}
                >
                    <Select
                        disabled={loading}
                        placeholder="Add labels"
                        showSearch
                        onSearch={(val) => {
                            setLabelChange(val);
                        }}
                        onBlur={() => {
                            {
                                if (labelChange) {
                                    setSelectedLabelData(
                                        Array.from(new Set(
                                            [...selectedLabelData,
                                            labelChange + " (New label)"]
                                        )));
                                }
                            }
                        }}
                        mode='multiple'
                        onChange={(val) => {
                            setSelectedLabelData(val);
                            setLabelChange("");
                        }}
                        value={selectedLabelData}
                    >
                        {labelChange === "" ?
                            labelData?.map((item, index) =>
                                <Select.Option key={index} value={item}>
                                    {item}
                                </Select.Option>
                            )
                            :
                            [labelData, labelChange].map((item, index) =>
                                <Select.Option key={index} value={item}>
                                    {item === labelChange ? item + " (New label)" : item}
                                </Select.Option>
                            )}
                    </Select>
                </Form.Item>
            </Form>
        </BEDrawer>
    );
}
