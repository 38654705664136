import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BETabs } from '../../../../../Components/BETabs';
import BEChat from '../../../../../Components/BEChat';
import { TopicEvidanceTable } from '../NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/EvidenceTable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import './styles.scss';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';

interface IActivitiesAndEvidences {
	open: boolean;
	setOpen: Function;
	uniqueCode: string;

}

export function ActivitiesAndEvidences(props: IActivitiesAndEvidences) {
	const activityActions = useTypedSelector(state => state.reporting.reportActivityActions);
	const currentReport = useTypedSelector(state => state.reporting.currentReport);
	const chats = useTypedSelector(state => state.reporting.reportChats);
	return (
		<BEDrawer
			heading="Activities and evidences"
			footer={
				<>
					<BEButton
						onClick={() => props.setOpen(false)}
					>
						Close
					</BEButton>
				</>
			}
			open={props.open}
			setOpen={props.setOpen}
			width='1000px'
		>
			<div style={{ padding: '0rem' }} className='activities-evidences-drawer-content'>
				<BETabs
					items={
						[
							{
								key: '1',
								label: 'Activity',
								children: <div style={{margin:'0.5rem'}}>
									 <BEChat
									actions={activityActions.data.filter((action) => action.unique_code == props.uniqueCode
										&& action.report == currentReport?.id
									)}
									chats={chats.data.filter(chat => chat.unique_code == props.uniqueCode
										&& chat.report == currentReport?.id
									)}
									height={'600px'}
									uniqueCode={props.uniqueCode}
								/>
								</div>
							},
							{
								key: '2',
								label: 'Evidences',
								children: <div style={{margin:'0.5rem'}}>
									<TopicEvidanceTable uniqueCode={props.uniqueCode} />
								</div>
							},

						]
					}
				/>

			</div>
		</BEDrawer>
	);
}
