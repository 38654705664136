import * as React from 'react';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { Form } from 'antd';
import { BETextArea } from '../../../../../../Components/BEFormItems/BETextArea';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { patchEvent } from '../../../Actions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { BEMessage } from '../../../../../../Components/BEMessage';

interface ICloseInvestigationReportProps {
	open: boolean;
	setOpen: Function;
	isReject: boolean;
}

export function CloseInvestigationReport(props: ICloseInvestigationReportProps) {
	const [form] = Form.useForm();
	const dispatch = useAppDispatch();
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const [loading, setLoading] = React.useState(false);

	const handleClose = async (values: any) => {
		if (props.isReject) {
			if (currentEvent) {
				setLoading(true);
				await dispatch(patchEvent(currentEvent?.id, {
					stage: 6,
					rejection_reason: values.comments,
				})).then((status) => {
					if (status) {
						BEMessage.success('Investigation report rejected successfully');
					} else {
						BEMessage.error('Failed to reject investigation report');
					}
				})
				setLoading(false);
			}
			props.setOpen(false);
		} else {
			if (currentEvent) {
				setLoading(true);
				await dispatch(patchEvent(currentEvent?.id, {
					stage: 4,
					conclusion: values.comments,
				})).then((status) => {
					if (status) {
						BEMessage.success('Event closed successfully');
					} else {
						BEMessage.error('Failed to close event');
					}
				})
				setLoading(false);
			}
			props.setOpen(false);
		}
	}

	return (
		<BEDrawer
			heading={props.isReject ? 'Reject investigation report' : 'Close Event'}
			open={props.open}
			setOpen={props.setOpen}
			footer={
				<>
					<BEButton loading={loading} className='primary' onClick={() => form.submit()}>
						{props.isReject ? 'Reject' : 'Close'}
					</BEButton>
					<BEButton className='secondary-green' onClick={() => props.setOpen(false)}>
						Cancel
					</BEButton>
				</>
			}
		>
			<Form form={form} onFinish={handleClose}>
				<LableRequired>
					Comments
				</LableRequired>
				<Form.Item name={'comments'} rules={[{ required: true, message: 'Please enter comments' }]}>
					<BETextArea
						name="comments"
						form={form}
						placeholder="Write here"
						rows={5}
						maxLength={200}
						count={{
							show: true,
							max: 200,
						}}
					/>
				</Form.Item>
			</Form>

		</BEDrawer>
	);
}
