import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    setMyDashboardMetrics,
    setMyDerivedDashboardMetrics,
} from "../../../Redux/DataManagerReducer";
import { setQuickSightDashboard } from "../../../Redux/CommonReducer";
import {
    DELETE_MY_DASHBOARD_METRICS,
    GET_MY_DASHBOARD_METRICS,
    GET_QUICKSIGHT_AUTHOR_DASHBOARD_DETAILS,
    GET_QUICKSIGHT_READER_DASHBOARD_DETAILS,
    GET_MY_DERIVED_DASHBOARD_METRICS,
    POST_MY_DERIVED_DASHBOARD_METRICS,
    PATCH_MY_DERIVED_DASHBOARD_METRICS,
    DELETE_MY_DERIVED_DASHBOARD_METRICS,
    PATCH_MY_DASHBOARD_METRICS,
    POST_MY_DASHBOARD_METRICS,
    GET_MY_DASHBOARD_ANALYTICS,
    GET_MY_DASHBOARD_INSIGHTS,
    GET_MY_DASHBOARD_INSIGHTS_ALERT_MESSAGES,
    GET_MY_DASHBOARD_CONTRIBUTER_INSIGHTS,
    GET_MY_DASHBOARD_COMPARISION_ANALYTICS,
    GET_MY_DASHBOARD_DRILLDOWN,
    GET_MY_DASHBOARD_INSIGHTS_PROGRESS,
} from "../../../Utils/Routes/DashboardRoutes";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { setCompareInsightsProgress, setDashboardCompareAnalytics } from "../../../Redux/DashboardReducer";

export const getMyDashboardComparisionAnalytics = (year: number,compareYear: number,scope?: number, code?: any) => async (dispatch:AppDispatch, getState: () => RootState) => {
    const dashboardCompareAnalytics = getState().dashboard.compareAnalytics;
    dispatch(setDashboardCompareAnalytics({
        status: "loading",
        data: {...dashboardCompareAnalytics.data}
    }));
    const businessUnit = getState().common.homePage.currentBusinessUnit;
    const businessGroup = getState().common.homePage.currentBusinessGroup;
    const scope2EmissionsType = getState().emissions.scope2EmissionType;
    // month of today
    let today = new Date();
    let month = today.getMonth() + 1;
    let query:any = 'year='+year+'&comparision_year='+compareYear+'&month='+month;
    query += '&scope_2_type='+scope2EmissionsType;
    if (scope) query += '&scope='+scope;
    if (code) query += '&code='+code;
    if (businessUnit) query += '&business_unit='+businessUnit;
    if (businessGroup) query += '&business_group='+businessGroup;
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_COMPARISION_ANALYTICS(query));
    if (data) {
        dispatch(setDashboardCompareAnalytics({
            status: "success",
            data: data.data
        }));
    }
    else{
        dispatch(setDashboardCompareAnalytics({
            status: "error",
            data: {}
        }));
    }

}

export const getMyDashboardInsightsComparisonProgress = (year: number,compareYear: number, pillar?: 'E' | 'S' | 'G' | 'O') => async (dispatch:AppDispatch, getState: () => RootState) => {
    const dashboardInsightsCompareProgress = getState().dashboard.compareInsightsProgress;
    dispatch(setCompareInsightsProgress({
        status: "loading",
        data: {...dashboardInsightsCompareProgress.data}
    }));
    // month of today
    let today = new Date();
    let month = today.getMonth();
    let query:any = 'year='+year+'&comparison_year='+compareYear+'&month='+month 
    if (pillar) query += '&pillars='+pillar;
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_INSIGHTS_PROGRESS(query));
    if (data) {
        dispatch(setCompareInsightsProgress({
            status: "success",
            data: data.data
        }));
    }
    else{
        dispatch(setCompareInsightsProgress({
            status: "error",
            data: {}
        }));
    }

}

export const generatePDF = (setLoading: Function, elementID: string) => async (dispatch: any) => {
    const input = document.getElementById(elementID);
    if (!input) return;

    setLoading(true);

    try {
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, millimeters, A4
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        let y = 0; // Starting Y position

        while (y < imgHeight) {
            pdf.addImage(imgData, 'PNG', 0, -y, imgWidth, imgHeight);
            y += pdfHeight; // Move to next page

            if (y < imgHeight) pdf.addPage(); // Add new page if content remains
        }

        pdf.save('data.pdf');
    } catch (error) {
        console.error('Error generating PDF:', error);
    } finally {
        setLoading(false);
    }
};

export const getDashboardDrilldown = (type: number,scope:number,bcode:string, years?: number[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const businessUnit = getState().common.homePage.currentBusinessUnit;
    const businessGroup = getState().common.homePage.currentBusinessGroup;
    const scope2EmissionsType = getState().emissions.scope2EmissionType;
    let query:any = 'type='+type+'&scope='+scope+'&bcode='+bcode;
    query += '&scope_2_type='+scope2EmissionsType;
    if (years){
        years.forEach((year) => {
            query += '&years='+year;
        });
    }
    if (businessUnit) query += '&business_unit='+businessUnit;
    if (businessGroup) query += '&business_group='+businessGroup;
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_DRILLDOWN(query));
    if (data) {
        return data.data;
    }
    else{
        // BEMessage.error(error.msg);
        return null
    }
}

export const getMyDashboardMetrics = () => async (dispatch:AppDispatch) => {
    dispatch(setMyDashboardMetrics({
            status: "loading",
            data: []
        }));
    const userId = JSON.parse(localStorage.getItem('userInfo') || '{}').id;
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_METRICS());
    if (data) {
        dispatch(setMyDashboardMetrics({
            status: "success",
            data: data?.data
            .filter((item:any) => item.user === userId)
        }));
    }
    else{
        dispatch(setMyDashboardMetrics({
            status: "error",
            data: []
        }));
    }
}


export const postMyDashboardMetrics = (data:any, isDefault: boolean = false) => async (dispatch:AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DASHBOARD_METRICS(data));
    if (dataRes) {
        await dispatch(getMyDashboardMetrics());
        if (!isDefault)
        BEMessage.success("Metric Added Successfully");
        return true;
    }
    else{
        if (!isDefault)
        BEMessage.error("Something went wrong");
        return null
    }
}

export const deleteMyDashboardMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_DASHBOARD_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getMyDashboardMetrics());
    }
}


export const patchMyDashboardMetrics = (id: any, data: any,silent?:boolean) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_DASHBOARD_METRICS(id,data));
    if (dataRes) {
        if (!silent)
        dispatch(getMyDashboardMetrics());
    }
    else{
        BEMessage.error("Something went wrong");
    }
}

export const getQuickSightDashboardDetails = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setQuickSightDashboard({
        status: "loading",
        data: {}
    }));
    const role = getState().user.userRole;
    let data, error;
    if (role === 'SUPER_ADMIN') {
        [data, error] = await handleAPICall(GET_QUICKSIGHT_AUTHOR_DASHBOARD_DETAILS());
    } else {
        [data, error] = await handleAPICall(GET_QUICKSIGHT_READER_DASHBOARD_DETAILS());
    }
    if (data) {
        dispatch(setQuickSightDashboard({
            status: "success",
            data: data?.data
        }));
    } else {
        dispatch(setQuickSightDashboard({
            status: "error",
            data: {}
        }));
        
    }
}

export const getMyDerivedDashboardMetrics = () => async (dispatch:AppDispatch) => {
    dispatch(setMyDerivedDashboardMetrics({
            status: "loading",
            data: []
        }));
    const userId = JSON.parse(localStorage.getItem('userInfo') || '{}').id;
    const [data, error] = await handleAPICall(GET_MY_DERIVED_DASHBOARD_METRICS());
    if (data) {
        dispatch(setMyDerivedDashboardMetrics({
            status: "success",
            data: data?.data
            .filter((item:any) => item.user === userId)
        }));
    }
    else{
        dispatch(setMyDerivedDashboardMetrics({
            status: "error",
            data: []
        }));
    }
}

export const postMyDerivedDashboardMetrics = (data:any) => async (dispatch:AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DERIVED_DASHBOARD_METRICS(data));
    if (dataRes) {
        await dispatch(getMyDerivedDashboardMetrics());
        BEMessage.success("Metric Added Successfully");
        return true;
    }
    else{
        BEMessage.error("Something went wrong");
        return null
    }
}

export const deleteMyDerivedDashboardMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_DERIVED_DASHBOARD_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getMyDerivedDashboardMetrics());
    }
}

export const patchMyDerivedDashboardMetrics = (id: any, data: any, silent?:boolean) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_MY_DERIVED_DASHBOARD_METRICS(id,data));
    if (dataRes) {
        if (!silent)
        dispatch(getMyDerivedDashboardMetrics());
    }
    else{
        BEMessage.error("Something went wrong");
    }
}


export const getDashoardAnalytics = (
    metricBcodes: string[],
    businessUnits: number[],
    startDate: string,
    endDate: string,
) => async (dispatch: AppDispatch) => {
    
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_ANALYTICS(metricBcodes, businessUnits, startDate, endDate));
    if (data) {
        return data.data;
    }
    else{
        return null
    }

}

export const getDashoardInsights = (year: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_INSIGHTS(year));
    if (data) {
        return data;
    }
    else{
        return null
    }

}

export const getDashoardInsightsAlertMessages = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_INSIGHTS_ALERT_MESSAGES());
    if (data) {
        return data;
    }
    else{
        return null
    }

}
export const getDashoardContributerInsights = (year: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_MY_DASHBOARD_CONTRIBUTER_INSIGHTS(year));
    if (data) {
        return data;
    }
    else{
        return null
    }

}