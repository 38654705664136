import { Switch, SwitchProps } from 'antd';
import * as React from 'react';
import './style.scss';
import { PrimaryTheme } from '../../../../Config/Theme/theames';

export interface IToggleButtonProps {
  value: boolean;
  onChange: Function;
  text:{
    right: string;
    left: string;
  }
  switchProps?: SwitchProps;
}

export function ToggleButton (props: IToggleButtonProps) {
  return (
    <div className="toggle-button">
      <p className={`toggle-text ${props.value?'':'active'}`}>{props.text.left}</p>
        <Switch 
          size='small'
          style={{backgroundColor:props.value?PrimaryTheme.primaryGreen:PrimaryTheme.primaryGray}} 
          checked={props.value} onChange={(value) => props.onChange(value)}
          {...props.switchProps}
        />
      <p className={`toggle-text ${props.value?'active':''}`}>{props.text.right}</p>
    </div> 
  );
}
