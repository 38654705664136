import { Form } from 'antd';
import form, { FormInstance } from 'antd/es/form';
import * as React from 'react';
import FileUpload from '../../../../../Components/BEFileUpload/FileUpload';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import BEDatePicker from '../../../../../Components/BEDatePicker';

interface IInvestigationDetailsProps {
	onFinish: (values: any) => void;
	form: FormInstance<any> | undefined;
	setFile: Function;
}

export function InvestigationDetails(props: IInvestigationDetailsProps) {
	const currentInvestigationReportSection = useTypedSelector(state => state.eventManager.currentInvestigationReportSection);
	const currentEvent = useTypedSelector(state => state.eventManager.currentEvent);
	const eventUsers = useTypedSelector(state => state.eventManager.eventUsers);
	const [investigationTeam, setInvestigationTeam] = React.useState<any[]>([]);

	React.useEffect(() => {
		if (currentEvent) {
			const investigationTeam = currentEvent?.investigation_team?.split(',');
			if (investigationTeam && investigationTeam?.length > 0) {
				const team = eventUsers?.data?.filter((user: any) => investigationTeam?.includes(String(user.id)));
				if (team) {
					setInvestigationTeam(team);
				}
			} else {
				setInvestigationTeam([]);
			}
		}
	}, [currentEvent, eventUsers]);

	return (
		<Form form={props.form} style={{ color: PrimaryTheme.primaryGray }} onFinish={props.onFinish}>
			<div style={{ display: 'flex', gap: '1rem', marginTop: '1.5rem' }}>
				<Form.Item name={'investigationReport'} style={{color: PrimaryTheme.primaryGray}} >
					<LableRequired >
						Investigation Report
					</LableRequired>
					<div style={{ marginTop: '0.2rem', display: 'flex', gap: '1rem' }}>
						<FileUpload
							height='75px'
							width='315px'
							fileType={'file'}
							text='Upload Investigation Report'
							hint='PDF, Max 10MB'
							handleFileChange={props.setFile}
							refreshUpload={currentInvestigationReportSection === 'Investigation'}
							fileSize={10000000}
						/>


					</div>
				</Form.Item>
				<>
					<div style={{ height: '159px', width: '80%', }}>
						<p>Team</p>
						<div style={{ height: '159px', width: '100%', border: `1px solid ${PrimaryTheme.primaryGreyLight}`, borderRadius: '7px', margin: '0.1rem', display: 'flex' }}>
							{
								investigationTeam.map((user) => {
									return <div style={{ margin: '0.5rem', width: 'fit-content', height: "20px", borderRadius: '7px', padding: '0.1rem 1rem ', display: 'flex', alignItems: 'center', backgroundColor: PrimaryTheme.primaryGreyLight }}>{user.first_name} {user.last_name}</div>
								})
							}
						</div>
					</div>
				</>
			</div>
			<Form.Item name={'deadline'}>
				<p style={{ color: PrimaryTheme.primaryGray }}>Deadline</p>
				<BEDatePicker
					disabled
					format='DD/MM/YYYY'
					field='deadline'
					value={currentEvent?.deadline}
				/>
			</Form.Item>
			<LableRequired>
				Root Cause
			</LableRequired>
			<Form.Item name={'rootCause'} rules={[{ required: true, message: 'Please enter root cause' }]}>
				<BETextArea
					form={props.form}
					placeholder='Write here'
					rows={5}
					maxLength={500}
					count={{
						show: true,
						max: 500
					}}
				/>
			</Form.Item>
			<LableRequired>
				Contributory cause
			</LableRequired>
			<Form.Item name={'contributoryCause'} rules={[{ required: true, message: 'Please enter contributory cause' }]}>
				<BETextArea
					form={props.form}
					placeholder='Write here'
					rows={5}
					maxLength={500}
					count={{
						show: true,
						max: 500
					}}
				/>
			</Form.Item>
		</Form>
	);
}
