import type { StatusType } from "../Types/userTypes";
import { CommonState } from "../Types/commonTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: CommonState = {
    showWelcomePage: false,
    selectedRow: {},
    showEventsMenu: false,
    menuBar: {
        activeMenuItem: 0, // sidebar/menubar
        collapsed: false,
    },
    onboardingStage: {
        stage: 0,
        status: 'idle'
    },
    menuCollapsed: true,
    menuClicks: 0,
    homePage: {
        currentBusinessUnit: 0, // if both are zero then we are inside company 
        currentBusinessGroup: 0, // when assigning business unit make business group zero and vice versa
    },
    notification: {
        status: 'idle',
        data: []
    },
    version: {
        number: '',
        date: ''
    },
    financialYears: {
        data: [],
        status: 'idle'
    },
    quickSightDashboard: {
        data: {},
        status: 'idle'
    }
};


export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setSelectedRow: (state, action) => {   //*********used in table row selection ******/
            state.selectedRow = action.payload;
        },
        setSelectedMenuItem: (state, action) => {
            state.menuBar.activeMenuItem = action.payload
        },
        setOnboardingStage: (state, action) => {
            state.onboardingStage = action.payload
        },
        setShowEventsMenu: (state, action) => {
            state.showEventsMenu = action.payload
        },
        setMenuCollapsed: (state, action) => {
            state.menuCollapsed = action.payload
        },
        increaseMenuClicks: (state) => {
            state.menuClicks = state.menuClicks + 1
        },
        setCurrentBusinessUnit: (state, action) => {
            state.homePage.currentBusinessUnit = action.payload
            localStorage.setItem('currentBusinessUnit', action.payload)
        },
        setCurrentBusinessGroup: (state, action) => {
            state.homePage.currentBusinessGroup = action.payload
            localStorage.setItem('currentBusinessGroup', action.payload)
        },
        setNotification: (state, action) => {
            state.notification = action.payload
        },
        setNotificationRead: (state, action) => {
           let Notificationid = state.notification?.data.findIndex((item:any)=>item.id===action.payload.id)
           if(Notificationid === -1) return
           state.notification.data[Notificationid].read = action.payload.read
        }
        ,
        setShowWelcomePage: (state, action) => {
            state.showWelcomePage = action.payload
        },
        setVersion: (state, action) => {
            state.version = action.payload
        },
        setFinancialYears: (state, action) => {
            state.financialYears.status = action.payload?.status
            state.financialYears.data = action.payload?.data.filter((item: any) => item !== 0)
        },
        addFinancialYear: (state, action) => {
            state.financialYears?.data.push(action.payload)
        },
        setQuickSightDashboard: (state, action) => {
            state.quickSightDashboard = action.payload
        }
    }
});

export const {
    setSelectedRow,
    setSelectedMenuItem,
    setOnboardingStage,
    setMenuCollapsed,
    increaseMenuClicks,
    setCurrentBusinessGroup,
    setCurrentBusinessUnit,
    setNotification,
    setNotificationRead,
    setShowWelcomePage,
    setVersion,
    setShowEventsMenu,
    setFinancialYears,
    addFinancialYear,
    setQuickSightDashboard
} = userSlice.actions;


const commonReducer: Reducer<CommonState> = userSlice.reducer;

export default commonReducer;
