import API from "../../API";

export const ADD_USER = async (data: any) => {
  return await API.POST("/add-user-account/", data);
};

export const EDIT_USER = async (data: any,id:number) => {
  return await API.PATCH(`/add-user-account/${id}/`, data);
};

export const DELETE_USER = async (id: any) => {
  return await API.DELETE(`/add-user-account/${id}/`);
}

export const GET_ALL_USERS = async () => {
    return await API.GET("/add-user-account/");
}

export const USERS_BULK_UPLOAD = async (data: any) => {
  return await API.POST("/user-bulk-upload/?module=ESG", data, true);
}