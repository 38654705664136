import * as React from 'react';
import { BEDrawerType2 } from '../../../../../Components/BEDrawer/BEDrawerType2';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BESectionDropDown } from '../../../../../Components/BESectionDropDown';
import { RightSectionEditTemplate } from './RightBody';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { createTemplateTopics, getAllTemplateDrafts, getTopicsByFramework, patchTemplateDraft } from '../../../Actions/template';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { setSelectedTemplateDimension, setSelectedTemplateSection, setSelectedTemplateTopics } from '../../../../../Redux/ReportingReducer';
import { filterDataForLeftSection } from '../../../Actions/filterActions';
import { BEMessage } from '../../../../../Components/BEMessage';
import { Frameworks } from '../../../../../data/frameworks';
import ToolTip from '../../../../../Components/BEToolTip';
import { Dropdown, MenuProps } from 'antd';


export interface IEditTemplateProps {
    open: boolean;
    setOpen: Function;
}

export function EditTemplate(props: IEditTemplateProps) {
    const dispatch = useAppDispatch();
    const currentTemplate = useTypedSelector(state => state.reporting.currentTemplate);
    const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
    const templateFrameworkTopics = useTypedSelector(state => state.reporting.templateFrameworkTopics);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const selectedTemplateSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const selectedTemplateDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
    const selectedIndustry = useTypedSelector(state => state.reporting.selectedIndustry);
    const selectedTemplateTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const [leftData, setLeftData] = React.useState<any[]>([]);
    const [load, setLoad] = React.useState(false);
    const [loadingMain, setLoadingMain] = React.useState(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'ALL Sections',
            onClick: () => {
                let dataToSet = templateFrameworkTopics
                dispatch(setSelectedTemplateTopics(dataToSet));
            }
        },
        {
            key: '2',
            label: 'Current Category',
            onClick: () => {
                const selectedIds = selectedTemplateTopics?.data.map((item) => item.id);
                console.log('selectedIds', selectedIds,'selectedTemplateSection', selectedTemplateSection, 'selectedTemplateDimension', selectedTemplateDimension, 'selectedIndustry', selectedIndustry);
                const tempTableData = templateFrameworkTopics?.data.filter((item) =>
                    (
                      item.section == selectedTemplateSection &&
                      (item.dimension == selectedTemplateDimension || !selectedTemplateDimension) &&
                      (selectedIndustry === 'All' || !item?.hasOwnProperty('industry') || item?.industry === selectedIndustry) 
                    //   !selectedIds.includes(item.id)
                    ));
                console.log('tempTableData', tempTableData);
               const dataToSet = [...selectedTemplateTopics?.data, ...tempTableData];
                dispatch(setSelectedTemplateTopics({
                    data: dataToSet,
                    status: 'success',
                }));
            }
        },
    ]

    const getTopicsByFrameworkFunc = async () => {
        setLoadingMain(true);
        let res = await dispatch(getTopicsByFramework(currentTemplate?.framework!));
        if (Frameworks?.find((item) => item.code === currentTemplate?.framework)?.allSelected)
            dispatch(setSelectedTemplateTopics({
                data: currentTemplate?.framework === 'BRSR' ? res?.filter(
                    (item: any) => item.category !== 'Leadership Indicators'
                ) : res,
                status: 'success',
            }));
        setLoadingMain(false);
    }

    const getTopicsByFrameworkFuncForLeftSection = async () => {
        if (templateFrameworkTopics?.status === 'success') {
            const dataToUse = await dispatch(filterDataForLeftSection(templateFrameworkTopics?.data));
            const sortedData = dataToUse.sort((a, b) => a.section.localeCompare(b.section));
            setLeftData(sortedData);
            // dispatch(setSelectedTemplateSection(dataToUse?.length ? dataToUse[0]?.section : null));
        }
    }

    React.useEffect(() => {
        if (currentTemplate) {
            dispatch(setSelectedTemplateDimension(null));
            dispatch(setSelectedTemplateSection(null));
            dispatch(setSelectedTemplateTopics({
                data: [],
                status: 'idle',
            }));
            getTopicsByFrameworkFunc();
        }
    }, [currentTemplate, props.open, currentGRESBSection]);

    React.useEffect(() => {
        getTopicsByFrameworkFuncForLeftSection();
    }, [templateFrameworkTopics, props.open, currentGRESBSection]);

    React.useEffect(() => {
        if(leftData?.length > 0 && leftData[0]?.subSections?.length > 0)
        dispatch(setSelectedTemplateDimension(leftData[0]?.subSections[0!]?.name));
    }, [leftData]);

    const handleCreateTemplate = async () => {
        setLoad(true);
        if (selectedTopics?.data?.length === 0) {
            BEMessage.error('Please select atleast one topic to create template');
            setLoad(false);
            return;
        }
        const dataToSend = selectedTopics?.data.map((item) => {
            return { ...item, topic_id: item.id, template: currentTemplate?.id ,fy:(item.fy && item.fy!== "nan")? Number(item.fy) :0}
        });
        // send in the batch of 200
        // await dispatch(createTemplateTopics(dataToSend.slice(-100, -1)));
        let i, j, temparray: any = [], chunk = 100, dataToDispatch: any = [], noError = true;
        for (i = 0, j = dataToSend?.length; i < j; i += chunk) {
            temparray = dataToSend.slice(i, i + chunk);
            dataToDispatch.push(temparray);
        }
        await Promise.all([...dataToDispatch.map(async (item: any) => {
            noError = await dispatch(createTemplateTopics(item)) && noError;
        })]);
        await dispatch(getAllTemplateDrafts());
        await dispatch(patchTemplateDraft(currentTemplate?.id, { topicsChosen: true, stage: 2 }));
        noError ? BEMessage.success('Topics added successfully & Template is created') : BEMessage.error('Error in adding all topics');
        setLoad(false);
        props.setOpen(false);
    }


    return (
        <BEDrawerType2
            secondryHeaderRight={
                // <ToolTip title='On Clicking This, all the topics will be selected' >
                   <Dropdown menu = {{items}} >
                        <BEButton 
                        // onClick={() => {
                        //     let dataToSet = templateFrameworkTopics
                        //     console.log(dataToSet);
                        //     dispatch(setSelectedTemplateTopics(dataToSet));
                        // }} 
                        className='primary'>Select All</BEButton>
                   </Dropdown>
                // </ToolTip>
            }
            loading={loadingMain}
            open={props.open}
            setOpen={props.setOpen}
            heading='Topic selection - Step 3 of 3'
            secondaryHeading='Select Topics'
            secondarySubHeading='Select topics, Industry and Materiality are enhancements to be applied here'
            leftFooter={
                <div style={{ display: "flex", gap: "1rem" }}>
                    <BEButton
                        loading={load}
                        className='primary'
                        onClick={async () => {
                            await handleCreateTemplate();
                        }}
                    >
                        Create Template
                    </BEButton>
                    <BEButton onClick={() => props.setOpen(false)}>
                        Cancel
                    </BEButton>
                </div>
            }

            leftBody={
                <BESectionDropDown data={leftData} />
            }
            rightBody={
                <RightSectionEditTemplate />
            }
        />
    );
}
