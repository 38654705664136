import type { StatusType } from "../Types/userTypes";
import { DataManagerState } from "../Types/dataManagerTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";
import { getFYByDate } from "../../Config/Functions/UsefullFunctions";
import dayjs from "dayjs";


const initialState: DataManagerState = {
    metrics: {
        dateSlicer: {
            // currentYear: Number(localStorage.getItem('currentYear')) || getFYByDate(dayjs().format('YYYY-MM-DD'), '2024-02-01'),
            currentYear:0,
            currentMonth: 0,
        },
        tableDataDM:{},
        tableDerivedMetricsDataDM:{
            status: 'idle',
            data: {}
        },
        tableFullYearDerivedMetricsDataDM:{
            status: 'idle',
            data: {}
        },
        fullYearTableDataDM:{},
        metricStatus: {
            status: 'idle',
            data: []
        },
        searchTabMetrics:{
            tabsValue: "All", // will be "All" by default
            data: [],
            dropDownMetric: null
        },
        myDashboardMetrics: {
            status: 'idle',
            data: []
        },
        myDerivedDashboardMetrics: {
            status: 'idle',
            data: []
        },
        omittedMetrics: {
            status: 'idle',
            data: []
        }
    },
    logs:{
        filteredLogs:{
            status: 'idle',
            data: []
        },
    },
    labels: {
        status: 'idle',
        data: []
    },
    pillars: {},
    filter:{
        TopicStatus:'All',
        MetricTypeDM:'All',
        DepartmentTypeDM:'All',
    },
    assignedDepartmentByCategory: {
        status: 'idle',
        data: []
    },
    contributorsByMetrics: {
        status: 'idle',
        data: []
    },
    currentMyMetricDataBulkUploadFileId: null,
    myMetricDataBulkUploadFiles: {
        status: 'idle',
        data: []
    }
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setDateSlicerMonthDM: (state, action) => {
            state.metrics.dateSlicer.currentMonth = action.payload;
        },
        setDateSlicerYearDM: (state, action) => {
            state.metrics.dateSlicer.currentYear = action.payload;
            localStorage.setItem('currentYear', action.payload);
        },
        settableDataDM: (state, action) => {
            state.metrics.tableDataDM = action.payload;
        },
        setTableDerivedMetricsDataDM: (state, action) => {
            state.metrics.tableDerivedMetricsDataDM = action.payload;
        },
        setFullYearDerivedMetricsDataDM: (state, action) => {
            state.metrics.tableFullYearDerivedMetricsDataDM = action.payload;
        },
        setFullYearTableDataDM: (state, action) => {
            state.metrics.fullYearTableDataDM = action.payload;
        },
        setMetricStatusDM: (state, action) => {
            state.metrics.metricStatus = action.payload;
        },
        setSearchTabMetrics: (state, action) => {
            state.metrics.searchTabMetrics = action.payload;
        },
        setMyDashboardMetrics: (state, action) => {
            state.metrics.myDashboardMetrics = action.payload;
        },
        setMyDerivedDashboardMetrics: (state, action) => {
            state.metrics.myDerivedDashboardMetrics = action.payload;
        },
        setFilteredLogsDM: (state, action) => {
            state.logs.filteredLogs = action.payload;
        },
        setPillarsDM: (state, action) => {
            state.pillars = action.payload;
        },
        setCurrentTopicStatusDM: (state, action) => {
            state.filter.TopicStatus = action.payload;
        },
        setCurrentMetricTypeDM: (state, action) => {
            state.filter.MetricTypeDM = action.payload;
        },
        setCurrentDepartmentDM: (state, action) => {
            state.filter.DepartmentTypeDM = action.payload;
        },
        setAssignedDepartmentByCategory: (state, action) => {
            state.assignedDepartmentByCategory = action.payload;
        },
        setOmittedMetrics: (state, action) => {
            state.metrics.omittedMetrics = action.payload;
        },
        setLabels: (state, action) => {
            state.labels = action.payload;
        },
        setContributorsByMetrics: (state, action) => {
            state.contributorsByMetrics = action.payload;
        },
        setMyMetricDataBulkUploadFiles: (state, action) => {
            state.myMetricDataBulkUploadFiles = action.payload;
        },
        setCurrentMyMetricDataBulkUploadFileId: (state, action) => {
            state.currentMyMetricDataBulkUploadFileId = action.payload;
        },
        //this is a function to replace the dashboard graph positions
        setDashboardGraphPositions: (state, action) => {
            let tempGraphMetrics:any = [...state.metrics.myDashboardMetrics.data]
            let tempDerivedGraphMetrics:any = [...state.metrics.myDerivedDashboardMetrics.data]

            action.payload.forEach((item:any) => {
                if(item.isDerived){
                    let index = tempDerivedGraphMetrics.findIndex((metric:any) => metric.id ===  item.id);
                    tempDerivedGraphMetrics[index].position_graph = item.position_graph;
                }
                else{
                    let index = tempGraphMetrics.findIndex((metric:any) => metric.id ===  item.id);
                    tempGraphMetrics[index].position_graph = item.position_graph;
                }
            })
            state.metrics.myDashboardMetrics.data = tempGraphMetrics;
            state.metrics.myDerivedDashboardMetrics.data = tempDerivedGraphMetrics;
        },
    }
});

export const {
    setDateSlicerMonthDM,
    setDateSlicerYearDM,
    settableDataDM,
    setTableDerivedMetricsDataDM,
    setFullYearTableDataDM,
    setMetricStatusDM,
    setFullYearDerivedMetricsDataDM,
    setSearchTabMetrics,
    setFilteredLogsDM,
    setMyDashboardMetrics,
    setMyDerivedDashboardMetrics,
    setPillarsDM,
    setCurrentTopicStatusDM,
    setAssignedDepartmentByCategory,
    setCurrentMetricTypeDM,
    setCurrentDepartmentDM,
    setContributorsByMetrics,
    setMyMetricDataBulkUploadFiles,
    setCurrentMyMetricDataBulkUploadFileId,
    setLabels,
    setOmittedMetrics,
    setDashboardGraphPositions
} = userSlice.actions;

const DataManagerReducer: Reducer<DataManagerState> = userSlice.reducer;

export default DataManagerReducer;
