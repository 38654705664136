import * as React from 'react';
import './style.scss';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getAllEmissionCalculations, getScope3EmissionCalculations } from '../../../Emissions/Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { BEWrapper } from '../../../../Components/BEWrapper';
import { HorizontalStackBar } from '../../Components/HorizontalStackBar';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { HorizontalBarChart } from '../../../../Components/BECharts/HorizontalBarChart';
import { DashboardEmissionsBySource } from './SourceEmissions';
import { DashboardEmissionsByScope } from './ScopeEmissions';
import { DashboardEmissionsByBuBg } from './BuBgEmissions';
import { DashboaredDataAndTargetCards } from './DataAndTargetCards';
import { generatePDF, getMyDashboardComparisionAnalytics } from '../../Actions';
import { AddTrends } from '../Drawers/AddTrends';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { ToggleButton } from '../../Components/ToggleButton';
import { setScope2EmissionType } from '../../../../Redux/EmmisionsReducer';
import { DashboardMonthlySourceEmissions } from './SourceMonthlyEmissions';

export interface IDashboardOverviewProps {
}

export function DashboardOverview (props: IDashboardOverviewProps) {
  const dispatch = useAppDispatch();
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    
  const scope1Emissions = useTypedSelector((state) => state.emissions.emissionsCalculations.scope1Calculations);
  const scope2Emissions = useTypedSelector((state) => state.emissions.emissionsCalculations.scope2Calculations);
  const scope3Emissions = useTypedSelector((state) => state.emissions.emissionsCalculations.scope3Calculations);

  //these emissions are filtered by month and bu/bg
  const scope1FilteredEmissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const scope2FilteredEmissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const scope3FilteredEmissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope3Calculations);
  
  const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType);
  const [openAddTrends, setOpenAddTrends] = React.useState(false);
  const [loadingDownloadPDF, setLoadingDownloadPDF] = React.useState(false);

  if(scope1Emissions.status === 'loading' || scope2Emissions.status === 'loading' || scope3Emissions.status === 'loading') 
    return <GlobalLoader height='80vh'/>

  return (
    <div className='dashboard-overview'>
      <div className='dashboard-overview-header'>
        <ToggleButton
           value={scope2EmissionType === 2} 
           onChange={(value:boolean) => dispatch(setScope2EmissionType(value ? 2 : 1))}
           text={{left: 'Location based', right: 'Market based'}}
        />
        <BEButton 
        style={{marginLeft: 'auto'}}
          loading={loadingDownloadPDF}
          onClick={async() =>dispatch(
            generatePDF(setLoadingDownloadPDF, 'dashboard-container')
          )} 
          size='large' className='primary'>
           Download PDF
         </BEButton>
         <BEButton onClick={() => setOpenAddTrends(true)} size='large' className='primary'>Add Trends +</BEButton>
      </div>
        <div id='dashboard-container'>
          <DashboaredDataAndTargetCards/>
          <br/><br/>
          <BEWrapper type={2} title='Total GHG emissions'>
            <HorizontalStackBar unit='tCO2e'
              data={[
                {name: 'Scope 1', value: scope1FilteredEmissions.data.reduce((acc, item) => acc + item.tco2e, 0)},
                {name: 'Scope 2', value: scope2FilteredEmissions.data.reduce((acc, item) => acc + item.tco2e, 0)},
                {name: 'Scope 3', value: scope3FilteredEmissions.data.reduce((acc, item) => acc + item.tco2e, 0)}
              ]}
            />
          </BEWrapper>
            
          <br/><br/>
          <DashboardEmissionsBySource/>
          <br/><br/>
          <DashboardMonthlySourceEmissions/>
          <br/><br/>
          <DashboardEmissionsByScope/>
          <br/><br/>
          <DashboardEmissionsByBuBg/>
          <br/><br/>
          <AddTrends addCards open={openAddTrends} setOpen={() => setOpenAddTrends(false)}/>
        </div>

        {/* data set functions */}
    </div>
  );
}
