import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import { HorizontalBarChart } from '../../../../../Components/BECharts/HorizontalBarChart';
import { AddCommaToNumber, changeSnakeCaseToSentence, showFYFormatByYear } from '../../../../../Config/Functions/UsefullFunctions';
import { Select } from 'antd';
import { setCurrentAnalyticsCompareYear, setCurrentAnalyticsSource } from '../../../../../Redux/DashboardReducer';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { ComparisonCard } from '../../../Components/ComparisonCard';
import { shortMonths } from '../../../../../Config/Data/genralData';
import { getMyDashboardComparisionAnalytics } from '../../../Actions';
import TrackerIcon from '../../../../../Components/BEIcons/TrackerIcon';
import { getEmissionCategoryNameToShow } from '../../../../Emissions/Data/data';

export interface IDashboardEmissionsBySourceProps {
}

export function DashboardEmissionsBySource (props: IDashboardEmissionsBySourceProps) {
  const dispatch = useAppDispatch();
  const [sourceEmissionsData, setSourceEmissionsData] = React.useState<any>([])
  const scope1FilteredEmissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const scope2FilteredEmissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const scope3FilteredEmissions = useTypedSelector((state) => state.emissions.filteredEmissionCalculations.scope3Calculations);
  const currentAnalyticsSource = useTypedSelector(state => state.dashboard.currentAnalyticsSource);
  const compareAnalytics = useTypedSelector(state => state.dashboard.compareAnalytics);
  const recentYears = useTypedSelector(state => state.common.financialYears?.data)
  const currentAnalyticsCompareYear = useTypedSelector(state => state.dashboard.currentAnalyticsCompareYear);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const monthIndex = new Date().getMonth();
  const month = shortMonths[monthIndex];
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [nameCodeMap, setNameCodeMap] = React.useState<any>({})
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType);
  
  
  React.useEffect(() => {
    if(!currentYear) return;
    const comparisionYear = currentAnalyticsCompareYear ? currentAnalyticsCompareYear : currentYear - 1;
    dispatch(getMyDashboardComparisionAnalytics(currentYear,comparisionYear,currentAnalyticsSource?.scope, currentAnalyticsSource?.code));
  }, [currentBusinessUnit, currentAnalyticsSource ,currentYear, currentAnalyticsCompareYear,currentBusinessGroup,scope2EmissionType ])
  
  React.useEffect(() => {
    if(scope1FilteredEmissions.status !== 'success' || scope2FilteredEmissions.status !== 'success' || scope3FilteredEmissions.status !== 'success') return;
    let tempDat:any = {}
    let tempMap:any = {}
    const combinedData = [...scope1FilteredEmissions.data, ...scope2FilteredEmissions.data, ...scope3FilteredEmissions.data];
    combinedData.forEach((item) => {
      // if(!item.emission_category) return;
      if(tempDat[item.emission_category]) tempDat[item.emission_category].tco2e += item.tco2e;
      else tempDat[item.emission_category] = {...item}
    })
    let tempArr = []
    for(let key in tempDat){
    
    if(!key || key === 'undefined') continue; // by shivang
    const _name = changeSnakeCaseToSentence(getEmissionCategoryNameToShow(key),false,true)
    tempArr.push({
      name: _name,
      value: tempDat[key].tco2e
    })
    tempMap[_name] = {
      scope: Number(tempDat[key].scope.split(' ')[1]),
      name: tempDat[key].exact_name,
      code: tempDat[key].derived_bcode ? tempDat[key].derived_bcode : tempDat[key].method ? Math.round(tempDat[key].method/100) : tempDat[key].emission_category
    }
  }
  
    //only top 5
    setSourceEmissionsData(tempArr.sort((a,b) => b.value - a.value))
    setNameCodeMap(tempMap)
  }, [scope1FilteredEmissions, scope2FilteredEmissions, scope3FilteredEmissions])

  return (
    <div>
      <BEWrapper type={2} title='GHG Emissions by Source' icon={<TrackerIcon inheritSize stroke={'black'}/>}>
        <div className='dashboard-emissions-by-source'>
          <HorizontalBarChart
            tooltipFormatter={(value:any) => `${AddCommaToNumber(value,false,true,true)} tCO2e`}
            onClick={(name) => dispatch(setCurrentAnalyticsSource({
              name: nameCodeMap[name] ? nameCodeMap[name].name : null,
              code: nameCodeMap[name] ? nameCodeMap[name].code : null,
              scope: nameCodeMap[name] ? nameCodeMap[name].scope : null
            }))}
           type={1} data={sourceEmissionsData} isPercentage
          />
        
          <BEWrapper>
              <div className='dashboard-emissions-by-source-details'>
                <div className='year-selector'>
                  <p>Compare with:</p>
                  <Select
                    placeholder='Select year'
                    style={{width: 200}}
                    value={currentAnalyticsCompareYear ? currentAnalyticsCompareYear : currentYear - 1}
                    onChange={(value) => dispatch(setCurrentAnalyticsCompareYear(value))}
                    >
                    {recentYears?.filter((item:any) => item !== currentYear).
                    map((item:any) => <Select.Option key={item} value={item}>{showFYFormatByYear(item,yearEnd || '12-10' )}</Select.Option>)}
                  </Select>
                </div>
                  {
                    compareAnalytics.status === 'loading' ? <GlobalLoader height='10rem'/> :
                      <ComparisonCard 
                          data={[
                            {
                              title:'Compared to last year', 
                              value:compareAnalytics.data?.current_year_data, 
                              previousValue:compareAnalytics.data?.comparison_year_data
                            },
                            {
                              title:'Average monthly emissions',
                              value:compareAnalytics.data?.average_monthly_data,
                            }
                          ]}
                          unit='tCO2e'
                        />
                  }
              </div>
          </BEWrapper>
        </div>
      </BEWrapper>
    </div>
  );
}
