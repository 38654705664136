import * as React from 'react';
import { ESGScore } from './ESGScore';
import { ReportsStatus } from './ReportsStatus';
import { AlertMessages } from './AlertMessages';
import './style.scss';

interface IDashboardInsightsProps {
}

export function DashboardInsights(props: IDashboardInsightsProps) {
	return (
		<>
			<br />
			<ESGScore />
			<br />
			<div className='insights-container'>
				<div style={{ flex: 1 }}>
					<ReportsStatus />
				</div>
				<div style={{ flex: 1 }}>
					<AlertMessages />
				</div>
			</div>
			<br />
		</>
	)
}
