import * as React from 'react';
import { useAppDispatch } from '../Config/Hooks/useAppDispatch';
import { BEButton } from '../Components/BEFormItems/BEButton';

import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import ReactDOM from 'react-dom';
import { ApproveReport } from '../Features/Reporting/Views/ApproveReport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import StackedColumnCharts from '../Components/BECharts/StackedColumnCharts';
import { HorizontalStackBar } from '../Features/Dashboard/Components/HorizontalStackBar';
import { BEWrapper } from '../Components/BEWrapper';
import { ComparisonCard } from '../Features/Dashboard/Components/ComparisonCard';
import { DashboardMetricCard } from '../Features/Dashboard/Components/DashboardMetricCard';

export interface ITestComponentProps {
}

const DownloadComponent: React.FC<any> = (props) => {
  return (
    <div>
      <h1>Download Component</h1>
      <BEButton className='primary'>Downloaded with css</BEButton>
    </div>
  );
}

const xAxisLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; 
const data = [
  {title:'Compared to last month',value:55, change: 5},  
  {title:'Compared to last year',value:44, change: -5},
  {title:'Compared to 2022 JUN vs 2023 JUN',value:23, change: 3},
]


export function TestComponent(props: ITestComponentProps) {
  const dispatch = useAppDispatch();

  //approve-report-box


  return (
    <div style={{width:'20%'}}>

    </div>
  );
}
