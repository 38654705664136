import * as React from 'react';
import { PrimaryTheme } from '../../Config/Theme/theames';
import './style.scss';
import InfoIcon from '../BEIcons/InfoIcon';
import { BEButton } from '../BEFormItems/BEButton';

export interface IBENoDataProps {
  onClick?: Function;
  description?: string;
  size?:'small'|'middle'|'large'; //by default medium
  buttonLabel?:string;
  icon?:any
}

export function BENoData(props: IBENoDataProps) {
  return (
    <div className={'be-no-data ' + props.size }>
      <div className='empty-icon'>
        <div className='icon-box'>{props.icon ? props.icon :<InfoIcon fill={PrimaryTheme.primaryGreenDark} inheritSize/>}</div>
      </div>
      <div className='description'>
        <span >{props.description}</span>
        
      </div>
      <BEButton size={props.size || 'middle'} className='primary' onClick={()=>props.onClick && props.onClick()}>
        {props.buttonLabel || 'Add Data'}
      </BEButton>
    </div>
  );
}
